import * as Yup from 'yup'

const BlogCategoryFormValidations = () => {

  return Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    about: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('About is required')
  })
  
}

export {BlogCategoryFormValidations}
