import { FC, useEffect, useState } from 'react'
import ShopOrderApi from '../../../../infrastructure/api/ShopOrderApi'
import { IShopOrderModel } from '../../../../models/responses/ShopOrderModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const ShopOrderListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [shopOrders, setShopOrders] =
    useState<PagedResponseModel<IShopOrderModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getShopOrders() {
      var response = await ShopOrderApi.GetShopOrders(paginationFilter)
      setShopOrders(response.data)
    }
    getShopOrders()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Shop Orders'
        data={shopOrders?.items}
        currentTotalRecords={shopOrders?.totalRecords!!}
        currentPageNumber={shopOrders?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          { label: 'UserName', name: '' },
          { label: 'Edupoint', name: 'edupoint' },
          { label: 'Status', name: 'status' },
          {
            label: 'Name',
            name: 'shopProductName',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
              sort: false
            },
          },
          { label: 'Date', name: 'date' },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={shopOrders?.items[dataIndex]?.id!!}
                    itemParam={shopOrders?.items[dataIndex]?.shopProductName!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { ShopOrderListModal }
