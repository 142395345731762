import { FC, Fragment, useEffect, useState } from 'react'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import { ICompanyModel } from '../../../../models/responses/CompanyModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

type Props = {
  onClickItem: (itemId: string, itemName: string, type: string) => void
  refresh: number
}

const CompanyListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [companies, setCompanies] = useState<PagedResponseModel<ICompanyModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getCompanies() {
      var response = await CompanyApi.GetCompanies(paginationFilter)
      setCompanies(response.data)
    }
    getCompanies()
  }, [refresh, pageNumber, pageSize, searchText, sort])
  const generateRow = (subCompanies: ICompanyModel[]) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={1}></td>
          <td colSpan={5} style={{ width: '100%' }}>
            {subCompanies.length > 0 ?

              <TableContainer component={Paper} className={"mt-5"}>
                <Table padding={'default'} size={'small'} aria-label="sticky table">
                  <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Logo</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>#</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subCompanies.map((row, index) => {
                      return (
                        <Fragment key={row.id}>
                          <TableRow>
                            <TableCell colSpan={5} style={{ width: '100%', border: 'none' }}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell><img src={`${process.env.REACT_APP_CDN_URL}images/company/${row.logo}`} width={80} /></TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>{row.title}</TableCell>
                                    <TableCell>
                                      <TableHelperButtons
                                        itemId={row.id}
                                        itemParam={row.name}
                                        types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                                        onClickItem={onClickItem}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  {row.subCompanies.length > 0 &&
                                    <TableRow>
                                      <TableCell colSpan={5} style={{ width: '100%' }}>
                                        <Fragment>{generateRow(row.subCompanies)}</Fragment>
                                      </TableCell>
                                    </TableRow>
                                  }
                                </TableBody>
                              </Table>
                            </TableCell>

                          </TableRow>

                        </Fragment>
                      )
                    }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-5">
                <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                  <span>No other companies affiliated with this company were found.</span>
                </div>
              </div>

            }
          </td>
        </tr>
      </Fragment >

    )
  }

  const options = {
    rowsPerPage: pageSize,
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      return (
        generateRow(companies?.items[rowMeta.rowIndex].subCompanies!!)
      );
    },
  };

  return (
    <>
      <GenericTable
        title='Companies'
        data={companies?.items}
        currentTotalRecords={companies?.totalRecords!!}
        currentPageNumber={companies?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
            },
          },
          {
            label: 'Logo',
            name: 'logo',
            options: {
              customBodyRender: (value) => {
                return <img src={`${process.env.REACT_APP_CDN_URL}images/company/${value}`} width={80} />
              },
            },
          },
          {
            label: 'Name',
            name: 'name',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          { label: 'Title', name: 'title' },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={companies?.items[dataIndex]?.id!!}
                    itemParam={companies?.items[dataIndex]?.name!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={options}
        /* customOptions={{
           customTableBodyFooterRender: (opts) => {
             return <div></div>
           },
         }}*/
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { CompanyListModal }
