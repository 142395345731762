import * as Yup from 'yup'

const GuideFormValidations = () => {
  return Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 150 symbols')
      .required('Name is required')
  })
}

export { GuideFormValidations }
