import { FC, useEffect, useState } from 'react'
import PresentationApi from '../../../../infrastructure/api/PresentationApi'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IPresentationModel, presentationInitValues } from '../../../../models/responses/PresentationModel'
import { IPresentationRequestModel } from '../../../../models/requests/PresentationRequestModel'
import { PresentationFormValidations } from '../../../validations/PresentationFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import moment from 'moment'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { slugify } from '../../../helpers/StringHelper'
import FileApi from '../../../../infrastructure/api/FileApi'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'


type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const PresentationFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [presentation, setPresentation] = useState<IPresentationModel>(presentationInitValues)
  const updatePresentation = (fieldsToUpdate: Partial<IPresentationModel>) => {
    const updatedData = { ...presentation, ...fieldsToUpdate }
    setPresentation(updatedData)
  }

  const [loadFile, setLoadFile] = useState<any>(undefined)

  const formik = useFormik<IPresentationModel>({
    initialValues: presentation,
    enableReinitialize: true,
    validationSchema: PresentationFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IPresentationRequestModel = {
          id: values.id,
          name: values.name,
          link: values.link == "" ? slugify(values.name) : values.link,
          file: values.file
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IPresentationRequestModel) {
    try {
      if (loadFile != undefined) {
        let d = new Date();
        const fileName = slugify(`${model.name}-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelPicture = new FormData()
        modelPicture.append('name', fileName)
        modelPicture.append('file', loadFile)
        modelPicture.append('type', FileTypeEnum.Presentation)
        await FileApi.AddFile(modelPicture);

        model.file = (fileName + "." + loadFile.name.split('.').pop())
      }
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await PresentationApi.AddPresentation(model)
      else {
        result = await PresentationApi.UpdatePresentation(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IPresentationModel
    if (itemId > 0) {
      var response = await PresentationApi.GetPresentation(itemId)
      result = response.data
    } else {
      result = presentationInitValues
    }
    setPresentation(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeFile = (event: any) => {
    setLoadFile(event.target.files[0])
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Presentation</h1>
              <div className='text-muted fw-bold fs-5'>
                Presentation Management <small>Educall</small>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder required'>Name</label>
                  <input
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder required'>Link</label>
                  <input
                    {...formik.getFieldProps('link')}
                    type='text'
                    name='link'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.link} error={formik.errors.link} />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder required'>File</label>

                  <input
                    {...formik.getFieldProps('file')}
                    type="file"
                    value={undefined}
                    className='form-control form-control-solid'
                    onChange={(event) => handleChangeFile(event)} accept=".png, .jpg, .jpeg"
                  />

                  <FormikValidationError touched={formik.touched.file} error={formik.errors.file} />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { PresentationFormModal }
