import { FC, useEffect, useState } from 'react'
import PopupApi from '../../../../infrastructure/api/PopupApi'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IPopupModel, popupInitValues } from '../../../../models/responses/PopupModel'
import { IPopupRequestModel } from '../../../../models/requests/PopupRequestModel'
import { PopupFormValidations } from '../../../validations/PopupFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { PopupTypeEnum } from '../../../../enums/PopupTypeEnum'
import { PopupWhenTypeEnum } from '../../../../enums/PopupWhenTypeEnum'
import { PopupWhereEnum } from '../../../../enums/PopupWhereEnum'
import { PopupWhoseEnum } from '../../../../enums/PopupWhoseEnum'
import moment from 'moment'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { slugify } from '../../../helpers/StringHelper'
import FileApi from '../../../../infrastructure/api/FileApi'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'


type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const PopupFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [popup, setPopup] = useState<IPopupModel>(popupInitValues)
  const updatePopup = (fieldsToUpdate: Partial<IPopupModel>) => {
    const updatedData = { ...popup, ...fieldsToUpdate }
    setPopup(updatedData)
  }


  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(1, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
  }

  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };
  const typeList = EnumToList(PopupTypeEnum);
  const whenList = EnumToList(PopupWhenTypeEnum);
  const whereList = EnumToList(PopupWhereEnum);
  const whoseList = EnumToList(PopupWhoseEnum);

  const [selectedType, setSelectedType] = useState<SelectModel>(defaultSelect)
  const [selectedWhen, setSelectedWhen] = useState<SelectModel>(defaultSelect)
  const [selectedWhere, setSelectedWhere] = useState<SelectModel>(defaultSelect)
  const [selectedWhose, setSelectedWhose] = useState<SelectModel>(defaultSelect)

  const [loadFile, setLoadFile] = useState<any>(undefined)
  const [picture, setPicture] = useState('')

  let ranges = {
    "Today Only": [moment(), moment()],
    "Yesterday Only": [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "3 Days": [moment().subtract(3, "days"), moment()]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false
  }

  const formik = useFormik<IPopupModel>({
    initialValues: popup,
    enableReinitialize: true,
    validationSchema: PopupFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IPopupRequestModel = {
          id: values.id,
          title: values.title,
          url: values.url,
          message: values.message,
          popupType: parseInt(selectedType.value),
          popupWhenType: parseInt(selectedWhen.value),
          popupWhose: parseInt(selectedWhose.value),
          popupWhere: parseInt(selectedWhere.value),
          image: '',
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD")
        }
        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: IPopupRequestModel) {
    try {
      if (loadFile != undefined) {
        let d = new Date();
        const fileName = slugify(`${model.title}-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelPicture = new FormData()
        modelPicture.append('name', fileName)
        modelPicture.append('file', loadFile)
        modelPicture.append('type', FileTypeEnum.Popup)
        await FileApi.AddFile(modelPicture);

        model.image = (fileName + "." + loadFile.name.split('.').pop())
      }
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await PopupApi.AddPopup(model)
      else {
        result = await PopupApi.UpdatePopup(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IPopupModel
    if (itemId > 0) {
      var response = await PopupApi.GetPopup(itemId)
      result = response.data
      setStart(moment(result.startDate))
      setEnd(moment(result.endDate))
      handleChangeType(response.data.popupType ? typeList.find(i => i.value == response.data.popupType.toString()) : defaultSelect)
      handleChangeWhere(response.data.popupWhere ? whereList.find(i => i.value == response.data.popupType.toString()) : defaultSelect)
      handleChangeWhose(response.data.popupWhose ? whoseList.find(i => i.value == response.data.popupType.toString()) : defaultSelect)
      handleChangeWhen(response.data.popupWhenType ? whenList.find(i => i.value == response.data.popupWhenType.toString()) : defaultSelect)
    } else {
      result = popupInitValues
    }
    setPicture(result.image != '' ? `${process.env.REACT_APP_CDN_URL}images/popup/${result.image}` : toAbsoluteUrl('/media/misc/none.png'));
    setPopup(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeType = (e: any) => {
    setSelectedType(e);
  }

  const handleChangeWhose = (e: any) => {
    setSelectedWhose(e);
  }

  const handleChangeWhen = (e: any) => {
    setSelectedWhen(e);
  }

  const handleChangeWhere = (e: any) => {
    setSelectedWhere(e);
  }
  const handleChangeImage = (event: any) => {
    setLoadFile(event.target.files[0])
    setPicture(URL.createObjectURL(event.target.files[0]));
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Popup</h1>
              <div className='text-muted fw-bold fs-5'>
                Popup Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder'>When to see?</label>
                <Select
                  {...formik.getFieldProps('popupWhenType')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeWhen(event)}
                  value={selectedWhen}
                  options={whenList}
                />
                <FormikValidationError touched={formik.touched.popupWhenType} error={formik.errors.popupWhenType} />
              </div>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder'>Date Range</label>
                <DateTimeRangeContainer
                  ranges={ranges}
                  start={start}
                  style={{
                    darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                  }}
                  end={end}
                  local={local}
                  applyCallback={applyCallback}
                >
                  <input
                    {...formik.getFieldProps('dateRange')}
                    id="formControlsTextB"
                    className='form-control form-control-solid'
                    value={`${start.format(local.format)} - ${end.format(local.format)}`}
                    type="text"
                  />
                </DateTimeRangeContainer>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Popup Type</label>
                <Select
                  {...formik.getFieldProps('popupType')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeType(event)}
                  value={selectedType}
                  options={typeList}
                />
                <FormikValidationError touched={formik.touched.popupType} error={formik.errors.popupType} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Where to see?</label>
                <Select
                  {...formik.getFieldProps('popupWhere')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeWhere(event)}
                  value={selectedWhere}
                  options={whereList}
                />
                <FormikValidationError touched={formik.touched.popupWhere} error={formik.errors.popupWhere} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Who to see?</label>
                <Select
                  {...formik.getFieldProps('popupWhose')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeWhose(event)}
                  value={selectedWhose}
                  options={whoseList}
                />
                <FormikValidationError touched={formik.touched.popupWhose} error={formik.errors.popupWhose} />
              </div>
            </div>
            <div className='row'>

              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Picture</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`, minWidth: '100%' }}
                  >
                    <div
                      className='image-input-wrapper'
                      style={{ backgroundImage: `url(${picture})`, minWidth: '100%', backgroundSize: 'contain', backgroundPosition: 'center' }}
                    ></div>
                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                      <i className="fas fa-pencil-alt"></i>
                      <input
                        {...formik.getFieldProps('image')}
                        type="file"
                        value={undefined}
                        onChange={(event) => handleChangeImage(event)} accept=".png, .jpg, .jpeg"
                      />
                    </label>
                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel avatar">
                      <i className="bi bi-x fs-2"></i>
                    </span>
                  </div>
                  <FormikValidationError touched={formik.touched.image} error={formik.errors.image} />
                </div>

              </div>
              <div className='col-lg-8'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder required'>Title</label>
                  <input
                    {...formik.getFieldProps('title')}
                    type='text'
                    name='title'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>URL (Referral Link)</label>
                  <input
                    {...formik.getFieldProps('url')}
                    type='text'
                    name='url'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.url} error={formik.errors.url} />
                </div>
                <div className=''>
                  <div className='col-lg-12'>
                    <label className='form-label fw-bolder'>Message</label>
                    <input
                      {...formik.getFieldProps('message')}
                      type='text'
                      name='message'
                      className='form-control form-control-solid'
                      placeholder='Entry..'
                    />
                    <FormikValidationError touched={formik.touched.message} error={formik.errors.message} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { PopupFormModal }
