export interface IJobInterviewModel {
    id: string
    title: string
    companyId: string
    studentIds: []
}

const jobInterviewInitValues: IJobInterviewModel = {
    id: '',
    title: '',
    companyId: '',
    studentIds: []
}

export { jobInterviewInitValues }