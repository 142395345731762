import { FC, useEffect, useState } from 'react'
import BlogPostApi from '../../../../infrastructure/api/BlogPostApi'
import { IBlogPostModel, blogPostInitValues } from '../../../../models/responses/BlogPostModel'
import { useFormik } from 'formik'
import { IBlogPostRequestModel } from '../../../../models/requests/BlogPostRequestModel'
import { BlogPostFormValidations } from '../../../validations/BlogPostFormValidations'
import { PostTypeEnum } from '../../../../enums/PostTypeEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { useParams } from 'react-router'
import { SelectModel } from '../../../models/SelectModel'
import BlogCategoryApi from '../../../../infrastructure/api/BlogCategoryApi'
import Select from 'react-select';
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { slugify, tagify } from '../../../helpers/StringHelper'
import FileApi from '../../../../infrastructure/api/FileApi'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'

type Props = { itemId: number, show: boolean, handleClose: () => void }
const BlogPostFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const { id } = useParams<any>()
  itemId = id ? id : 0

  const [visibles, setVisibles] = useState<string[]>(['post', 'picture']);
  const [isActive, setActive] = useState<boolean>(false)

  const [blogPost, setBlogPost] = useState<IBlogPostModel>(blogPostInitValues)
  const updateBlogPost = (fieldsToUpdate: Partial<IBlogPostModel>) => {
    const updatedData = { ...blogPost, ...fieldsToUpdate }
    setBlogPost(updatedData)
  }

  const [loadFile, setLoadFile] = useState<any>(undefined)


  const [categories, setCategories] = useState<SelectModel[]>([])
  const [levels, setLevels] = useState<SelectModel[]>([])
  const languages = EnumToList(LanguageEnum);
  const postTypes = EnumToList(PostTypeEnum);
  const [selectedCategory, setSelectedCategory] = useState<SelectModel>()
  const [selectedLevel, setSelectedLevel] = useState<SelectModel>()
  const [selectedLanguage, setSelectedLanguage] = useState<SelectModel>()
  const [selectedPostType, setSelectedPostType] = useState<SelectModel>()

  const [post, setPost] = useState('')
  const [picture, setPicture] = useState('')

  const formik = useFormik<IBlogPostModel>({
    initialValues: blogPost!!,
    enableReinitialize: true,
    validationSchema: BlogPostFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IBlogPostRequestModel = {
          id: blogPost!!.id,
          blogCategoryId: parseInt(selectedCategory?.value!!),
          content: post,
          description: values.description,
          image: values.image,
          language: parseInt(selectedLanguage?.value!!),
          link: (values.link != '' ? values.link : slugify(values.name)),
          name: values.name,
          postType: parseInt(selectedPostType?.value!!),
          presentLevel: parseInt(selectedLevel?.value!!),
          quoteAuthor: values.quoteAuthor,
          socialShare: values.socialShare,
          tags: (values.tags != '' ? values.tags : tagify(values.name)),
          url: values.url,
          applicationUserId: values.id!!,
          isActive: isActive,
        }
        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: IBlogPostRequestModel) {
    try {
      if (loadFile != undefined) {
        let d = new Date();
        const fileName = slugify(`${model.name}-head-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelPicture = new FormData()
        modelPicture.append('name', fileName)
        modelPicture.append('file', loadFile)
        modelPicture.append('type', FileTypeEnum.BlogPost)
        await FileApi.AddFile(modelPicture);

        model.image = (fileName + "." + loadFile.name.split('.').pop())
      }
      var result = undefined
      if (model.id === undefined || model.id === 0) {
        result = await BlogPostApi.AddBlogPost(model)
      }
      else {
        result = await BlogPostApi.UpdateBlogPost(model)
      }

      setComplete(true)
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  async function init() {
    var response = await BlogCategoryApi.GetBlogCategoryDictionary()
    var dataCategories: SelectModel[] = [...response.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setCategories(dataCategories)

    var responseLevels = await LevelApi.GetLevelDictionary()
    var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setLevels(dataLevels)

    let result: IBlogPostModel
    if (itemId > 0) {
      const response = await BlogPostApi.GetBlogPost(itemId)
      result = response.data

      if (response.data.blogCategoryId) {
        handleChangeCategory({
          value: response.data.blogCategoryId.toString(),
          label: dataCategories.find(i => i.value == response.data.blogCategoryId.toString())?.label
        })
      }
      if (response.data.language) {
        handleChangeLanguage({
          value: response.data.language.toString(),
          label: languages.find(i => i.value == response.data.language.toString())?.label
        })
      }
      if (response.data.postType) {
        handleChangePostType({
          value: response.data.postType.toString(),
          label: postTypes.find(i => i.value == response.data.postType.toString())?.label
        })
      }
      if (response.data.presentLevel) {
        handleChangeLevel({
          value: response.data.presentLevel.toString(),
          label: dataLevels.find(i => i.value == response.data.presentLevel.toString())?.label
        })
      }
      setActive(response.data.isActive)
    }
    else {
      result = blogPostInitValues;
    }
    setPicture(result.image != '' ? `${process.env.REACT_APP_CDN_URL}images/post/${result.image}` : toAbsoluteUrl('/media/misc/none.png'));
    setBlogPost(result)
  }


  useEffect(() => {
    formik.resetForm();
    init()
  }, [itemId])

  const handleChangeActive = () => {
    setActive(!isActive)
  }
  const handleChangeCategory = (e: any) => {
    setSelectedCategory(e);
  }
  const handleChangePostType = (e: any) => {
    setSelectedPostType(e);
    switch (e.value) {
      case PostTypeEnum.Image: setVisibles(['picture', 'post']); break;
      case PostTypeEnum.Video: setVisibles(['url', 'post']); break;
      case PostTypeEnum.Link: setVisibles(['url']); break;
      case PostTypeEnum.Text: setVisibles(['author', 'post']); break;
      default: break;
    }
  }
  const handleChangeLevel = (e: any) => {
    setSelectedLevel(e);
  }
  const handleChangeLanguage = (e: any) => {
    setSelectedLanguage(e);
  }
  const handleChangePicture = (event: any) => {
    setLoadFile(event.target.files[0])
    setPicture(URL.createObjectURL(event.target.files[0]));
  }
  return (
    <form
      className='form'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h1>{itemId === 0 ? 'Add' : 'Edit'} Post</h1>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='row mb-10'>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder required'>Type</label>
            <Select
              {...formik.getFieldProps('postType')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangePostType(event)}
              value={selectedPostType}
              options={postTypes}
            />
            <FormikValidationError touched={formik.touched.postType} error={formik.errors.postType} />
          </div>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder required'>Language</label>
            <Select
              {...formik.getFieldProps('language')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeLanguage(event)}
              value={selectedLanguage}
              options={languages}
            />
            <FormikValidationError touched={formik.touched.language} error={formik.errors.language} />
          </div>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder required'>Level</label>
            <Select
              {...formik.getFieldProps('presentLevel')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeLevel(event)}
              value={selectedLevel}
              options={levels}
            />
            <FormikValidationError touched={formik.touched.presentLevel} error={formik.errors.presentLevel} />
          </div>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder required'>Category</label>
            <Select
              {...formik.getFieldProps('blogCategoryId')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeCategory(event)}
              value={selectedCategory}
              options={categories}
            />
            <FormikValidationError touched={formik.touched.blogCategoryId} error={formik.errors.blogCategoryId} />
          </div>
        </div>
        <div className='row mb-10'>
          <div className='col-lg-5'>
            <label className='form-label fw-bolder required'>Head</label>
            <input
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className='form-control form-control-solid'
              placeholder='Entry..'
            />
            <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
          </div>
          <div className='col-lg-7'>
            <label className='form-label fw-bolder required'>Description</label>
            <input
              {...formik.getFieldProps('description')}
              type='text'
              name='description'
              className='form-control form-control-solid'
              placeholder='Entry..'
            />
            <FormikValidationError touched={formik.touched.description} error={formik.errors.description} />
          </div>
        </div>
        <div className='row mb-10'>
          {visibles.some(i => i == "url") &&
            <div className='col-lg-4'>
              <label className='form-label fw-bolder'>Url</label>
              <input
                {...formik.getFieldProps('url')}
                type='text'
                name='url'
                className='form-control form-control-solid'
                placeholder="Entry.."
              />
              <FormikValidationError touched={formik.touched.url} error={formik.errors.url} />
            </div>
          }
          {visibles.some(i => i == "author") &&
            <div className='col-lg-4'>
              <label className='form-label fw-bolder'>Author</label>
              <input
                {...formik.getFieldProps('quoteAuthor')}
                type='text'
                name='quoteAuthor'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.quoteAuthor} error={formik.errors.quoteAuthor} />
            </div>
          }
          {visibles.some(i => i == "picture") &&
            <div className='col-lg-4'>
              <label className='form-label fw-bolder'>Picture</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`, minWidth: '100%' }}
                >
                  <div
                    className='image-input-wrapper'
                    style={{ backgroundImage: `url(${picture})`, minWidth: '100%', backgroundSize: 'contain', backgroundPosition: 'center' }}
                  ></div>
                  <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                    <i className="fas fa-pencil-alt"></i>
                    <input
                      {...formik.getFieldProps('image')}
                      type="file"
                      value={undefined}
                      onChange={(event) => handleChangePicture(event)} accept=".png, .jpg, .jpeg"
                    />
                  </label>
                  <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel avatar">
                    <i className="bi bi-x fs-2"></i>
                  </span>
                </div>
                <FormikValidationError touched={formik.touched.image} error={formik.errors.image} />
              </div>

            </div>
          }
          <div className='col-lg-4'>
            <label className='form-label fw-bolder'>Tags</label>
            <input
              {...formik.getFieldProps('tags')}
              type='text'
              name='tags'
              className='form-control form-control-solid'
              placeholder="( Format: tag1,tag2,tag3 / If you don't fill, system fill. )"
            />
            <FormikValidationError touched={formik.touched.tags} error={formik.errors.tags} />
          </div>
          <div className='col-lg-4'>
            <label className='form-label fw-bolder'>Link</label>
            <input
              {...formik.getFieldProps('link')}
              type='text'
              name='link'
              className='form-control form-control-solid'
              placeholder="( If you don't fill, system fill. )"
            />
            <FormikValidationError touched={formik.touched.link} error={formik.errors.link} />
          </div>
        </div>
        <div className='form-check form-switch form-check-custom form-check-solid mb-10 me-10'>
          <input
            {...formik.getFieldProps('isActive')}
            className='form-check-input h-30px w-50px'
            type='checkbox'
            name='isActive'
            checked={isActive}
            onClick={handleChangeActive}
          />
          <FormikValidationError touched={formik.touched.isActive} error={formik.errors.isActive} />
          <label className='form-check-label'>Active</label>
        </div>
        {visibles.some(i => i == "post") &&
          <div>
            <label className='form-label form-label'>Post</label>
            <DefaultEditor
              toolbars={[EditorToolbarEnum.Basic, EditorToolbarEnum.Extended, EditorToolbarEnum.Media]}
              setData={setPost}
              fileType={FileTypeEnum.BlogPost}
              data={blogPost.content}
            />
          </div>
        }
      </div>
      <div className='card-footer py-3'>
        <div className='d-flex justify-content-end'>
          <SaveChangesButton
            loading={loading}
            setLoading={setLoading}
            complete={complete}
            setComplete={setComplete}
            valid={formik.isValid}
            submit={formik.isSubmitting}
            setSubmit={formik.submitForm} />
        </div>
      </div>
    </form >
  )
}

export { BlogPostFormModal }