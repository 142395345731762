import { FC } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { slugify } from '../../helpers/StringHelper';
import FileApi from '../../../infrastructure/api/FileApi';
import { FileTypeEnum } from '../../../enums/FileTypeEnum';
import { EditorToolbarEnum } from '../../enums/EditorToolbarEnum';
var moment = require('moment');

type Props = {
    data: string,
    setData: (data: string, index?: number) => void,
    index?: number,
    toolbars?: EditorToolbarEnum[],
    fileType?: FileTypeEnum,
    onInit?: () => void
}
const DefaultEditor: FC<Props> = ({ data, setData, index, toolbars, fileType, onInit = console.log }) => {

    const basicToolbar = [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        '|'
    ]

    const extendedToolbar = [
        'indent',
        'outdent',
        '|',
        'link',
        'blockQuote',
        'insertTable',
        '|'
    ]

    const mediaToolbar = [
        'imageUpload',
        'mediaEmbed',
    ]

    const editorConfiguration = {
        extraPlugins: [uploadPlugin],
        toolbar: [...basicToolbar],
        FileRepository: {
            toolbar: [
                'imageStyle:full',
                'imageStyle:side',
                '|',
                'imageTextAlternative'
            ]
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells'
            ]
        },
    }

    if (toolbars?.some(i => i === EditorToolbarEnum.Extended)) editorConfiguration.toolbar = [...editorConfiguration.toolbar, ...extendedToolbar];
    if (toolbars?.some(i => i === EditorToolbarEnum.Media)) editorConfiguration.toolbar = [...editorConfiguration.toolbar, ...mediaToolbar];


    function uploadAdapter(loader: any) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then(async (file: any) => {
                        let d = new Date();
                        const fileName = slugify(`file-${moment().format("YYYYMMDDhhmmss")}`)
                        body.append('name', fileName)
                        body.append('file', file)
                        body.append('type', fileType || FileTypeEnum.Undefined)
                        await FileApi.AddFile(body)
                            .then((res: any) => {
                                resolve({
                                    default: `${res.data}`
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                        ;
                    });
                });
            }
        };
    }
    function uploadPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader);
        };
    }

    return (
        <CKEditor
            editor={ClassicEditor}
            data={data}
            config={editorConfiguration}
            onReady={(editor: any) => {
                onInit()
            }}
            onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setData(data, index);
            }}
            onBlur={(event: any, editor: any) => {
            }}
            onFocus={(event: any, editor: any) => {
            }}
        />
    )
}




export { DefaultEditor }