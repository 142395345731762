import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { BasicTable } from '../../../components/tables/BasicTable'
import { IStudentReportModel } from '../../../../models/responses/StudentReportModel'
import StudentReportApi from '../../../../infrastructure/api/StudentReportApi'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { SelectModel } from '../../../models/SelectModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as level from '../../../pages/levels/redux/LevelRedux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { downloadFile } from '../../../helpers/FileHelper'
import { SendNotificationFormModal } from '../../users/_modals/SendNotificationFormModal'
import * as account from '../../../modules/accounts/redux/AccountRedux'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const StudentReportViewModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState<IStudentReportModel>()
  const excelJS = require("exceljs");
  const [admins, setAdmins] = useState<IUserModel[]>([])
  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const dispatch = useDispatch()
  const [showSendNotification, setShowSendNotification] = useState(false)
  const handleCloseSendNotification = () => { setShowSendNotification(false); }
  const handleShowSendNotification = () => setShowSendNotification(true)

  async function downloadExcel() {

    var data: any[] = []
    for (let index = 0; index < report?.items.length!!; index++) {
      var d = report?.items[index]!!;

      var value = d.studentPlans?.map((value) => {
        return DayOfWeekEnum[value.dayOfWeek] + "=>" + value.startDate.substring(0, 5)
      });


      data.push({
        student: d.firstName + " " + d.lastName,
        tolkPackage: d.tolkPackage,
        startupLevel: levelDictionary.find(i => i.value == d.startupLevelId?.toString())?.label,
        presentLevel: levelDictionary.find(i => i.value == d.presentLevelId?.toString())?.label,
        levelChangeNumber: d.levelChangeNumber,
        demoStatus: d.demoStatus,
        isJobInterview: d.isJobInterview,
        isOurStudent: d.isOurStudent,
        firstCallNumber: d.firstTolkNumber,
        remainingCall: d.callRemaining,
        numberOfCallAddedLater: d.numberOfTolkAddedLater,
        tolkAddedDate: d.tolkAddedDate,
        whoAddedTolk: d.studentId != d.whoAddedTolk ? admins.find(i => i.id == d.whoAddedTolk)?.email : "Self",
        completedCallNumber: d.completedCallNumber,
        cancelledCallNumber: d.cancelledCallNumber,
        closedCallNumber: d.closedCallNumber,

        attendanceRate: d.attendanceRate!!.toFixed(2) + "%",
        studentPlans: value?.join(", "),
        phoneNumber: d.phoneNumber,
        email: d.email,
        pleasure: d.pleasure,
        consultantFullName: d.consultantFullName,
        educationEndDate: d.educationEndDate

      })
    }

    var fields = [
      { key: 'student', header: 'Student', width: 30 },
      { key: 'tolkPackage', header: 'Tolk Package' },
      { key: 'startupLevel', header: 'Startup Level', width: 30 },
      { key: 'presentLevel', header: 'Present Level', width: 30 },
      { key: 'levelChangeNumber', header: 'Level Changed Number' },
      { key: 'demoStatus', header: 'Demo Status' },
      { key: 'isJobInterview', header: 'Job Interview' },
      { key: 'isOurStudent', header: 'Our Student' },
      { key: 'firstCallNumber', header: 'First Call Number' },
      { key: 'remainingCall', header: 'Remaining Call' },
      { key: 'numberOfCallAddedLater', header: 'Last Added Call Number' },
      { key: 'tolkAddedDate', header: 'Tolk Added Date', width: 30 },
      { key: 'whoAddedTolk', header: 'Who Added Tolk', width: 30 },
      { key: 'completedCallNumber', header: 'Completed Call' },
      { key: 'cancelledCallNumber', header: 'Cancelled Call' },
      { key: 'closedCallNumber', header: 'Closed Call' },
      { key: 'attendanceRate', header: 'Attendance Rate' },
      { key: 'studentPlans', header: 'Student Plans', width: 30 },
      { key: 'phoneNumber', header: 'phoneNumber', width: 30 },
      { key: 'email', header: 'email', width: 30 },
      { key: 'pleasure', header: 'Pleasure' },
      { key: 'consultantFullName', header: 'Consultant', width: 30 },
      { key: 'educationEndDate', header: 'Education End Date', width: 30 }
    ];

    const workbook = new excelJS.Workbook();
    const worksheet = workbook.addWorksheet("Educall Student Reports");

    worksheet.columns = fields
    worksheet.getRow(1).eachCell((cell: any) => {
      cell.font = { bold: true };
    });

    worksheet.getColumn(17).alignment = { vertical: 'middle', wrapText: true };


    worksheet.addRows(data);
    const buffer = await workbook.xlsx.writeBuffer();

    downloadFile(buffer, `Educall Student Reports - ${report?.createdAt}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;');
    setComplete(true)
  }

  const getAdminDatas = async () => {

    const paginationFilter: IPaginationFilter = {
      page: 1,
      size: 99999,
      orderBy: "firstName_asc",
      search: ""
    }

    var filters: DictionaryModel[] = [{ type: "Role", value: "Administrator" }]
    var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
    setAdmins(response.data.items);

  }


  async function init() {
    getAdminDatas()
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    if (itemId !== '') {
      var response = await StudentReportApi.GetStudentReport(itemId)
      setReport(response.data)
      const reportUsers: IUserDictionaryModel[] = response.data?.items.map((d) => (
        {
          id: d.studentId!,
          firstName: d.firstName,
          lastName: d.lastName,
          email: d.email,
          phoneNumber: d.phoneNumber,
          check: false
        }
      )) ?? []
      
      dispatch(account.actions.setNotifUsers(reportUsers));
    }


  }

  useEffect(() => {
    if (show) init()
  }, [itemId, show])
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body mx-5 pt-0'>
          <div className='text-center mb-6'>
            <h1 className='mb-3'>View Student Report</h1>
            <div className='text-muted fw-bold fs-5 mb-6'>
              Report Management <small>Educall</small>
            </div>
            <a
              href='#'
              onClick={() => { setShowSendNotification(true) }}
              className='btn btn-primary align-self-center'
            >
              <KTSVG
                path='/media/icons/duotone/Code/Plus.svg'
                className='svg-icon-3'
              />
              Send Notification
            </a>
            <SendNotificationFormModal show={showSendNotification} handleClose={handleCloseSendNotification} />

          </div>
          <div className='row'>
            <BasicTable
              title='Student Report'
              data={report?.items}
              columns={[
                {
                  label: '#',
                  name: '#',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <>{++dataIndex}</>
                    },
                    sort: false,
                    viewColumns: false
                  },
                },
                {
                  label: 'Student',
                  name: 'student',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <span>{report?.items[dataIndex].firstName} {report?.items[dataIndex].lastName}</span>
                    },
                  },
                },
                {
                  label: 'Tolk Package',
                  name: 'tolkPackage',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <span>{report?.items[dataIndex].tolkPackage}</span>
                    },
                  },
                },
                {
                  label: 'Startup Level',
                  name: 'startupLevelId',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{levelDictionary.find(i => i.value == value)?.label}</span>
                    },
                  },
                },
                {
                  label: 'Present Level',
                  name: 'presentLevelId',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{levelDictionary.find(i => i.value == value)?.label}</span>
                    },
                  },
                },
                {
                  label: 'Level Changed',
                  name: 'levelChangeNumber',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Demo',
                  name: 'demoStatus',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Job Interview',
                  name: 'isJobInterview',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Our Student',
                  name: 'isOurStudent',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'First Call Number',
                  name: 'firstCallNumber',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Remaining Call',
                  name: 'callRemaining',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Last Added Call Number',
                  name: 'numberOfCallAddedLater',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Tolk Added Date',
                  name: 'tolkAddedDate',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Who Added Tolk',
                  name: 'whoAddedTolk',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      var studentId = report?.items[dataIndex].studentId;
                      var whoAddedId = report?.items[dataIndex].whoAddedTolk;
                      return studentId != whoAddedId ? <span>{admins.find(i => i.id == whoAddedId)?.email}</span> : <span>Self</span>;

                    },
                  },
                },
                {
                  label: 'Completed Call',
                  name: 'completedCallNumber',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Cancelled Call',
                  name: 'cancelledCallNumber',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Closed Call',
                  name: 'closedCallNumber',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Attendance Rate',
                  name: 'attendanceRate',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value.toFixed(2)}%</span>
                    },
                  },
                },
                {
                  label: 'Plans',
                  name: 'studentPlans',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      var value = report?.items[dataIndex].studentPlans?.map((value) => {
                        return DayOfWeekEnum[value.dayOfWeek] + "=>" + value.startDate.substring(0, 5)
                      });


                      return <span>{value?.join(", ")}</span>


                    },
                  },
                },
                {
                  label: 'PhoneNumber',
                  name: 'phoneNumber',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Email',
                  name: 'email',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Pleasure',
                  name: 'pleasure',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Consultant',
                  name: 'consultantFullName',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Education End Date',
                  name: 'educationEndDate',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: '#',
                  name: 'Id',
                  options: {
                    customHeadLabelRender: (options) => {
                      return (
                        <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                      )
                    },
                    customBodyRenderLite: (dataIndex) => {
                      return (
                        <TableHelperButtons
                          itemId={report?.items[dataIndex]?.studentId!!}
                          itemParam={report?.items[dataIndex]?.studentId!!}
                          links={[{ type: 'user', value: '/account/users/' + report?.items[dataIndex]?.studentId!! + '/overview' }]}
                          types={[]}
                          onClickItem={console.log}
                        />
                      )
                    },
                    filter: false,
                    sort: false,
                    viewColumns: false,
                  },
                },

              ]}
              Content={() => {
                return (
                  <SaveChangesButton
                    loading={loading}
                    setLoading={setLoading}
                    complete={complete}
                    setComplete={setComplete}
                    valid={true}
                    submit={true}
                    customClasses={!loading ? "btn-sm bg-transparent btn-outline-primary btn-icon btn-active-light-dark btn-circle btn-custom" : "btn-sm btn-secondary"}
                    title={"<i className='fas fa-download text-gray-700'></i>"}
                    setSubmit={() => downloadExcel()} />
                )
              }}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <div className='d-flex flex-center '>
            <button className='btn btn-primary' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export { StudentReportViewModal }
