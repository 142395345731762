export enum CloudMessageTypeEnum {
    OpenVideoScreen = 2,
    OpenTrainerCommentScreen = 3,
    SendAlert = 4,
    OpenExternalWebPage = 5,
    OpenDashboardScreen = 7,
    OpenProfileScreen = 8,
    OpenAboutAsScreen = 10,
    OpenFollowUsScreen = 11,
    OpenUserAggrementScreen = 13,
    OpenCallRecordsScreen = 14,
    OpenSettingsScreen = 15,
    OpenTodaysTopicScreen = 16,
    OpenNextTopicScreen = 17,
    OpenCallHoursScreen = 18,
    OpenGrammarGuideScreen = 19
}
