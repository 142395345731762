import { FC, useEffect, useState } from 'react'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel, initPagedResponse } from '../../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import moment from 'moment'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { useDispatch } from 'react-redux'
import { IUserDictionaryModel } from '../../../../models/responses/UserModel'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import StudentApi from '../../../../infrastructure/api/StudentApi'
import * as account from '../../../modules/accounts/redux/AccountRedux'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { StudentFilters } from './_StudentFilters'
import { UserFilters } from './_UserFilters'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const UserListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [users, setUsers] = useState<PagedResponseModel<IUserDictionaryModel[]>>(initPagedResponse)
  const [sectionUserFilters, setSectionUserFilters] = useState<DictionaryModel[]>([])
  const [sectionStudentFilters, setSectionStudentFilters] = useState<Map<string, string>>(new Map())

  const [filterType, setFilterType] = useState<string>("User")
  const [selectedConsultant, setSelectedConsultant] = useState<SelectModel>(selectInitValues)

  const [selectedStartupLevel, setSelectedStartupLevel] = useState<SelectModel>(selectInitValues)
  const [selectedPresentLevel, setSelectedPresentLevel] = useState<SelectModel>(selectInitValues)

  const [selectedGender, setSelectedGender] = useState<SelectModel>(selectInitValues)
  const [selectedStatus, setSelectedStatus] = useState<SelectModel>(selectInitValues)

  const [selectedBirthday, setSelectedBirthday] = useState<SelectModel>(selectInitValues)
  const [selectedRegistrationDate, setSelectedRegistrationDate] = useState<SelectModel>(selectInitValues)

  const [selectedTestAccount, setSelectedTestAccount] = useState<SelectModel>(selectInitValues)
  const [selectedPhoneConfirmed, setSelectedPhoneConfirmed] = useState<SelectModel>(selectInitValues)
  const [selectedEmailConfirmed, setSelectedEmailConfirmed] = useState<SelectModel>(selectInitValues)
  const [selectedNeverLoggedIn, setSelectedNeverLoggedIn] = useState<SelectModel>(selectInitValues)
  const [selectedSelfRegistered, setSelectedSelfRegistered] = useState<SelectModel>(selectInitValues)
  const [selectedSupportedByEducall, setSelectedSupportedByEducall] = useState<SelectModel>(selectInitValues)
  const [selectedSupportedByCompany, setSelectedSupportedByCompany] = useState<SelectModel>(selectInitValues)
  const [selectedEducationEnded, setSelectedEducationEnded] = useState<SelectModel>(selectInitValues)

  const [selectedOurStudent, setSelectedOurStudent] = useState<SelectModel>(selectInitValues)
  const [selectedToeflStudent, setSelectedToeflStudent] = useState<SelectModel>(selectInitValues)
  const [selectedYdsStudent, setSelectedYdsStudent] = useState<SelectModel>(selectInitValues)
  const [selectedHavePlan, setSelectedHavePlan] = useState<SelectModel>(selectInitValues)
  const [selectedDemoStudent, setSelectedDemoStudent] = useState<SelectModel>(selectInitValues)
  const [selectedFrozenStudent, setSelectedFrozenStudent] = useState<SelectModel>(selectInitValues)
  const [selectedJobInterviewStudent, setSelectedJobInterviewStudent] = useState<SelectModel>(selectInitValues)
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)
  const [selectedCountry, setSelectedCountry] = useState<SelectModel>(selectInitValues)
  const [selectedCity, setSelectedCity] = useState<SelectModel>(selectInitValues)
  const [selectedJob, setSelectedJob] = useState<SelectModel>(selectInitValues)
  const [selectedHeardOn, setSelectedHeardOn] = useState<SelectModel>(selectInitValues)


  const [selectedTolkPackage, setSelectedTolkPackage] = useState<SelectModel>(selectInitValues)
  const [selectedRemainingTolk, setSelectedRemainingTolk] = useState<string>("")
  const [selectedPreferCallingPlatform, setSelectedPreferCallingPlatform] = useState<SelectModel>(selectInitValues)


  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const [startRegistration, setStartRegistration] = useState(startTime)
  const [endRegistration, setEndRegistration] = useState(endTime)

  const [startNeverLoggedIn, setStartNeverLoggedIn] = useState(startTime)
  const [endNeverLoggedIn, setEndNeverLoggedIn] = useState(endTime)

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')

  useEffect(() => {
    if (filterType == "Student") setStudentFilters(sectionStudentFilters)
    else setUserFilters(sectionUserFilters)
  }, [refresh, pageNumber, pageSize, searchText, sort])

  async function callNotificationUsers() {
    if (filterType == "Student") {
      const studentPaginationFilter: IPaginationFilter = {
        page: pageNumber,
        size: pageSize,
        orderBy: sort,
        search: searchText,
        filters: sectionStudentFilters
      }
      var response = await StudentApi.GetStudentDictionary(studentPaginationFilter);
      dispatch(account.actions.setNotifUsers(response.data));

    } else {
      var response = await UserApi.GetUserDictionaryFilter(sectionUserFilters)
      dispatch(account.actions.setNotifUsers(response.data));
    }

    onClickItem('0', 'Number List', 'sendNotification');
    setComplete(true);
  }

  async function setPage(value: number) {
    setPageNumber(value)
  }

  async function setUserFilters(userFilters: any) {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText
    }

    setSectionUserFilters(userFilters);
    var response = await UserApi.GetUsersByFilterForDictionary(paginationFilter, userFilters)

    if (response.data.totalRecords > 0) {
      setUsers(response.data)
    } else {
      dispatch(account.actions.setNotifUsers([]));
      setUsers(initPagedResponse)
    }

  }

  async function setStudentFilters(studentFilters: any) {
    const studentPaginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
      filters: studentFilters
    }
    setSectionStudentFilters(studentFilters);

    var response = await StudentApi.GetStudents(studentPaginationFilter);

    if (response.data.totalRecords > 0) {
      setUsers(response.data)
    } else {
      dispatch(account.actions.setNotifUsers([]));
      setUsers(initPagedResponse)
    }
  }


  return (
    <>
      <div className='accordion mb-5' id='kt_accordion_1'>
        <div className='accordion-item'>
          <h2 className='accordion-header' id='kt_accordion_1_header_1'>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_accordion_1_body_1'
              aria-expanded='false'
              aria-controls='kt_accordion_1_body_1'
            >
              User Filters
            </button>
          </h2>
          <div
            id='kt_accordion_1_body_1'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_1'
            data-bs-parent='#kt_accordion_1'
          >
            <div className='accordion-body'>
              <UserFilters
                selectedGender={selectedGender} setSelectedGender={setSelectedGender}
                selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}
                selectedPhoneConfirmed={selectedPhoneConfirmed} setSelectedPhoneConfirmed={setSelectedPhoneConfirmed}
                selectedEmailConfirmed={selectedEmailConfirmed} setSelectedEmailConfirmed={setSelectedEmailConfirmed}
                selectedNeverLoggedIn={selectedNeverLoggedIn} setSelectedNeverLoggedIn={setSelectedNeverLoggedIn}
                selectedTestAccount={selectedTestAccount} setSelectedTestAccount={setSelectedTestAccount}
                selectedBirthday={selectedBirthday} setSelectedBirthday={setSelectedBirthday}
                selectedRegistrationDate={selectedRegistrationDate} setSelectedRegistrationDate={setSelectedRegistrationDate}
                selectedSelfRegistered={selectedSelfRegistered} setSelectedSelfRegistered={setSelectedSelfRegistered}
                selectedJob={selectedJob} setSelectedJob={setSelectedJob}
                selectedHeardOn={selectedHeardOn} setSelectedHeardOn={setSelectedHeardOn}
                selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}
                selectedCity={selectedCity} setSelectedCity={setSelectedCity}
                start={start} setStart={setStart}
                end={end} setEnd={setEnd}
                startRegistration={startRegistration} setStartRegistration={setStartRegistration}
                endRegistration={endRegistration} setEndRegistration={setEndRegistration}
                startNeverLoggedIn={startNeverLoggedIn} setStartNeverLoggedIn={setStartNeverLoggedIn}
                endNeverLoggedIn={endNeverLoggedIn} setEndNeverLoggedIn={setEndNeverLoggedIn}
                setFilterType={(e) => setFilterType(e)}
                setPageNumber={(e) => setPage(e)}
                setResult={(e) => setUserFilters(e)}
              />

            </div>
          </div>
        </div>

        <div className='accordion-item'>
          <h2 className='accordion-header' id='kt_accordion_1_header_2'>
            <button
              className='accordion-button fs-4 fw-bold collapsed'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_accordion_1_body_2'
              aria-expanded='false'
              aria-controls='kt_accordion_1_body_2'
            >
              Student Filters
            </button>
          </h2>
          <div
            id='kt_accordion_1_body_2'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_2'
            data-bs-parent='#kt_accordion_1'
          >
            <div className='accordion-body'>

              <StudentFilters
                selectedPresentLevel={selectedPresentLevel} setSelectedPresentLevel={setSelectedPresentLevel}
                selectedStartupLevel={selectedStartupLevel} setSelectedStartupLevel={setSelectedStartupLevel}
                selectedTolkPackage={selectedTolkPackage} setSelectedTolkPackage={setSelectedTolkPackage}
                selectedEducationEnded={selectedEducationEnded} setSelectedEducationEnded={setSelectedEducationEnded}
                selectedSupportedByEducall={selectedSupportedByEducall} setSelectedSupportedByEducall={setSelectedSupportedByEducall}
                selectedSupportedByCompany={selectedSupportedByCompany} setSelectedSupportedByCompany={setSelectedSupportedByCompany}
                selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany}
                selectedOurStudent={selectedOurStudent} setSelectedOurStudent={setSelectedOurStudent}
                selectedToeflStudent={selectedToeflStudent} setSelectedToeflStudent={setSelectedToeflStudent}
                selectedYdsStudent={selectedYdsStudent} setSelectedYdsStudent={setSelectedYdsStudent}
                selectedHavePlan={selectedHavePlan} setSelectedHavePlan={setSelectedHavePlan}
                selectedConsultant={selectedConsultant} setSelectedConsultant={setSelectedConsultant}
                selectedDemoStudent={selectedDemoStudent} setSelectedDemoStudent={setSelectedDemoStudent}
                selectedFrozenStudent={selectedFrozenStudent} setSelectedFrozenStudent={setSelectedFrozenStudent}
                selectedRemainingTolk={selectedRemainingTolk} setSelectedRemainingTolk={setSelectedRemainingTolk}
                selectedPreferCallingPlatform={selectedPreferCallingPlatform} setSelectedPreferCallingPlatform={setSelectedPreferCallingPlatform}
                selectedJobInterviewStudent={selectedJobInterviewStudent} setSelectedJobInterviewStudent={setSelectedJobInterviewStudent}
                setFilterType={(e) => setFilterType(e)}
                setPageNumber={(e) => setPage(e)}
                setResult={(e) => setStudentFilters(e)}  />
            </div>
          </div>
        </div>

      </div>

      <div
        className='mb-5 text-end'>

        {users?.totalRecords > 0 && ((sectionStudentFilters.size > 0 && filterType == "Student") || (sectionUserFilters.length > 0) && filterType == "User") &&
          <SaveChangesButton
            title='Send Notification'
            loading={loading}
            setLoading={setLoading}
            customClasses='btn btn-sm btn-primary'
            complete={complete}
            setComplete={setComplete}
            valid={true}
            submit={true}
            completeText='Ready'
            setSubmit={() => { callNotificationUsers(); }} />
        }

      </div>
      <div className='card'>
        <div className='card-body'>

          <GenericTable
            title="Users"
            data={users?.items}
            currentTotalRecords={users?.totalRecords!!}
            currentPageNumber={users?.pageNumber!!}
            columns={[
              {
                label: "#", name: "#", options: {
                  filter: false,
                  customBodyRenderLite: (dataIndex) => {
                    return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                  },
                  sort: false,
                  viewColumns: false
                }
              },
              {
                label: "FullName", name: "firstName", options: {
                  filter: false,
                  customBodyRenderLite: (dataIndex) => {
                    return (
                      <b>{users?.items[dataIndex].firstName}&nbsp;{users?.items[dataIndex].lastName}</b>
                    )
                  },
                },
              },
              { label: "Email", name: "email", options: { filter: false } },
              { label: "Phone", name: "phoneNumber", options: { filter: false } },
              { label: "Created At", name: "createdAt", options: { filter: false } },
              {
                label: "#", name: "Id", options: {
                  customHeadLabelRender: (options) => {
                    return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
                  },
                  customBodyRenderLite: (dataIndex) => {
                    return (
                      <TableHelperButtons
                        itemId={users?.items[dataIndex]?.id!!}
                        itemParam={`${users?.items[dataIndex]?.firstName!!} ${users?.items[dataIndex]?.lastName!!}`}
                        links={[
                          { type: 'user', value: '/account/users/' + users?.items[dataIndex]?.id!! }
                        ]}
                        types={[TableHelperButtonEnum.Delete]}
                        onClickItem={onClickItem}
                      />
                    )
                  },
                  filter: false,
                  sort: false,
                  viewColumns: false
                },
              },

            ]}
            customOptions={{
              rowsPerPage: pageSize,
              rowsPerPageOptions: [10, 20, 50, 100, 200],
              filter: false
            }}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            setSearchText={setSearchText}
            setSort={setSort}
          />

          <div className='d-flex flex-column align-items-end'>
            <div className='flex-column MUIDataTablePagination-tableCellContainer-1117'>
              <a href="#" className={`btn btn-default ${1 == pageNumber ? 'disabled' : ''}`}
                onClick={() => { setPageNumber(1) }}>
                <i className='fa fa-angle-double-left'></i></a>
              <a href="#" className={`btn btn-default ${Math.ceil(users.totalRecords / users.pageSize) == pageNumber ? 'disabled' : ''}`}
                onClick={() => { setPageNumber(Math.ceil(users.totalRecords / users.pageSize)) }}>
                <i className='fa fa-angle-double-right'></i></a>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export { UserListModal }