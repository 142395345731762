import { IAnnouncementRequestModel, IReadAnnouncementRequestModel } from '../../models/requests/AnnouncementRequestModel'
import { IAnnouncementModel } from '../../models/responses/AnnouncementModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class AnnouncementApi {
  async GetAnnouncements(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IAnnouncementModel[]>>(`e/Admin/Announcements${generateQueryParams(filter)}`)
  }

  async GetAnnouncement(id: string) {
    return await API.get<IAnnouncementModel>('e/Admin/Announcements/' + id)
  }

  async GetAnnouncementAlerts(userId: string, filter: IPaginationFilter) {
    return await API.get<IAnnouncementModel[]>(`e/Admin/Announcements/alert/${userId}${generateQueryParams(filter)}`)
  }

  async AddAnnouncement(model: IAnnouncementRequestModel) {
    return await API.post('e/Admin/Announcements', model)
  }

  async UpdateAnnouncement(model: IAnnouncementRequestModel) {
    return await API.put('e/Admin/Announcements/' + model.id, model)
  }

  async ReadAnnouncement(model: IReadAnnouncementRequestModel) {
    return await API.put('e/Admin/Announcements/read/' + model.id, model)
  }

  async DeleteAnnouncement(itemId: string) {
    return await API.delete<boolean>('e/Admin/Announcements/' + itemId)
  }
}

export default new AnnouncementApi()
