import { FC, useEffect, useState } from 'react'
import BlogCommentApi from '../../../../infrastructure/api/BlogCommentApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IBlogCommentModel, blogCommentInitValues } from '../../../../models/responses/BlogCommentModel'
import { IBlogCommentRequestModel } from '../../../../models/requests/BlogCommentRequestModel'
import { BlogCommentFormValidations } from '../../../validations/BlogCommentFormValidations'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { checkPermission, UserModel } from '../../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'

type Props = {
  itemId: number
  show: boolean
  itemType: string
  handleClose: () => void
}

const BlogCommentFormModal: FC<Props> = ({ itemId, show, itemType, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [comment, setComment] = useState('')

  const [blogComment, setBlogComment] = useState<IBlogCommentModel>(blogCommentInitValues)
  const updateBlogComment = (fieldsToUpdate: Partial<IBlogCommentModel>) => {
    const updatedData = { ...blogComment, ...fieldsToUpdate }
    setBlogComment(updatedData)
  }
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const formik = useFormik<IBlogCommentModel>({
    initialValues: blogComment,
    enableReinitialize: true,
    validationSchema: BlogCommentFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IBlogCommentRequestModel = {
          id: values.id,
          author: values.author,
          email: values.email,
          body: comment,
          blogPostId: values.blogPostId,
          parentCommentId: values.parentCommentId,
          isActive: values.isActive,
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IBlogCommentRequestModel) {
    try {
      var result = await BlogCommentApi.UpdateBlogComment(model)
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function getBlogComment() {
    let result: IBlogCommentModel
    if (itemId > 0) {
      var response = await BlogCommentApi.GetBlogComment(itemId)
      result = response.data
    } else {
      result = blogCommentInitValues
    }
    setBlogComment(result)
  }

  useEffect(() => {
    if (show && ((itemId == 0 && !checkPermission(user, PermissionEnum.BlogCommentCreate)) || (itemId > 0 && !checkPermission(user, PermissionEnum.BlogCommentUpdate)))) {
      document.location.href = "/error/404"
    }
    
    formik.resetForm()
    if (show) getBlogComment()
  }, [itemId, show])

  const handleChangeActive = () => {
    updateBlogComment({ isActive: !blogComment!!.isActive });
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemType === 'comment' ? 'Reply' : 'Edit'} Comment</h1>
              <div className='text-muted fw-bold fs-5'>
                Blog Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Author</label>
              <input
                {...formik.getFieldProps('author')}
                type='text'
                name='author'
                disabled
                className='form-control form-control-solid'
                placeholder='Comment Head'
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>E-mail</label>
              <input
                {...formik.getFieldProps('email')}
                type='text'
                name='email'
                disabled
                className='form-control form-control-solid'
                placeholder='Comment About'
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Post</label>
              <input
                {...formik.getFieldProps('blogPostName')}
                type='text'
                name='blogPostName'
                disabled
                className='form-control form-control-solid'
                placeholder='Order'
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Comment</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setComment}
                data={blogComment.body}
              />
            </div>
            <div className='form-check form-switch form-check-custom form-check-solid me-10'>
              <input
                {...formik.getFieldProps('isActive')}
                className='form-check-input h-30px w-50px'
                type='checkbox'
                name='isActive'
                checked={blogComment?.isActive}
                onClick={handleChangeActive}
              />
              <label className='form-check-label'>Active</label>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { BlogCommentFormModal }
