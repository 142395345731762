import { IShopOrderRequestModel } from '../../models/requests/ShopOrderRequestModel'
import { IShopOrderModel } from '../../models/responses/ShopOrderModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ShopOrderApi {

  async GetShopOrders(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IShopOrderModel[]>>(`e/Admin/ShopOrders${generateQueryParams(filter)}`)
  }

  async GetShopOrder(id: number) {
    return await API.get<IShopOrderModel>('e/Admin/ShopOrders/' + id)
  }

  async AddShopOrder(model: IShopOrderRequestModel) {
    return await API.post('e/Admin/ShopOrders', model)
  }

  async UpdateShopOrder(model: IShopOrderRequestModel) {
    return await API.put('e/Admin/ShopOrders/' + model.id, model)
  }

  async DeleteShopOrder(itemId: number) {
    return await API.delete<boolean>('e/Admin/ShopOrders/' + itemId)
  }
}

export default new ShopOrderApi()
