import { FC, useCallback, useEffect, useState } from 'react'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import UserLogApi from '../../../../infrastructure/api/UserLogApi'
import { IUserLogModel } from '../../../../models/responses/UserLogModel'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { downloadFile } from '../../../helpers/FileHelper'
import { JsonToCsv } from '../../../helpers/CsvHelper'
import { RoleEnum } from '../../../../enums/RoleEnum'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import Select from 'react-select'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const UserLogsListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const [filters, setFilters] = useState<Map<string, string>>(new Map())


  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
    getUsers(startDate, endDate)
  }

  let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 3 Days': [moment().subtract(2, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false,
  }

  const [users, setUsers] = useState<PagedResponseModel<IUserLogModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')
  const [roles, setRoles] = useState<SelectModel[]>([])

  const init = useCallback(async () => {
    let newFilters: Map<string, string> = new Map()

    if (roles.length < 1) {
      var responseRoles = await RoleApi.GetRoleDictionary()
      var dataRoles: SelectModel[] = [...responseRoles.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      setRoles(dataRoles)
    }
  }, []);

  useEffect(() => {
    init();
    getUsers(start, end);

  }, [refresh, filters, pageNumber, pageSize, searchText, sort])

  async function getUsers(start: moment.Moment, end: moment.Moment) {
    var startTime = start.format("YYYY-MM-DDT00:00:00");
    var endTime = end.format("YYYY-MM-DDT23:59:59");
    var newFilters: DictionaryModel[] = [
      { type: "StartTime", value: startTime },
      { type: "EndTime", value: endTime },
    ]

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
      filters: filters
    }

    var response = await UserLogApi.GetUserLogsByFilter(paginationFilter, newFilters)
    response.data.items.forEach(i => i.loginNumber = i.logs.filter(i => i.event == "1000").length)
    setUsers(response.data)
  }


  async function getReports(start: moment.Moment, end: moment.Moment) {

    var startTime = start.format("YYYY-MM-DDT00:00:00");
    var endTime = end.format("YYYY-MM-DDT23:59:59");

    var newFilters: DictionaryModel[] = [
      { type: "StartTime", value: startTime },
      { type: "EndTime", value: endTime }
    ]
    const paginationFilter: IPaginationFilter = {
      page: 1,
      size: users?.totalRecords || 9999,
      orderBy: sort,
      search: searchText,
      filters: filters
    }

    var response = await UserLogApi.GetUserLogsByFilter(paginationFilter, newFilters)
    response.data.items.forEach(i => i.loginNumber = i.logs.filter(i => i.event == "1000").length)

    const fields = [{ value: 'firstName', label: 'First Name' }, { value: 'lastName', label: 'Last Name' }, { value: 'email', label: 'E-mail' }, { value: 'phoneNumber', label: 'Phone Number' }, { value: 'loginNumber', label: 'Logins' }];
    downloadFile(JsonToCsv(response.data.items, fields), `Educall User Logins - (${start.format("YYYY-MM-DD")} _ ${end.format("YYYY-MM-DD")}) _ ${moment().format("YYYY-MM-DD-HH-mm-ss")}`);

  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-3'>
          <label className='form-label fw-bolder'>Date Range</label>
          <DateTimeRangeContainer
            ranges={ranges}
            start={start}
            style={{
              darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
            }}
            end={end}
            local={local}
            applyCallback={applyCallback}
          >
            <input
              id="formControlsTextB"
              className='form-control form-control-solid'
              value={`${start.format(local.format)} - ${end.format(local.format)}`}
              type="text"
            />
          </DateTimeRangeContainer>
        </div>
      </div>
      <GenericTable
        title={"Login Informations"}
        data={users?.items}
        currentTotalRecords={users?.totalRecords!!}
        currentPageNumber={users?.pageNumber!!}
        columns={[
          {
            label: "#", name: "#", options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              filter: false,
              sort: false,
              viewColumns: false,
              download: false,
              print: false
            }
          },
          {
            label: "FullName", name: "firstName", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <b>{users?.items[dataIndex].firstName} {users?.items[dataIndex].lastName}</b>
                )
              },
              filter: false,
            },

          },
          { label: "Email", name: "email", options: { filter: false } },
          {
            label: "Login Number", name: "loginNumber", options: {
              filter: false,
              sort: false,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <div className='badge badge-lg badge-circle badge-light'>{users?.items[dataIndex].loginNumber}</div>
                )
              },
            },
          },
          {
            label: "Logins", name: "logs", options: {
              filter: false,
              sort: false,
              customBodyRenderLite: (dataIndex) => {
                return (
                  users?.items[dataIndex].logs.filter(i => i.event == "1000").map((item, index) => {
                    return (
                      <div key={index} className='badge badge-sm badge-primary ms-1 mb-1'>{moment(item.createdAt).format("YYYY-MM-DD H:m:s")}</div>
                    )
                  })
                )
              },
            },
          },
          {
            label: "#", name: "Id", options: {
              filter: false,
              customHeadLabelRender: (options) => {
                return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={users?.items[dataIndex]?.userId!!}
                    itemParam={`${users?.items[dataIndex]?.firstName!!} ${users?.items[dataIndex]?.lastName!!}`}
                    links={[
                      { type: 'user', value: '/account/users/' + users?.items[dataIndex]?.userId!! }
                    ]}
                    types={[]}
                    onClickItem={onClickItem}
                  />
                )
              },
              download: false,
              print: false,
              sort: false,
              viewColumns: false
            },
          },
          { label: 'Role', name: '#', options: { display: 'excluded', filterOptions: { names: roles.map(i => i.label!!) } } },

        ]}

        customOptions={
          {
            rowsPerPage: pageSize,
            textLabels: {
              filter: {
                all: "All Roles"
              }
            },
            filter: true,
            onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
              setPageNumber(1)
              let newFilters: Map<string, string> = new Map()
              for (let i = 0; i < filterList.length; i++) {
                if (filterList[i].length > 0) {
                  if (i == 6) newFilters.set("Role", filterList[i][0].toString())
                }
              }
              setFilters(newFilters)
            },
            customTableBodyFooterRender: (opts) => {
              return <div></div>
            }
          }}

        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
        exportExcel={() => getReports(start, end)}
        size={pageSize}
      />
    </>
  )
}

export { UserLogsListModal }
