/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { BlogCommentFormModal } from './_modals/BlogCommentFormModal'
import { BlogCommentListModal } from './_modals/BlogCommentListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import BlogCommentApi from '../../../infrastructure/api/BlogCommentApi'
import { BlogCommentReplyModal } from './_modals/BlogCommentReplyModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'

const BlogCommentPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')
  const [itemType, setItemType] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const [showReplyForm, setShowReplyForm] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)
  const handleCloseForm = () => setShowForm(false)
  const handleShowForm = () => setShowForm(true)
  const handleCloseReplyForm = () => setShowReplyForm(false)
  const handleShowReplyForm = () => setShowReplyForm(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    setItemType(type)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      case 'reply':
        handleShowReplyForm()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function DeleteBlogComment(itemId: number) {
    var response = await BlogCommentApi.DeleteBlogComment(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            {/* <span className='card-label fw-bolder fs-3 mb-1'>Members Statistics</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
          </h3>
        </div>

        <div className='card-body py-3'>
          <BlogCommentListModal refresh={refresh} onClickItem={onClickItem} />
          <BlogCommentFormModal
            itemId={itemId}
            show={showForm}
            itemType={itemType}
            handleClose={handleCloseForm}
          />
          <BlogCommentReplyModal
            itemId={itemId}
            show={showReplyForm}
            itemType={itemType}
            handleClose={handleCloseReplyForm}
          />

          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Blog Comment'
            confirm={DeleteBlogComment}
          />
        </div>
      </div>
    </>
  )
}

const BlogCommentWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Blog</PageTitle>
      <BlogCommentPage />
    </>
  )
}

export { BlogCommentWrapper }
