import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { IBlogCategoryModel, blogCategoryInitValues } from '../../../../models/responses/BlogCategoryModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetBlogCategories: '[Set BlogCategories] Action'
}

export interface IAccountState {
    setBlogCategories?: Array<IBlogCategoryModel>,
}

const initialAccountState: IAccountState = {
    setBlogCategories: []
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-blog-categories',
        whitelist: ['setBlogCategories'],
        blacklist: []
    },
    (state: IAccountState = initialAccountState, action: ActionWithPayload<IAccountState>) => {
        switch (action.type) {
            case actionTypes.SetBlogCategories: {
                const setBlogCategories = action.payload?.setBlogCategories
                return { ...state, setBlogCategories }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setBlogCategories: (blogCategories: Array<IBlogCategoryModel>) => ({ type: actionTypes.SetBlogCategories, payload: { blogCategories } }),
}
