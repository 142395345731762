import { FC, useEffect, useState } from 'react'
import BlogCategoryApi from '../../../../infrastructure/api/BlogCategoryApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import Select from 'react-select';
import { useFormik } from 'formik'
import { IBlogCategoryModel, blogCategoryInitValues } from '../../../../models/responses/BlogCategoryModel'
import { IBlogCategoryRequestModel, blogCategoryRequestInitValues as initialValues } from '../../../../models/requests/BlogCategoryRequestModel'
import { BlogCategoryFormValidations } from '../../../validations/BlogCategoryFormValidations'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

import { UserModel, checkPermission } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { PermissionEnum } from '../../../../enums/PermissionEnum'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}
const BlogCategoryFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const mainCategory = {
    value: "0", label: "--MAIN CATEGORY--"
  };
  const [selectedParentCategory, setSelectedParentCategory] = useState<SelectModel>(mainCategory)
  const [parentCategories, setParentCategories] = useState<SelectModel[]>([])

  const formik = useFormik<IBlogCategoryRequestModel>({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: BlogCategoryFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IBlogCategoryRequestModel = {
          id: initialValues.id,
          name: values.name,
          order: values.order,
          parentBlogCategoryId: parseInt(selectedParentCategory.value),
          about: values.about,
          link: values.name,
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IBlogCategoryRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await BlogCategoryApi.AddBlogCategory(model)
      else {
        result = await BlogCategoryApi.UpdateBlogCategory(model)
      }
      setComplete(true)
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  async function getBlogCategory() {
    var response = await BlogCategoryApi.GetBlogCategoryDictionary()

    var parentCategories: SelectModel[] = [...[mainCategory], ...response.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]

    let result: IBlogCategoryModel
    if (itemId > 0) {
      const response = await BlogCategoryApi.GetBlogCategory(itemId)
      result = response.data
      parentCategories = parentCategories.filter(i => i.value != "0")
    }
    else {
      result = blogCategoryInitValues;
    }
    setParentCategories(parentCategories)

    initialValues.id = result?.id || 0
    initialValues.name = result?.name || ''
    initialValues.about = result?.about || ''
    initialValues.order = result?.order || 0
    initialValues.parentBlogCategoryId = parseInt(selectedParentCategory.value!!) || 0

    if (result?.parentBlogCategoryId) {
      handleChangeParent({
        value: result?.parentBlogCategoryId?.toString(),
        label: parentCategories?.find(i => i.value == result?.parentBlogCategoryId?.toString())?.label
      })
    } else {
      handleChangeParent(mainCategory);
    }
  }

  useEffect(() => {
    if (show && ((itemId == 0 && !checkPermission(user, PermissionEnum.BlogCategoryCreate)) || (itemId > 0 && !checkPermission(user, PermissionEnum.BlogCategoryUpdate)))) {
      document.location.href = "/error/404"
    }

    formik.resetForm();
    if (show) getBlogCategory()
  }, [itemId, show])

  const handleChangeParent = (e: any) => {
    setSelectedParentCategory(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-550px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Category</h1>

            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            <div className='mb-10'>
              <label className='form-label required form-label fw-bolder'>Parent Category</label>
              <Select
                {...formik.getFieldProps('parentBlogCategoryId')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeParent(event)}
                value={selectedParentCategory}
                options={parentCategories}
              />
              {formik.touched.parentBlogCategoryId && formik.errors.parentBlogCategoryId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.parentBlogCategoryId}</div>
                </div>
              )}
            </div>
            <div className='mb-10'>
              <label className='form-label required form-label fw-bolder'>Name</label>
              <input
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className='form-control form-control-solid'
                placeholder='Category Head'
              />
            </div>
            <div className='mb-10'>
              <label className='form-label required form-label fw-bolder'>About</label>
              <input
                {...formik.getFieldProps('about')}
                type='text'
                name='about'
                className='form-control form-control-solid'
                placeholder='Category About'
              />
            </div>
            <div>
              <label className='form-label required form-label fw-bolder'>Order</label>
              <input
                {...formik.getFieldProps('order')}
                type='number'
                name='order'
                className='form-control form-control-solid'
                placeholder='Order'
              />
            </div>
          </div>

          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm} />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { BlogCategoryFormModal }
