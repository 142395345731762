export interface IBlogPostModel {
  id: number
  name: string
  link: string
  tags: string
  image: string
  content: string
  description: string
  url: string
  quoteAuthor: string
  socialShare: boolean
  presentLevel: number
  viewCount: number
  postType: number
  language: number
  blogCategoryId: number
  blogCategoryName: string
  isActive: boolean
  date: string
}

const blogPostInitValues: IBlogPostModel = {
  id: 0,
  name: '',
  link: '',
  tags: '',
  image: '',
  content: '',
  description: '',
  url: '',
  quoteAuthor: '',
  socialShare: false,
  presentLevel: 0,
  viewCount: 0,
  postType: 0,
  language: 0,
  blogCategoryId: 0,
  blogCategoryName: '',
  isActive: false,
  date: '',
}

export { blogPostInitValues }