import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import EmergencyApi from '../../../../infrastructure/api/EmergencyApi'
import { IEmergencyModel } from '../../../../models/responses/EmergencyModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const EmergencyListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [emergencies, setEmergencies] = useState<PagedResponseModel<IEmergencyModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  async function init() {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    var response = await EmergencyApi.GetEmergencies(paginationFilter)
    setEmergencies(response.data)
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Emergencies'
        data={emergencies?.items}
        currentTotalRecords={emergencies?.totalRecords!!}
        currentPageNumber={emergencies?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Date',
            name: 'date',
            options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{value}</div>
              },
            },
          },
          {
            label: 'Trainer',
            name: 'trainer',
            options: {
              customBodyRenderLite(dataIndex, rowIndex) {
                return <div className='d-flex align-items-center'>{emergencies?.items[rowIndex].firstName} {emergencies?.items[rowIndex].lastName}</div>
              }
            },
          },
          {
            label: 'Email',
            name: 'email',
            options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{value}</div>
              },
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { EmergencyListModal }
