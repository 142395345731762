import API from './Api'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IStudentReportModel } from '../../models/responses/StudentReportModel'
import { IStudentReportRequestModel } from '../../models/requests/StudentReportRequestModel'

class StudentReportApi {

  async GetStudentReports(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IStudentReportModel[]>>(`s/Admin/StudentReports${generateQueryParams(filter)}`)
  }

  async GetStudentReport(id: string) {
    return await API.get<IStudentReportModel>('s/Admin/StudentReports/' + id)
  }

  async CreateStudentReport(model: IStudentReportRequestModel) {
    return await API.post('s/Admin/StudentReports', model)
  }

}

export default new StudentReportApi()
