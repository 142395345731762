import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IMissionModel, IMissionsModel } from '../../models/responses/MissionModel'
import { IMissionRequestModel } from '../../models/requests/MissionRequestModel'

class MissionApi {

  async GetMissions(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IMissionModel[]>>(`e/Admin/Missions${generateQueryParams(filter)}`)
  }

  async GetMission(id: string) {
    return await API.get<IMissionModel>('e/Admin/Missions/' + id)
  }

  async GetStudentMissions(userId: string, levelId: number, companyId?: string) {
    return await API.get<IMissionsModel>(`e/Admin/Missions/student/${userId}/${levelId}` + (companyId != undefined ? `?companyId=${companyId}` : ''))
  }

  async AddMission(model: IMissionRequestModel) {
    return await API.post('e/Admin/Missions', model)
  }

  async UpdateMission(model: IMissionRequestModel) {
    return await API.put('e/Admin/Missions/' + model.id, model)
  }

  async DeleteMission(itemId: string) {
    return await API.delete<boolean>('e/Admin/Missions/' + itemId)
  }
  async DeleteStudentMission(itemId: string, userId: string) {
    return await API.delete<boolean>(`e/Admin/Missions/${itemId}/${userId}`)
  }
}

export default new MissionApi()
