import { IExamRequestModel } from '../../models/requests/ExamRequestModel'
import { IExamModel } from '../../models/responses/ExamModel'
import { IExamDictionaryModel } from '../../models/responses/ExamDictionaryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ExamApi {
  async GetExams(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IExamModel[]>>(`ex/Admin/Exams${generateQueryParams(filter)}`)
  }

  async GetExam(id: string) {
    return await API.get<IExamModel>('ex/Admin/Exams/' + id)
  }
  
  async GetExamDictionary() {
    return await API.get<IExamDictionaryModel[]>(`ex/Admin/Exams/dictionary`)
  }

  async AddExam(model: IExamRequestModel) {
    return await API.post('ex/Admin/Exams', model)
  }

  async UpdateExam(model: IExamRequestModel) {
    return await API.put('ex/Admin/Exams/' + model.id, model)
  }

  async DeleteExam(itemId: string) {
    return await API.delete<boolean>('ex/Admin/Exams/' + itemId)
  }
}

export default new ExamApi()
