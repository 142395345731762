import { FC, useEffect, useState } from 'react'
import AnnouncementApi from '../../../../infrastructure/api/AnnouncementApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { ITaskRequestModel } from '../../../../models/requests/TaskRequestModel'
import { TaskFormValidations } from '../../../validations/TaskFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { ITaskDefineModel, ITaskModel, taskInitValues } from '../../../../models/responses/TaskModel'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import TaskApi from '../../../../infrastructure/api/TaskApi'
import AsyncSelect from 'react-select/async'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import debounce from 'es6-promise-debounce'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const TaskFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [task, setTask] = useState<ITaskModel>(taskInitValues)
  const [explanation, setExplanation] = useState('')
  const [isOneTransaction, setOneTransaction] = useState(false)

  const [associatedUsers, setAssociatedUsers] = useState<SelectModel[]>([])
  const [selectedAssociatedUsers, setSelectedAssignedUsers] = useState<SelectModel[]>([])

  const [selectedSubject, setSelectedSubject] = useState<SelectModel>(selectInitValues)

  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  const taskDefines: ITaskDefineModel[] = useSelector<RootState>(({ task }) => task.taskDefines, shallowEqual) as ITaskDefineModel[]

  const formik = useFormik<ITaskModel>({
    initialValues: task,
    enableReinitialize: true,
    validationSchema: TaskFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ITaskRequestModel = {
          id: values.id == '' ? undefined : values.id,
          taskDefineId: selectedSubject.value,
          explanation: explanation,
          order: values.order,
          assignedUsers: users.filter(x => x.check == true).map(i => i.id),
          associatedUsers: selectedAssociatedUsers.map(i => i.value),
          isOneTransaction: isOneTransaction,
          expirationAt: values.expirationAt == '' ? undefined : values.expirationAt,
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: ITaskRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = await TaskApi.AddTask(model)
      else {
        result = await TaskApi.UpdateTask(model)
      }

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: ITaskModel
    if (itemId !== '') {
      var response = await TaskApi.GetTask(itemId)
      result = response.data
      setOneTransaction(response.data.isOneTransaction)
      var subject = taskDefines.find(i => i.id == response.data.taskDefineId);
      handleChangeSubject({ label: subject?.title, value: subject?.id!! })
      if (result.expirationAt) result.expirationAt = result.expirationAt.substring(0, 19)
    } else {
      setAssociatedUsers([])

      result = taskInitValues
    }
    setTask(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  const getUserDatas = async (event: string, setUser: any) => {
    if (event.length > 1) {
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 5,
        orderBy: "firstName_asc",
        search: event
      }

      var filters: DictionaryModel[] = []
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var datas: SelectModel[] = [
        ...response.data.items.map((d) => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })),
      ]
      setUser(datas)
      return datas;
    }
    return [];
  }

  const getResponsibleUsers = async (userIds: string[]) => {
    if (userIds) {
      var responseUsers = await UserApi.GetUserDictionaryIds(userIds)
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setUsers([...dataUsers]);
    }
  }

  const handleChangeSelectUser = (e: any, index: number) => {
    var newUsers = users;
    newUsers[index].check = !newUsers[index].check;
    setUsers([...newUsers]);
  }

  const handleChangeSubject = (e: any) => {
    setSelectedSubject(e);
    getResponsibleUsers(taskDefines.find(i => i.id == e.value)?.responsibleUsers!!)
  }
  const handleChangeOneTransaction = () => {
    setOneTransaction(!isOneTransaction);
  }

  const handleAssociatedChange = (e: any) => {
    setSelectedAssignedUsers(e);
  }

  const loadAssociatedOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var usersData = await getUserDatas(inputValue, setAssociatedUsers);
        resolve(usersData);
      }, 1000);
    });

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-5 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Task</h1>
              <div className='text-muted fw-bold fs-5'>
                Task Management <small>Educall</small>
              </div>
            </div>

            <div className='row mb-6'>
              <div className={'col-' + (itemId === '' ? '6' : '9')}>
                <label className='form-label fw-bolder required'>Subject</label>
                <Select
                  {...formik.getFieldProps('taskDefineId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeSubject(event)}
                  value={selectedSubject}
                  options={taskDefines.map(s => ({ value: s.id, label: s.title.toUpperCase() }))}
                />
                <FormikValidationError touched={formik.touched.taskDefineId} error={formik.errors.taskDefineId} />
              </div>
              {itemId == '' &&
                <div className='col-lg-3'>
                  <label className='form-label fw-bolder required'>Associated Users</label>
                  <AsyncSelect
                    cacheOptions
                    {...formik.getFieldProps('associatedUsers')}
                    styles={customSelectStyles(true)}
                    isMulti={true}
                    loadOptions={debounce(loadAssociatedOptions, 500)}
                    onChange={(event) => handleAssociatedChange(event)}
                    defaultOptions={associatedUsers}
                    value={selectedAssociatedUsers}
                  />
                </div>
              }
              <div className='col-3'>
                <label className='form-label fw-bolder required'>Expiration At</label>
                <input
                  {...formik.getFieldProps('expirationAt')}
                  type='datetime-local'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Expiration At'
                />
                <FormikValidationError touched={formik.touched.expirationAt} error={formik.errors.expirationAt} />
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Explanation</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setExplanation}
                data={itemId != '' ? task.explanation : ''}
              />
            </div>
            {itemId == '' &&
              <>
                <div className='mb-5'>
                  <h4>To be assigned to {users.length} users</h4>
                  <div className='row'>
                    {users.map((item, index) => {
                      return (
                        <div className='col-4 mb-3' key={index}>
                          <label className='badge badge-light-primary w-100' key={index}>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                defaultChecked={item.check}
                                onChange={(e) => handleChangeSelectUser(e, index)}
                                value={item.email}
                              />
                              &nbsp; {item.email}
                            </div>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='form-check form-switch form-check-custom form-check-solid mb-10'>
                  <input
                    {...formik.getFieldProps('isOneTransaction')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isOneTransaction'
                    checked={isOneTransaction}
                    onClick={handleChangeOneTransaction}
                  />
                  <label className='form-check-label'>Is Enough One Transaction</label>
                </div>
              </>
            }
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { TaskFormModal }
