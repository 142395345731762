import API from './Api'
import { IChangeLevelsRequestModel } from '../../models/requests/ChangeLevelsRequestModel'
import { IDeleteTrainerPlanRequestModel } from '../../models/requests/DeleteTrainerPlanRequestModel'
import { ICancelTrainerPlanModel, IRemoveFreezeModel, ISchedulePlanModel, ITrainerDictionaryModel, ITrainerModel, ITrainerSettingsModel, ITrainersModel } from '../../models/responses/TrainerModel'
import { IChangeTrainerPlanRequestModel } from '../../models/requests/ChangeTrainerPlanRequestModel'
import { ITrainerRequestModel } from '../../models/requests/TrainerRequestModel'
import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IGetTrainerPlansRequestModel, IGetTrainerSchedulePlansRequestModel } from '../../models/requests/GetTrainerPlansRequestModel'
import { IGetTrainerPerformanceRequestModel } from '../../models/requests/GetTrainerPerformanceRequestModel'
import { ITrainerPerformanceModel } from '../../models/responses/TrainerPerformanceModel'
import { IAddSchedulePlanRequestModel } from '../../models/requests/AddSchedulePlanRequestModel'
import { IJoinLeaveSchedulePlanRequestModel } from '../../models/requests/JoinLeaveSchedulePlanRequestModel'
import { IGetAvailableTrainersRequestModel } from '../../models/requests/GetAvailableTrainersRequestModel'

class TrainerApi {
    async GetTrainer(id: string) {
        return await API.get<ITrainerModel>('s/Admin/Trainers/' + id)
    }

    async GetTrainerSettings(id: string) {
        return await API.get<ITrainerSettingsModel>('s/Admin/Trainers/Settings/' + id)
    }

    async UpdateAllTrainerAvailablePlans() {
        return await API.get<ITrainerSettingsModel>('s/Admin/Trainers/UpdateAllTrainerAvailablePlans')
    }

    async GetTrainerPlans(filter: IPaginationFilter, model: IGetTrainerPlansRequestModel) {
        return await API.post<PagedResponseModel<ITrainersModel[]>>(`s/Admin/Trainers/${generateQueryParams(filter)}`, model)
    }

    async GetTrainerSchedulePlans(filter: IPaginationFilter, model: IGetTrainerSchedulePlansRequestModel) {
        return await API.post<PagedResponseModel<ISchedulePlanModel[]>>(`s/Admin/Trainers/SchedulePlans/${generateQueryParams(filter)}`, model)
    }

    async GetTrainerSchedulePlan(id: string) {
        return await API.get<ISchedulePlanModel>(`s/Admin/Trainers/SchedulePlan/${id}`)
    }

    async JoinLeaveSchedulePlan(model: IJoinLeaveSchedulePlanRequestModel) {
        return await API.patch('s/Admin/Trainers/JoinLeave', model)
    }

    async AddSchedulePlan(model: IAddSchedulePlanRequestModel) {
        return await API.patch('s/Admin/Trainers/AddSchedulePlan', model)
    }

    async UpdateSchedulePlan(model: IAddSchedulePlanRequestModel) {
        return await API.patch('s/Admin/Trainers/UpdateSchedulePlan', model)
    }

    async GetAvailableTrainers(model: IGetAvailableTrainersRequestModel) {
        return await API.post<ITrainerDictionaryModel[]>('s/Admin/Trainers/AvailablePlans', model)
    }

    async ChangeLevels(model: IChangeLevelsRequestModel) {
        return await API.put('s/Admin/Trainers/ResponsibleLevel', model)
    }

    async ChangeTrainerPlan(model: IChangeTrainerPlanRequestModel) {
        return await API.patch('s/Admin/Trainers/ChangePlan', model)
    }

    async GetTrainerPerformance(id: string, model: IGetTrainerPerformanceRequestModel) {
        return await API.post<ITrainerPerformanceModel[]>('s/Admin/Trainers/Performance/' + id, model)
    }

    async UpdateTrainer(model: ITrainerRequestModel) {
        return await API.put('s/Admin/Trainers/' + model.id, model)
    }

    async DeleteCallPlan(model: IDeleteTrainerPlanRequestModel) {
        return await API.patch<boolean>('s/Admin/Trainers/DeletePlan', model)
    }

    async CancelTrainerPlan(model: ICancelTrainerPlanModel) {
        return await API.patch('s/Admin/Trainers/CancelTrainerPlan', model)
    }

    async RemoveFreeze(model: IRemoveFreezeModel) {
        return await API.patch('s/Admin/Trainers/RemoveFreeze', model)
    }

    async DeleteScheduleCallPlan(id: string) {
        return await API.patch<boolean>('s/Admin/Trainers/DeleteSchedulePlan/' + id)
    }
}

export default new TrainerApi()
