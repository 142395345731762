import { ProfileDetails } from './cards/ProfileDetails'
import { AccountInfos } from './cards/AccountInfos'
import { CareerDetails } from './cards/CareerDetails'
import { IUserModel } from '../../../../../models/responses/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'

export function Infos() {
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  return (
    <>
      <div className='row'>
        <div className='col-md-7'>
          <ProfileDetails />
        </div>
        <div className='col-md-5'>
          <AccountInfos />
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <CareerDetails />
          </div>
        </div>
      </div>
      {//user.roles?.some(s => s == RoleEnum.Student) &&
      }
      {/*      
      <SignInMethod />
      <ConnectedAccounts />
      <EmailPreferences />
      <Notifications />
      <DeactivateAccount />
      */}
    </>
  )
}
