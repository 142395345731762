import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { EmergencyListModal } from './_modals/EmergencyListModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'

const EmergencyPage: FC = () => {

  const [refresh, setRefresh] = useState<number>(0)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: string, name: string, type: string) => {
  }


  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
        </div>

        <div className='card-body py-3'>
          <EmergencyListModal refresh={refresh} onClickItem={onClickItem} />
        </div>
      </div>
    </>
  )
}

const EmergencyWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Emergencies of Trainers</PageTitle>
      <EmergencyPage />
    </>
  )
}

export { EmergencyWrapper }
