import { IPricingItemModel } from './PricingItemModel'

export interface IPricingModel {
  id: number
  minute: number
  day: number
  amount: number
  type: number
  pricingItems: Array<IPricingItemModel>
}

const pricingInitValues: IPricingModel = {
  id: 0,
  minute: 0,
  day: 0,
  type: 1,
  amount: 0,
  pricingItems: [],
}

export { pricingInitValues }
