import * as Yup from 'yup'

const ProfileDetailFormValidations = () => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().required().email('Email is not valid'),
        phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
        address: Yup.string().min(3, "Minimum 2 symbols"),

        /* photo: Yup.string(),
        birthDate: Yup.string().required(),
        gender: Yup.string(),
        countryId: Yup.string(),
        cityId: Yup.string(),
        stateId: Yup.string(),
        zipCode: Yup.string(),
        language: Yup.string(),
        timeZone: Yup.string(),
        currency: Yup.string()*/
    })
}

export { ProfileDetailFormValidations }