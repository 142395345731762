export interface IExamTypeModel {
    id: string,
    title: string,
    isVisible: boolean
}

const examTypeInitValues = {
    id: '',
    title: '',
    isVisible: true
}

export { examTypeInitValues }