
import { FC, useEffect, useState } from "react";
import Select from 'react-select'
import { customSelectStyles } from "../../../helpers/ReactSelectHelper";
import { SelectModel, selectInitValues } from "../../../models/SelectModel";
import { useRegionHelper } from "../../../helpers/RegionHelper";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from "moment";
import JobApi from "../../../../infrastructure/api/JobApi";
import { KTSVG } from '../../../../_metronic/helpers'
import { DictionaryModel } from "../../../../models/responses/DictionaryModel";

type Props = {
    selectedGender: SelectModel
    setSelectedGender: (event: any) => void
    selectedStatus: SelectModel
    setSelectedStatus: (event: any) => void
    selectedPhoneConfirmed: SelectModel
    setSelectedPhoneConfirmed: (event: any) => void
    selectedEmailConfirmed: SelectModel
    setSelectedEmailConfirmed: (event: any) => void
    selectedNeverLoggedIn: SelectModel
    setSelectedNeverLoggedIn: (event: any) => void
    selectedTestAccount: SelectModel
    setSelectedTestAccount: (event: any) => void
    selectedBirthday: SelectModel
    setSelectedBirthday: (event: any) => void
    selectedRegistrationDate: SelectModel
    setSelectedRegistrationDate: (event: any) => void
    selectedSelfRegistered: SelectModel
    setSelectedSelfRegistered: (event: any) => void
    selectedJob: SelectModel
    setSelectedJob: (event: any) => void
    selectedHeardOn: SelectModel
    setSelectedHeardOn: (event: any) => void
    selectedCountry: SelectModel
    setSelectedCountry: (event: any) => void
    selectedCity: SelectModel
    setSelectedCity: (event: any) => void
    start: moment.Moment
    setStart: (event: any) => void
    end: moment.Moment
    setEnd: (event: any) => void
    startRegistration: moment.Moment
    setStartRegistration: (event: any) => void
    endRegistration: moment.Moment
    setEndRegistration: (event: any) => void
    startNeverLoggedIn: moment.Moment
    setStartNeverLoggedIn: (event: any) => void
    endNeverLoggedIn: moment.Moment
    setEndNeverLoggedIn: (event: any) => void
    setFilterType: (value: string) => void
    setPageNumber: (page: number) => void
    setResult: (filters: any) => void
}

const UserFilters: FC<Props> = ({
    selectedGender, setSelectedGender,
    selectedStatus, setSelectedStatus,
    selectedPhoneConfirmed, setSelectedPhoneConfirmed,
    selectedEmailConfirmed, setSelectedEmailConfirmed,
    selectedNeverLoggedIn, setSelectedNeverLoggedIn,
    selectedTestAccount, setSelectedTestAccount,
    selectedBirthday, setSelectedBirthday,
    selectedRegistrationDate, setSelectedRegistrationDate,
    selectedSelfRegistered, setSelectedSelfRegistered,
    selectedJob, setSelectedJob,
    selectedHeardOn, setSelectedHeardOn,
    selectedCountry, setSelectedCountry,
    selectedCity, setSelectedCity,
    start, setStart,
    end, setEnd,
    startRegistration, setStartRegistration,
    endRegistration, setEndRegistration,
    startNeverLoggedIn, setStartNeverLoggedIn,
    endNeverLoggedIn, setEndNeverLoggedIn,
    setFilterType, setPageNumber, setResult
}) => {

    const [selectedState, setSelectedState] = useState<SelectModel>(selectInitValues)
    const [jobs, setJobs] = useState<SelectModel[]>([])

    const genders = [selectInitValues, { label: "Male", value: "M" }, { label: "Female", value: "F" }]
    const statuses = [selectInitValues, { label: "Active", value: "1" }, { label: "Passive", value: "0" }]
    const yesno = [selectInitValues, { label: "Yes", value: "1" }, { label: "No", value: "0" }]
    const yes = [selectInitValues, { label: "Yes", value: "1" }]
    const [countries, setCountries] = useState<SelectModel[]>([])
    const [cities, setCities] = useState<SelectModel[]>([])
    const [states, setStates] = useState<SelectModel[]>([])
    const regionHelper = useRegionHelper([], "", setCountries, setCities, setStates, setSelectedCountry, setSelectedCity, setSelectedState)
    const educallHeards = [
        selectInitValues,
        { value: "1", label: "Internet Advertising" },
        { value: "2", label: "Social Media" },
        { value: "3", label: "Friend Recommendation" },
        { value: "4", label: "Search Engine" },
    ]

    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(3, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false,
    }
    useEffect(() => {
        if (countries.length < 1) {
            init();
        }
    }, []);

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
    }


    const applyCallbackRegistration = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStartRegistration(startDate)
        setEndRegistration(endDate)
    }

    const applyCallbackNeverLoggedIn = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStartNeverLoggedIn(startDate)
        setEndNeverLoggedIn(endDate)
    }

    async function setFilters() {
        setFilterType("User");
        setPageNumber(1);

        var filters: DictionaryModel[] = []
        if (selectedGender.value != "") filters.push({ type: "Gender", value: selectedGender.value })
        if (selectedStatus.value != "") filters.push({ type: "IsActive", value: selectedStatus.value })
        if (selectedPhoneConfirmed.value != "") filters.push({ type: "PhoneNumberConfirmed", value: selectedPhoneConfirmed.value })
        if (selectedEmailConfirmed.value != "") filters.push({ type: "EmailConfirmed", value: selectedEmailConfirmed.value })
        if (selectedSelfRegistered.value != "") filters.push({ type: "SelfRegistered", value: selectedSelfRegistered.value })
        if (selectedTestAccount.value != "") filters.push({ type: "IsTestAccount", value: selectedTestAccount.value })

        if (selectedJob.value != "") filters.push({ type: "JobId", value: selectedJob.value })
        if (selectedHeardOn.value != "") filters.push({ type: "EnglishNeeds", value: selectedHeardOn.value })
        if (selectedCountry.value != "") filters.push({ type: "CountryId", value: selectedCountry.value })
        if (selectedCity.value != "") filters.push({ type: "CityId", value: selectedCity.value })

        if (selectedNeverLoggedIn.value != "") {
            filters.push({ type: "NeverLoggedIn", value: `${startNeverLoggedIn.format("YYYY-MM-DDT00:00:00")}/${endNeverLoggedIn.format("YYYY-MM-DDT00:00:00")}` })
        }
        if (selectedRegistrationDate.value != "") {
            filters.push({ type: "RegistrationStartDate", value: startRegistration.format("YYYY-MM-DDT00:00:00") })
            filters.push({ type: "RegistrationEndDate", value: endRegistration.format("YYYY-MM-DDT23:59:59") })
        }
        if (selectedBirthday.value != "") {
            filters.push({ type: "BirthdayStartDate", value: start.format("YYYY-MM-DDT00:00:00") })
            filters.push({ type: "BirthdayEndDate", value: end.format("YYYY-MM-DDT23:59:59") })
        }

        setResult(filters)
    }


    async function init() {

        regionHelper.getCountries();

        let jobs = await JobApi.GetJobs()
        var dataJobs: SelectModel[] = [selectInitValues,
            ...jobs.data.map((d) => ({
                value: d.id.toString(),
                label: d.name,
            }))
        ]
        setJobs(dataJobs);
    }

    return (<>
        <div className='row'>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedGender.value != "" && "text-success"}`}>Gender</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedGender(event!)}
                    value={selectedGender}
                    options={genders}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedStatus.value != "" && "text-success"}`}>Status</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedStatus(event!)}
                    value={selectedStatus}
                    options={statuses}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedPhoneConfirmed.value != "" && "text-success"}`}>Phone Confirmed</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedPhoneConfirmed(event!)}
                    value={selectedPhoneConfirmed}
                    options={yesno}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedEmailConfirmed.value != "" && "text-success"}`}>Email Confirmed</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedEmailConfirmed(event!)}
                    value={selectedEmailConfirmed}
                    options={yesno}
                />
            </div>

            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedTestAccount.value != "" && "text-success"}`}>Test Account</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedTestAccount(event!)}
                    value={selectedTestAccount}
                    options={yesno}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedJob.value != "" && "text-success"}`}>Job</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedJob(event!)}
                    value={selectedJob}
                    options={jobs}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedBirthday.value != "" && "text-success"}`}>Birthday</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedBirthday(event!)}
                    value={selectedBirthday}
                    options={yes}
                />
                {selectedBirthday.value == "1" &&
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={start}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={end}
                        local={local}
                        applyCallback={applyCallback}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${start.format(local.format)} - ${end.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                }
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedRegistrationDate.value != "" && "text-success"}`}>Registration Date</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedRegistrationDate(event!)}
                    value={selectedRegistrationDate}
                    options={yes}
                />
                {selectedRegistrationDate.value == "1" &&
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={startRegistration}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={endRegistration}
                        local={local}
                        applyCallback={applyCallbackRegistration}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${startRegistration.format(local.format)} - ${endRegistration.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                }
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedSelfRegistered.value != "" && "text-success"}`}>Self Registered</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedSelfRegistered(event!)}
                    value={selectedSelfRegistered}
                    options={yes}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedHeardOn.value != "" && "text-success"}`}>Reference</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedHeardOn(event!)}
                    value={selectedHeardOn}
                    options={educallHeards}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedNeverLoggedIn.value != "" && "text-success"}`}>Never Logged In</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedNeverLoggedIn(event!)}
                    value={selectedNeverLoggedIn}
                    options={yes}
                />
                {selectedNeverLoggedIn.value == "1" &&
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={startNeverLoggedIn}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={endNeverLoggedIn}
                        local={local}
                        applyCallback={applyCallbackNeverLoggedIn}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${startNeverLoggedIn.format(local.format)} - ${endNeverLoggedIn.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                }
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedCountry.value != "" && "text-success"}`}>Country</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => regionHelper.handleChangeCountry(event)}
                    value={selectedCountry}
                    options={countries}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedCity.value != "" && "text-success"}`}>City</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => regionHelper.handleChangeCity(event)}
                    value={selectedCity}
                    options={cities}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col text-end'>
                <a
                    href='#'
                    onClick={() => { setFilters(); }}
                    className='btn btn-sm btn-success'
                >
                    <KTSVG
                        path='/media/icons/duotone/General/Save.svg'
                        className='svg-icon-3'
                    />
                    Set User Filters
                </a>
            </div>
        </div></>)
}


export { UserFilters }