export interface ISendNotificationRequestModel {
  id?: string,
  title?: string,
  message: string,
  channel: number,
  type?: number,
  image?: string,
  plannedAt: string,
  params?: string,
  tokens?: string[],
  userIds?: string[]
}

const sendNotificationRequestInitValues: ISendNotificationRequestModel = {
  id: undefined,
  title: '',
  message: '',
  channel: 0,
  type: 0,
  image: '',
  plannedAt: '',
  params: '',
  tokens: [],
  userIds: []
}

export { sendNotificationRequestInitValues }
