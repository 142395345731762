import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { StudentDetailFormValidations } from '../../../../../validations/StudentDetailFormValidations'
import { IStudentDetailsFormModel, studentDetailsInitValues as initialValues } from '../../../../../models/StudentDetailsFormModel'
import UserApi from '../../../../../../infrastructure/api/UserApi'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import * as account from '../../../redux/AccountRedux'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { IUserClaimsRequestModel } from '../../../../../../models/requests/UserClaimsRequestModel'
import { ClaimTypeEnum } from '../../../../../../enums/ClaimTypeEnum'
import { FormikValidationError } from '../../../../../components/validations/FormikValidationError'
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../../../models/SelectModel'
import { DictionaryModel } from '../../../../../../models/responses/DictionaryModel'
import { IPaginationFilter } from '../../../../../../models/requests/PaginationFilter'
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import { IUserDictionaryModel } from '../../../../../../models/responses/UserDictionaryModel'
import CompanyApi from '../../../../../../infrastructure/api/CompanyApi'
import StudentApi from '../../../../../../infrastructure/api/StudentApi'
import { IStudentRequestModel } from '../../../../../../models/requests/StudentRequestModel'
import PermissionProcessApi from '../../../../../../infrastructure/api/PermissionProcessApi'
import { PermissionTypeEnum } from '../../../../../../enums/PermissionTypeEnum'
import debounce from 'es6-promise-debounce'
import LevelApi from '../../../../../../infrastructure/api/LevelApi'
import * as level from '../../../../../pages/levels/redux/LevelRedux'
import { IStudentModel, studentInitValues } from '../../../../../../models/responses/StudentModel'
import { EnumToList } from '../../../../../helpers/EnumHelper'
import { TolkPackageEnum } from '../../../../../../enums/TolkPackageEnum'
import { PreferCallingPlatformEnum } from '../../../../../../enums/PreferCallingPlatformEnum'
import EducallUserApi from '../../../../../../infrastructure/api/EducallUserApi'
import { IEducallUserRequestModel } from '../../../../../../models/requests/EducallUserRequestModel'

const StudentDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const [student, setStudent] = useState<IStudentModel>(studentInitValues)

  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)

  const [selectedStartupLevel, setSelectedStartupLevel] = useState<SelectModel>(selectInitValues)
  const [selectedPresentLevel, setSelectedPresentLevel] = useState<SelectModel>(selectInitValues)

  const [data, setData] = useState<IStudentDetailsFormModel>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IStudentDetailsFormModel>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [isOurStudent, setIsOurStudent] = useState<boolean>(false)
  const [isAggrementAccepted, setAggrementAccepted] = useState<boolean>(false)
  const [isSmsActive, setSmsActive] = useState<boolean>(false)
  const [isEmailActive, setEmailActive] = useState<boolean>(false)
  const [isToeflActive, setToeflActive] = useState<boolean>(false)
  const [isYdsActive, setYdsActive] = useState<boolean>(false)
  const [isSupportByCompany, setSupportByCompany] = useState<boolean>(false)
  const [isSupportByEducall, setSupportByEducall] = useState<boolean>(false)
  const [isSafeExam, setSafeExam] = useState<boolean>(false)


  const [tolkRemaining, setTolkRemaining] = useState<number>(0)
  const [examFinisher, setExamFinisher] = useState<number>(0)
  const [edupoint, setEdupoint] = useState<number>(0)


  const [isJobInterview, setJobInterview] = useState<boolean>(false)
  const [isDemoStudent, setDemoStudent] = useState<boolean>(false)

  const [parents, setParents] = useState<SelectModel[]>([selectInitValues])
  const [selectedParent, setSelectedParent] = useState<SelectModel>(selectInitValues)

  const [selectedTolkPackage, setSelectedTolkPackage] = useState<SelectModel>(selectInitValues)

  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const tolkPackagesList = EnumToList(TolkPackageEnum)
  const callingPlatformList = EnumToList(PreferCallingPlatformEnum)
  const [selectedCallingPlatform, setSelectedCallingPlatform] = useState<SelectModel>(selectInitValues)


  const formik = useFormik<IStudentDetailsFormModel>({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: StudentDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        processUser(values)
      }, 500)
    },
  })

  async function processUser(values: IStudentDetailsFormModel) {
    try {

      let modelStudent: IStudentRequestModel = {
        id: user.id,
        companyId: selectedCompany.value == "" ? undefined : selectedCompany.value,
        parentId: selectedParent.value == "" ? undefined : selectedParent.value,
        presentLevelId: parseInt(selectedPresentLevel.value),
        startupLevelId: parseInt(selectedStartupLevel.value),
        isJobInterview: isJobInterview,
        isDemoStudent: isDemoStudent,
        isSupportByCompany: isSupportByCompany,
        isSupportByEducall: isSupportByEducall,
        isOurStudent: isOurStudent,
        isToeflActive: isToeflActive,
        isYdsActive: isYdsActive,
        tolkPackage: parseInt(selectedTolkPackage.value),
        tolkRemaining: tolkRemaining,
        preferCallingPlatform: parseInt(selectedCallingPlatform.value),
        educationStartDate: values.educationStartDate == '' ? undefined : values.educationStartDate,
        educationEndDate: values.educationEndDate == '' ? undefined : values.educationEndDate
      }

      await StudentApi.UpdateStudent(modelStudent);

      if ((user.details?.find(i => i.type == ClaimTypeEnum.IsEmailActive)?.value!! == "1") != isEmailActive) {
        await PermissionProcessApi.AddPermissionProcess({
          userId: user.id,
          type: PermissionTypeEnum.Email,
          isActive: isEmailActive
        });
      }
      if ((user.details?.find(i => i.type == ClaimTypeEnum.IsSmsActive)?.value!! == "1") != isSmsActive) {

        await PermissionProcessApi.AddPermissionProcess({
          userId: user.id,
          type: PermissionTypeEnum.Sms,
          isActive: isSmsActive
        });
      }

      if ((user.details?.find(i => i.type == ClaimTypeEnum.IsAggrementAccepted)?.value!! == "1") != isSmsActive) {

        await PermissionProcessApi.AddPermissionProcess({
          userId: user.id,
          type: PermissionTypeEnum.UserAgreement,
          isActive: isAggrementAccepted
        });
      }

      let modelEducallUser: IEducallUserRequestModel = {
        id: user.id,
        edupoint: edupoint,
        companyId: selectedCompany.value == "" ? undefined : selectedCompany.value
      }
      await EducallUserApi.UpdateUser(modelEducallUser);


      let modelClaims: IUserClaimsRequestModel = {
        id: user.id,
        claims: [
          { type: ClaimTypeEnum.IsAggrementAccepted, value: (isAggrementAccepted ? 1 : 0).toString() },
          { type: ClaimTypeEnum.IsSmsActive, value: (isSmsActive ? 1 : 0).toString() },
          { type: ClaimTypeEnum.IsEmailActive, value: (isEmailActive ? 1 : 0).toString() },
          { type: ClaimTypeEnum.IsSafeExam, value: (isSafeExam ? 1 : 0).toString() },
          { type: ClaimTypeEnum.ExamFinisher, value: examFinisher.toString() },
          { type: ClaimTypeEnum.Edupoint, value: edupoint.toString() },
          { type: ClaimTypeEnum.ParentId, value: selectedParent.value!!.toString() },
          { type: ClaimTypeEnum.CompanyId, value: selectedCompany.value },
          { type: ClaimTypeEnum.PresentLevelId, value: selectedPresentLevel.value.toString() },
          { type: ClaimTypeEnum.StartupLevelId, value: selectedStartupLevel.value.toString() },
        ]
      }


      await UserApi.UpdateUserClaims(modelClaims);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const getParentDatas = async (event: string) => {
    if (event.length > 1) {
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 5,
        orderBy: "firstName_asc",
        search: event
      }

      var filters: DictionaryModel[] = [{ type: "Role", value: "Student" }]
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var dataParents: SelectModel[] = [
        ...response.data.items.map((d) => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })),
      ]
      setParents(dataParents)
      return dataParents;
    }
    return [];
  }

  const init = async () => {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    } else {
      var dataLevels = levelDictionary;
    }


    var responseStudent = await StudentApi.GetStudent(user.id)
    if (responseStudent.data != null) {
      setStudent(responseStudent.data)
      setDemoStudent(responseStudent.data.isDemoStudent)
      setJobInterview(responseStudent.data.isJobInterview)
      setToeflActive(responseStudent.data.isToeflActive)
      setYdsActive(responseStudent.data.isYdsActive)
      setIsOurStudent(responseStudent.data.isOurStudent)
      setSupportByCompany(responseStudent.data.isSupportByCompany)
      setSupportByEducall(responseStudent.data.isSupportByEducall)
      var responseEducallUser = await EducallUserApi.GetUser(user.id);
      if (responseStudent.data.tolkPackage != null && responseStudent.data.tolkPackage > 0) {
        handleChangeTolkPackage({
          value: responseStudent.data.tolkPackage,
          label: `${tolkPackagesList.find(i => i.value == responseStudent.data.tolkPackage?.toString())?.value} Minutes`
        })
      }

      if (responseStudent.data.preferCallingPlatform != null && responseStudent.data.preferCallingPlatform > 0) {
        handleChangeCallingPlatform({
          value: responseStudent.data.preferCallingPlatform,
          label: `${callingPlatformList.find(i => i.value == responseStudent.data.preferCallingPlatform?.toString())?.label}`
        })
      }

      if (responseStudent.data!!.presentLevelId != null) {
        handleChangePresentLevel({
          value: responseStudent.data.presentLevelId,
          label: dataLevels.find(i => i.value == responseStudent.data!!.presentLevelId?.toString())?.label
        })
      }

      if (responseStudent.data!!.startupLevelId != null) {
        handleChangeStartupLevel({
          value: responseStudent.data.startupLevelId,
          label: dataLevels.find(i => i.value == responseStudent.data!!.startupLevelId?.toString())?.label
        })
      }

      var newData = data;
      newData.educationStartDate = responseStudent.data.educationStartDate.substring(0, 16);
      newData.educationEndDate = responseStudent.data.educationEndDate.substring(0, 16);
      setData(newData)

    }

    var companies = await CompanyApi.GetSubCompaniesDictionary()
    var dataParentCompanies: SelectModel[] = [selectInitValues,
      ...companies.data.map((d) => ({
        value: d.id.toString(),
        label: d.name,
      })),
    ]
    setCompanies(dataParentCompanies)


    var companyId = user.details?.find(i => i.type == ClaimTypeEnum.CompanyId)?.value!! || '';
    if (companyId != null && companyId != '') {
      handleChangeCompany({
        value: companyId,
        label: dataParentCompanies.find(i => i.value == companyId)?.label
      })
    }

    let parentId = user.details?.find(i => i.type == ClaimTypeEnum.ParentId)?.value || '';
    if (parentId != '') {
      var responseUsers = await UserApi.GetUserDictionaryIds([user.details?.find(i => i.type == ClaimTypeEnum.ParentId)?.value!!])
      var dataUsers: IUserDictionaryModel[] = responseUsers.data

      let parent = dataUsers.find(i => i.id == parentId);
      handleChangeParent({
        value: parentId,
        label: `${parent?.firstName} ${parent?.lastName} - ${parent?.email}`
      })
    }
    setEdupoint(responseEducallUser!.data.edupoint || 0)
    setTolkRemaining(responseStudent.data.tolkRemaining || 0)
    setExamFinisher(parseInt(user.details?.find(i => i.type == ClaimTypeEnum.ExamFinisher)?.value!!) || 0)
    setAggrementAccepted(parseInt(user.details?.find(i => i.type == ClaimTypeEnum.IsAggrementAccepted)?.value!!) == 1)
    setSmsActive(parseInt(user.details?.find(i => i.type == ClaimTypeEnum.IsSmsActive)?.value!!) == 1)
    setEmailActive(parseInt(user.details?.find(i => i.type == ClaimTypeEnum.IsEmailActive)?.value!!) == 1)
    setSafeExam(parseInt(user.details?.find(i => i.type == ClaimTypeEnum.IsSafeExam)?.value!!) == 1)
  }

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user])

  const handleChangeIsOurStudent = () => {
    setIsOurStudent(!isOurStudent)
  }

  const handleChangeDemoStudent = () => {
    setDemoStudent(!isDemoStudent)
  }

  const handleChangeJobInterview = () => {
    setJobInterview(!isJobInterview)
  }
  const handleChangeAggrementAccepted = () => {
    setAggrementAccepted(!isAggrementAccepted)
  }
  const handleChangeSmsActive = () => {
    setSmsActive(!isSmsActive)
  }
  const handleChangeEmailActive = () => {
    setEmailActive(!isEmailActive)
  }
  const handleChangeToeflActive = () => {
    setToeflActive(!isToeflActive)
  }
  const handleChangeYdsActive = () => {
    setYdsActive(!isYdsActive)
  }
  const handleChangeSupportByCompany = () => {
    setSupportByCompany(!isSupportByCompany)
  }
  const handleChangeSupportByEducall = () => {
    setSupportByEducall(!isSupportByEducall)
  }
  const handleChangeSafeExam = () => {
    setSafeExam(!isSafeExam)
  }

  const handleChangeParent = (e: any) => {
    setSelectedParent(e);
  }

  const handleInputChange = (newValue: string) => {
    return newValue;
  };



  const loadOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var data = await getParentDatas(inputValue);
        setParents([selectInitValues, ...data]);
        resolve(data);
      }, 1000);
    });


  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  const handleChangePresentLevel = (e: any) => {
    setSelectedPresentLevel(e);
  }

  const handleChangeStartupLevel = (e: any) => {
    setSelectedStartupLevel(e);
  }

  const handleChangeTolkPackage = (e: any) => {
    setSelectedTolkPackage(e);
  }

  const handleChangeCallingPlatform = (e: any) => {
    setSelectedCallingPlatform(e);
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Student Config</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Our Student</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isOurStudent')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isOurStudent'
                    checked={isOurStudent}
                    onClick={handleChangeIsOurStudent}
                  />
                  <FormikValidationError touched={formik.touched.isOurStudent} error={formik.errors.isOurStudent} />
                </div>
              </div>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Aggrement Accepted</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isTestAccount')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isAggrementAccepted'
                    checked={isAggrementAccepted}
                    onClick={handleChangeAggrementAccepted}
                  />
                  <FormikValidationError touched={formik.touched.isAggrementAccepted} error={formik.errors.isAggrementAccepted} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Demo Account</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isDemoStudent')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isDemoStudent'
                    checked={isDemoStudent}
                    onClick={handleChangeDemoStudent}
                  />
                  <FormikValidationError touched={formik.touched.isDemoStudent} error={formik.errors.isDemoStudent} />
                </div>
              </div>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Job Interview</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isJobInterview')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isJobInterview'
                    checked={isJobInterview}
                    onClick={handleChangeJobInterview}
                  />
                  <FormikValidationError touched={formik.touched.isJobInterview} error={formik.errors.isJobInterview} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Toefl Active</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isToeflActive')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isToeflActive'
                    checked={isToeflActive}
                    onClick={handleChangeToeflActive}
                  />
                  <FormikValidationError touched={formik.touched.isToeflActive} error={formik.errors.isToeflActive} />
                </div>
              </div>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Yds Active</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isYdsActive')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isYdsActive'
                    checked={isYdsActive}
                    onClick={handleChangeYdsActive}
                  />
                  <FormikValidationError touched={formik.touched.isYdsActive} error={formik.errors.isYdsActive} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>SMS Active</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isSmsActive')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isSmsActive'
                    checked={isSmsActive}
                    onClick={handleChangeSmsActive}
                  />
                  <FormikValidationError touched={formik.touched.isSmsActive} error={formik.errors.isSmsActive} />
                </div>
              </div>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>E-Mail Active</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isEmailActive')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isEmailActive'
                    checked={isEmailActive}
                    onClick={handleChangeEmailActive}
                  />
                  <FormikValidationError touched={formik.touched.isEmailActive} error={formik.errors.isEmailActive} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Support by Educall</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isSupportByEducall')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isSupportByEducall'
                    checked={isSupportByEducall}
                    onClick={handleChangeSupportByEducall}
                  />
                  <FormikValidationError touched={formik.touched.isSupportByEducall} error={formik.errors.isSupportByEducall} />
                </div>
              </div>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Support by Company</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isSupportByCompany')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isSupportByCompany'
                    checked={isSupportByCompany}
                    onClick={handleChangeSupportByCompany}
                  />
                  <FormikValidationError touched={formik.touched.isSupportByCompany} error={formik.errors.isSupportByCompany} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Safe Exam</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isSafeExam')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isSafeExam'
                    checked={isSafeExam}
                    onClick={handleChangeSafeExam}
                  />
                  <FormikValidationError touched={formik.touched.isSupportByCompany} error={formik.errors.isSafeExam} />
                </div>
              </div>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Exam Finisher</label>
              <div className='col-lg-2 fv-row'>
                <input
                  {...formik.getFieldProps('examFinisher')}
                  type='number'
                  value={examFinisher}
                  onChange={(e) => setExamFinisher(parseInt(e.target.value))}
                  className='form-control form-control-sm form-control-solid'
                />
                <FormikValidationError touched={formik.touched.examFinisher} error={formik.errors.examFinisher} />

              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Calling Platform</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('preferedCallingPlatform')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeCallingPlatform(event)}
                  value={selectedCallingPlatform}
                  options={callingPlatformList.map((d) => ({
                    value: d.value.toString(),
                    label: d.label?.toString()
                  }))}
                />
                <FormikValidationError touched={formik.touched.preferCallingPlatform} error={formik.errors.preferCallingPlatform} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Tolk Package</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('tolkPackage')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeTolkPackage(event)}
                  value={selectedTolkPackage}
                  options={tolkPackagesList.map((d) => ({
                    value: d.value.toString(),
                    label: `${d.value.toString()} Minutes`
                  }))}
                />
                <FormikValidationError touched={formik.touched.tolkPackage} error={formik.errors.tolkPackage} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Tolk Remaining</label>
              <div className='col-lg-5 fv-row'>
                <input
                  {...formik.getFieldProps('tolkRemaining')}
                  type='number'
                  value={tolkRemaining}
                  onChange={(e) => setTolkRemaining(parseInt(e.target.value))}
                  className='form-control form-control-lg form-control-solid'
                />
                <FormikValidationError touched={formik.touched.tolkRemaining} error={formik.errors.tolkRemaining} />
              </div>
              <div className='col-lg-2 col-form-label'><label className='badge badge-info'>{(tolkRemaining / parseInt(selectedTolkPackage.value)).toFixed(2).replace(/\.00$/, '')} Call</label>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Education Start Date</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('educationStartDate')}
                  type='datetime-local'
                  className='form-control form-control-solid'
                />
                <FormikValidationError touched={formik.touched.educationStartDate} error={formik.errors.educationStartDate} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Education End Date</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('educationEndDate')}
                  type='datetime-local'
                  className='form-control form-control-solid'
                />
                <FormikValidationError touched={formik.touched.educationEndDate} error={formik.errors.educationEndDate} />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Edupoint</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('edupoint')}
                  type='number'
                  value={edupoint}
                  onChange={(e) => setEdupoint(parseInt(e.target.value))}
                  className='form-control form-control-lg form-control-solid'
                />
                <FormikValidationError touched={formik.touched.edupoint} error={formik.errors.edupoint} />

              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Present Level</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('presentLevelId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangePresentLevel(event)}
                  value={selectedPresentLevel}
                  options={levelDictionary}
                />
                <FormikValidationError touched={formik.touched.presentLevelId} error={formik.errors.presentLevelId} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Startup Level</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('startupLevelId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeStartupLevel(event)}
                  value={selectedStartupLevel}
                  options={levelDictionary}
                />
                <FormikValidationError touched={formik.touched.startupLevelId} error={formik.errors.startupLevelId} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Company</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('companyId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeCompany(event)}
                  value={selectedCompany}
                  options={companies}
                />
                <FormikValidationError touched={formik.touched.companyId} error={formik.errors.companyId} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Parent User</label>
              <div className='col-lg-8 fv-row'>
                <AsyncSelect
                  cacheOptions
                  {...formik.getFieldProps('parentId')}
                  styles={customSelectStyles(true)}
                  loadOptions={debounce(loadOptions, 500)}
                  onChange={(event) => handleChangeParent(event)}
                  defaultOptions={parents}
                  value={selectedParent}
                />
                <FormikValidationError touched={formik.touched.parentId} error={formik.errors.parentId} />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div >
    </div >
  )
}

export { StudentDetails }
