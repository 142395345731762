export interface IShopOrderModel {
  id: number
  shopProductId: number
  shopProductName: string
  edupoint: number
  status: string
  date: string
}

const shopOrderInitValues: IShopOrderModel = {
  id: 0,
  shopProductId: 0,
  shopProductName: '',
  edupoint: 0,
  status: '',
  date: '',
}

export {shopOrderInitValues}
