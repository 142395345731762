import { FC, useEffect, useState } from 'react'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { ISchedulesModel } from '../../../../models/responses/ScheduleModel'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { Link } from 'react-router-dom'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { EnumToList } from '../../../helpers/EnumHelper'
import { Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import { ScheduleStatusColorizer } from '../../../functions/ScheduleStatusColorizer'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const ScheduleListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('title_asc')
    const [schedules, setSchedules] = useState<PagedResponseModel<ISchedulesModel[]>>()
    const [scheduleStatus, setScheduleStatus] = useState<ScheduleStatusEnum>()
    const [scheduleType, setScheduleType] = useState<ScheduleTypeEnum>()
    let typeList: string[] = EnumToList(ScheduleTypeEnum).map(i => (i.label?.toString() || '')) || [];
    let statusList: string[] = EnumToList(ScheduleStatusEnum).map(i => (i.label?.toString() || '')) || [];

    let weekDay = moment().utc().day()
    let startTime = moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let endTime = moment(startTime).add(1, "days").subtract(1, "seconds");

    const [start, setStart] = useState(startTime)
    const [end, setEnd] = useState(endTime)

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
        init(startDate, endDate)
    }

    let ranges = {
        'Today': [moment().utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), moment().utc().set({ hour: 23, minute: 59, second: 59 })],
        'Yesterday': [moment().utc().subtract(1, 'days').set({ hour: 0, minute: 0, second: 0 }), moment().utc().subtract(1, 'days').set({ hour: 23, minute: 59, second: 59 })]
    }
    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false,
    }

    async function init(startTime: moment.Moment, endTime: moment.Moment) {

        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText
        }

        var response = await ScheduleApi.GetSchedules(paginationFilter, {
            dayOfWeek: weekDay,
            startDate: startTime.format(),
            endDate: endTime.format(),
            type: scheduleType,
            status: scheduleStatus
        })
        setSchedules(response.data)
    }

    useEffect(() => {
        init(start, end)
    }, [refresh, pageNumber, pageSize, searchText, sort, scheduleType, scheduleStatus])

    return (
        <>
            <div className='row'>
                <div className='col-lg-3'>
                    <label className='form-label fw-bolder'>Date Range</label>
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={start}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={end}
                        local={local}
                        applyCallback={applyCallback}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${start.format(local.format)} - ${end.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                </div>
            </div>
            <GenericTable
                title='Schedule'
                data={schedules?.items}
                currentTotalRecords={schedules?.totalRecords!!}
                currentPageNumber={schedules?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: '#',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                            },
                            sort: false,
                            viewColumns: false,
                            filter: false
                        },
                    },
                    {
                        label: "Student", name: "students", options: {
                            sort: false,
                            customBodyRenderLite: (dataIndex) => {
                                return <b>{schedules?.items[dataIndex]?.title}{
                                    schedules?.items[dataIndex]?.students.map((item: any) => {
                                        return (
                                            <b>
                                                <ButtonToolbar>
                                                    <OverlayTrigger
                                                        key={item.id}
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id={`tooltip-bottom`}>
                                                                <ul>
                                                                    <li>{item.email}</li>
                                                                    <li>{item.phoneNumber}</li>
                                                                </ul>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Link className='btn-sm' to={'/account/users/' + item?.id + '/overview'}>{item?.firstName} {item?.lastName}</Link>
                                                    </OverlayTrigger>
                                                </ButtonToolbar>
                                                <br /></b>
                                        )
                                    })
                                }</b>
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Planned", name: "plannedDate", options: {
                            sort: false,
                            customBodyRender: (value) => {
                                return <>{value}</>
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Status", name: "status", options: {
                            sort: false,
                            customBodyRender: (value) => {
                                return <span className={`badge bg-${ScheduleStatusColorizer(value)}`}>
                                    {ScheduleStatusEnum[value]}</span>
                            },
                            filterOptions: { fullWidth: true, names: statusList }
                        }
                    },
                    {
                        label: "Trainer", name: "trainer", options: {
                            sort: false,
                            customBodyRender: (value) => {
                                return <b><Link to={'/account/users/' + value?.id + '/overview'}>{value?.firstName} {value?.lastName}</Link><br /></b>
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Type", name: "type", options: {
                            sort: false,
                            customBodyRender: (value) => {
                                return <>{ScheduleTypeEnum[value]}</>
                            },
                            filterOptions: { fullWidth: true, names: typeList }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={schedules?.items[dataIndex]?.id!!}
                                        itemParam={schedules?.items[dataIndex]?.plannedDate!!}
                                        types={[TableHelperButtonEnum.View, TableHelperButtonEnum.Delete, TableHelperButtonEnum.Update]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            searchable: false,
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    filter: true,
                    onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                        setPageNumber(1)
                        setScheduleStatus(undefined)
                        setScheduleType(undefined)
                        for (let i = 0; i < filterList.length; i++) {
                            if (filterList[i].length > 0) {
                                if (i == 5) {
                                    var val = filterList[i][0];
                                    const strEnum = val as unknown as ScheduleTypeEnum;
                                    setScheduleType(strEnum)
                                } else if (i == 3) {
                                    var val = filterList[i][0];
                                    const strEnum = val as unknown as ScheduleStatusEnum;
                                    setScheduleStatus(strEnum);
                                }
                            }
                        }
                    },
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { ScheduleListModal }
