/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import { SelectModel } from '../../../models/SelectModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import ResultApi from '../../../../infrastructure/api/ResultApi'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IResultModel } from '../../../../models/responses/ResultModel'
import { GenericTable } from '../../../components/tables/GenericTable'
import * as level from '../../../pages/levels/redux/LevelRedux'

import { KTSVG } from '../../../../_metronic/helpers'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { DeleteModal } from '../../../components/modals/DeleteModal'
import { ResultViewModal } from '../../../pages/results/_modals/ResultViewModal'

export function Exams() {
    const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
    const levelId: number = parseInt(user.details?.find(i => i.type == "PresentLevelId")?.value!!);
    const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
    const examDictionary: SelectModel[] = useSelector<RootState>(({ exam }) => exam.examDictionary, shallowEqual) as SelectModel[]
    const examTypeDictionary: SelectModel[] = useSelector<RootState>(({ exam }) => exam.examTypeDictionary, shallowEqual) as SelectModel[]
    const unitDictionary: SelectModel[] = useSelector<RootState>(({ unit }) => unit.unitDictionary, shallowEqual) as SelectModel[]

    const [selectedLevel, setSelectedLevel] = useState<SelectModel>()

    const dispatch = useDispatch()
    const [results, setResults] = useState<PagedResponseModel<IResultModel[]>>()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('endTime_desc')
    const [filters, setFilters] = useState<Map<string, string>>(new Map())

    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleCloseDelete = () => setShowDelete(false)
    const handleShowDelete = () => setShowDelete(true)

    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => setShowForm(false)
    const handleShowForm = () => setShowForm(true)

    const [showView, setShowView] = useState(false)
    const handleCloseView = () => setShowView(false)
    const handleShowView = () => setShowView(true)

    const init = useCallback(async () => {
        if (levelDictionary.length < 1) {
            var responseLevels = await LevelApi.GetLevelDictionary()
            var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
                value: d.id.toString(),
                label: d.name
            }))]
            dispatch(level.actions.setLevelDictionary(dataLevels));
        }
        handleChangeLevel(levelDictionary.find(i => i.value == levelId.toString()))

        if (user.id != null && user.id != '') {
            var lastList = filters;
            lastList.set("StudentId", user.id)

            const paginationFilter: IPaginationFilter = {
                page: pageNumber,
                size: pageSize,
                orderBy: sort,
                search: searchText,
                filters: lastList
            }
            var response = await ResultApi.GetResults(paginationFilter)
            setResults(response.data)
        }
    }, [user, levelId, pageNumber, pageSize, searchText, sort, filters]);

    useEffect(() => {
        // if (!user.roles?.some(s => s == RoleEnum.Student)) {
        //    document.location.href = "/error/404"
        // } else {
        init()
        // }
    }, [user, pageNumber, pageSize, searchText, sort, filters])

    const handleChangeLevel = (e: any) => {
        setSelectedLevel(e)
    }

    async function deleteResult(itemId: string) {
        var response = await ResultApi.DeleteResult(itemId)
        init()
    }

    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'delete':
                handleShowDelete()
                break
            case 'view':
                handleShowView()
                break
            default:
                handleShowForm()
                break
        }
    }

    return (
        <>
            <div className='row'>
                <ResultViewModal itemId={itemId} show={showView} handleClose={handleCloseView} />

                <DeleteModal
                    itemId={itemId}
                    itemName={itemName}
                    handleClose={handleCloseDelete}
                    show={showDelete}
                    type=''
                    confirm={deleteResult}
                />
                <div className='col-md-12'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Exam Results</h3>
                            </div>
                            {/* <div className='mt-4 col-5'>
                                <Select
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeLevel(event)}
                                    value={selectedLevel}
                                    options={levelDictionary}
                                /></div> */}
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                <GenericTable
                                    title=''
                                    data={results?.items}
                                    currentTotalRecords={results?.totalRecords!!}
                                    currentPageNumber={results?.pageNumber!!}
                                    columns={[
                                        {
                                            label: '#',
                                            name: '#',
                                            options: {
                                                filter: false,
                                                customBodyRenderLite: (dataIndex) => {
                                                    return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                                                },
                                                sort: false,
                                                viewColumns: false,
                                            },
                                        },
                                        {
                                            label: 'Title',
                                            name: '',
                                            options: {
                                                filter: false,
                                                customBodyRenderLite: (dataIndex) => {
                                                    return <div className='d-flex align-items-center'>
                                                        <span className="badge bg-light-dark text-dark me-1">{examTypeDictionary.find(i => i.value == results?.items[dataIndex].examTypeId)?.label}</span> {results?.items[dataIndex]?.title.toUpperCase()}
                                                    </div>
                                                },
                                            },
                                        },
                                        {
                                            label: 'Correct',
                                            name: 'totalCorrect', options: { filter: false }
                                        },
                                        {
                                            label: 'Wrong',
                                            name: 'totalWrong', options: { filter: false }
                                        },
                                        {
                                            label: 'Empty',
                                            name: 'totalEmpty', options: { filter: false }
                                        },
                                        {
                                            label: 'Success',
                                            name: 'success', options: {
                                                filter: false,
                                                customBodyRenderLite: (dataIndex) => {
                                                    return (
                                                        <b>
                                                            {results?.items[dataIndex].success}
                                                            {!results?.items[dataIndex].isEvaluated && results?.items[dataIndex].isWriting ?
                                                                <><KTSVG path='/media/icons/duotone/General/Dislike.svg' className='svg-icon svg-icon-danger' /> Not Evaluated</>
                                                                : <></>
                                                            }
                                                        </b>
                                                    )
                                                }
                                            },
                                        },
                                        {
                                            label: 'Violation', name: 'violationSeconds',
                                            options: {
                                                filter: false,
                                                customBodyRenderLite: (dataIndex) => {
                                                    return (
                                                        <b>
                                                            {results?.items[dataIndex].violationSeconds! > 30 ?
                                                                <span className='text-danger'> Violation</span>
                                                                : <></>
                                                            }
                                                        </b>
                                                    )
                                                }
                                            }
                                        },
                                        {
                                            label: 'Date',
                                            name: 'endTime', options: { filter: false }
                                        },
                                        {
                                            label: 'Exam Type', name: 'examTypeId',
                                            options: {
                                                display: 'excluded',
                                                filterOptions: { names: [...examTypeDictionary.map(d => d.label || '')] },
                                            }
                                        },
                                        {
                                            label: 'Exam', name: 'examId',
                                            options: {
                                                display: 'excluded',
                                                filterOptions: { names: [...examDictionary.map(d => d.label || '')] },
                                            }
                                        }, {
                                            label: 'Level', name: 'levelId',
                                            options: {
                                                display: 'excluded',
                                                filterOptions: { names: [...levelDictionary.map(d => d.label || '')] },
                                            }
                                        },
                                        {
                                            label: 'Unit', name: 'unitId', options: {
                                                display: 'excluded',
                                                filterOptions: { names: [...unitDictionary.map(d => d.label || '')] },
                                            }
                                        },
                                        {
                                            label: 'Essay Evaluation', name: 'isEvaluated',
                                            options: {
                                                display: 'excluded',
                                                filterOptions: { names: ['false', 'true'] },
                                            },
                                        },

                                        {
                                            label: '#',
                                            name: 'Id',
                                            options: {
                                                customHeadLabelRender: (options) => {
                                                    return (
                                                        <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                                    )
                                                },
                                                customBodyRenderLite: (dataIndex) => {
                                                    return (
                                                        <TableHelperButtons
                                                            itemId={results?.items[dataIndex]?.id!!}
                                                            itemParam={results?.items[dataIndex]?.title!!}
                                                            types={[TableHelperButtonEnum.View, TableHelperButtonEnum.Delete]}
                                                            onClickItem={onClickItem}
                                                        />
                                                    )
                                                },
                                                filter: false,
                                                sort: false,
                                                viewColumns: false,
                                            },
                                        },
                                    ]}

                                    customOptions={{
                                        rowsPerPage: pageSize,
                                        filter: true,
                                        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                                            setPageNumber(1)
                                            let newFilters: Map<string, string> = new Map()
                                            for (let i = 0; i < filterList.length; i++) {
                                                if (filterList[i].length > 0) {
                                                    if (i == 7) newFilters.set("ExamTypeId", examTypeDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                                                    if (i == 8) newFilters.set("ExamId", examDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                                                    if (i == 9) newFilters.set("LevelId", levelDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                                                    if (i == 10) newFilters.set("UnitId", unitDictionary.find(j => j.label == filterList[i][0].toString())?.value.toString() || '')
                                                    if (i == 11) newFilters.set("IsEvaluated", filterList[i][0].toString())
                                                }
                                            }
                                            setFilters(newFilters)
                                        },
                                        customTableBodyFooterRender: (opts) => {
                                            return <div></div>
                                        },
                                    }}
                                    setPageNumber={setPageNumber}
                                    setPageSize={setPageSize}
                                    setSearchText={setSearchText}
                                    setSort={setSort}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
