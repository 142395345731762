import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { ScheduleGroupListModal } from './_modals/ScheduleGroupListModal'
import { ScheduleGroupFormModal } from './_modals/ScheduleGroupFormModal'
import { KTSVG } from '../../../_metronic/helpers'
import TrainerApi from '../../../infrastructure/api/TrainerApi'
import { DeleteModal } from '../../components/modals/DeleteModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const ScheduleGroupPage: FC = () => {
    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')

    const [showDelete, setShowDelete] = useState(false)
    const handleCloseDelete = () => setShowDelete(false)
    const handleShowDelete = () => setShowDelete(true)

    const [refresh, setRefresh] = useState<number>(0)
    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
    const handleShowForm = () => setShowForm(true)

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'delete':
                handleShowDelete()
                break
            default:
                handleShowForm()
                break
        }
    }

    async function deleteScheduleGroup(itemId: string) {
        var response = await TrainerApi.DeleteScheduleCallPlan(itemId)
        setRefresh(refresh + 1)
    }


    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    {checkPermission(user, PermissionEnum.TrainerPlanCreate) &&
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add a Group Plan'
                        >
                            <a
                                href='#'
                                onClick={() => onClickItem('', '', 'create')}
                                className='btn btn-sm btn-light-primary'
                            >
                                <KTSVG
                                    path='/media/icons/duotone/Code/Plus.svg'
                                    className='svg-icon-3'
                                />
                                Add Group Plan
                            </a>
                        </div>
                    }
                </div>
                <div className='card-body py-3'>
                    <ScheduleGroupFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
                    <ScheduleGroupListModal refresh={refresh} onClickItem={onClickItem} />
                    <DeleteModal
                        itemId={itemId}
                        itemName={itemName}
                        handleClose={handleCloseDelete}
                        show={showDelete}
                        type='Group Plan'
                        confirm={deleteScheduleGroup}
                    />
                </div>
            </div>
        </>
    )
}

const ScheduleGroupWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Schedule</PageTitle>
            <ScheduleGroupPage />

        </>
    )
}

export { ScheduleGroupWrapper }
