import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { TaskViewModal } from './_modals/TaskViewModal'
import { TaskFormModal } from './_modals/TaskFormModal'
import { TaskTransactionModal } from './_modals/TaskTransactionModal'
import { TaskListModal } from './_modals/TaskListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import * as task from './redux/TaskRedux'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ITaskDefineModel } from '../../../models/responses/TaskModel'
import { RootState } from '../../../setup'
import TaskApi from '../../../infrastructure/api/TaskApi'
import { IPaginationFilter } from '../../../models/requests/PaginationFilter'
import { Route, Switch } from 'react-router-dom'
import { TaskMyListModal } from './_modals/TaskMyListModal'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const TaskPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const [showTransaction, setShowTransaction] = useState(false)
  const handleCloseTransaction = () => { setShowTransaction(false); setRefresh(refresh + 1) }
  const handleShowTransaction = () => setShowTransaction(true)

  const [showView, setShowView] = useState(false)
  const handleCloseView = () => setShowView(false)
  const handleShowView = () => setShowView(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const taskDefines: ITaskDefineModel[] = useSelector<RootState>(({ task }) => task.taskDefines, shallowEqual) as ITaskDefineModel[]

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'view':
        handleShowView()
        break
      case 'reply':
        handleShowTransaction()
        break
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function init() {
    if (taskDefines.length < 1) {
      var paginationFilter: IPaginationFilter = {
        page: 1,
        size: 9999,
        orderBy: 'title_asc',
        search: ''
      }
      var responseLevels = await TaskApi.GetTaskDefines(paginationFilter)
      var dataTaskDefines: ITaskDefineModel[] = [...responseLevels.data.items.map(d => ({
        id: d.id.toString(),
        title: d.title,
        definition: d.definition,
        order: d.order,
        isOneTransaction: d.isOneTransaction,
        expiryDay: d.expiryDay,
        responsibleUsers: d.responsibleUsers
      }))]
      dispatch(task.actions.setTaskDefines(dataTaskDefines));
    }
  }

  useEffect(() => {
    init();
  }, [])

  async function deleteTask(itemId: string) {
    var response = await TaskApi.DeleteTask(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
          {checkPermission(user, PermissionEnum.TaskProcessCreate) &&
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a Task'
            >
              <a
                href='#'
                onClick={() => onClickItem('', '', 'create')}
                className='btn btn-sm btn-light-primary'
              >
                <KTSVG
                  path='/media/icons/duotone/Code/Plus.svg'
                  className='svg-icon-3'
                />
                Add Task
              </a>
            </div>
          }
        </div>

        <div className='card-body py-3'>
          <Switch>
            <Route path='/task/myTasks'>
              <TaskMyListModal refresh={refresh} onClickItem={onClickItem} />
            </Route>
            <Route path='/task/process'>
              <TaskListModal refresh={refresh} onClickItem={onClickItem} />
            </Route>
          </Switch>
          <TaskViewModal itemId={itemId} show={showView} handleClose={handleCloseView} />
          <TaskFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <TaskTransactionModal itemId={itemId} show={showTransaction} handleClose={handleCloseTransaction} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Task'
            confirm={deleteTask}
          />
        </div>
      </div>
    </>
  )
}

const TaskWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Tasks</PageTitle>
      <TaskPage />
    </>
  )
}

export { TaskWrapper }
