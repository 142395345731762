import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { ITroubledTopicsReportModel } from '../../../../models/responses/TroubledTopicsReportModel'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { ITopicsModel } from '../../../../models/responses/TopicModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const TroubledTopicsReportListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [topics, setTopics] = useState<PagedResponseModel<ITroubledTopicsReportModel[]>>()
  const [topicDetails, setTopicDetails] = useState<PagedResponseModel<ITopicsModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('id_asc')

  async function init() {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    var response = await ScheduleApi.GetTroubledTopicsReport(paginationFilter)
    setTopics(response.data)

    var topicFilters : IPaginationFilter = {
      page: 1,
      size: pageSize,
      orderBy: sort,
      search: "",
    };
    var topicIds = response.data.items.map(i => i.topicId)

    let newFilters: Map<string, string> = new Map()
    if (topicIds.length > 0) newFilters.set("TopicIds", topicIds.join(","))

    topicFilters.filters = newFilters;

    var topicDetails = await TopicApi.GetTopics(topicFilters)
    setTopicDetails(topicDetails.data)
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Troubled Topics Report'
        data={topics?.items}
        currentTotalRecords={topics?.totalRecords!!}
        currentPageNumber={topics?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: "Topic", name: "id", options: {
              customBodyRender: (value) => {
                return <b>{topicDetails?.items?.find(i => i.id == value)?.title} </b>
              }
            }
          },
          {
            label: 'Rating Count',
            name: 'count',
          },
          {
            label: 'Score',
            name: 'score',
          },
          {
            label: 'Sum Quality',
            name: 'sumQualityPoint',
          },
          {
            label: 'Avg Quality',
            name: 'avgQualityPoint',
          },
          {
            label: 'Sum Difficulty',
            name: 'sumDifficultyPoint',
          },
          {
            label: 'Avg Difficulty',
            name: 'avgDifficultyPoint',
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={topics?.items[dataIndex]?.topicId!!}
                    itemParam={topics?.items[dataIndex]?.topicId!!}
                    types={[]}
                    links={[
                      { type: 'edit', value: '/topic/topics/edit/' + topics?.items[dataIndex]?.topicId!! }
                    ]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { TroubledTopicsReportListModal }
