export interface IGuideModel {
  id: number
  name: string
  link: string
  content: string
  order: number
}

const guideInitValues: IGuideModel = {
  id: 0,
  name: '',
  link: '',
  content: '',
  order: 0,
}

export {guideInitValues}
