import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserRolesRequestModel, userRolesRequestModelInitValues } from '../../../../models/requests/UserRolesRequestModel'
import { RoleModel } from '../../../../models/responses/RoleModel'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import * as account from './../redux/AccountRedux'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

export function Roles() {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const [roles, setRoles] = useState<RoleModel[]>([])

  async function getRoles() {
    var response = await RoleApi.GetRoles()
    var roleList: RoleModel[] = []
    response.data.items.forEach(item => {
      let isActive = user.roles?.some(i => i == item.name) ? true : false
      item.isActive = isActive;
      roleList.push(item);
    })
    setRoles(roleList)
  }

  useEffect(() => {
    if (user) {
      getRoles();
    }
  }, [user?.roles]);

  const formik = useFormik({
    initialValues: userRolesRequestModelInitValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IUserRolesRequestModel = {
          id: user.id,
          roles: []
        }
        roles.filter(f => f.isActive == true).map((role) => {
          model.roles?.push(role.name);
        })

        processRole(model)
      }, 500)
    }
  })

  async function processRole(model: IUserRolesRequestModel) {
    try {
      await UserApi.UpdateUserRoles(model);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const handleRoleOnClick = (roleIndex: number) => {
    var tempRoles = roles;
    tempRoles[roleIndex].isActive = !tempRoles[roleIndex].isActive;    
    setRoles(tempRoles)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Roles</h3>
          </div>
        </div>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body p-9'>
            {roles.map((role, index) => {
              return (
                <div key={index} className='col-12'>
                  <label className='form-check form-check-custom form-check-solid align-items-start me-10 mb-3'>
                    <input
                      {...formik.getFieldProps('roles')}
                      className='form-check-input me-3'
                      type='checkbox'
                      name='roles'
                      checked={role.isActive || false}
                      onClick={() =>
                        handleRoleOnClick(index)
                      }
                    />
                    <span className='form-check-label d-flex flex-column align-items-start'>
                      <span className='fs-5 mb-0'>{role.name}</span>
                    </span>
                  </label>
                </div>
              )
            })}
          </div>
          <div className='card-footer'>
            <div className='text-end'>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm} />
            </div>
          </div>
        </form>

      </div>
    </>
  )
}

