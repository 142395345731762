import { FileTypeEnum } from '../../enums/FileTypeEnum'
import API from './Api'

class FileApi {
  async AddFile(model: FormData) {
    return await API.post('e/Files',
      model, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }

  async GetFileList(path: string, type: FileTypeEnum) {
    return await API.get<string[]>(`e/Files?path=${path}&type=${type}`)
  }
}

export default new FileApi()
