import { IPricingRequestModel } from '../../models/requests/PricingRequestModel'
import { IPricingModel } from '../../models/responses/PricingModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PricingApi {

  async GetPricings(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPricingModel[]>>(`e/Admin/Pricings${generateQueryParams(filter)}`)
  }

  async GetPricing(id: number) {
    return await API.get<IPricingModel>('e/Admin/Pricings/' + id)
  }

  async AddPricing(model: IPricingRequestModel) {
    return await API.post('e/Admin/Pricings', model)
  }

  async UpdatePricing(model: IPricingRequestModel) {
    return await API.put('e/Admin/Pricings/' + model.id, model)
  }

  async DeletePricing(itemId: number) {
    return await API.delete<boolean>('e/Admin/Pricings/' + itemId)
  }
}

export default new PricingApi()
