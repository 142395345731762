import React, { FC, useEffect, useState, useRef, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import PermissionApi from '../../../../infrastructure/api/PermissionApi'
import { RoleModel } from '../../../../models/responses/RoleModel'
import { RoleRequestModel } from '../../../../models/requests/RoleRequestModel'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { PanelModel } from '../../../../models/responses/PanelModel'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const RoleFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [role, setRole] = useState<RoleModel | undefined>()
  const [permissions, setPermissions] = useState([] as PanelModel[])

  var initialState: RoleModel = {
    id: '',
    name: '',
    permissions: [],
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: role!!,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        let model: RoleRequestModel = {
          id: values.id,
          name: values.name,
          claims: []
        }
        permissions.map((panel) => {
          panel.modules.map((module) => {
            module.permissions.filter(i => i.isActive == true).map((permission) => {
              model.claims.push({ type: "RolePermission", value: `${module.name}${permission.name}` });
            });
          })
        })

        processRole(model)
      }, 500)
    },
  })

  async function getRole() {
    var response = await RoleApi.GetRole(itemId)
    setRole(response.data)
    var permissionList: PanelModel[] = []
    permissions.forEach(panel => {
      panel.modules.forEach(module => {
        module.permissions.forEach(permission => {
          var isActive = response.data != undefined ? response.data.permissions!!.some(s => s == `${module.name}${permission.name}`) : false;
          permission.isActive = isActive;
        })
      })
      permissionList.push(panel);
    });

    setPermissions(permissionList)
  }

  async function getPermissions() {
    var response = await PermissionApi.GetPermissions()
    setPermissions(response.data)
  }

  async function processRole(model: RoleRequestModel) {
    try {
      setLoading(true)
      var result = undefined
      if (model.id == undefined || model.id == '')
        result = await RoleApi.AddRole(model)
      else {
        result = await RoleApi.UpdateRole(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    getPermissions()
    if (itemId != '') {
      getRole()
    } else {
      setRole(initialState)
    }
  }, [itemId, show])

  const handleOnClick = (panelIndex: number, moduleIndex: number, permissionIndex: number) => {
    var tempPermissions = permissions;
    tempPermissions[panelIndex].modules[moduleIndex].permissions[permissionIndex].isActive = !tempPermissions[panelIndex].modules[moduleIndex].permissions[permissionIndex].isActive
    setPermissions(tempPermissions)
  };

  const handleAllCheckOnClick = () => {
    setIsAllChecked(!isAllChecked)
    var tempPermissions = permissions;
    tempPermissions.map((panel, ix) => {
      panel.modules.map((module, i) => {
        module.permissions.map((permission, j) => {
          permission.isActive = !isAllChecked;
        })
      })
    })
    setPermissions(tempPermissions)
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-850px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header'>

            <h2 className='fw-bolder'>{itemId === '' ? 'Add' : 'Edit'} Role</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            <div className='mb-10'>
              <label className='fs-5 fw-bolder form-label mb-2 required'>Name</label>
              <input
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className='form-control form-control-solid'
                placeholder='Name'
              />
            </div>
            <label className="fs-5 fw-bolder form-label mb-2">Role Permissions</label>
            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-5">
                <tbody className="text-gray-600 fw-bold">
                  <tr>
                    <td className="text-gray-800 min-w-200px">Full Access
                      <i className="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip" title="" data-bs-original-title="Allows a full access to the system"
                        aria-label="Allows a full access to the system"></i></td>
                    <td>
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                        <input className="form-check-input" type="checkbox" value=""
                          onChange={handleAllCheckOnClick}
                          checked={isAllChecked || false}
                        />
                        <span className="form-check-label">Select all</span>
                      </label>
                    </td>
                  </tr>
                  {permissions.map((panel, ip) => {
                    return (
                      <Fragment key={ip}>
                        <tr>
                          <td className="text-gray-400 fw-bold mb-0 pe-2" colSpan={2}>{panel.name}</td>
                        </tr>
                        {panel.modules.map((module, im) => {
                          return (
                            <tr key={im}>
                              <td className="text-gray-800">{module.name.split(/(?=[A-Z])/).join(" ")}</td>
                              <td>
                                <div className="d-flex">
                                  {module.permissions.map((permission, i) => {
                                    return (
                                      <label key={i} className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                        <input className="form-check-input" type="checkbox"
                                          {...formik.getFieldProps('permissions')}
                                          onClick={() => handleOnClick(ip, im, i)}
                                          value={`${module.name}${permission.name}`}
                                          checked={permission.isActive || false}
                                        />
                                        <span className="form-check-label">{permission.name}</span>
                                      </label>
                                    )
                                  })}
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>

          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && 'Submit'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { RoleFormModal }
