import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ITaskDefineModel } from '../../../../models/responses/TaskModel'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetTaskDefines: '[Set Task Defines] Action'
}

export interface ITaskState {
    taskDefines?: ITaskDefineModel[],
}

const initialTaskState: ITaskState = {
    taskDefines: []
}

export const reducer = persistReducer(
    {
        storage, key: 'v100-demo1-task-defines',
        whitelist: ['taskDefines'],
        blacklist: []
    },
    (state: ITaskState = initialTaskState, action: ActionWithPayload<ITaskState>) => {
        switch (action.type) {
            case actionTypes.SetTaskDefines: {
                const taskDefines = action.payload?.taskDefines
                return { ...state, taskDefines }
            }
            default:
                return state
        }
    }
)

export const actions = {
    setTaskDefines: (taskDefines: Array<ITaskDefineModel>) => ({ type: actionTypes.SetTaskDefines, payload: { taskDefines } }),
}
