import * as Yup from 'yup'

const TaskFormValidations = () => {

  return Yup.object().shape({

  })

}

export { TaskFormValidations }
