import {FC, useEffect, useState} from 'react'
import PartofSpeechApi from '../../../../infrastructure/api/PartofSpeechApi'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap-v5'
import {useFormik} from 'formik'
import {
  IPartofSpeechModel,
  partofSpeechInitValues,
} from '../../../../models/responses/PartofSpeechModel'
import {IPartofSpeechRequestModel} from '../../../../models/requests/PartofSpeechRequestModel'
import {PartofSpeechFormValidations} from '../../../validations/PartofSpeechFormValidations'
import {FormikValidationError} from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import {SaveChangesButton} from '../../../components/buttons/SaveChangesButton'
import {customSelectStyles} from '../../../helpers/ReactSelectHelper'
import {SelectModel} from '../../../models/SelectModel'
import {LanguageEnum} from '../../../../enums/LanguageEnum'
import {EnumToList} from '../../../helpers/EnumHelper'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const PartofSpeechFormModal: FC<Props> = ({itemId, show, handleClose}) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const languages = EnumToList(LanguageEnum)
  const [selectedLanguage, setSelectedLanguage] = useState<SelectModel>()
  const [partofSpeech, setPartofSpeech] = useState<IPartofSpeechModel>(partofSpeechInitValues)

  const updatePartofSpeech = (fieldsToUpdate: Partial<IPartofSpeechModel>) => {
    const updatedData = {...partofSpeech, ...fieldsToUpdate}
    setPartofSpeech(updatedData)
  }

  const formik = useFormik<IPartofSpeechModel>({
    initialValues: partofSpeech,
    enableReinitialize: true,
    validationSchema: PartofSpeechFormValidations,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        let model: IPartofSpeechRequestModel = {
          id: values.id,
          name: values.name,
          displayName: values.displayName,
          languageId: parseInt(selectedLanguage?.value!!),
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IPartofSpeechRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = await PartofSpeechApi.AddPartofSpeech(model)
      else {
        result = await PartofSpeechApi.UpdatePartofSpeech(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IPartofSpeechModel
    if (itemId !== '') {
      var response = await PartofSpeechApi.GetPartofSpeech(itemId)
      result = response.data
      if (response.data.languageId) {
        handleChangeLanguage({
          value: response.data.languageId.toString(),
          label: languages.find((i) => i.value === response.data.languageId.toString())?.label,
        })
      }
    } else {
      result = partofSpeechInitValues
    }
    setPartofSpeech(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeLanguage = (e: any) => {
    setSelectedLanguage(e)
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Part of Speech</h1>
              <div className='text-muted fw-bold fs-5'>
                Part of Speech Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Name</label>
              <input
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Display Name</label>
              <input
                {...formik.getFieldProps('displayName')}
                type='text'
                name='displayName'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError
                touched={formik.touched.displayName}
                error={formik.errors.displayName}
              />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Language</label>
              <Select
                {...formik.getFieldProps('languageId')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeLanguage(event)}
                value={selectedLanguage}
                options={languages}
              />
              <FormikValidationError
                touched={formik.touched.languageId}
                error={formik.errors.languageId}
              />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export {PartofSpeechFormModal}
