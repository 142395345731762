
export enum PermissionEnum {
    BlogPostRead = 110,
    BlogPostCreate = 111,
    BlogPostUpdate = 112,
    BlogPostDelete = 113,

    BlogCategoryRead = 120,
    BlogCategoryCreate = 121,
    BlogCategoryUpdate = 122,
    BlogCategoryDelete = 123,

    BlogCommentRead = 130,
    BlogCommentCreate = 131,
    BlogCommentUpdate = 132,
    BlogCommentDelete = 133,

    CampaignParticipantRead = 210,
    CampaignParticipantCreate = 211,
    CampaignParticipantUpdate = 212,
    CampaignParticipantDelete = 213,

    CampaignRead = 220,
    CampaignCreate = 221,
    CampaignUpdate = 222,
    CampaignDelete = 223,

    CompanyRead = 310,
    CompanyCreate = 311,
    CompanyUpdate = 312,
    CompanyDelete = 313,

    ConfigRead = 410,
    ConfigCreate = 411,
    ConfigUpdate = 412,
    ConfigDelete = 413,

    ContactRead = 510,
    ContactCreate = 511,
    ContactUpdate = 512,
    ContactDelete = 513,

    GuideRead = 610,
    GuideCreate = 611,
    GuideUpdate = 612,
    GuideDelete = 613,

    JobApplicationRead = 710,
    JobApplicationCreate = 711,
    JobApplicationUpdate = 712,
    JobApplicationDelete = 713,

    LevelGroupRead = 810,
    LevelGroupCreate = 811,
    LevelGroupUpdate = 812,
    LevelGroupDelete = 813,

    LevelRead = 820,
    LevelCreate = 821,
    LevelUpdate = 822,
    LevelDelete = 823,

    PaymentRead = 910,
    PaymentCreate = 911,
    PaymentUpdate = 912,
    PaymentDelete = 913,

    PopupRead = 1010,
    PopupCreate = 1011,
    PopupUpdate = 1012,
    PopupDelete = 1013,

    PresentationRead = 1110,
    PresentationCreate = 1111,
    PresentationUpdate = 1112,
    PresentationDelete = 1113,

    PricingRead = 1210,
    PricingCreate = 1211,
    PricingUpdate = 1212,
    PricingDelete = 1213,

    PricingCouponRead = 1220,
    PricingCouponCreate = 1221,
    PricingCouponUpdate = 1222,
    PricingCouponDelete = 1223,

    ShopOrderRead = 1310,
    ShopOrderCreate = 1311,
    ShopOrderUpdate = 1312,
    ShopOrderDelete = 1313,

    ShopProductRead = 1321,
    ShopProductCreate = 1322,
    ShopProductUpdate = 1323,
    ShopProductDelete = 1324,

    UnitRead = 1410,
    UnitCreate = 1411,
    UnitUpdate = 1412,
    UnitDelete = 1413,

    VideoRead = 1510,
    VideoCreate = 1511,
    VideoUpdate = 1512,
    VideoDelete = 1513,

    TopicRead = 1710,
    TopicCreate = 1711,
    TopicUpdate = 1712,
    TopicDelete = 1713,

    VocabularyRead = 1810,
    VocabularyCreate = 1811,
    VocabularyUpdate = 1812,
    VocabularyDelete = 1813,

    TopicListRead = 1910,
    TopicListCreate = 1911,
    TopicListUpdate = 1912,
    TopicListDelete = 1913,

    PartofSpeechRead = 2010,
    PartofSpeechCreate = 2011,
    PartofSpeechUpdate = 2012,
    PartofSpeechDelete = 2013,

    ExamTypeRead = 2110,
    ExamTypeCreate = 2111,
    ExamTypeUpdate = 2112,
    ExamTypeDelete = 2113,

    ExamRead = 2210,
    ExamUpdate = 2212,
    ExamCreate = 2211,
    ExamDelete = 2213,

    ResultRead = 2310,
    ResultUpdate = 2312,
    ResultDelete = 2313,

    AnnouncementRead = 2410,
    AnnouncementCreate = 2411,
    AnnouncementUpdate = 2412,
    AnnouncementDelete = 2413,

    PermissionProcessRead = 2510,
    
    TaskDefineRead = 2610,
    TaskDefineCreate = 2612,
    TaskDefineUpdate = 2613,
    TaskDefineDelete = 2614,

    TaskProcessRead = 2710,
    TaskProcessCreate = 2712,
    TaskProcessUpdate = 2713,
    TaskProcessDelete = 2714,

    JobInterviewRead = 2810,
    JobInterviewCreate = 2811,
    JobInterviewUpdate = 2812,
    JobInterviewDelete = 2813,

    PlacementRead = 3010,
    PlacementCreate = 3011,
    PlacementUpdate = 3012,
    PlacementDelete = 3013,

    AutoCommentRead = 3110,
    AutoCommentCreate = 3111,
    AutoCommentUpdate = 3112,
    AutoCommentDelete = 3113,

    MissionRead = 3210,
    MissionCreate = 3211,
    MissionUpdate = 3212,
    MissionDelete = 3213,

    ScheduleRead = 3310,
    GroupPlanRead = 3313,
    StudentCommentRead = 3314,
    TrainerCommentRead = 3315,
    TrainerCallReportRead = 3316,
    DescendingTolkRead = 3317,

    StudentPlanRead = 3341,
    StudentPlanCreate = 3342,
    StudentPlanUpdate = 3343,
    StudentPlanDelete = 3344,

    TrainerPlanRead = 3351,
    TrainerPlanCreate = 3352,
    TrainerPlanUpdate = 3353,
    TrainerPlanDelete = 3354,


    MenuAccessEducall = 9000,
    MenuAccessContent = 9001,
    MenuAccessMarketing = 9002,
    MenuAccessSchedule = 9003,
    MenuAccessExam = 9004,

    UserRead = 20110,
    UserCreate = 20111,
    UserUpdate = 20112,
    UserDelete = 20113,
    RoleRead = 20120,
    RoleCreate = 20121,
    RoleUpdate = 20122,
    RoleDelete = 20123,

    FileRead = 100001,
    FileCreate = 100002,
}
