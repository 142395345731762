import { FC, useEffect, useRef, useState } from 'react'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { TopicValidations } from '../../../validations/TopicValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import { useParams } from 'react-router'
import { slugify } from '../../../helpers/StringHelper'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'
import FileApi from '../../../../infrastructure/api/FileApi'
import { IVocabularyDetailFormModel } from '../../../models/VocabularyDetailFormModel'
import { ITopicVocabularyFormModel } from '../../../models/TopicVocabularyFormModel'
import { TalkingPoints } from '../components/TalkingPoints'
import { ITopicModel, topicInitValues } from '../../../../models/responses/TopicModel'
import { ITopicRequestModel } from '../../../../models/requests/TopicRequestModel'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { TopicTypeEnum } from '../../../../enums/TopicTypeEnum'
import UnitApi from '../../../../infrastructure/api/UnitApi'
import PartofSpeechApi from '../../../../infrastructure/api/PartofSpeechApi'
import { IPartofSpeechModel } from '../../../../models/responses/PartofSpeechModel'
import { IVocabularyModel } from '../../../../models/responses/VocabularyModel'
import { IVocabularyRequestModel } from '../../../../models/requests/VocabularyRequestModel'
import { Vocabularies } from '../components/Vocabularies'

type Props = {
    itemId: string
    show: boolean
    handleClose: () => void
}

const TopicFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const { id } = useParams<any>()
    itemId = id ? id : ''
    const nameForm = useRef(null)
    const languages = EnumToList(LanguageEnum)
    const types = EnumToList(TopicTypeEnum)
    const [selectedType, setSelectedType] = useState<SelectModel>(types[0])
    const [selectedLanguage, setSelectedLanguage] = useState<SelectModel>(languages[0])
    const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])
    const [partofSpeechs, setPartofSpeechs] = useState<IPartofSpeechModel[]>([])
    const [selectedLevel, setSelectedLevel] = useState<SelectModel>()
    const [topic, setTopic] = useState<ITopicModel>(topicInitValues)

    const [units, setUnits] = useState<SelectModel[]>([])
    const [selectedUnits, setSelectedUnits] = useState<SelectModel[]>([])
    const [isGroupTopic, setGroupTopic] = useState<boolean>(false)

    const [talkingPoints, setTalkingPoints] = useState<string[]>([])
    const [vocabularies, setVocabularies] = useState<ITopicVocabularyFormModel[]>([])
    const [content, setContent] = useState('')
    const [picture, setPicture] = useState('')
    const [loadFile, setLoadFile] = useState<any>(undefined)

    const formik = useFormik<ITopicModel>({
        initialValues: topic,
        enableReinitialize: true,
        validationSchema: TopicValidations,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setTimeout(async () => {
                let model: ITopicRequestModel = {
                    id: values.id == '' ? undefined : values.id,
                    title: values.title,
                    link: slugify(values.title),
                    comment: values.comment,
                    order: values.order,
                    image: values.image,
                    video: values.video,
                    content: content,
                    isGroupTopic: isGroupTopic,
                    levelId: parseInt(selectedLevel?.value!!),
                    languageId: parseInt(selectedLanguage?.value!!),
                    companyId: undefined,
                    topicUnits: [],
                    talkingPoints: talkingPoints,
                    vocabularies: []
                }

                selectedUnits.forEach(unit => {
                    model.topicUnits.push(parseInt(unit.value))
                })

                vocabularies.forEach(vocab => {
                    let vocabularyModel: IVocabularyRequestModel = {
                        id: vocab.id,
                        word: vocab.word!!,
                        vocabularyDetails: []
                    }

                    vocab.details?.forEach(detail => {
                        vocabularyModel.vocabularyDetails.push({
                            id: detail.id == '' ? undefined : detail.id,
                            example: detail.example,
                            definition: detail.definition,
                            pronunciation: detail.pronunciation,
                            partofSpeech: detail.partofSpeech?.value,
                            levelId: parseInt(detail.level?.value!!),
                            image: detail.path
                        })
                    })

                    model.vocabularies.push(vocabularyModel)
                })
                processForm(model)
            }, 500)
        },
    })

    async function processForm(model: ITopicRequestModel) {
        try {
            let d = new Date()
            if (loadFile != undefined) {
                let d = new Date();
                const fileName = slugify(`${model.title}-${d.getMilliseconds()}-${d.getMinutes()}`)
                model.image = (fileName + "." + loadFile.name.split('.').pop())
                let modelPicture = new FormData()
                modelPicture.append('name', fileName)
                modelPicture.append('file', loadFile)
                modelPicture.append('type', FileTypeEnum.Topic)
                var uploadFile = await FileApi.AddFile(modelPicture);
            }
            var requests = vocabularies.map(async (vocab, vocabIndex) => {
                vocab.details!!.map(async (detail, index) => {
                    if (detail.file !== undefined) {
                        const fileName = slugify(`${vocab.word}-${index}-${d.getMilliseconds()}-${d.getMinutes()}`)
                        const imagePath = fileName + '.' + detail.file.name.split('.').pop();
                        model.vocabularies[vocabIndex].vocabularyDetails[index].image = imagePath
                        let modelPicture = new FormData()
                        modelPicture.append('name', fileName)
                        modelPicture.append('file', detail.file)
                        modelPicture.append('type', FileTypeEnum.Vocabulary)
                        var uploadFile = await FileApi.AddFile(modelPicture)
                    }
                    model.vocabularies[vocabIndex].vocabularyDetails = model.vocabularies[vocabIndex].vocabularyDetails.filter(i => i.example?.trim() !== '')
                });
            })
            model.vocabularies = model.vocabularies.filter(i => i.word.trim() !== '')
            Promise.all(requests).then(async () => {
                var result = undefined
                if (model.id === undefined || model.id === '')
                    result = await TopicApi.AddTopic(model)
                else {
                    result = await TopicApi.UpdateTopic(model)
                }
                //formik.resetForm()
                handleClose()
                //reload()
            })
        } catch (error) {
            alert('Error: ' + error)
            setLoading(false)
        }
        setLoading(false)
    }

    async function init() {
        var responseLevels = await LevelApi.GetLevelDictionary()
        var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
            value: d.id.toString(),
            label: d.name
        }))]
        setLevelDictionary(dataLevels)
        var responseUnits = await UnitApi.GetUnitDictionary()
        var dataUnits: SelectModel[] = [...responseUnits.data.map(d => ({
            value: d.id.toString(),
            label: d.name
        }))]
        setUnits(dataUnits)

        var responsePartofSpeechs = await PartofSpeechApi.GetPartofSpeechs({
            page: 1,
            size: 9999,
            orderBy: 'name_asc',
            search: '',
        })
        setPartofSpeechs(responsePartofSpeechs.data.items)

        let result: ITopicModel
        if (itemId !== '') {
            var response = await TopicApi.GetTopic(itemId)
            result = response.data
            if (response.data.type) {
                handleChangeType({
                    value: response.data.type.toString(),
                    label: languages.find((i) => i.value === response.data.type.toString())?.label,
                })
            }
            if (response.data.languageId) {
                handleChangeLanguage({
                    value: response.data.languageId.toString(),
                    label: languages.find((i) => i.value === response.data.languageId.toString())?.label,
                })
            }
            if (response.data.levelId) {
                handleChangeLevel({
                    value: response.data.levelId.toString(),
                    label: dataLevels.find((i) => i.value === response.data.levelId.toString())?.label,
                })
            }
            if (response.data.talkingPoints) {
                var newTalkingPoints = response.data.talkingPoints || []
                setTalkingPoints([...newTalkingPoints])
            }
            if (response.data.topicUnitIds) {
                let unitArray: SelectModel[] = []
                response.data.topicUnitIds.forEach(unitId => {
                    unitArray.push(dataUnits.find(i => i.value == unitId.toString())!!)
                });
                handleChangeUnits(unitArray)
            } else {
                handleChangeUnits([])
            }
            setGroupTopic(response.data.isGroupTopic)

            setVocabularyArea(response.data.vocabularies, responsePartofSpeechs.data.items)
        } else {
            result = topicInitValues
        }
        setPicture(result.image != '' ? `${process.env.REACT_APP_CDN_URL}images/${FileTypeEnum.Topic.toLowerCase()}/${result.image}` : toAbsoluteUrl('/media/misc/none.png'));
        setTopic(result)
    }

    useEffect(() => {
        formik.resetForm()
        init()
    }, [itemId])

    const setVocabularyArea = (vocabularies: IVocabularyModel[], partofSpeechs: IPartofSpeechModel[]) => {
        if (vocabularies) {
            let vocablist: ITopicVocabularyFormModel[] = []

            vocabularies.map((vocabulary, vocabIndex) => {
                let vocabModel: ITopicVocabularyFormModel = {
                    id: vocabulary.id,
                    word: vocabulary.word,
                    language: vocabulary.languageId,
                    details: [],
                    availableDetails: []
                }
                if (vocabulary.vocabularyDetails) {
                    vocabulary.vocabularyDetails.map((vocabularyDetail, detailIndex) => {
                        let detailModel: IVocabularyDetailFormModel = {
                            id: vocabularyDetail.id,
                            example: vocabularyDetail?.example,
                            definition: vocabularyDetail.definition,
                            pronunciation: vocabularyDetail.pronunciation,
                            partofSpeech: { value: vocabularyDetail.partofSpeech },
                            level: { value: vocabularyDetail.levelId?.toString() || '' },
                            path: vocabularyDetail.image,
                            picture: vocabularyDetail.image != null && vocabularyDetail.image !== ''
                                ? `${process.env.REACT_APP_CDN_URL}images/${FileTypeEnum.Vocabulary.toLowerCase()}/${vocabularyDetail.image}`
                                : toAbsoluteUrl('/media/misc/none.png')
                        }
                        if (vocabularyDetail.topicId == itemId) {
                            let getPartofSpeech = partofSpeechs.find(i => i.name == vocabularyDetail.partofSpeech);
                            detailModel.partofSpeech = { label: getPartofSpeech?.displayName, value: getPartofSpeech?.name || '' }
                            vocabModel.details?.push(detailModel)
                        } else {
                            detailModel.partofSpeech = { label: vocabularyDetail.partofSpeech, value: vocabularyDetail.partofSpeech }
                            vocabModel.availableDetails?.push(detailModel)
                        }
                    })
                }
                vocablist.push(vocabModel)
            });
            setVocabularies(vocablist)
        }
    }

    const handleChangeType = (e: any) => {
        setSelectedType(e)
    }
    const handleChangeLevel = (e: any) => {
        setSelectedLevel(e)
    }
    const handleChangeLanguage = (e: any) => {
        setSelectedLanguage(e)
        setVocabularyArea(topic.vocabularies, partofSpeechs)
    }
    const handleChangeUnits = (e: any) => {
        setSelectedUnits(e);
    }
    const handleChangePicture = (event: any) => {
        setLoadFile(event.target.files[0])
        setPicture(URL.createObjectURL(event.target.files[0]));
    }

    const handleChangeGroupTopic = () => {
        setGroupTopic(!isGroupTopic)
    }

    const getInput = (name: string) => {
        const form = nameForm.current
        return form!![name]
    }

    return (
        <form ref={nameForm} className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                    <h1>{itemId === '' ? 'Add' : 'Edit'} Topic</h1>
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='row mb-8'>
                    <div className='col-lg-8 mb-3'>
                        <label className='form-label fw-bolder required'>Title</label>
                        <input
                            {...formik.getFieldProps('title')}
                            type='text'
                            name='title'
                            className='form-control form-control-solid'
                            placeholder='Entry..'
                        />
                        <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
                    </div>
                    <div className='col-lg-2 mb-3'>
                        <label className='form-label fw-bolder required'>Type</label>
                        <Select
                            {...formik.getFieldProps('type')}
                            styles={customSelectStyles(true)}
                            onChange={(event) => handleChangeType(event)}
                            value={selectedType}
                            options={types}
                        />
                        <FormikValidationError
                            touched={formik.touched.languageId}
                            error={formik.errors.languageId}
                        />
                    </div>
                    <div className='col-lg-2 mb-3'>
                        <label className='form-label fw-bolder required'>Order</label>
                        <input
                            {...formik.getFieldProps('order')}
                            type='text'
                            name='order'
                            className='form-control form-control-solid'
                            placeholder='Entry..'
                        />
                        <FormikValidationError touched={formik.touched.order} error={formik.errors.order} />
                    </div>
                    <div className='col-lg-2'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <label className='form-label fw-bolder'>Picture</label>
                                <div className='col-lg-8'>
                                    <div
                                        className='image-input image-input-outline'
                                        data-kt-image-input='true'
                                        style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`, minWidth: '100%' }}
                                    >
                                        <div
                                            className='image-input-wrapper'
                                            style={{ backgroundImage: `url(${picture})`, minWidth: '100%', backgroundSize: 'contain', backgroundPosition: 'center' }}
                                        ></div>
                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                                            <i className="fas fa-pencil-alt"></i>
                                            <input
                                                {...formik.getFieldProps('image')}
                                                type="file"
                                                value={undefined}
                                                onChange={(event) => handleChangePicture(event)} accept=".png, .jpg, .jpeg"
                                            />
                                        </label>
                                        <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel avatar">
                                            <i className="bi bi-x fs-2"></i>
                                        </span>
                                    </div>
                                    <FormikValidationError touched={formik.touched.image} error={formik.errors.image} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-10'>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <label className='form-label fw-bolder required'>Youtube Link</label>
                                <input
                                    {...formik.getFieldProps('video')}
                                    type='text'
                                    name='video'
                                    className='form-control form-control-solid'
                                    placeholder='https://www.youtube.com/watch?v=a6Eozn55Lqs..'
                                />
                                <FormikValidationError touched={formik.touched.link} error={formik.errors.link} />
                            </div>
                            <div className='col-lg-3 mb-3'>
                                <label className='form-label fw-bolder required'>Language</label>
                                <Select
                                    {...formik.getFieldProps('language')}
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeLanguage(event)}
                                    value={selectedLanguage}
                                    options={languages}
                                />
                                <FormikValidationError
                                    touched={formik.touched.languageId}
                                    error={formik.errors.languageId}
                                />
                            </div>
                            <div className='col-lg-3 mb-3'>
                                <label className='form-label fw-bolder required'>Level</label>
                                <Select
                                    {...formik.getFieldProps('levelId')}
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeLevel(event)}
                                    value={selectedLevel}
                                    options={levelDictionary}
                                />
                                <FormikValidationError touched={formik.touched.levelId} error={formik.errors.levelId} />
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <label className='form-label fw-bolder required'>Comment</label>
                                <input
                                    {...formik.getFieldProps('comment')}
                                    type='text'
                                    name='comment'
                                    className='form-control form-control-solid'
                                    placeholder='Entry..'
                                />
                                <FormikValidationError touched={formik.touched.comment} error={formik.errors.comment} />
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <label className='form-label fw-bolder'>Related Units</label>
                                <Select
                                    {...formik.getFieldProps('topicUnitIds')}
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeUnits(event)}
                                    value={selectedUnits}
                                    isMulti={true}
                                    options={units}
                                />
                            </div>
                        </div>
                    </div>
                    <TalkingPoints setTalkingPoints={setTalkingPoints} talkingPoints={talkingPoints} />
                    <Vocabularies setVocabularies={setVocabularies} vocabularies={vocabularies} partofSpeechs={partofSpeechs} selectedLevel={parseInt(selectedLevel?.value!!)} selectedLanguage={parseInt(selectedLanguage.value!!)} getInput={getInput} />
                    <div className='col-lg-12 mb-6'>
                        <div className='mt-10'>
                            <label className='form-label form-label'>Content</label>
                            <DefaultEditor
                                toolbars={[EditorToolbarEnum.Basic, EditorToolbarEnum.Extended, EditorToolbarEnum.Media]}
                                setData={setContent}
                                fileType={FileTypeEnum.BlogPost}
                                data={topic.content}
                            />
                        </div>
                    </div>
                    <div className='col'>
                        <div className='form-check form-switch form-check-custom form-check-solid' >
                            <input
                                {...formik.getFieldProps('isGroupTopic')}
                                className='form-check-input h-30px w-50px'
                                type='checkbox'
                                name='isGroupTopic'
                                checked={isGroupTopic}
                                onClick={handleChangeGroupTopic}
                            />
                            <FormikValidationError touched={formik.touched.isGroupTopic} error={formik.errors.isGroupTopic} />
                            <label className='form-check-label'>Group Topic</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <div className='d-flex flex-center '>
                    <SaveChangesButton
                        loading={loading}
                        setLoading={setLoading}
                        complete={complete}
                        setComplete={setComplete}
                        valid={formik.isValid}
                        submit={formik.isSubmitting}
                        setSubmit={formik.submitForm}
                    />
                </div>
            </div>
        </form >
    )
}

export { TopicFormModal }