import { IVocabularyDetailModel } from './VocabularyDetailModel'

export interface IVocabularyModel {
  id: string
  word: string
  languageId: number
  type: number
  vocabularyDetails: IVocabularyDetailModel[]
}

const vocabularyInitValues: IVocabularyModel = {
  id: '',
  word: '',
  languageId: 0,
  type: 0,
  vocabularyDetails: []
}

export { vocabularyInitValues }
