import { IUserPermissionsRequestModel } from '../../models/requests/UserPermissionsRequestModel'
import { IUserClaimsRequestModel, IUserClaimRequestModel } from '../../models/requests/UserClaimsRequestModel'
import { IUserPasswordRequestModel, IUserRequestModel } from '../../models/requests/UserRequestModel'
import { IUserRolesRequestModel } from '../../models/requests/UserRolesRequestModel'
import { DictionaryModel } from '../../models/responses/DictionaryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IUserDictionaryModel, IUserModel } from '../../models/responses/UserModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'

import API from './Api'

class UserApi {
  async GetUsers(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IUserModel[]>>(`i/Users${generateQueryParams(filter)}`)
  }

  async GetUser(id: string) {
    return await API.get<IUserModel>('i/Users/' + id)
  }

  async AddUser(model: IUserRequestModel) {
    return await API.post('i/Users', model)
  }

  async ImportUser(model: FormData) {
    return await API.post('i/Users/Import',
      model, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }

  async GetUsersByFilter(filter: IPaginationFilter, filters: DictionaryModel[]) {
    return await API.post<PagedResponseModel<IUserModel[]>>(`i/Users/filters${generateQueryParams(filter)}`, filters)
  }

  async GetUsersByFilterForDictionary(filter: IPaginationFilter, filters: DictionaryModel[]) {
    return await API.post<PagedResponseModel<IUserDictionaryModel[]>>(`i/Users/filters${generateQueryParams(filter)}`, filters)
  }

  async GetUserDictionaryFilter(filters: DictionaryModel[]) {
    return await API.post<IUserDictionaryModel[]>('i/Users/dictionary/filters', filters)
  }

  async GetUserDictionaryIds(ids: string[]) {
    return await API.post('i/Users/dictionary/ids', ids)
  }

  async UpdateUser(model: IUserRequestModel) {
    return await API.put('i/Users/' + model.id, model)
  }

  async UpdateUserPassword(model: IUserPasswordRequestModel) {
    return await API.put('i/Users/password/' + model.id, model)
  }

  async UpdateUserRoles(model: IUserRolesRequestModel) {
    return await API.put('i/Users/roles/' + model.id, model)
  }

  async UpdateUserPermissions(model: IUserPermissionsRequestModel) {
    return await API.put('i/Users/permissions/' + model.id, model)
  }

  async UpdateUserClaims(model: IUserClaimsRequestModel) {
    return await API.put('i/Users/claims/' + model.id, model)
  }

  async AddUserClaim(model: IUserClaimRequestModel) {
    return await API.post('i/Users/claim', model)
  }

  async DeleteUserClaim(id: string, type: string, value: string) {
    return await API.delete(`i/Users/claim/${id}?type=${type}&value=${value}`)
  }

  async DeleteUser(itemId: number) {
    return await API.delete('i/Users/' + itemId)
  }
}

export default new UserApi()
