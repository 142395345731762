import { IBlogPostRequestModel } from '../../models/requests/BlogPostRequestModel'
import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'
import { IBlogPostModel } from '../../models/responses/BlogPostModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import API from './Api'

class BlogPostApi {
  async GetBlogPosts(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IBlogPostModel[]>>(`e/Admin/BlogPosts${generateQueryParams(filter)}`)
  }

  async GetBlogPost(id: number) {
    return await API.get<IBlogPostModel>('e/Admin/BlogPosts/' + id)
  }

  async AddBlogPost(model: IBlogPostRequestModel) {
    return await API.post('e/Admin/BlogPosts', model)
  }

  async UpdateBlogPost(model: IBlogPostRequestModel) {
    return await API.put('e/Admin/BlogPosts/' + model.id, model)
  }

  async DeleteBlogPost(itemId: number) {
    return await API.delete<boolean>('e/Admin/BlogPosts/' + itemId)
  }
}

export default new BlogPostApi()
