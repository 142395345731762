/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { UserLogsListModal } from './_modals/UserLogsListModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'

const UserLog: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [refresh, setRefresh] = useState<number>(0)

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 mt-6'>
          <UserLogsListModal refresh={refresh} onClickItem={onClickItem} />
        </div>
      </div>
    </>
  )
}

const UserLogWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>User Logs</PageTitle>
      <UserLog />
    </>
  )
}

export { UserLogWrapper }
