import { FC, useEffect, useState } from 'react'
import PricingCouponApi from '../../../../infrastructure/api/PricingCouponApi'
import { IPricingCouponModel } from '../../../../models/responses/PricingCouponModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const PricingCouponListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [pricingCoupons, setPricingCoupons] = useState<PagedResponseModel<IPricingCouponModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getPricingCoupons() {
      var response = await PricingCouponApi.GetPricingCoupons(paginationFilter)
      setPricingCoupons(response.data)
    }
    getPricingCoupons()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Pricing Coupons'
        data={pricingCoupons?.items}
        currentTotalRecords={pricingCoupons?.totalRecords!!}
        currentPageNumber={pricingCoupons?.pageNumber!!}
        columns={[
          {
            label: '#', name: '#', options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: 'User', name: 'definedUserId', options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  pricingCoupons?.items[dataIndex].definedUserId
                    ? <>{pricingCoupons?.items[dataIndex].definedUserId}</>
                    : <>Multiple Users</>
                )
              }

            }
          },
          {
            label: 'Code', name: 'code', options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          { label: 'Price Discount', name: 'amount' },
          { label: 'Percent Discount', name: 'percent' },
          { label: 'Condition Amount', name: 'conditionAmount' },
          { label: 'Start Date', name: 'startDate' },
          { label: 'Finish Date', name: 'endDate' },
          {
            label: 'Price Usage', name: 'usedCount', options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <div className='d-flex align-items-center'>
                    {pricingCoupons?.items[dataIndex].usedCount + '/' + pricingCoupons?.items[dataIndex].capacity}
                  </div>
                )
              },
            },
          },
          {
            label: 'Status', name: 'usedCount', options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  pricingCoupons?.items[dataIndex].usedCount!! >= pricingCoupons?.items[dataIndex].capacity!!
                    ? <Tooltip title='Completed'><CheckCircle htmlColor='black' /></Tooltip>
                    : moment(pricingCoupons?.items[dataIndex].endDate) <= moment()
                      ? <Tooltip title='Time Expired'><Cancel color="error" /></Tooltip>
                      : <Tooltip title='Avaible'><CheckCircle htmlColor="#4caf50" /></Tooltip>
                )
              },
              sort: false
            },
          }, {
            label: '#', name: 'Id', options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={pricingCoupons?.items[dataIndex]?.id!!}
                    itemParam={pricingCoupons?.items[dataIndex]?.code!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { PricingCouponListModal }
