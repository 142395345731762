import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { StudentPlanFormValidations } from '../../../validations/StudentPlanFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { useParams } from 'react-router-dom'
import { ISchedulePlanModel, IScheduleTopicStatus, ITrainerDictionaryModel, schedulePlanInitValues } from '../../../../models/responses/TrainerModel'
import * as level from '../../../pages/levels/redux/LevelRedux'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { RootState } from '../../../../setup'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { IAddSchedulePlanRequestModel } from '../../../../models/requests/AddSchedulePlanRequestModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const ScheduleGroupFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const { id } = useParams<any>()

  const [loading, setLoading] = useState(false)

  const [trainers, setTrainers] = useState<SelectModel[]>([])
  const [plan, setPlan] = useState<ISchedulePlanModel>(schedulePlanInitValues)
  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const [selectedLevel, setSelectedLevel] = useState<SelectModel>()
  const [topics, setTopics] = useState<SelectModel[]>([])
  const [selectedTopics, setSelectedTopics] = useState<SelectModel[]>([])

  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()

  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };
  const dayList = EnumToList(DayOfWeekEnum);
  const [selectedDay, setSelectedDay] = useState<SelectModel>(defaultSelect)
  const [selectedTrainer, setSelectedTrainer] = useState<SelectModel>(defaultSelect)
  const [startDate, setStartDate] = useState<string>()
  const [duration, setDuration] = useState<number>(0)

  const formik = useFormik<ISchedulePlanModel>({
    initialValues: plan,
    enableReinitialize: true,
    validationSchema: StudentPlanFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IAddSchedulePlanRequestModel = {
          id: itemId == '' ? undefined : itemId,
          title: values.title,
          scheduleType: ScheduleTypeEnum.Group,
          trainerId: selectedTrainer.value,
          levelId: selectedLevel?.value,
          topicIds: selectedTopics.map(i => i.value),
          capacity: values.capacity,
          dayOfWeek: parseInt(selectedDay.value),
          startDate: startDate!,
          duration: duration!
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IAddSchedulePlanRequestModel) {
    try {
      var result = undefined
      result = itemId == '' ? await TrainerApi.AddSchedulePlan(model) : await TrainerApi.UpdateSchedulePlan(model)  
      formik.resetForm()
      handleClose()
    } catch (error: any) {
      setLoading(false)
    }
    setLoading(false)
  }

  const loadTrainers = async (levelId: number, duration: number, dayOfWeek: number, time?: string) => {
    if (duration > 0 && levelId > 0 && dayOfWeek > -1 && time != undefined) {
      var responseUsers = await TrainerApi.GetAvailableTrainers({
        dayOfWeek: dayOfWeek,
        levelId: levelId,
        time: time,
        duration: duration
      })
      var dataTrainers: ITrainerDictionaryModel[] = responseUsers.data
      setTrainers([...dataTrainers.map(d => ({
        value: d.id!.toString(),
        label: d.firstName + " " + d.lastName
      }))]);
    }
  }

  const init = async () => {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    let result: ISchedulePlanModel
    if (itemId !== '') {
      var response = await TrainerApi.GetTrainerSchedulePlan(itemId)
      result = response.data
      if (result.levelId != undefined) {
        handleChangeLevel(levelDictionary.find(i => i.value == result?.levelId?.toString()), result!.topics!)
      } else {
        handleChangeLevel(selectInitValues)
      }
      setStartDate(result.startDate);
      setDuration(result.duration);
      if (result.trainerId != undefined) handleChangeTrainer({ value: result?.trainerId?.toString(), label: "SELECTED" })
      else handleChangeTrainer(selectInitValues)
      if (result.dayOfWeek != undefined) handleChangeDay(dayList.find(i => i.value == result?.dayOfWeek?.toString()))
      else handleChangeDay(selectInitValues)

      loadTrainers(result.levelId || 0, result.duration, result.dayOfWeek, result.startDate)
    } else {
      result = schedulePlanInitValues
    }
    setPlan(result)
  }

  const getTopicLists = async (levelId: number, selectedTopics: IScheduleTopicStatus[]) => {
    if (levelId > 0) {
      var responseTopicList = await TopicApi.GetTopicListsWithLevel(levelId);
      if (responseTopicList.data.topics) {
        var dataTopics: SelectModel[] = [...responseTopicList.data.topics.map(d => ({
          value: d.topicId!.toString(),
          label: d.topicTitle
        }))]
        setTopics(dataTopics)
        handleChangeSelectedTopics(dataTopics.filter(i => selectedTopics!.some(s => s.topicId == i.value && !s.isComplete)))
        loadTrainers(levelId, duration, parseInt(selectedDay.value), startDate)
      }
    } else {
      setTopics([])
      setSelectedTopics([])
    }
  }

  useEffect(() => {
    formik.resetForm()
    setPlan(schedulePlanInitValues)
    init()
  }, [itemId, show])

  const handleChangeDay = (e: any) => {
    setSelectedDay(e);
    loadTrainers(parseInt(selectedLevel?.value! || '0'), duration, parseInt(e.value), startDate)
  }

  const handleChangeLevel = (e: any, topics: IScheduleTopicStatus[] = []) => {
    setSelectedLevel(e)
    getTopicLists(parseInt(e?.value), topics)
  }

  const handleChangeTrainer = (e: any) => {
    setSelectedTrainer(e);
  }

  const handleChangeStartDate = (e: any) => {
    setStartDate(e);
    loadTrainers(parseInt(selectedLevel?.value! || '0'), duration, parseInt(selectedDay.value), e)
  }

  const handleChangeDuration = (e: any) => {
    setDuration(e);
    loadTrainers(parseInt(selectedLevel?.value! || '0'), e, parseInt(selectedDay.value))
  }

  const handleChangeSelectedTopics = (e: any) => {
    setSelectedTopics(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Trainer Group Plan</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12 mb-6'>
                <label className='form-label fw-bolder required'>Title</label>
                <input
                  {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
              </div>
              <div className='col-lg-4 mb-6'>
                <label className='form-label fw-bolder required'>Day of Week</label>
                <Select
                  {...formik.getFieldProps('dayOfWeek')}
                  styles={customSelectStyles(true)}
                  isDisabled={itemId !== ''}
                  onChange={(event) => handleChangeDay(event)}
                  value={selectedDay}
                  options={dayList}
                />
                <FormikValidationError touched={formik.touched.dayOfWeek} error={formik.errors.dayOfWeek} />
              </div>
              <div className='col-lg-4 mb-6'>
                <label className='form-label fw-bolder required'>Start Time</label>
                <input
                  {...formik.getFieldProps('startDate')}
                  type='time'
                  readOnly={itemId !== ''}
                  value={startDate}
                  onChange={(event) => handleChangeStartDate(event.target.value)}
                  name='startDate'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.startDate} error={formik.errors.startDate} />
              </div>
              <div className='col-lg-4 mb-6'>
                <label className='form-label fw-bolder required'>Minutes</label>
                <input
                  {...formik.getFieldProps('duration')}
                  type='number'
                  value={duration}
                  readOnly={itemId !== ''}
                  onChange={(event) => handleChangeDuration(event.target.value)}
                  name='duration'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.duration} error={formik.errors.duration} />

              </div>
              <div className='col-lg-12 mb-6'>
                <label className='form-label fw-bolder required'>Level</label>
                <Select
                  {...formik.getFieldProps('levelId')}
                  styles={customSelectStyles(true)}
                  isDisabled={itemId !== ''}
                  onChange={(event) => handleChangeLevel(event)}
                  value={selectedLevel}
                  options={levelDictionary}
                />
                <FormikValidationError touched={formik.touched.levelId} error={formik.errors.levelId} />
              </div>
              <hr />
              <div className='col-lg-8 mb-6'>
                <label className='form-label fw-bolder required'>Trainer</label>
                <Select
                  {...formik.getFieldProps('trainer')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeTrainer(event)}
                  value={selectedTrainer}
                  options={[defaultSelect, ...trainers]}
                />
                <FormikValidationError touched={formik.touched.trainerId} error={formik.errors.trainerId} />
              </div>
              <div className='col-lg-4 mb-6'>
                <label className='form-label fw-bolder required'>Capacity</label>
                <input
                  {...formik.getFieldProps('capacity')}
                  type='number'
                  name='capacity'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.capacity} error={formik.errors.capacity} />

              </div>
              <div className='col-lg-12 mb-6'>
                <label className='form-label fw-bolder required'>Topics</label>
                <Select
                  {...formik.getFieldProps('topicIds')}
                  isMulti={true}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeSelectedTopics(event)}
                  value={selectedTopics}
                  options={topics}
                />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { ScheduleGroupFormModal }
