export interface IAutoCommentModel {
    id?: string,
    message: string,
    point: number,
    group: number
}

const autoCommentInitValues: IAutoCommentModel = {
    id: '',
    message: '',
    point: 0,
    group: 0
}

export { autoCommentInitValues }