import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IJobInterviewModel } from '../../models/responses/JobInterviewModel'
import { IJobInterviewRequestModel } from '../../models/requests/JobInterviewRequestModel'

class LevelApi {
  async GetJobInterviews(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IJobInterviewModel[]>>(`e/Admin/JobInterviews${generateQueryParams(filter)}`)
  }

  async GetJobInterviewDictionary() {
    return await API.get<IJobInterviewModel[]>(`e/Admin/JobInterviews/dictionary`)
  }

  async GetJobInterview(id: string) {
    return await API.get<IJobInterviewModel>('e/Admin/JobInterviews/' + id)
  }

  async AddJobInterview(model: IJobInterviewRequestModel) {
    return await API.post('e/Admin/JobInterviews', model)
  }

  async UpdateJobInterview(model: IJobInterviewRequestModel) {
    return await API.put('e/Admin/JobInterviews/' + model.id, model)
  }

  async DeleteJobInterview(itemId: string) {
    return await API.delete<boolean>('e/Admin/JobInterviews/' + itemId)
  }
}

export default new LevelApi()
