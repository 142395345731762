/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { BlogPostFormModal } from './_modals/BlogPostFormModal'
import { BlogPostListModal } from './_modals/BlogPostListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import BlogPostApi from '../../../infrastructure/api/BlogPostApi'
import { Link, Route, Switch } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const BlogPostPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deleteBlogPost(itemId: number) {
    var response = await BlogPostApi.DeleteBlogPost(itemId)
    setRefresh(refresh + 1)
  }

  const postsBreadCrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]
  const postBreadCrumbs: Array<PageLink> = [
    {
      title: 'Blog Posts',
      path: `/blog/posts`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <div className='card mb-5 mb-xl-8'>

        <Switch>
          <Route path={['/blog/posts/edit/:id', '/blog/posts/add']}>
            <PageTitle breadcrumbs={postBreadCrumbs}>Post</PageTitle>
            <BlogPostFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          </Route>
          <Route path='/blog/posts'>
            <PageTitle breadcrumbs={postsBreadCrumbs}>Posts</PageTitle>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                {/* <span className='card-label fw-bolder fs-3 mb-1'>Members Statistics</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
              </h3>
              {checkPermission(user, PermissionEnum.BlogPostCreate) &&
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add a Post'
                >

                  <Link
                    to={'/blog/posts/add'}
                    className='btn btn-sm btn-light-primary'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Code/Plus.svg'
                      className='svg-icon-3'
                    />
                    Add Post
                  </Link>

                </div>
              }
            </div>

            <div className='card-body py-3'>
              <BlogPostListModal refresh={refresh} onClickItem={onClickItem} />
              <DeleteModal
                itemId={itemId}
                itemName={itemName}
                handleClose={handleCloseDelete}
                show={showDelete}
                type='Blog Post'
                confirm={deleteBlogPost}
              />
            </div>
          </Route>
        </Switch>

      </div>

    </>
  )
}

const BlogPostWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Blog</PageTitle>
      <BlogPostPage />
    </>
  )
}

export { BlogPostWrapper }
