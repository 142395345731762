import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IEmergencyModel } from '../../models/responses/EmergencyModel'

class EmergencyApi {
  async GetEmergencies(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IEmergencyModel[]>>(`s/Admin/Emergencies${generateQueryParams(filter)}`)
  }
}

export default new EmergencyApi()
