import { FC, useEffect, useState } from 'react'
import BlogCommentApi from '../../../../infrastructure/api/BlogCommentApi'
import { IBlogCommentModel } from '../../../../models/responses/BlogCommentModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const BlogCommentListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [blogComments, setBlogComments] = useState<PagedResponseModel<IBlogCommentModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText
    }
    async function getBlogComments() {
      var response = await BlogCommentApi.GetBlogComments(paginationFilter)
      setBlogComments(response.data)
    }
    getBlogComments()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <GenericTable
      title="Blog Comments"
      data={blogComments?.items}
      currentTotalRecords={blogComments?.totalRecords!!}
      currentPageNumber={blogComments?.pageNumber!!}
      columns={[
        {
          label: '#',
          name: '#',
          options: {
            customBodyRenderLite: (dataIndex) => {
              return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
            },
            sort: false,
            viewColumns: false
          },
        },
        {
          label: "Author", name: "author", options: {
            customBodyRender: (value) => {
              return <b>{value}</b>
            }
          }
        },
        { label: 'Email', name: 'email' },
        {
          label: 'Post', name: 'blogPostName', options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>{blogComments?.items[dataIndex].blogPostName === null ? '-' : blogComments?.items[dataIndex].blogPostName}</div>
              )
            },
            sort: false
          }
        },
        {
          label: 'Date', name: 'date', options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <div>{blogComments?.items[dataIndex].date.split('T', 1)}</div>
              )
            }
          }
        },
        {
          label: "Check", name: "isActive", options: {
            customBodyRenderLite: (dataIndex) => {
              return (
                <div className='ps-5 fw-bolder'>{blogComments?.items[dataIndex].isActive == true ? '✓' : null}</div>
              )
            }
          }
        },
        {
          label: "#", name: "Id", options: {
            customHeadLabelRender: (options) => {
              return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
            },
            customBodyRenderLite: (dataIndex) => {
              return (
                <TableHelperButtons
                  itemId={blogComments?.items[dataIndex]?.id!!}
                  itemParam={blogComments?.items[dataIndex]?.author!!}
                  types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Reply, TableHelperButtonEnum.Delete]}
                  onClickItem={onClickItem}
                />
              )
            },
            filter: false,
            sort: false,
            viewColumns: false
          },
        }
      ]}
      customOptions={{
        rowsPerPage: pageSize,
        customTableBodyFooterRender: (opts) => {
          return <div></div>
        }
      }}
      setPageNumber={setPageNumber}
      setPageSize={setPageSize}
      setSearchText={setSearchText}
      setSort={setSort}
    />
  )
}

export { BlogCommentListModal }
