import { IVideoRequestModel } from '../../models/requests/VideoRequestModel'
import { IVideoModel } from '../../models/responses/VideoModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class VideoApi {

  async GetVideos(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IVideoModel[]>>(`e/Admin/Videos${generateQueryParams(filter)}`)
  }

  async GetVideo(id: number) {
    return await API.get<IVideoModel>('e/Admin/Videos/' + id)
  }

  async AddVideo(model: IVideoRequestModel) {
    return await API.post('e/Admin/Videos', model)
  }

  async UpdateVideo(model: IVideoRequestModel) {
    return await API.put('e/Admin/Videos/' + model.id, model)
  }

  async DeleteVideo(itemId: number) {
    return await API.delete<boolean>('e/Admin/Videos/' + itemId)
  }
}

export default new VideoApi()
