import { IResultRequestModel } from '../../models/requests/ResultRequestModel'
import { IResultModel } from '../../models/responses/ResultModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ResultApi {
  async GetResults(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IResultModel[]>>(`ex/Admin/Results${generateQueryParams(filter)}`)
  }

  async GetResult(id: string) {
    return await API.get<IResultModel>('ex/Admin/Results/' + id)
  }

  async UpdateResult(model: IResultRequestModel) {
    return await API.put('ex/Admin/Results/' + model.id, model)
  }

  async DeleteResult(itemId: string) {
    return await API.delete<boolean>('ex/Admin/Results/' + itemId)
  }
}

export default new ResultApi()
