import { IPartofSpeechRequestModel } from '../../models/requests/PartofSpeechRequestModel'
import { IPartofSpeechModel } from '../../models/responses/PartofSpeechModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PartofSpeechApi {
  async GetPartofSpeechs(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPartofSpeechModel[]>>(`t/Admin/PartofSpeechs${generateQueryParams(filter)}`)
  }

  async GetPartofSpeech(id: string) {
    return await API.get<IPartofSpeechModel>('t/Admin/PartofSpeechs/' + id)
  }

  async AddPartofSpeech(model: IPartofSpeechRequestModel) {
    return await API.post('t/Admin/PartofSpeechs', model)
  }

  async UpdatePartofSpeech(model: IPartofSpeechRequestModel) {
    return await API.put('t/Admin/PartofSpeechs/' + model.id, model)
  }

  async DeletePartofSpeech(itemId: string) {
    return await API.delete<boolean>('t/Admin/PartofSpeechs/' + itemId)
  }
}

export default new PartofSpeechApi()
