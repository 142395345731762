import * as Yup from 'yup'

const InvoiceDetailFormValidations = () => {
    return Yup.object().shape({
        invoiceTitle: Yup.string().required().min(3, 'Minimum 3 symbols'),
        invoiceAddress: Yup.string().required().min(2, 'Minimum 2 symbols'),
        invoiceZipcode: Yup.string().required().min(3, 'Minimum 3 symbols'),
        taxDepartment: Yup.string().required().min(3, 'Minimum 3 symbols'),
        taxNumber: Yup.string().required().min(3, 'Minimum 3 symbols'),
        landPhone: Yup.string().required().min(6, 'Minimum 6 symbols'),
        invoiceCountryId: Yup.string().required('Country is required'),
        invoiceCityId: Yup.string().required('City is required'),
        invoiceStateId: Yup.string().required('State is required'),
    })
}

export { InvoiceDetailFormValidations }
