import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import UserApi from '../../../../../../infrastructure/api/UserApi'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { IUserClaimsRequestModel } from '../../../../../../models/requests/UserClaimsRequestModel'

import { FormikValidationError } from '../../../../../components/validations/FormikValidationError'
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../../../models/SelectModel'
import Select from 'react-select';

import { ITrainerSettingsModel, trainerSettingsInitValues as initialValues } from '../../../../../../models/responses/TrainerModel'
import CompanyApi from '../../../../../../infrastructure/api/CompanyApi'
import { ClaimTypeEnum } from '../../../../../../enums/ClaimTypeEnum'
import { ICompanyManagerRequestModel } from '../../../../../../models/requests/CompanyManagerRequestModel'
import { CompanyManagerDetailFormValidations } from '../../../../../validations/CompanyManagerDetailFormValidations copy'

const CompanyManagerDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const [trainerSettings, setTrainerSettings] = useState<ITrainerSettingsModel>(initialValues)
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)

  const formik = useFormik<ICompanyManagerRequestModel>({
    initialValues: trainerSettings,
    enableReinitialize: true,
    validationSchema: CompanyManagerDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        var model: ICompanyManagerRequestModel = {

        }
        process(model)

      }, 500)
    },
  })

  async function process(model: ICompanyManagerRequestModel) {
    try {

      let modelClaims: IUserClaimsRequestModel = {
        id: user.id,
        claims: [
          { type: ClaimTypeEnum.CompanyId, value: selectedCompany.value!!.toString() },
        ]
      }

      await UserApi.UpdateUserClaims(modelClaims);

      setComplete(true)
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const init = async () => {
    var companies = await CompanyApi.GetSubCompaniesDictionary()
    var dataParentCompanies: SelectModel[] = [selectInitValues,
      ...companies.data.map((d) => ({
        value: d.id.toString(),
        label: d.name,
      })),
    ]
    setCompanies(dataParentCompanies)

    var companyId = user.details?.find(i => i.type == ClaimTypeEnum.CompanyId)?.value!! || '';
    if (companyId != null && companyId != '') {
      handleChangeCompany({
        value: companyId,
        label: dataParentCompanies.find(i => i.value == companyId)?.label
      })
    }


  }

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user])


  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Company Manager Config</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Company</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('companyId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeCompany(event)}
                  value={selectedCompany}
                  options={companies}
                />
                <FormikValidationError touched={formik.touched.companyId} error={formik.errors.companyId} />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div >
    </div >
  )
}

export { CompanyManagerDetails }
