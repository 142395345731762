import Select from 'react-select'
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers"
import { IVocabularyDetailFormModel } from "../../../models/VocabularyDetailFormModel"
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { FC, useCallback, useEffect, useState } from 'react'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { IPartofSpeechModel } from '../../../../models/responses/PartofSpeechModel'
import { ILevelModel } from '../../../../models/responses/LevelModel'

type Props = {
    details?: IVocabularyDetailFormModel[]
    setDetails: (details: IVocabularyDetailFormModel[], index?: number) => void
    levelsData: ILevelModel[]
    partofSpeechsData: IPartofSpeechModel[]
    languageId: number
    isForTopic?: boolean
    wordIndex?: number
}
export const VocabularyDetails: FC<Props> = ({ details, setDetails, levelsData, partofSpeechsData, languageId, isForTopic = false, wordIndex }) => {
    const [partofSpeechs, setPartofSpeechs] = useState<SelectModel[]>([])
    const [levels, setLevels] = useState<SelectModel[]>([])
    const [isMount, setMount] = useState<number>(0)

    const init = useCallback(async () => {
        var dataLevels: SelectModel[] = [...levelsData.map(d => ({
            value: d.id.toString(),
            label: d.name
        }))]
        setLevels(dataLevels)


        var dataPartofSpeechs: SelectModel[] = [...partofSpeechsData.filter(i => i.languageId == languageId).map(d => ({
            value: d.name.toString(),
            label: d.displayName.toString()
        }))]
        setPartofSpeechs(dataPartofSpeechs)


        if ((details && partofSpeechs && levelsData)) {


            details?.map((detail, index) => {
                handleChangeLevel(dataLevels.find(i => i.value == detail.level?.value), index)
                handleChangePartofSpeech(dataPartofSpeechs.find(i => i.value == detail.partofSpeech?.value), index)
            });
        }


    }, [details, partofSpeechsData, levelsData]);

    useEffect(() => {
        init()
    }, [languageId, details])

    const addNewDetail = () => {
        var newFiles = details || []
        newFiles.push({ id: '', definition: '', pronunciation: '', example: '' })
        setDetails([...newFiles], wordIndex)
    }

    const removeDetail = (index: number) => {
        var newFiles = details!!
        if (newFiles[index]) {
            newFiles.splice(index, 1);
        }
        setDetails([...newFiles], wordIndex)
    }

    const handleChangeLevel = (e: any, index: number) => {
        var newFiles = details!!
        if (newFiles[index]) {
            newFiles[index].level = e
        }
        setDetails([...newFiles], wordIndex)
    }

    const handleChangePartofSpeech = (e: any, index: number) => {
        var newFiles = details!!
        if (newFiles[index]) {
            newFiles[index].partofSpeech = e
        }
        setDetails([...newFiles], wordIndex)
    }

    const handleChangeExample = (e: any, index: number) => {
        var newFiles = details!!
        if (newFiles[index]) {
            newFiles[index].example = e.target.value
        }
        setDetails(newFiles, wordIndex)
    }

    const handleChangePronunciation = (e: any, index: number) => {
        var newFiles = details!!
        if (newFiles[index]) {
            newFiles[index].pronunciation = e.target.value
        }
        setDetails(newFiles, wordIndex)
    }

    const handleChangeDefinition = (e: any, index: number) => {
        var newFiles = details!!
        if (newFiles[index]) {
            newFiles[index].definition = e.target.value
        }
        setDetails(newFiles, wordIndex)
    }

    const handleChangeImage = (event: any, index: number) => {
        if (event.target.files[0] != null) {
            var newFiles = details!!
            if (newFiles[index]) {
                newFiles[index].file = event.target.files[0]
                newFiles[index].picture = URL.createObjectURL(event.target.files[0])
            }
            setDetails([...newFiles], wordIndex)
        }
    }

    return (
        <>
            <div className='row'>
                {details?.map((item, index) => {
                    return (
                        <div className='col-lg-12 mb-5' key={index}>
                            <div className='card shadow-sm'>
                                <div className='flex-nowrap border-0 pt-9 p-9'>
                                    <div style={{ textAlign: 'right' }}>
                                        <a
                                            href='#'
                                            onClick={() => removeDetail(index)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                        >
                                            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
                                        </a>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row mb-5'>

                                        <div className='col-lg-3'>
                                            <label className='form-label fw-bolder'>Image</label>
                                            <div className='col-lg-8'>
                                                <div
                                                    className='image-input image-input-outline'
                                                    data-kt-image-input='true'
                                                    style={{
                                                        backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`,
                                                        minWidth: '100%',
                                                    }}
                                                >
                                                    <div
                                                        className='image-input-wrapper'
                                                        style={{
                                                            backgroundImage: `url(${details[index].picture})`,
                                                            minWidth: '100%',
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center',
                                                        }}
                                                    ></div>
                                                    <label
                                                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow'
                                                        data-kt-image-input-action='change'
                                                        data-bs-toggle='tooltip'
                                                        title=''
                                                        data-bs-original-title='Change avatar'
                                                    >
                                                        <i className='fas fa-pencil-alt'></i>
                                                        <input
                                                            type='file'
                                                            value={undefined}
                                                            onChange={(event) => handleChangeImage(event, index)}
                                                            accept='.png, .jpg, .jpeg'
                                                        />
                                                    </label>
                                                    <span
                                                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                                        data-kt-image-input-action='cancel'
                                                        data-bs-toggle='tooltip'
                                                        title=''
                                                        data-bs-original-title='Cancel avatar'
                                                    >
                                                        <i className='bi bi-x fs-2'></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <label className='form-label fw-bolder required'>Part of Speech</label>
                                            <Select
                                                styles={customSelectStyles(true)}
                                                onChange={(event) => handleChangePartofSpeech(event, index)}
                                                value={details[index]?.partofSpeech}
                                                className='mb-5'
                                                options={partofSpeechs}
                                            />
                                            {!isForTopic &&
                                                <div>
                                                    <label className='form-label fw-bolder required'>Level</label>
                                                    <Select
                                                        styles={customSelectStyles(true)}
                                                        onChange={(event) => handleChangeLevel(event, index)}
                                                        value={details[index]?.level}
                                                        options={levels}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className='col-lg-7'>
                                            <div className='row'>
                                                <div className='col-lg-9'>
                                                    <label className='form-label fw-bolder required'>Example</label>
                                                    <input
                                                        type='text'
                                                        onChange={(event) => handleChangeExample(event, index)}
                                                        defaultValue={details[index].example}
                                                        className='form-control form-control-solid mb-6'
                                                        placeholder='Entry..'
                                                    />
                                                </div>
                                                <div className='col-lg-3'>
                                                    <label className='form-label fw-bolder required'>Pronunciation</label>
                                                    <input
                                                        type='text'
                                                        onChange={(event) => handleChangePronunciation(event, index)}
                                                        defaultValue={details[index].pronunciation}
                                                        className='form-control form-control-solid mb-6'
                                                        placeholder='Entry..'
                                                    />
                                                </div>
                                            </div>

                                            <label className='form-label fw-bolder required'>Definition</label>
                                            <input
                                                type='text'
                                                onChange={(event) => handleChangeDefinition(event, index)}
                                                defaultValue={details[index].definition}
                                                className='form-control form-control-solid'
                                                placeholder='Entry..'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={{ textAlign: 'right' }}>
                <button type='button' onClick={() => addNewDetail()} className='btn btn-success btn-sm'>
                    <i className='fa fa-plus'></i> Add Detail
                </button>
            </div>
        </>
    )
}