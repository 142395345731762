import { IExamTypeRequestModel } from '../../models/requests/ExamTypeRequestModel'
import { IExamTypeModel } from '../../models/responses/ExamTypeModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IExamDictionaryModel } from '../../models/responses/ExamDictionaryModel'

class ExamTypeApi {
  async GetExamTypes(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IExamTypeModel[]>>(`ex/Admin/ExamTypes${generateQueryParams(filter)}`)
  }

  async GetExamType(id: string) {
    return await API.get<IExamTypeModel>('ex/Admin/ExamTypes/' + id)
  }

  async GetExamTypeDictionary() {
    return await API.get<IExamDictionaryModel[]>(`ex/Admin/ExamTypes/dictionary`)
  }

  async AddExamType(model: IExamTypeRequestModel) {
    return await API.post('ex/Admin/ExamTypes', model)
  }

  async UpdateExamType(model: IExamTypeRequestModel) {
    return await API.put('ex/Admin/ExamTypes/' + model.id, model)
  }

  async DeleteExamType(itemId: string) {
    return await API.delete<boolean>('ex/Admin/ExamTypes/' + itemId)
  }
}

export default new ExamTypeApi()
