/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { Silent } from '../modules/auth/components/Silent'
import { Callback } from '../modules/auth/components/Callback'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)

  return (
    <Switch>
      <Route path='/auth/callback' component={Callback} />
      <Route path='/auth/silent-renew' component={Silent} />
      <Route path='/logout' component={Logout} />
      <Route path='/error' component={ErrorsPage} />

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>)
      }

    </Switch>
  )
}

export { Routes }
