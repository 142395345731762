import { IChangeStudentBlockedTrainersRequestModel } from '../../models/requests/ChangeStudentBlockedTrainersRequestModel'
import { IDeleteStudentPlanRequestModel } from '../../models/requests/DeleteStudentPlanRequestModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IStudentModel, IStudentsModel } from '../../models/responses/StudentModel'
import { IAddStudentPlanRequestModel } from '../../models/requests/AddStudentPlanRequestModel'
import { IGetStudentPlansRequestModel } from '../../models/requests/GetStudentPlansRequestModel'
import { IStudentRequestModel } from '../../models/requests/StudentRequestModel'
import { IStudentDescendingTolksModel } from '../../models/responses/StudentDescendingTolksModel'
import { IReAutoPairPlansRequestModel } from '../../models/requests/ReAutoPairPlansRequestModel'
import { IOurStudentsWithoutPlanModel } from '../../models/responses/OurStudentsWithoutPlanModel'
import { IUserDictionaryModel } from '../../models/responses/UserModel'

class StudentApi {

    async GetStudent(id: string) {
        return await API.get<IStudentModel>('s/Admin/Students/' + id)
    }

    async GetStudents(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<IUserDictionaryModel[]>>(`s/Admin/Students${generateQueryParams(filter)}`)
    }


    async GetStudentDictionary(filter: IPaginationFilter) {
        return await API.get<IUserDictionaryModel[]>(`s/Admin/Students/dictionary${generateQueryParams(filter)}`)
    }

    async GetStudentPlans(filter: IPaginationFilter, model: IGetStudentPlansRequestModel) {
        return await API.post<PagedResponseModel<IStudentsModel[]>>(`s/Admin/Students/Plans/${generateQueryParams(filter)}`, model)
    }

    async ReAutoPairPlans(model: IReAutoPairPlansRequestModel) {
        return await API.post(`s/Admin/Students/ReAutoPairPlans`, model)
    }

    async GetDescendingTolks(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<IStudentDescendingTolksModel[]>>(`s/Admin/Students/DescendingTolk${generateQueryParams(filter)}`)
    }

    async GetOurStudentsWithoutPlan(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<IOurStudentsWithoutPlanModel[]>>(`s/Admin/Students/OurStudentsWithoutPlan${generateQueryParams(filter)}`)
    }

    async BlockedTrainer(model: IChangeStudentBlockedTrainersRequestModel) {
        return await API.put('s/Admin/Students/BlockedTrainer', model)
    }

    async AddStudentPlan(model: IAddStudentPlanRequestModel) {
        return await API.patch('s/Admin/Students/AddPlan', model)
    }

    async UpdateStudent(model: IStudentRequestModel) {
        return await API.put('s/Admin/Students/' + model.id, model)
    }

    async DeleteCallPlan(model: IDeleteStudentPlanRequestModel) {
        return await API.patch<boolean>('s/Admin/Students/DeletePlan', model)
    }
}

export default new StudentApi()
