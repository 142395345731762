import { IPricingCouponRequestModel } from '../../models/requests/PricingCouponRequestModel'
import { IPricingCouponModel } from '../../models/responses/PricingCouponModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PricingCouponApi {

  async GetPricingCoupons(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPricingCouponModel[]>>(`e/Admin/PricingCoupons${generateQueryParams(filter)}`)
  }

  async GetPricingCoupon(id: number) {
    return await API.get<IPricingCouponModel>('e/Admin/PricingCoupons/' + id)
  }

  async AddPricingCoupon(model: IPricingCouponRequestModel) {
    return await API.post('e/Admin/PricingCoupons', model)
  }

  async UpdatePricingCoupon(model: IPricingCouponRequestModel) {
    return await API.put('e/Admin/PricingCoupons/' + model.id, model)
  }

  async DeletePricingCoupon(itemId: number) {
    return await API.delete<boolean>('e/Admin/PricingCoupons/' + itemId)
  }
}

export default new PricingCouponApi()
