import { PanelModel } from '../../models/responses/PanelModel'
import API from './Api'

class PermissionApi {
  async GetPermissions() {
    return await API.get<PanelModel[]>('i/Permissions')
  }
}

export default new PermissionApi()
