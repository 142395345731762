import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { IUserRequestModel } from '../../../../../../models/requests/UserRequestModel'
import { ProfileDetailFormValidations } from '../../../../../validations/ProfileDetailFormValidations'
import { IProfileDetailsFormModel, profileDetailsInitValues as initialValues } from '../../../../../models/ProfileDetailsFormModel'
import UserApi from '../../../../../../infrastructure/api/UserApi'
import FileApi from '../../../../../../infrastructure/api/FileApi'
import Select from 'react-select';
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import * as account from './../../../redux/AccountRedux'
import { SelectModel, selectInitValues } from '../../../../../models/SelectModel'
import { useRegionHelper } from '../../../../../helpers/RegionHelper'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { IUserClaimsRequestModel } from '../../../../../../models/requests/UserClaimsRequestModel'
import { slugify } from '../../../../../helpers/StringHelper'
import { ClaimTypeEnum } from '../../../../../../enums/ClaimTypeEnum'
import { FileTypeEnum } from '../../../../../../enums/FileTypeEnum'

const ProfileDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const [data, setData] = useState<IProfileDetailsFormModel>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IProfileDetailsFormModel>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  const [gender, setGender] = useState('0')
  const [avatar, setAvatar] = useState('')
  const [countries, setCountries] = useState<SelectModel[]>([])
  const [cities, setCities] = useState<SelectModel[]>([])
  const [states, setStates] = useState<SelectModel[]>([])

  const [selectedCountry, setSelectedCountry] = useState<SelectModel>(selectInitValues)
  const [selectedCity, setSelectedCity] = useState<SelectModel>(selectInitValues)
  const [selectedState, setSelectedState] = useState<SelectModel>(selectInitValues)

  const regionHelper = useRegionHelper(user.details!!, "", setCountries, setCities, setStates, setSelectedCountry, setSelectedCity, setSelectedState)

  const formik = useFormik<IProfileDetailsFormModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: ProfileDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        values.countryId = parseInt(selectedCountry?.value!! || '')
        values.cityId = parseInt(selectedCity?.value!!)
        values.stateId = parseInt(selectedState?.value!!)
        const updatedData = Object.assign(data, values)
        setData(updatedData)

        processUser(values)
      }, 500)
    },
  })

  async function processUser(values: IProfileDetailsFormModel) {
    try {

      let model: IUserRequestModel = {
        id: user.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        isActive: true,
      }
      await UserApi.UpdateUser(model);

      let modelClaims: IUserClaimsRequestModel = {
        id: user.id,
        claims: [
          { type: ClaimTypeEnum.Birthdate, value: values.birthDate!!.toString() },
          { type: ClaimTypeEnum.Gender, value: gender!! },
          { type: ClaimTypeEnum.Address, value: values.address!!.toString() },
          { type: ClaimTypeEnum.CountryId, value: values.countryId!!.toString() },
          { type: ClaimTypeEnum.CityId, value: values.cityId!!.toString() },
          { type: ClaimTypeEnum.StateId, value: values.stateId!!.toString() },
          { type: ClaimTypeEnum.Zipcode, value: values.zipCode!!.toString() }
        ]
      }

      if (values.photo != '') {
        let d = new Date();
        const fileName = slugify(`${model.id}-${model.firstName}-${model.lastName}-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelAvatar = new FormData()
        modelAvatar.append('name', fileName)
        modelAvatar.append('file', values.photo)
        modelAvatar.append('type', FileTypeEnum.Avatar)

        await FileApi.AddFile(modelAvatar);

        modelClaims.claims?.push({ type: ClaimTypeEnum.Photo, value: (fileName + "." + values.photo.name.split('.').pop()) });
      }

      await UserApi.UpdateUserClaims(modelClaims);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }


  useEffect(() => {
    if (user) {
      regionHelper.getCountries();
      updateData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        birthDate: user.details?.find(i => i.type == ClaimTypeEnum.Birthdate)?.value!! || '',
        address: user.details?.find(i => i.type == ClaimTypeEnum.Address)?.value!! || '',
        zipCode: user.details?.find(i => i.type == ClaimTypeEnum.Zipcode)?.value!! || '',
        gender: user.details?.find(i => i.type == ClaimTypeEnum.Gender)?.value!! || '0',
      })
      setGender(user.details?.find(i => i.type == ClaimTypeEnum.Gender)?.value!!)
      setAvatar(user.photo);
    }
  }, [user])

  const handleChangeAvatar = (event: any) => {
    formik.setFieldValue("photo", event.target.files[0])
    setAvatar(URL.createObjectURL(event.target.files[0]));
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{ backgroundImage: `url(${avatar})` }}
                  ></div>
                  <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                    <i className="fas fa-pencil-alt"></i>
                    <input
                      {...formik.getFieldProps('photo')}
                      type="file"
                      value={undefined}
                      onChange={(event) => handleChangeAvatar(event)} accept=".png, .jpg, .jpeg" />
                  </label>
                  <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel avatar">
                    <i className="bi bi-x fs-2"></i>
                  </span>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      {...formik.getFieldProps('firstName')}
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstName}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 fv-row'>
                    <input
                      {...formik.getFieldProps('lastName')}
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>E-mail</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('email')}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Phone</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('phoneNumber')}
                  type='tel'
                  placeholder='Phone number'
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Birthdate</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('birthDate')}
                  type='date'                
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Birthdate'
                />
                {formik.touched.birthDate && formik.errors.birthDate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.birthDate}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Gender</label>
              <div className='col-lg-8 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      {...formik.getFieldProps('gender')}
                      value='F'
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      checked={gender === 'F'}
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <span className='fw-bold ps-2 fs-6'>Female</span>
                  </label>
                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      {...formik.getFieldProps('gender')}
                      value='M'
                      className='form-check-input'
                      type='radio'
                      name='gender'
                      checked={gender === 'M'}
                      onChange={(e) => { setGender(e.target.value); }}
                    />
                    <span className='fw-bold ps-2 fs-6'>Male</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('address')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.address}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Country</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('countryId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => regionHelper.handleChangeCountry(event)}
                  value={selectedCountry}
                  options={countries}
                />
                {formik.touched.countryId && formik.errors.countryId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.countryId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>City</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('cityId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => regionHelper.handleChangeCity(event)}
                  value={selectedCity}
                  options={cities}
                />
                {formik.touched.cityId && formik.errors.cityId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cityId}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>State</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('stateId')}
                  styles={customSelectStyles(true)}
                  value={selectedState}
                  onChange={(event) => regionHelper.handleChangeState(event)}
                  options={states}
                />
                {formik.touched.stateId && formik.errors.stateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.stateId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Zip Code</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('zipCode')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.zipCode && formik.errors.zipCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.zipCode}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div >
    </div >
  )
}

export { ProfileDetails }
