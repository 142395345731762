import { FC, Fragment, useEffect, useState } from 'react'
import PricingApi from '../../../../infrastructure/api/PricingApi'
import { IPricingModel } from '../../../../models/responses/PricingModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const PricingListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [pricings, setPricings] = useState<PagedResponseModel<IPricingModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(999)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('amount_asc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getPricings() {
      var response = await PricingApi.GetPricings(paginationFilter)
      setPricings(response.data)
    }
    getPricings()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <Fragment>
      <div className="row g-5 g-xl-9">
        <div className="col-md-4">
          <div className="card h-md-100">
            <div className="card-body d-flex flex-center">
              <button type="button" className="btn btn-clear d-flex flex-column flex-center" onClick={() => onClickItem(0, '', 'create')}>
                <img
                  src={toAbsoluteUrl('/media/illustrations/banking.png')} alt="" className="mw-100 mh-150px mb-7" />
                <div className="fw-bolder fs-3 text-gray-600 text-hover-primary">Add New Pricing</div>
              </button>
            </div>
          </div>
        </div>
        {pricings?.items.filter(i => i.type == 1).map((item, i) => {
          return (
            <div key={i} className="col-lg-4">
              <div className="card card-flush h-md-100">
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{item.day} Days, {item.minute} Minutes</span>
                    <span className='text-muted mt-1 fw-bold'>₺{item.amount}</span>
                  </h3>
                </div>
                <div className="card-body pt-1">
                  <div className="d-flex flex-column text-gray-600">

                    {item.pricingItems.map((pricingItem, j) => {
                      return (
                        <div className="d-flex align-items-center pt-2 py-5" key={j}>
                          <span className="text-dark fw-bolder fs-2x lh-0"> {pricingItem.month}</span>&nbsp;Month&nbsp;<span className="bullet bg-primary me-3"></span><span className="text-dark  fs-6 lh-0">
                            {pricingItem.discount > 0 && <><span className="fw-bolder">%{pricingItem.discount}</span>&nbsp;Discount,</>}
                            Coupon&nbsp;<span className="fw-bolder">{pricingItem.isCouponActive ? "Active" : "Passive"}</span></span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="card-footer flex-wrap pt-0">
                  <Link to={'/pricing/pricings/edit/' + item.id} onClick={() => onClickItem(item.id, `${item.day} Days, ${item.minute} Minutes`, 'update')} className="btn btn-light-primary btn-active-primary my-1 me-2">Edit</Link>
                  <Link to={'/pricing/pricings/edit/' + item.id} onClick={() => onClickItem(item.id, `${item.day} Days, ${item.minute} Minutes`, 'delete')} className="btn btn-light-danger btn-active-danger my-1">Delete</Link>
                </div>
              </div>
            </div>
          )
        })}
        <div className="col-12">
          <h1>Group Plans</h1>
          <hr />
        </div>
        {pricings?.items.filter(i => i.type == 2).map((item, i) => {
          return (
            <div key={i} className="col-lg-4">
              <div className="card card-flush h-md-100">
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{item.day} Days, {item.minute} Minutes</span>
                    <span className='text-muted mt-1 fw-bold'>₺{item.amount}</span>
                  </h3>
                </div>
                <div className="card-body pt-1">
                  <div className="d-flex flex-column text-gray-600">

                    {item.pricingItems.map((pricingItem, j) => {
                      return (
                        <div className="d-flex align-items-center pt-2 py-5" key={j}>
                          <span className="text-dark fw-bolder fs-2x lh-0"> {pricingItem.month}</span>&nbsp;Month&nbsp;<span className="bullet bg-primary me-3"></span><span className="text-dark  fs-6 lh-0">
                            {pricingItem.discount > 0 && <><span className="fw-bolder">%{pricingItem.discount}</span>&nbsp;Discount,</>}
                            Coupon&nbsp;<span className="fw-bolder">{pricingItem.isCouponActive ? "Active" : "Passive"}</span></span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="card-footer flex-wrap pt-0">
                  <Link to={'/pricing/pricings/edit/' + item.id} onClick={() => onClickItem(item.id, `${item.day} Days, ${item.minute} Minutes`, 'update')} className="btn btn-light-primary btn-active-primary my-1 me-2">Edit</Link>
                  <Link to={'/pricing/pricings/edit/' + item.id} onClick={() => onClickItem(item.id, `${item.day} Days, ${item.minute} Minutes`, 'delete')} className="btn btn-light-danger btn-active-danger my-1">Delete</Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export { PricingListModal }
