/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RoleEnum } from '../../../../enums/RoleEnum'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import { PaymentUserListModal } from '../../../pages/payments/_modals/PaymentUserListModal'
import { InvoiceDetails } from './infos/cards/InvoiceDetails'

export function InvoicePayment() {
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  useEffect(() => {
    if (!user.roles?.some(s => s == RoleEnum.Student)) {
      document.location.href = "/error/404"
    }
  }, [user])
  return (
    <>
      <div className='row'>
        <div className='col'>
          <InvoiceDetails />
        </div>
        <div className='col-7'>
          <PaymentUserListModal onClickItem={console.log} refresh={0} />
        </div>
      </div>
    </>
  )
}
