import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { TopicFormModal } from './_modals/TopicFormModal'
import { TopicListModal } from './_modals/TopicListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import TopicApi from '../../../infrastructure/api/TopicApi'
import { Link, Route, Switch } from 'react-router-dom'
import { InfoModal } from '../../components/modals/InfoModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const TopicPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const [showInfo, setShowInfo] = useState(false)
  const handleCloseInfo = () => setShowInfo(false)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }
  const topicsBreadCrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]
  const topicBreadCrumbs: Array<PageLink> = [
    {
      title: 'Topics',
      path: `/topic/topics`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  async function deleteTopic(itemId: string) {
    var response = await TopicApi.DeleteTopic(itemId)
    setRefresh(refresh + 1)
  }

  async function setTopicLists() {
    var response = await TopicApi.SetTopicLists({
      companyId: undefined
    })
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <Switch>
          <Route path={['/topic/topics/edit/:id', '/topic/topics/add']}>
            <PageTitle breadcrumbs={topicBreadCrumbs}>Topic</PageTitle>
            <TopicFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          </Route>
          <Route path='/topic/topics'>
            <PageTitle breadcrumbs={topicsBreadCrumbs}>Topics</PageTitle>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'></h3>
              {checkPermission(user, PermissionEnum.TopicCreate) &&

                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Toolbars'
                >
                  <button onClick={() => setShowInfo(true)} className='btn btn-sm btn-light-danger'>
                    <KTSVG
                      path='/media/icons/duotone/Communication/Shield-thunder.svg'
                      className='svg-icon-3'
                    />
                    Set Topic Lists
                  </button>
                  <Link to={'/topic/topics/add'} className='btn btn-sm btn-light-primary' style={{ marginLeft: 10 }}>
                    <KTSVG
                      path='/media/icons/duotone/Code/Plus.svg'
                      className='svg-icon-3'
                    />
                    Add Topic
                  </Link>
                </div>
              }
            </div>
            <div className='card-body py-3'>
              <TopicListModal refresh={refresh} onClickItem={onClickItem} />
              <DeleteModal
                itemId={itemId}
                itemName={itemName}
                handleClose={handleCloseDelete}
                show={showDelete}
                type='Topic'
                confirm={deleteTopic}
              />

              <InfoModal
                handleClose={handleCloseInfo}
                show={showInfo}
                confirm={setTopicLists}
                title='Topic Lists Will Be Reset!'
                text='All users will be affected when we reset the Topic lists, are you sure?'
              />
            </div>
          </Route>
        </Switch>
      </div>
    </>
  )
}

const TopicWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Topic</PageTitle>
      <TopicPage />
    </>
  )
}

export { TopicWrapper }
