export interface IUserRolesRequestModel {
  id: string
  roles?: Array<string>
}

const userRolesRequestModelInitValues: IUserRolesRequestModel = {
  id: '',
  roles: []
}

export { userRolesRequestModelInitValues }