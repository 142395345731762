import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IStudentPlanningLogModel } from '../../models/responses/StudentPlanningModel'

class StudentPlanningLogApi {
  async GetStudentPlanningLogs(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IStudentPlanningLogModel[]>>(`s/Admin/StudentPlanningLogs${generateQueryParams(filter)}`)
  }
}

export default new StudentPlanningLogApi()
