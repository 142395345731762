export interface IAccountInfoFormModel {
    facebook: string
    twitter: string
    instagram: string
    youtube: string
    zoom: string
    skype: string
    website: string
}

export const accountInfoInitValues: IAccountInfoFormModel = {
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    zoom: "",
    skype: "",
    website: ""
}