import { FC } from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import { createTheme } from '@material-ui/core/styles'

type Props = {
    data: any
    columns: MUIDataTableColumnDef[]
    title: string
    customOptions?: MUIDataTableOptions
    exportExcel?: () => void
    Content?: React.ElementType
}
const BasicTable: FC<Props> = ({ data, columns, title, customOptions, exportExcel, Content }) => {

    const HeaderElements = () => {
        return (
            <>
                {
                    Content != undefined &&
                    <Content />
                }
                {
                    exportExcel != undefined &&
                    <button onClick={() => exportExcel()} className='btn btn-icon btn-active-light-dark btn-circle btn-custom'> <i className='fas fa-download text-gray-700'></i></button>
                }
            </>
        )
    };

    const defaultOptions: MUIDataTableOptions = {
        customToolbar: () => (<HeaderElements />),
        elevation: 0,
        serverSide: false,
        selectableRows: 'none',
        pagination: true,
        download: false,
        print: false,
        filter: false
    };

    const options = { ...defaultOptions, ...customOptions };
    const getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    zIndex: 0
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    fontSize: 14,
                }
            },
            MuiTableBody: {
                root: {
                    resize: 'block'
                }
            }
        }
    })
    return (
        <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                data={data}
                title={title}
                columns={columns}
                options={options}
            />
        </MuiThemeProvider>
    )
}

export { BasicTable }