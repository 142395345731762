import { IUnitRequestModel } from '../../models/requests/UnitRequestModel'
import { IUnitModel } from '../../models/responses/UnitModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { UnitTypeEnum } from '../../enums/UnitTypeEnum'
import { IUnitDictionaryModel } from '../../models/responses/UnitDictionaryModel'

class UnitApi {

  async GetUnits(type: UnitTypeEnum, filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IUnitModel[]>>(`e/Admin/Units/type/${type}${generateQueryParams(filter)}`)
  }

  async GetUnitDictionary(type?: UnitTypeEnum) {
    return await API.get<IUnitDictionaryModel[]>(`e/Admin/Units/dictionary` + (type != undefined ? `/${type}` : ''))
  }

  async GetUnit(id: number) {
    return await API.get<IUnitModel>('e/Admin/Units/' + id)
  }

  async AddUnit(model: IUnitRequestModel) {
    return await API.post('e/Admin/Units', model)
  }

  async UpdateUnit(model: IUnitRequestModel) {
    return await API.put('e/Admin/Units/' + model.id, model)
  }

  async DeleteUnit(itemId: number) {
    return await API.delete<boolean>('e/Admin/Units/' + itemId)
  }
}

export default new UnitApi()
