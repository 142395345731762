import { FC, useEffect, useState } from 'react'
import VocabularyApi from '../../../../infrastructure/api/VocabularyApi'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { IVocabularyModel, vocabularyInitValues } from '../../../../models/responses/VocabularyModel'
import { IVocabularyRequestModel } from '../../../../models/requests/VocabularyRequestModel'
import { VocabularyValidations } from '../../../validations/VocabularyValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import { VocabularyTypeEnum } from '../../../../enums/VocabularyTypeEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import { useParams } from 'react-router'
import { slugify } from '../../../helpers/StringHelper'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'
import FileApi from '../../../../infrastructure/api/FileApi'
import { IVocabularyDetailFormModel } from '../../../models/VocabularyDetailFormModel'
import { VocabularyDetails } from '../components/VocabularyDetails'
import { IPartofSpeechModel } from '../../../../models/responses/PartofSpeechModel'
import PartofSpeechApi from '../../../../infrastructure/api/PartofSpeechApi'
import { ILevelModel } from '../../../../models/responses/LevelModel'
import LevelApi from '../../../../infrastructure/api/LevelApi'
type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const VocabularyFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const { id } = useParams<any>()
  itemId = id ? id : ''
  const languages = EnumToList(LanguageEnum)
  const [selectedLanguage, setSelectedLanguage] = useState<SelectModel>(languages[0])
  const vocabularyTypes = EnumToList(VocabularyTypeEnum)
  const [partofSpeechs, setPartofSpeechs] = useState<IPartofSpeechModel[]>([])
  const [selectedVocabularyType, setSelectedVocabularyType] = useState<SelectModel>()
  const [vocabulary, setVocabulary] = useState<IVocabularyModel>(vocabularyInitValues)
  const [details, setDetails] = useState<IVocabularyDetailFormModel[]>()
  const [levels, setLevels] = useState<ILevelModel[]>([])

  const formik = useFormik<IVocabularyModel>({
    initialValues: vocabulary,
    enableReinitialize: true,
    validationSchema: VocabularyValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IVocabularyRequestModel = {
          id: values.id,
          word: values.word,
          type: parseInt(selectedVocabularyType?.value!!),
          languageId: parseInt(selectedLanguage?.value!!),
          vocabularyDetails: [],
        }
        details?.forEach(detail => {
          model.vocabularyDetails.push({
            id: detail.id == '' ? undefined : detail.id,
            example: detail.example,
            definition: detail.definition,
            pronunciation: detail.pronunciation,
            partofSpeech: detail.partofSpeech?.value,
            levelId: parseInt(detail.level?.value!!),
            image: detail.path
          })
        });

        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: IVocabularyRequestModel) {
    try {
      let d = new Date()
      const requests = details!!.map(async (detail, index) => {
        if (detail.file !== undefined) {
          const fileName = slugify(`${model.word}-${index}-${d.getMilliseconds()}-${d.getMinutes()}`)
          let modelPicture = new FormData()
          modelPicture.append('name', fileName)
          modelPicture.append('file', detail.file)
          modelPicture.append('type', FileTypeEnum.Vocabulary)
          await FileApi.AddFile(modelPicture)
          model.vocabularyDetails[index].image = fileName + '.' + detail.file.name.split('.').pop()
        }
      });

      Promise.all(requests).then(async () => {
        var result = undefined
        if (model.id === undefined || model.id === '')
          result = await VocabularyApi.AddVocabulary(model)
        else {
          result = await VocabularyApi.UpdateVocabulary(model)
        }
        formik.resetForm()
        handleClose()
      })
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {

    var responseLevels = await LevelApi.GetLevelDictionary()
    setLevels(responseLevels.data)

    var responsePartofSpeechs = await PartofSpeechApi.GetPartofSpeechs({
      page: 1,
      size: 9999,
      orderBy: 'name_asc',
      search: '',
    })
    setPartofSpeechs(responsePartofSpeechs.data.items)


    let result: IVocabularyModel
    if (itemId !== '') {
      var response = await VocabularyApi.GetVocabulary(itemId)
      result = response.data

 
      if (response.data.languageId) {
        handleChangeLanguage({
          value: response.data.languageId.toString(),
          label: languages.find((i) => i.value === response.data.languageId.toString())?.label,
        })
      }

      if (response.data.type) {
        handleChangeVocabularyType({
          value: response.data.type.toString(),
          label: vocabularyTypes.find((i) => i.value === response.data.type.toString())?.label,
        })
      }

      if (response.data.vocabularyDetails.length > 0) {
        var newDetails: IVocabularyDetailFormModel[] = []
        response.data.vocabularyDetails.map((detail, index) => {
          newDetails.push({
            id: detail.id,
            example: detail.example,
            definition: detail.definition,
            pronunciation: detail.pronunciation,
            partofSpeech: { value: detail.partofSpeech },
            level: { value: detail.levelId?.toString() || '' },
            path: detail.image,
            picture: detail.image != null && detail.image !== ''
              ? `${process.env.REACT_APP_CDN_URL}images/${FileTypeEnum.Vocabulary.toLowerCase()}/${detail.image}`
              : toAbsoluteUrl('/media/misc/none.png')
          })
        });
        setDetails(newDetails)

      }
    } else {
      result = vocabularyInitValues
    }
    setVocabulary(result)
  }

  useEffect(() => {
    formik.resetForm()
    init()
  }, [itemId])

  const handleChangeVocabularyType = (e: any) => {
    setSelectedVocabularyType(e)
  }
  const handleChangeLanguage = (e: any) => {
    setSelectedLanguage(e)
  }

  return (
    <form className='form' onSubmit={formik.handleSubmit} noValidate>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h1>{itemId === '' ? 'Add' : 'Edit'} Vocabulary</h1>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='row mb-8'>
          <div className='col-lg-6 mb-3'>
            <label className='form-label fw-bolder required'>Word</label>
            <input
              {...formik.getFieldProps('word')}
              type='text'
              name='word'
              className='form-control form-control-solid'
              placeholder='Entry..'
            />
            <FormikValidationError touched={formik.touched.word} error={formik.errors.word} />
          </div>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder required'>Language</label>
            <Select
              {...formik.getFieldProps('language')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeLanguage(event)}
              value={selectedLanguage}
              options={languages}
            />
            <FormikValidationError
              touched={formik.touched.languageId}
              error={formik.errors.languageId}
            />
          </div>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder required'>Type</label>
            <Select
              {...formik.getFieldProps('type')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeVocabularyType(event)}
              value={selectedVocabularyType}
              options={vocabularyTypes}
            />
            <FormikValidationError touched={formik.touched.type} error={formik.errors.type} />
          </div>
        </div>
        <h4>Details</h4>
        <hr />
        <VocabularyDetails
          details={details}
          setDetails={setDetails}
          partofSpeechsData={partofSpeechs}
          levelsData={levels}
          languageId={parseInt(selectedLanguage?.value.toString() || '2')} />
      </div>
      <div className='card-footer'>
        <div className='d-flex flex-column '>
          <SaveChangesButton
            loading={loading}
            setLoading={setLoading}
            complete={complete}
            setComplete={setComplete}
            valid={formik.isValid}
            submit={formik.isSubmitting}
            setSubmit={formik.submitForm}
          />
        </div>
      </div>
    </form>
  )
}

export { VocabularyFormModal }
