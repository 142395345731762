import { FC, useEffect, useState } from 'react'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { ITopicsModel } from '../../../../models/responses/TopicModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { SelectModel } from '../../../models/SelectModel'
import { ILevelModel } from '../../../../models/responses/LevelModel'
import { EnumToList } from '../../../helpers/EnumHelper'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const TopicListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [topics, setTopics] = useState<PagedResponseModel<ITopicsModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState<Map<string, string>>(new Map())
  const [sort, setSort] = useState('date_desc')
  const [levels, setLevels] = useState<ILevelModel[]>([])
  const languages = EnumToList(LanguageEnum);

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
      filters: filters
    }
    async function getTopics() {
      var response = await TopicApi.GetTopics(paginationFilter)
      setTopics(response.data)
    }

    async function getLevels() {
      var responseLevels = await LevelApi.GetLevelDictionary()
      setLevels(responseLevels.data)
    }

    getLevels()
    getTopics()
  }, [refresh, pageNumber, pageSize, searchText, sort, filters])

  return (
    <>
      <GenericTable
        title='Topics'
        data={topics?.items}
        currentTotalRecords={topics?.totalRecords!!}
        currentPageNumber={topics?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Title',
            name: 'title',
            options: {
              filter: false,
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Order',
            name: 'order',
            options: {
              filter: false,
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Level',
            name: 'levelId',
            options: {
              filter: true,
              filterOptions: { names: [...levels.map(d => d.name || '')] },
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{levels.find(i => i.id == value)?.name}</div>
              },
            },
          },
          {
            label: 'Language',
            name: 'languageId',
            options: {
              filterOptions: { names: [...languages.map(d => d.label || '')] },
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{LanguageEnum[value]}</div>
              },
            },
          },
          { label: 'Is Group Topic', name: 'IsGroupTopic', options: { display: 'excluded', filterOptions: { names: ['Yes', 'No'] } } },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={topics?.items[dataIndex]?.id!!}
                    itemParam={topics?.items[dataIndex]?.title!!}
                    links={[
                      { type: 'edit', value: '/topic/topics/edit/' + topics?.items[dataIndex]?.id!! }
                    ]}
                    types={[TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          filter: true,
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            setPageNumber(1)
            let newFilters: Map<string, string> = new Map()
            for (let i = 0; i < filterList.length; i++) {
              if (filterList[i].length > 0) {
                if (i == 3) newFilters.set("Level", levels.find(j => j.name == filterList[i][0].toString())?.id.toString() || '')
                else if (i == 4) newFilters.set("Language", LanguageEnum[filterList[i][0] as any])
                else if (i == 5) newFilters.set("IsGroupTopic", filterList[i][0].toString() == "Yes" ? "true" : "false")
              }
            }
            setFilters(newFilters)
          }
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { TopicListModal }
