import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { ScheduleFormValidations } from '../../../validations/ScheduleFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { IScheduleModel, scheduleInitValues } from '../../../../models/responses/ScheduleModel'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { IScheduleRequestModel } from '../../../../models/requests/ScheduleRequestModel'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { RoleEnum } from '../../../../enums/RoleEnum'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import AsyncSelect from 'react-select/async'
import debounce from 'es6-promise-debounce'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const ScheduleFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [schedule, setSchedule] = useState<IScheduleModel>(scheduleInitValues)
  const [trainers, setTrainers] = useState<SelectModel[]>([])


  const statusList = EnumToList(ScheduleStatusEnum)
  const [selectedStatus, setSelectedStatus] = useState<SelectModel>(selectInitValues)

  const [selectedTrainer, setSelectedTrainer] = useState<SelectModel>(selectInitValues)

  const getTrainerDatas = async (event: string) => {
    if (event.length > 1) {
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 5,
        orderBy: "firstName_asc",
        search: event
      }

      var filters: DictionaryModel[] = [{ type: "Role", value: RoleEnum.Trainer.toString() }]
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var dataConsultants: SelectModel[] = [
        ...response.data.items.map((d) => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })),
      ]
      setTrainers(dataConsultants)
      return dataConsultants;
    }
    return [];
  }

  const formik = useFormik<IScheduleModel>({
    initialValues: schedule,
    enableReinitialize: true,
    validationSchema: ScheduleFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IScheduleRequestModel = {
          id: itemId,
          trainerId: selectedTrainer.value,
          status: parseInt(selectedStatus.value)
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IScheduleRequestModel) {
    try {      
      var result = undefined
      if (model.id === undefined || model.id === '') {

      }
      else {
        result = await ScheduleApi.UpdateSchedule(model)
      }

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IScheduleModel
    if (itemId !== '') {
      var response = await ScheduleApi.GetSchedule(itemId)
      result = response.data
      handleChangeStatus(statusList.find(i => i.value == result.status.toString()))
      var responseUsers = await UserApi.GetUserDictionaryIds([result.trainerId])
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      var trainer = dataUsers.find(i => i.id == result.trainerId.toString());
      handleChangeTrainer(trainer)

      var selectObject: SelectModel = { value: result.trainerId, label: `${trainer?.firstName} ${trainer?.lastName} - ${trainer?.email}` }
      setSelectedTrainer(selectObject)

    } else {
      handleChangeTrainer(selectInitValues)
      result = scheduleInitValues
    }
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  const handleChangeTrainer = (e: any) => {
    setSelectedTrainer(e);
  }

  const handleChangeStatus = (e: any) => {
    setSelectedStatus(e);
  }

  const loadOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var data = await getTrainerDatas(inputValue);
        setTrainers(data);
        resolve(data);
      }, 1000);
    });


  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-5 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Schedule</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col'>
                <label className='form-label fw-bold fs-6'>Status</label>
                <Select
                  {...formik.getFieldProps('status')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeStatus(event)}
                  value={selectedStatus}
                  options={statusList}
                />
                <FormikValidationError touched={formik.touched.status} error={formik.errors.status} />
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col'>
                <label className='form-label fw-bold fs-6'>Trainer</label>
                <AsyncSelect
                  cacheOptions
                  {...formik.getFieldProps('trainerId')}
                  styles={customSelectStyles(true)}
                  loadOptions={debounce(loadOptions, 500)}
                  onChange={(event) => setSelectedTrainer(event!)}
                  defaultOptions={trainers}
                  value={selectedTrainer}
                />
                <FormikValidationError touched={formik.touched.trainerId} error={formik.errors.trainerId} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { ScheduleFormModal }
