import React, { Fragment, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import JobApi from '../../../../../../infrastructure/api/JobApi'
import Select from 'react-select';
import { SelectModel } from '../../../../../models/SelectModel'
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import { CareerDetailFormValidations } from '../../../../../validations/CareerDetailFormValidations'
import { ICareerDetailFormModel, careerDetailsInitValues as initialValues } from '../../../../../models/CareerDetailFormModel'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { IUserClaimsRequestModel, userClaimsInitValues } from '../../../../../../models/requests/UserClaimsRequestModel'
import UserApi from '../../../../../../infrastructure/api/UserApi'
import * as account from './../../../redux/AccountRedux'

const CareerDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const [jobs, setJobs] = useState<SelectModel[]>([])
  const [selectedJob, setSelectedJob] = useState<SelectModel>()

  const [selectedHeardOn, setSelectedHeardOn] = useState<SelectModel>()

  const educallHeards = [
    { value: "", label: "Select.." },
    { value: "1", label: "Internet Advertising" },
    { value: "2", label: "Social Media" },
    { value: "3", label: "Friend Recommendation" },
    { value: "4", label: "Search Engine" },
  ]


  async function getJobs() {
    let response = await JobApi.GetJobs()
    let jobs = response.data.map(d => ({
      "value": d.id.toString(),
      "label": d.name
    }));
    setJobs(jobs);

    const jobId = user?.details?.find(i => i.type == "JobId")?.value!!
    if (jobId) {
      handleChangeJob({
        value: jobId,
        label: jobs.find(i => i.value == jobId)?.label
      })
    }
  }

  async function getEducallHeards() {
    const heardId = user?.details?.find(i => i.type == "EducallHeardOnId")?.value!!
    if (heardId) {
      handleChangeHeardOn({
        value: heardId,
        label: educallHeards.find(i => i.value == heardId)?.label
      })
    }
  }

  useEffect(() => {
    if (user) {
      getJobs();
      getEducallHeards();
      initialValues.hobbies = user.details?.find(i => i.type == "Hobbies")?.value!! || ''
      initialValues.englishNeeds = user.details?.find(i => i.type == "EnglishNeeds")?.value!! || ''
      initialValues.school = user.details?.find(i => i.type == "School")?.value!! || ''
      initialValues.schoolDepartment = user.details?.find(i => i.type == "SchoolDepartment")?.value!! || ''
      initialValues.spokenLanguage = user.details?.find(i => i.type == "SpokenLanguage")?.value!! || ''
    }
  }, [user?.details])


  const formik = useFormik<ICareerDetailFormModel>({
    initialValues,
    validationSchema: CareerDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        values.jobId = parseInt(selectedJob?.value!!)
        values.educallHeardOnId = parseInt(selectedHeardOn?.value!!)
        userClaimsInitValues.claims = []
        let model = userClaimsInitValues
        model.id = user.id
        let claims: any = values
        Object.keys(values).map((item) => {
          model.claims?.push({ type: item.charAt(0).toUpperCase() + item.substring(1), value: claims[item].toString() });
        })

        processRole(model)
      }, 500)
    },
  })

  async function processRole(model: IUserClaimsRequestModel) {
    try {
      await UserApi.UpdateUserClaims(model);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const formDatas = [
    { key: 'hobbies', name: 'Hobbies', title: 'Hobbies and Interests' },
    { key: 'school', name: 'School', title: 'School' },
    { key: 'schoolDepartment', name: 'SchoolDepartment', title: 'School Department' },
    { key: 'englishNeeds', name: 'EnglishNeeds', title: 'English Needs' },
    { key: 'spokenLanguage', name: 'SpokenLanguage', title: 'Spoken Language' }
  ]


  const handleChangeJob = (e: any) => {
    setSelectedJob(e);
  }

  const handleChangeHeardOn = (e: any) => {
    setSelectedHeardOn(e);
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_career_details'
        aria-expanded='true'
        aria-controls='kt_account_career_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Career Details</h3>
        </div>
      </div>

      <div id='kt_account_career_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              {formDatas.map((item, index) => {
                const colSize = index == 0 ? 12 : 6
                return (
                  <Fragment key={index}>
                    <div className={'col-' + colSize}>
                      <label className='col-form-label fw-bold fs-6'>{item.title}</label>
                      <input
                        {...formik.getFieldProps(item.key)}
                        className='form-control form-control-lg form-control-solid'
                      />
                    </div>
                  </Fragment>
                )
              }
              )}

              <div className='col-6'>
                <label className='col-form-label fw-bold fs-6'>Educall Heard On</label>
                <Select
                  {...formik.getFieldProps('educallHeardOn')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeHeardOn(event)}
                  value={selectedHeardOn}
                  options={educallHeards}
                />
              </div>

              <div className='col-6'>
                <label className='col-form-label fw-bold fs-6'>Job</label>
                <Select
                  {...formik.getFieldProps('jobId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeJob(event)}
                  value={selectedJob}
                  options={jobs}
                />
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div>
    </div>
  )
}
export { CareerDetails }