import { IPopupRequestModel } from '../../models/requests/PopupRequestModel'
import { IPopupModel } from '../../models/responses/PopupModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PopupApi {

  async GetPopups(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPopupModel[]>>(`e/Admin/Popups${generateQueryParams(filter)}`)
  }

  async GetPopup(id: number) {
    return await API.get<IPopupModel>('e/Admin/Popups/' + id)
  }

  async AddPopup(model: IPopupRequestModel) {
    return await API.post('e/Admin/Popups', model)
  }

  async UpdatePopup(model: IPopupRequestModel) {
    return await API.put('e/Admin/Popups/' + model.id, model)
  }

  async DeletePopup(itemId: number) {
    return await API.delete<boolean>('e/Admin/Popups/' + itemId)
  }
}

export default new PopupApi()
