import { FC, useEffect, useState } from 'react'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import ScheduleApi from '../../../../infrastructure/api/ScheduleApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IStudentCommentModel } from '../../../../models/responses/ScheduleModel'
import { Link } from 'react-router-dom'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import parse from 'html-react-parser'
type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const StudentCommentListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('title_asc')
    const [studentComments, setStudentComments] = useState<PagedResponseModel<IStudentCommentModel[]>>()
    const [filters, setFilters] = useState<Map<string, string>>(new Map())

    async function init() {
        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
            filters: filters
        }

        var response = await ScheduleApi.GetStudentComments(paginationFilter)
        setStudentComments(response.data)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort, filters]);

    return (
        <>

            <GenericTable
                title='Student Comments'
                data={studentComments?.items}
                currentTotalRecords={studentComments?.totalRecords!!}
                currentPageNumber={studentComments?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: '#',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                            },
                            sort: false,
                            viewColumns: false,
                            filter: false
                        },
                    },
                    {
                        label: "Student", name: "studentFullName", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <b><Link to={'/account/users/' + studentComments?.items[dataIndex]?.studentId + '/overview'}>{studentComments?.items[dataIndex]?.studentFullName}</Link></b>
                            },
                        }
                    },
                    {
                        label: "Message", name: "message", options: {
                            filter: false,
                            customBodyRender: (value) => {
                                return <>{parse(value)}</>
                            }
                        }
                    },
                    {
                        label: "Pleasure", name: "pleasure", options: {
                            filterOptions: { names: ["5", "4", "3", "2", "1"] },
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "Difficulty", name: "difficulty", options: {
                            filterOptions: { names: ["5", "4", "3", "2", "1"] },
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "Useful", name: "useful", options: {
                            filterOptions: { names: ["5", "4", "3", "2", "1"] },
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "Quality", name: "quality", options: {
                            filterOptions: { names: ["5", "4", "3", "2", "1"] },
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "Prepared", name: "prepared", options: {
                            filterOptions: { names: ["5", "4", "3", "2", "1"] },
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "Trainer", name: "trainerFullName", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <b><Link to={'/account/users/' + studentComments?.items[dataIndex]?.trainerId + '/overview'}>{studentComments?.items[dataIndex]?.trainerFullName}</Link></b>
                            },
                        }
                    },
                    {
                        label: "Date", name: "date", options: {
                            filter: false,
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={studentComments?.items[dataIndex]?.scheduleId!!}
                                        itemParam={studentComments?.items[dataIndex]?.date}
                                        types={[TableHelperButtonEnum.View]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    filter: true,
                    onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                        setPageNumber(1)
                        let newFilters: Map<string, string> = new Map()
                        for (let i = 0; i < filterList.length; i++) {
                            if (filterList[i].length > 0) {
                                if (i == 3) newFilters.set("Pleasure", filterList[i][0].toString())
                                if (i == 4) newFilters.set("Difficulty", filterList[i][0].toString())
                                if (i == 5) newFilters.set("Useful", filterList[i][0].toString())
                                if (i == 6) newFilters.set("Quality", filterList[i][0].toString())
                                if (i == 7) newFilters.set("Prepared", filterList[i][0].toString())
                            }
                        }
                        setFilters(newFilters)
                    },
                    sort: false,
                    search: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { StudentCommentListModal }
