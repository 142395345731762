import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import { ISendNotificationRequestModel } from '../../models/requests/SendNotificationRequestModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IUserCloudMessageModel } from '../../models/responses/UserCloudMessageModel'
import API from './Api'

class CloudMessageApi {

    async GetUserCloudMessages(userId: string, filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<IUserCloudMessageModel[]>>(`e/Admin/CloudMessages/User/${userId}${generateQueryParams(filter)}`)
    }

    async AddCloudMessages(model: ISendNotificationRequestModel) {
        return await API.post('e/Admin/CloudMessages', { "CloudMessages": [model] })
    }

}

export default new CloudMessageApi()
