import API from './Api'
import { IEducallUserRequestModel } from '../../models/requests/EducallUserRequestModel'
import { IEducallUserModel } from '../../models/responses/EducallUserModel'

class EducallUserApi {

    async GetUser(id: string) {
        return await API.get<IEducallUserModel>('e/Admin/Users/' + id)
    }

    async UpdateUser(model: IEducallUserRequestModel) {
        return await API.put('e/Admin/Users/' + model.id, model)
    }
}

export default new EducallUserApi()
