import { SelectModel } from '../../app/models/SelectModel'
import { RoleRequestModel } from '../../models/requests/RoleRequestModel'
import { IRoleDictionaryModel } from '../../models/responses/RoleDictionaryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { RoleModel } from '../../models/responses/RoleModel'
import API from './Api'

class RoleApi {
  async GetRoles() {
    return await API.get<PagedResponseModel<RoleModel[]>>('i/Roles?pageSize=100')
  }

  async GetRoleDictionary() {
    return await API.get<IRoleDictionaryModel[]>('i/Roles/dictionary')
  }

  async GetRole(id: string) {
    return await API.get<RoleModel>('i/Roles/' + id)
  }

  async AddRole(model: RoleRequestModel) {
    return await API.post('i/Roles', model)
  }

  async UpdateRole(model: RoleRequestModel) {
    return await API.put('i/Roles/' + model.id, model)
  }

  async DeleteRole(itemId: string) {
    return await API.delete('i/Roles/' + itemId)
  }
}

export default new RoleApi()
