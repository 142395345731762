import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { ITaskDefineModel, ITaskModel } from '../../../../models/responses/TaskModel'
import TaskApi from '../../../../infrastructure/api/TaskApi'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual } from 'react-redux'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import parse from 'html-react-parser'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const TaskMyListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [tasks, setTasks] = useState<PagedResponseModel<ITaskModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [filters, setFilters] = useState<Map<string, string>>(new Map())

  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  const taskDefines: ITaskDefineModel[] = useSelector<RootState>(({ task }) => task.taskDefines, shallowEqual) as ITaskDefineModel[]
  const user: IUserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUserModel

  const getUsers = async (userIds: string[]) => {
    var responseUsers = await UserApi.GetUserDictionaryIds(userIds)
    var dataUsers: IUserDictionaryModel[] = responseUsers.data
    setUsers([...users, ...dataUsers]);
  }

  async function getTasks() {

    var lastList = filters;
    lastList.set("UserId", user.id)
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
      filters: lastList
    }
    var response = await TaskApi.GetTasks(paginationFilter)

    getUsers([...response.data.items.flatMap(s => s.assignedUsers), ...response.data.items.flatMap(s => s.associatedUsers), ...response.data.items.flatMap(s => s.createdBy)])
    setTasks(response.data)
  }

  useEffect(() => {
    getTasks()
  }, [refresh, pageNumber, pageSize, searchText, sort, filters])

  return (
    <>
      <GenericTable
        title='My Tasks'
        data={tasks?.items}
        currentTotalRecords={tasks?.totalRecords!!}
        currentPageNumber={tasks?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Subject / Creator',
            name: 'taskDefineId',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <div>{taskDefines.find(i => i.id == tasks?.items[dataIndex].taskDefineId)?.title.toUpperCase()}
                  <br />
                  <span className='badge badge-light-success mt-1'>{users.find(i => i.id == tasks?.items[dataIndex].createdBy)?.email}</span>
                </div>
              },
            },
          },
          {
            label: 'Explanation',
            name: 'explanation',
            options: {
              customBodyRender: (value) => {
                return (
                  <div>{parse(value)}</div>
                )

              },
            },
          },
          {
            label: 'Associated',
            name: 'associatedUsers',
            options: {
              customBodyRender: (value: string[]) => {
                return <>{value!!.map((item, key) => {
                  return (
                    <div key={key}><span className='badge badge-light-primary mb-1'>{users.find(i => i.id == item)?.email}</span><br /></div>
                  )
                }
                )
                }</>
              },
            },
          },
          {
            label: "Status",
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return (<>
                  {tasks?.items[dataIndex].isCompleted ? <label className='badge badge-light-primary'>Completed</label> :
                    (!tasks?.items[dataIndex].transactions.some(i => i.isCompleted && i.userId == user.id) ? <label className='badge badge-light-info'>Wait</label> : <label className='badge badge-light-primary'>Completed</label>)}
                </>)
              }
            }
          },

          {
            label: 'Expiration At',
            name: 'expirationAt'
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                let types = [TableHelperButtonEnum.View, TableHelperButtonEnum.Delete, TableHelperButtonEnum.Reply];
                if (tasks?.items[dataIndex]?.createdBy == user.id) types.push(TableHelperButtonEnum.Update)

                return (
                  <TableHelperButtons
                    itemId={tasks?.items[dataIndex]?.id!!}
                    itemParam={taskDefines.find(i => i.id == tasks?.items[dataIndex].taskDefineId)?.title}
                    types={types}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { TaskMyListModal }
