export interface IConfigModel {
  id: number
  key: string
  title: string
  value: string
  configType: number
}

const configInitValues: IConfigModel = {
  id: 0,
  key: '',
  title: '',
  value: '',
  configType: 0,
}

export {configInitValues}
