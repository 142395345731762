export interface ICareerDetailFormModel {
    hobbies: string,
    school: string,
    schoolDepartment: string,
    englishNeeds: string,
    spokenLanguage: string,
    educallHeardOnId: number,
    jobId: number
}

const careerDetailsInitValues: ICareerDetailFormModel = {
    hobbies: "",
    school: "",
    schoolDepartment: "",
    englishNeeds: "",
    spokenLanguage: "",
    educallHeardOnId: 0,
    jobId: 0
}

export { careerDetailsInitValues }