import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useSelector, shallowEqual } from 'react-redux'
import { ReportListModal } from './_modals/ReportListModal'

const ReportPage: FC = () => {
    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleShowDelete = () => setShowDelete(true)


    const [refresh, setRefresh] = useState<number>(0)
    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
    const handleShowForm = () => setShowForm(true)

    const [showView, setShowView] = useState(false)
    const handleCloseView = () => setShowView(false)
    const handleShowView = () => setShowView(true)

    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'view':
                handleShowView()
                break
            case 'delete':
                handleShowDelete()
                break
            default:
                handleShowForm()
                break
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                </div>

                <div className='card-body py-3'>
                    <ReportListModal refresh={refresh} onClickItem={onClickItem} />
                </div>
            </div>
        </>
    )
}

const ReportWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Report</PageTitle>
            <ReportPage />
        </>
    )
}

export { ReportWrapper }
