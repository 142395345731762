import * as Yup from 'yup'

const PaymentFormValidations = () => {
  return Yup.object().shape({
    code: Yup.string()
      .min(10, 'Minimum 10 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Code is required'),
    name: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Name is required'),
    surname: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Surname is required'),
  })
}

export { PaymentFormValidations }
