/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { UserListModal } from './_modals/UserListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import { UserFormModal } from '../users/_modals/UserFormModal'
import UserApi from '../../../infrastructure/api/UserApi'
import { UserImportModal } from './_modals/UserImportModal'
import { SendNotificationFormModal } from './_modals/SendNotificationFormModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const UserPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)

  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const [showSendNotification, setShowSendNotification] = useState(false)
  const handleCloseSendNotification = () => { setShowSendNotification(false); }
  const handleShowSendNotification = () => setShowSendNotification(true)

  const [showImport, setShowImport] = useState(false)
  const handleCloseImport = () => { setShowImport(false); setRefresh(refresh + 1) }
  const handleShowImport = () => setShowImport(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      case 'import':
        handleShowImport()
        break
      case 'sendNotification':
        handleShowSendNotification()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deleteUser(itemId: number) {
    var response = await UserApi.DeleteUser(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
          </h3>
          {checkPermission(user, PermissionEnum.UserCreate) &&

            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a User'
            >
              <a
                href='#'
                onClick={() => onClickItem(0, '', 'import')}
                className='btn btn-sm btn-light-info me-3'
              >
                <KTSVG
                  path='/media/icons/duotone/Files/File-plus.svg'
                  className='svg-icon-3'
                />
                Import Students
              </a>
              <a
                href='#'
                onClick={() => onClickItem(0, '', 'create')}
                className='btn btn-sm btn-light-primary'
              >
                <KTSVG
                  path='/media/icons/duotone/Code/Plus.svg'
                  className='svg-icon-3'
                />
                Add User
              </a>
            </div>
          }
        </div>

        <div className='card-body py-3'>
          <SendNotificationFormModal show={showSendNotification} handleClose={handleCloseSendNotification} />
          <UserImportModal itemId={itemId} show={showImport} handleClose={handleCloseImport} />
          <UserFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='User'
            confirm={deleteUser}
          />
        </div>
      </div>
      <UserListModal refresh={refresh} onClickItem={onClickItem} />
    </>
  )
}

const UserWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ACCOUNT.USERS' })}</PageTitle>
      <UserPage />
    </>
  )
}

export { UserWrapper }
