import * as Yup from 'yup'

const JobInterviewFormValidations = () => {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(250, 'Maximum 250 symbols')
      .required('Title is required'),
  })
}

export { JobInterviewFormValidations }
