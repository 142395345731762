export interface IStudentDetailsFormModel {
    isOurStudent: number,
    isAggrementAccepted: number,
    isSmsActive: number,
    isEmailActive: number,
    isToeflActive: boolean,
    isYdsActive: boolean,
    isSupportByEducall: number,
    isSupportByCompany: number,
    isSafeExam: number,
    edupoint: number,
    examFinisher: number,
    parentId?: string,
    companyId?: string,
    startupLevelId?: number,
    presentLevelId?: number,
    isJobInterview?: boolean,
    isDemoStudent?: boolean,
    tolkRemaining?: number,
    tolkPackage?: number,
    preferCallingPlatform: number,
    educationStartDate?: string,
    educationEndDate?: string
}

const studentDetailsInitValues: IStudentDetailsFormModel = {
    isOurStudent: 0,
    isAggrementAccepted: 0,
    isSmsActive: 0,
    isEmailActive: 0,
    isToeflActive: false,
    isYdsActive: false,
    isSupportByCompany: 0,
    isSupportByEducall: 0,
    isSafeExam: 0,
    edupoint: 0,
    examFinisher: 0,
    parentId: undefined,
    companyId: undefined,
    startupLevelId: 0,
    presentLevelId: 0,
    isDemoStudent: false,
    isJobInterview: false,
    tolkRemaining: 0,
    tolkPackage: 0,
    preferCallingPlatform: 0,
    educationStartDate: '',
    educationEndDate: ''
}

export { studentDetailsInitValues }