import * as Yup from 'yup'

const LevelGroupFormValidations = () => {
  return Yup.object().shape({
    title: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Name is required'),
  })
}

export { LevelGroupFormValidations }
