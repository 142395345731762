import { number } from "yup"

export interface IResultModel {
    id: string,
    title: string,
    examTypeId: string,
    examId: string,
    levelId: number,
    isWriting: boolean,
    unitId: number,
    isSafeExam: boolean,
    studentId: string,
    studentName: string,
    startTime: string,
    endTime: string,
    totalQuestion: number,
    totalCorrect: number,
    totalWrong: number,
    totalEmpty: number,
    success: string,
    score: number,
    copyPercentage: string,
    currentStudentLevelId: number,
    isEvaluated: boolean,
    violationSeconds: number,
    evaluationDate: string,
    evaluativeId: string,
    evaluation: string,
    choices: IChoiceModel[]
}
export interface IChoiceModel {
    questionId: string,
    questionTitle: string,
    correctAnswers: string[],
    answers: string[],
    date: string,
    isCorrect: boolean,
    isEmpty: boolean,
    status: string
}

const resultInitValues: IResultModel = {
    id: '',
    title: '',
    examTypeId: '',
    examId: '',
    levelId: 0,
    isWriting: false,
    unitId: 0,
    isSafeExam: false,
    studentId: '',
    studentName: '',
    startTime: '',
    endTime: '',
    totalQuestion: 0,
    totalCorrect: 0,
    totalWrong: 0,
    totalEmpty: 0,
    success: '',
    score: 0,
    copyPercentage: '',
    currentStudentLevelId: 0,
    isEvaluated: false,
    violationSeconds: 0,
    evaluationDate: '',
    evaluativeId: '',
    evaluation: '',
    choices: []
}

export { resultInitValues }