import { ICompanyRequestModel } from '../../models/requests/CompanyRequestModel'
import { ICompanyModel } from '../../models/responses/CompanyModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class CompanyApi {

  async GetCompanies(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ICompanyModel[]>>(`e/Admin/Companies${generateQueryParams(filter)}`)
  }

  async GetCompanyDictionary() {
    return await API.get<ICompanyModel[]>(`e/Admin/Companies/dictionary`)
  }

  async GetSubCompaniesDictionary(companyId: string = "") {
    return await API.get<ICompanyModel[]>(`e/Admin/Companies/sub` + (companyId != "" ? "/" + companyId : ""))
  }

  async GetCompany(id: string) {
    return await API.get<ICompanyModel>('e/Admin/Companies/' + id)
  }

  async AddCompany(model: ICompanyRequestModel) {
    return await API.post('e/Admin/Companies', model)
  }

  async UpdateCompany(model: ICompanyRequestModel) {
    return await API.put('e/Admin/Companies/' + model.id, model)
  }

  async DeleteCompany(itemId: string) {
    return await API.delete<boolean>('e/Admin/Companies/' + itemId)
  }
}

export default new CompanyApi()
