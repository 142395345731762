/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import Select from 'react-select'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import TopicApi from '../../../../infrastructure/api/TopicApi'
import { IProcessTopicModel, ITopicListItemModel } from '../../../../models/responses/TopicListModel'
import { TopicStatusEnum } from '../../../../enums/TopicStatusEnum'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { DeleteModal } from '../../../components/modals/DeleteModal'
import { LanguageEnum } from '../../../../enums/LanguageEnum'

export function Topics() {
    const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
    const levelId: number = user.presentLevelId || 0;
    const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])
    const [selectedLevel, setSelectedLevel] = useState<SelectModel>()
    const [topics, setTopics] = useState<ITopicListItemModel[]>([])
    const [selectedIsPresentLevel, setSelectedIsPresentLevel] = useState<boolean>(false)

    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleCloseDelete = () => setShowDelete(false)
    const handleShowDelete = () => setShowDelete(true)

    const [showActive, setShowActive] = useState(false)
    const handleCloseActive = () => setShowActive(false)
    const handleShowActive = () => setShowActive(true)


    const [refresh, setRefresh] = useState<number>(0)

    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'delete':
                handleShowDelete()
                break
            case 'active':
                handleShowActive()
                break
        }
    }

    const init = useCallback(async () => {
        if (levelDictionary.length < 1) {
            var responseLevels = await LevelApi.GetLevelDictionary()
            var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
                value: d.id.toString(),
                label: d.name + (levelId == d.id ? ' (Present Level)' : '')
            }))]
            setLevelDictionary(dataLevels)
            handleChangeLevel(dataLevels.find(i => i.value == levelId.toString()))
        }
    }, [levelId, refresh]);

    useEffect(() => {
        init()
    }, [user, refresh])

    const getTopicLists = async (levelId: number) => {
        if (levelId > 0) {
            var responseTopicList = await TopicApi.GetStudentTopicLists(user.id, levelId);
            if (responseTopicList.data.topics)
                setTopics(responseTopicList.data.topics)
            else setTopics([])
        } else setTopics([])
    }

    const handleChangeLevel = (e: any) => {
        setSelectedIsPresentLevel(levelId == e?.value)

        setSelectedLevel(e)
        getTopicLists(parseInt(e?.value))
    }

    async function cancelTopicsTopic(itemId: string) {

        var model: IProcessTopicModel = {
            id: itemId,
            link: '',
            studentId: user.id,
            languageId: LanguageEnum.English,
            status: TopicStatusEnum.Cancelled,
            levelId: parseInt(selectedLevel?.value || '')
        }
        await TopicApi.ProcessTopic(model)
        setRefresh(refresh + 1)
        getTopicLists(levelId)
    }

    async function activeTopicsTopic(itemId: string) {

        var model: IProcessTopicModel = {
            id: itemId,
            link: '',
            studentId: user.id,
            languageId: LanguageEnum.English,
            status: TopicStatusEnum.Active,
            levelId: parseInt(selectedLevel?.value || '')
        }
        await TopicApi.ProcessTopic(model)
        setRefresh(refresh + 1)
        getTopicLists(levelId)
    }

    return (
        <>
            <div className='row'>
                <DeleteModal
                    itemId={itemId}
                    itemName={itemName}
                    handleClose={handleCloseDelete}
                    show={showDelete}
                    type='Topic'
                    confirm={cancelTopicsTopic}
                />
                <DeleteModal
                    itemId={itemId}
                    itemName={itemName}
                    handleClose={handleCloseActive}
                    show={showActive}
                    type='Active Topic'
                    confirm={activeTopicsTopic}
                />
                <div className='col-md-8'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Available Topics</h3>
                            </div>
                            <div className='mt-4 col-5'>
                                <Select
                                    styles={customSelectStyles(true)}
                                    onChange={(event) => handleChangeLevel(event)}
                                    value={selectedLevel}
                                    options={levelDictionary}
                                /></div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                <div className='row'>
                                    {topics.filter(i => i.topicStatus == TopicStatusEnum.Active).map((topic, index) => {
                                        return (
                                            <div className='col-6' key={index}>
                                                <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-3">
                                                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                                                        <span className="badge badge-circle badge-dark">{index + 1}</span>
                                                    </span>
                                                    <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                                                        <h5 className="mb-1">{topic.topicTitle}</h5>
                                                    </div>
                                                    {selectedIsPresentLevel &&
                                                        <div className="col d-flex justify-content-end flex-stack text-end">
                                                            <TableHelperButtons
                                                                itemId={topic.topicId}
                                                                itemParam={topic.topicTitle}
                                                                types={[TableHelperButtonEnum.Delete]}
                                                                onClickItem={() => onClickItem(topic.topicId!!, topic.topicTitle!!, "delete")}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Completed Topics</h3>
                            </div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                {topics.filter(i => i.topicStatus == TopicStatusEnum.Completed).map((topic, index) => {
                                    return (
                                        <div key={index} className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-5 mb-3">
                                            <div className="d-flex flex-column text-success pe-0 pe-sm-10">
                                                <h5 className="mb-1">{topic.topicTitle}</h5>
                                                <span>{topic.date}</span>
                                            </div>
                                            {selectedIsPresentLevel &&
                                                <div className="col d-flex justify-content-end flex-stack text-end">
                                                    <TableHelperButtons
                                                        itemId={topic.topicId}
                                                        itemParam={topic.topicTitle}
                                                        types={[TableHelperButtonEnum.Delete]}
                                                        onClickItem={() => onClickItem(topic.topicId!!, topic.topicTitle!!, "active")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                        <div className='card-header cursor-pointer'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Cancelled Topics</h3>
                            </div>
                        </div>

                        <div className='card-body p-9'>
                            <div className="d-flex flex-column">
                                {topics.filter(i => i.topicStatus == TopicStatusEnum.Cancelled).map((topic, index) => {
                                    return (
                                        <div key={index} className="alert alert-dismissible bg-light-warning d-flex flex-column flex-sm-row p-5 mb-3">
                                            <div className="d-flex flex-column text-warning pe-0 pe-sm-10">
                                                <h5 className="mb-1">{topic.topicTitle}</h5>
                                                <span>{topic.date}</span>
                                            </div>
                                            {selectedIsPresentLevel &&
                                                <div className="col d-flex justify-content-end flex-stack text-end">
                                                    <TableHelperButtons
                                                        itemId={topic.topicId}
                                                        itemParam={topic.topicTitle}
                                                        types={[TableHelperButtonEnum.Delete]}
                                                        onClickItem={() => onClickItem(topic.topicId!!, topic.topicTitle!!, "active")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
