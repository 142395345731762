import { FC, useEffect, useState } from 'react'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { IPageViewingModel } from '../../../../models/responses/PageViewingModel'
import PageViewingApi from '../../../../infrastructure/api/PageViewingApi'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const PageViewingReportListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('page_asc')
    const [studentPageViewings, setStudentPageViewings] = useState<PagedResponseModel<IPageViewingModel[]>>()
    const { type } = useParams<any>()
    const [filters, setFilters] = useState<Map<string, string>>(new Map())
    const { id } = useParams<any>()

    let now = new Date();
    let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

    const [start, setStart] = useState(startTime)
    const [end, setEnd] = useState(endTime)

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
        getReport(startDate, endDate)
    }

    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false,
    }

    async function getReport(start: moment.Moment, end: moment.Moment) {
        var startTime = start.format("YYYY-MM-DDT00:00:00");
        var endTime = end.format("YYYY-MM-DDT23:59:59");

        let newFilters: Map<string, string> = new Map()

        newFilters.set("StartDate", startTime)
        newFilters.set("EndDate", endTime)
        if (id != "" && id != undefined) {
            newFilters.set("UserId", id)
        }

        setFilters(newFilters)


        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
            filters: newFilters
        }

        var response = await PageViewingApi.GetPageViewings(paginationFilter)
        setStudentPageViewings(response.data)
    }

    async function init() {
        getReport(start, end)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort])

    return (
        <>
            <div className='row'>
                <div className='col-lg-3'>
                    <label className='form-label fw-bolder'>Date Range</label>
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={start}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={end}
                        local={local}
                        applyCallback={applyCallback}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${start.format(local.format)} - ${end.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                </div>
            </div>
            <GenericTable
                title='Student Page Viewings'
                data={studentPageViewings?.items}
                currentTotalRecords={studentPageViewings?.totalRecords!!}
                currentPageNumber={studentPageViewings?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: '#',
                        options: {
                          customBodyRenderLite: (dataIndex) => {
                            return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                          },
                          sort: false,
                          viewColumns: false
                        },
                      },
                    {
                        label: "Page", name: "page", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Event", name: "event", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Total Minutes", name: "second", options: {
                            customBodyRender: (value) => {
                                return <>{(value / 60).toFixed(2)}</>
                            }
                        }
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    sort: false,
                    search: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { PageViewingReportListModal }
