import {FC, useEffect, useState} from 'react'
import ShopOrderApi from '../../../../infrastructure/api/ShopOrderApi'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap-v5'
import {useFormik} from 'formik'
import {IShopOrderModel, shopOrderInitValues} from '../../../../models/responses/ShopOrderModel'
import {IShopOrderRequestModel} from '../../../../models/requests/ShopOrderRequestModel'
import {ShopOrderFormValidations} from '../../../validations/ShopOrderFormValidations'
import {FormikValidationError} from '../../../components/validations/FormikValidationError'
import {SaveChangesButton} from '../../../components/buttons/SaveChangesButton'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const ShopOrderFormModal: FC<Props> = ({itemId, show, handleClose}) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [shopOrder, setShopOrder] = useState<IShopOrderModel>(shopOrderInitValues)
  const updateShopOrder = (fieldsToUpdate: Partial<IShopOrderModel>) => {
    const updatedData = {...shopOrder, ...fieldsToUpdate}
    setShopOrder(updatedData)
  }

  const formik = useFormik<IShopOrderModel>({
    initialValues: shopOrder,
    enableReinitialize: true,
    validationSchema: ShopOrderFormValidations,
    onSubmit: (values) => {
      setTimeout(async () => {
        let model: IShopOrderRequestModel = {
          id: values.id,
          shopProductId: values.shopProductId,
          shopProductName: values.shopProductName,
          date: values.date,
          edupoint: values.edupoint,
          status: values.status,
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IShopOrderRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === 0) result = await ShopOrderApi.AddShopOrder(model)
      else {
        result = await ShopOrderApi.UpdateShopOrder(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IShopOrderModel
    if (itemId > 0) {
      var response = await ShopOrderApi.GetShopOrder(itemId)
      result = response.data
    } else {
      result = shopOrderInitValues
    }
    setShopOrder(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Order</h1>
              <div className='text-muted fw-bold fs-5'>
                Order Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
                <label className='form-label fw-bolder'>Order Name</label>
                <input
                  {...formik.getFieldProps('shopProductName')}
                  type='text'
                  name='shopProductName'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                  disabled
                />
                <FormikValidationError
                  touched={formik.touched.shopProductName}
                  error={formik.errors.shopProductName}
                />
              </div>
            <div className='row mb-10'>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder'>Edupoint</label>
                <input
                  {...formik.getFieldProps('edupoint')}
                  type='number'
                  name='edupoint'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                  disabled
                />
                <FormikValidationError
                  touched={formik.touched.edupoint}
                  error={formik.errors.edupoint}
                />
              </div>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder'>Date</label>
                <input
                  {...formik.getFieldProps('date')}
                  type='text'
                  name='date'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                  disabled
                />
                <FormikValidationError touched={formik.touched.date} error={formik.errors.date} />
              </div>
            </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Status</label>
                  <textarea
                    {...formik.getFieldProps('status')}
                    typeof='text'
                    name='status'
                    rows={3}
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError
                    touched={formik.touched.status}
                    error={formik.errors.status}
                  />
                </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export {ShopOrderFormModal}
