import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import { IJobInterviewModel, jobInterviewInitValues } from '../../../../models/responses/JobInterviewModel'
import { JobInterviewFormValidations } from '../../../validations/JobInterviewFormValidations'
import { IJobInterviewRequestModel } from '../../../../models/requests/JobInterviewRequestModel'
import JobInterviewApi from '../../../../infrastructure/api/JobInterviewApi'
import { jobApplicationInitValues } from '../../../../models/responses/JobApplicationModel'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const JobInterviewFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [jobInterview, setJobInterview] = useState<IJobInterviewModel>(jobInterviewInitValues)
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)

  const formik = useFormik<IJobInterviewModel>({
    initialValues: jobInterview,
    enableReinitialize: true,
    validationSchema: JobInterviewFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IJobInterviewRequestModel = {
          id: values.id === '' ? undefined : values.id,
          title: values.title,
          companyId: selectedCompany.value
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IJobInterviewRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = await JobInterviewApi.AddJobInterview(model)
      else {
        result = await JobInterviewApi.UpdateJobInterview(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    var companies = await CompanyApi.GetSubCompaniesDictionary()
    var dataParentCompanies: SelectModel[] = [
      ...companies.data.map((d) => ({
        value: d.id.toString(),
        label: d.name,
      })),
    ]
    setCompanies(dataParentCompanies)

    let result: IJobInterviewModel
    if (itemId !== '') {
      var response = await JobInterviewApi.GetJobInterview(itemId)
      result = response.data

      handleChangeCompany(dataParentCompanies.find(i => i.value == result.companyId))
    } else {
      handleChangeCompany(selectInitValues)
      result = jobInterviewInitValues
    }
    setJobInterview(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Job Interview</h1>
              <div className='text-muted fw-bold fs-5'>
                Job Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Company</label>
              <Select
                {...formik.getFieldProps('companyId')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeCompany(event)}
                value={selectedCompany}
                options={companies}
              />
              <FormikValidationError touched={formik.touched.companyId} error={formik.errors.companyId} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Title</label>
              <input
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { JobInterviewFormModal }
