import { toAbsoluteUrl } from "../../_metronic/helpers";
import { DictionaryModel } from "./DictionaryModel";

export interface IUserModel {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  emailConfirmed: boolean,
  phoneNumber: string,
  phoneNumberConfirmed: boolean,
  password: string,
  passwordValid?: string,
  isActive?: boolean,
  createdAt?: Date,
  modifiedAt?: Date,
  userName?: string,
  roles?: Array<string>
  permissions?: Array<string>
  details?: Array<DictionaryModel>
  presentLevelId?: number,
  startupLevelId?: number,
  photo: string
}

export interface IUserDictionaryModel {
  id: string
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  createdAt?: Date
}

const userInitValues: IUserModel = {
  id: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordValid: '',
  email: '',
  emailConfirmed: false,
  isActive: false,
  phoneNumber: '',
  phoneNumberConfirmed: false,
  //createdAt: new Date(),
  //modifiedAt: new Date(),
  details: [],
  presentLevelId: 0,
  startupLevelId: 0,
  permissions: [],
  roles: [],
  userName: '',
  photo: toAbsoluteUrl('/media/avatars/blank.png'),
}

export { userInitValues }
