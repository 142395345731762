export enum FileTypeEnum {
    Undefined = "",
    BlogPost = "BlogPost",
    Campaign = "Campaign",
    CampaignBackground = "CampaignBackground",
    CompanyLogo = "CompanyLogo",
    CompanyBackground = "CompanyBackground",
    TrainerImage = "TrainerImage",
    TrainerVideo = "TrainerVideo",
    JobApplication = "JobApplication",
    Popup = "Popup",
    Presentation = "Presentation",
    ShopProduct = "ShopProduct",
    Avatar = "Avatar",
    Guide = "Guide",
    Topic = "Topic",
    Vocabulary = "Vocabulary",
    Exam = "Exam",
    ExamResult = "ExamResult"
}