import { FC, useEffect, useState } from 'react'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import UnitApi from '../../../../infrastructure/api/UnitApi'
import GuideApi from '../../../../infrastructure/api/GuideApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IUnitModel, unitInitValues } from '../../../../models/responses/UnitModel'
import { IUnitRequestModel } from '../../../../models/requests/UnitRequestModel'
import { UnitFormValidations } from '../../../validations/UnitFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { useParams } from 'react-router'
import { UnitTypeEnum } from '../../../../enums/UnitTypeEnum'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const UnitFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const { type } = useParams<any>()

  const [unit, setUnit] = useState<IUnitModel>(unitInitValues)
  const updateUnit = (fieldsToUpdate: Partial<IUnitModel>) => {
    const updatedData = { ...unit, ...fieldsToUpdate }
    setUnit(updatedData)
  }

  const defaultSelectLevel = {
    value: "0", label: "N/A"
  };
  const [levels, setLevels] = useState<SelectModel[]>([])
  const [selectedLevel, setSelectedLevel] = useState<SelectModel>(defaultSelectLevel)

  const [guides, setGuides] = useState<SelectModel[]>([])
  const [selectedGuides, setSelectedGuides] = useState<SelectModel[]>([])

  const formik = useFormik<IUnitModel>({
    initialValues: unit,
    enableReinitialize: true,
    validationSchema: UnitFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IUnitRequestModel = {
          id: values.id,
          title: values.title,
          explanation: values.explanation,
          questionFrequency: values.questionFrequency,
          levelId: parseInt(selectedLevel.value),
          guideIds: selectedGuides.map((i => { return parseInt(i.value) })),
          unitType: parseInt(UnitTypeEnum[type])
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IUnitRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await UnitApi.AddUnit(model)
      else {
        result = await UnitApi.UpdateUnit(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    var responseLevels = await LevelApi.GetLevelDictionary()
    var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setLevels(dataLevels)

    var responseGuides = await GuideApi.GetGuides({ page: 1, size: 999, search: '', orderBy: 'name_asc' })
    var dataGuides: SelectModel[] = [...responseGuides.data.items.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setGuides(dataGuides)

    let result: IUnitModel
    if (itemId > 0) {
      var response = await UnitApi.GetUnit(itemId)
      result = response.data

      handleChangeLevel(response.data.levelId ? dataLevels.find(i => i.value == response.data.levelId.toString()) : defaultSelectLevel)
      if (response.data.guides.length > 0) {
        let guideArray: SelectModel[] = []
        response.data.guides.forEach(guide => {
          guideArray.push(dataGuides.find(i => i.value == guide.id.toString())!!)
        });
        handleChangeGuides(guideArray)
      } else {
        handleChangeGuides([])
      }


    } else {
      result = unitInitValues
    }
    setUnit(result)
  }

  useEffect(() => {
    setSelectedGuides([])
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeLevel = (e: any) => {
    setSelectedLevel(e);
  }

  const handleChangeGuides = (e: any) => {
    setSelectedGuides(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Unit</h1>
              <div className='text-muted fw-bold fs-5'>
                Unit Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Title</label>
              <input
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Explanation</label>
              <input
                {...formik.getFieldProps('explanation')}
                type='string'
                name='explanation'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.explanation} error={formik.errors.explanation} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Level</label>
              <Select
                {...formik.getFieldProps('levelId')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeLevel(event)}
                value={selectedLevel}
                options={levels}
              />
              <FormikValidationError touched={formik.touched.levelId} error={formik.errors.levelId} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Guides</label>
              <Select
                {...formik.getFieldProps('guides')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeGuides(event)}
                value={selectedGuides}
                isMulti={true}
                options={guides}
              />
            </div>

            <div className='mb-10'>
              <label className='form-label fw-bolder'>Question Frequency</label>
              <input
                {...formik.getFieldProps('questionFrequency')}
                type='number'
                name='questionFrequency'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.questionFrequency} error={formik.errors.questionFrequency} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { UnitFormModal }
