export interface IBlogCommentModel {
  id: number
  author: string
  email: string
  body: string
  blogPostId: number
  blogPostName: string
  parentCommentId: number
  parentCommentBody: string
  date: string
  isActive: boolean
  subComments: IBlogCommentModel[]
}

const blogCommentInitValues: IBlogCommentModel = {
  id: 0,
  author: '',
  email: '',
  body: '',
  blogPostId: 0,
  blogPostName: '',
  parentCommentId: 0,
  parentCommentBody: '',
  date: '',
  isActive: false,
  subComments: []
}

export { blogCommentInitValues }