import { IShopProductRequestModel } from '../../models/requests/ShopProductRequestModel'
import { IShopProductModel } from '../../models/responses/ShopProductModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ShopProductApi {

  async GetShopProducts(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IShopProductModel[]>>(`e/Admin/ShopProducts${generateQueryParams(filter)}`)
  }

  async GetShopProduct(id: number) {
    return await API.get<IShopProductModel>('e/Admin/ShopProducts/' + id)
  }

  async AddShopProduct(model: IShopProductRequestModel) {
    return await API.post('e/Admin/ShopProducts', model)
  }

  async UpdateShopProduct(model: IShopProductRequestModel) {
    return await API.put('e/Admin/ShopProducts/' + model.id, model)
  }

  async DeleteShopProduct(itemId: number) {
    return await API.delete<boolean>('e/Admin/ShopProducts/' + itemId)
  }
}

export default new ShopProductApi()
