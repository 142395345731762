import { FC, useEffect, useState } from 'react'
import PaymentApi from '../../../../infrastructure/api/PaymentApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IPaymentModel, paymentInitValues } from '../../../../models/responses/PaymentModel'
import { IPaymentRequestModel } from '../../../../models/requests/PaymentRequestModel'
import { PaymentFormValidations } from '../../../validations/PaymentFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { PaymentTypeEnum } from '../../../../enums/PaymentTypeEnum'
import { dayList, durationList, monthList } from '../../../../constants/PaymentConstants'
import AsyncSelect from 'react-select/async';
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { RoleEnum } from '../../../../enums/RoleEnum'
import debounce from 'es6-promise-debounce'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { generateCode } from '../../../helpers/StringHelper'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const PaymentFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [payment, setPayment] = useState<IPaymentModel>(paymentInitValues)
  const updatePayment = (fieldsToUpdate: Partial<IPaymentModel>) => {
    const updatedData = { ...payment, ...fieldsToUpdate }
    setPayment(updatedData)
  }

  const paymentTypes = EnumToList(PaymentTypeEnum)
  const [selectedPaymentType, setSelectedPaymentType] = useState<SelectModel>(paymentTypes[0])

  const [tolk, setTolk] = useState<number>(0)
  const [selectedDay, setSelectedDay] = useState<SelectModel>(dayList[0])
  const [selectedDuration, setSelectedDuration] = useState<SelectModel>(durationList[0])
  const [selectedMonth, setSelectedMonth] = useState<SelectModel>(monthList[0])

  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };

  const [students, setStudents] = useState<SelectModel[]>([])
  const [selectedStudent, setSelectedStudent] = useState<SelectModel>(selectInitValues)

  const [sellers, setSellers] = useState<SelectModel[]>([])
  const [selectedSeller, setSelectedSeller] = useState<SelectModel>(selectInitValues)

  const formik = useFormik<IPaymentModel>({
    initialValues: payment,
    enableReinitialize: true,
    validationSchema: PaymentFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IPaymentRequestModel = {
          id: values.id,
          name: values.name,
          surname: values.surname,
          amount: values.amount,
          code: values.code,
          dayCount: parseInt(selectedDay?.value!!),
          monthCount: parseInt(selectedMonth?.value!!),
          talkingDuration: parseInt(selectedDuration?.value!!),
          tolkCount: tolk,
          installmentCount: values.installmentCount,
          salePercentage: values.salePercentage,
          studentId: selectedStudent.value,
          sellerId: selectedSeller.value == "" ? undefined : selectedSeller.value,
          couponId: values.couponId,
          response: values.response,
          paymentType: parseInt(selectedPaymentType?.value!!)
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IPaymentRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === 0) {
        model.response = "1"
        result = await PaymentApi.AddPayment(model)
      }
      else {
        result = await PaymentApi.UpdatePayment(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IPaymentModel
    if (itemId > 0) {
      var response = await PaymentApi.GetPayment(itemId)


      var responseUsers = await UserApi.GetUserDictionaryIds([response.data.studentId!!, response.data.sellerId!!])
      var dataUsers: IUserDictionaryModel[] = responseUsers.data

      if (response.data.studentId != undefined) {
        setStudents(dataUsers.map(d => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })))

        var selectStudent = dataUsers.find(i => i.id == response.data.studentId);
        setSelectedStudent({ value: selectStudent?.id!!, label: `${selectStudent!.firstName} ${selectStudent!.lastName} - ${selectStudent!.email}` })

      } else {
        setSelectedStudent(selectInitValues)
      }
      if (response.data.sellerId != undefined) {
        setSellers(dataUsers.map(d => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })))
        var selectSeller = dataUsers.find(i => i.id == response.data.sellerId);
        setSelectedSeller({ value: selectSeller?.id!!, label: `${selectSeller!.firstName} ${selectSeller!.lastName} - ${selectSeller!.email}` })
      } else {
        setSelectedSeller(selectInitValues)
      }
      result = response.data
    } else {
      paymentInitValues.code = "EC_M_" + generateCode(20)
      result = paymentInitValues
    }

    handleChangePaymentType(result.paymentType ? paymentTypes.find(i => i.value == result.paymentType.toString()) : defaultSelect)
    setSelectedDuration((result.talkingDuration ? durationList.find(i => i.value == result.talkingDuration.toString()) : defaultSelect) || defaultSelect)
    setSelectedDay((result.dayCount ? dayList.find(i => i.value == result.dayCount.toString()) : defaultSelect) || defaultSelect)
    setSelectedMonth((result.monthCount ? monthList.find(i => i.value == result.monthCount.toString()) : defaultSelect) || defaultSelect)
    setPayment(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangePaymentType = (e: any) => {
    setSelectedPaymentType(e)
  }

  const calculateTolkNumber = (month: number, day: number, duration: number) => {
    var tolkValue = month * day * duration * 4;
    setTolk(tolkValue)
  }

  const getUserDatas = async (event: string, role: string, setUser: any) => {
    if (event.length > 1) {
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 5,
        orderBy: "firstName_asc",
        search: event
      }

      var filters: DictionaryModel[] = [{ type: "Role", value: role }]
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var datas: SelectModel[] = [
        ...response.data.items.map((d) => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })),
      ]
      setUser(datas)
      return datas;
    }
    return [];
  }

  const handleChangeSeller = (e: any) => {
    setSelectedSeller(e);
  }

  const handleSellerInputChange = (newValue: string) => {
    return newValue;
  };

  const loadSellerOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var data = await getUserDatas(inputValue, RoleEnum.Seller, setSellers);
        setSellers(data);
        resolve(data);
      }, 1000);
    });

  const handleStudentChange = (e: any) => {
    setSelectedStudent(e);
  }

  const handleStudentInputChange = (newValue: string) => {
    return newValue;
  };


  const loadStudentOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var data = await getUserDatas(inputValue, RoleEnum.Student, setStudents);
        setStudents(data);
        resolve(data);
      }, 1000);
    });

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Payment</h1>
              <div className='text-muted fw-bold fs-5'>
                Payment Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Name</label>
                <input
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Surname</label>
                <input
                  {...formik.getFieldProps('surname')}
                  type='text'
                  name='surname'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.surname} error={formik.errors.surname} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Student By</label>
                <AsyncSelect
                  cacheOptions
                  {...formik.getFieldProps('studentId')}
                  styles={customSelectStyles(true)}
                  loadOptions={debounce(loadStudentOptions, 500)}
                  onChange={(event) => handleStudentChange(event)}
                  defaultOptions={students}
                  value={selectedStudent}
                />
                <FormikValidationError touched={formik.touched.studentId} error={formik.errors.studentId} />
              </div>
            </div>
            <div className="row mb-10">
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Payment Type</label>
                <Select
                  {...formik.getFieldProps('paymentType')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangePaymentType(event)}
                  value={selectedPaymentType}
                  options={paymentTypes}
                />
                <FormikValidationError touched={formik.touched.paymentType} error={formik.errors.paymentType} />
              </div>
              <div className='col-lg-5'>
                <label className='form-label fw-bolder required'>Code</label>
                <input
                  {...formik.getFieldProps('code')}
                  type='text'
                  name='code'
                  className='form-control form-control-solid'
                  placeholder='PNL-202201010009'
                />
                <FormikValidationError touched={formik.touched.code} error={formik.errors.code} />
              </div>
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Amount</label>
                <input
                  {...formik.getFieldProps('amount')}
                  type='number'
                  name='amount'
                  min="0.00" max="10000.00" step="0.01"
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.amount} error={formik.errors.amount} />
              </div>
            </div>
            <div className="row mb-10">
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Installment</label>
                <input
                  {...formik.getFieldProps('installmentCount')}
                  type='number'
                  name='installmentCount'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.installmentCount} error={formik.errors.installmentCount} />
              </div>
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Sale Percentage</label>
                <input
                  {...formik.getFieldProps('salePercentage')}
                  type='number'
                  name='salePercentage'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.salePercentage} error={formik.errors.salePercentage} />
              </div>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder required'>Seller By</label>
                <AsyncSelect
                  cacheOptions
                  {...formik.getFieldProps('sellerId')}
                  styles={customSelectStyles(true)}
                  loadOptions={debounce(loadSellerOptions, 500)}
                  onChange={(event) => handleChangeSeller(event)}
                  defaultOptions={sellers}
                  value={selectedSeller}
                />
                <FormikValidationError touched={formik.touched.sellerId} error={formik.errors.sellerId} />
              </div>
            </div>
            <div className="row">
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Duration</label>
                <Select
                  {...formik.getFieldProps('talkingDuration')}
                  styles={customSelectStyles(true)}
                  onChange={(event: any) => {
                    setSelectedDuration(event);
                    calculateTolkNumber(parseInt(selectedMonth.value!!), parseInt(selectedDay.value!!), parseInt(event.value!!));
                  }}
                  value={selectedDuration}
                  options={durationList}
                />
                <FormikValidationError touched={formik.touched.talkingDuration} error={formik.errors.talkingDuration}
                />
              </div>
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Day of Week</label>
                <Select
                  {...formik.getFieldProps('dayCount')}
                  styles={customSelectStyles(true)}
                  onChange={(event: any) => {
                    setSelectedDay(event);
                    calculateTolkNumber(parseInt(selectedMonth.value!!), parseInt(event.value!!), parseInt(selectedDuration.value!!));
                  }}
                  value={selectedDay}
                  options={dayList}
                />
                <FormikValidationError touched={formik.touched.dayCount} error={formik.errors.dayCount}
                />
              </div>
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Month</label>
                <Select
                  {...formik.getFieldProps('monthCount')}
                  styles={customSelectStyles(true)}
                  onChange={(event: any) => {
                    setSelectedMonth(event);
                    calculateTolkNumber(parseInt(event.value!!), parseInt(selectedDay.value!!), parseInt(selectedDuration.value!!));
                  }}
                  value={selectedMonth}
                  options={monthList}
                />
                <FormikValidationError touched={formik.touched.monthCount} error={formik.errors.monthCount}
                />
              </div>
              <div className='col-lg-3'>
                <label className='form-label fw-bolder required'>Tolks</label>
                <input
                  {...formik.getFieldProps('tolkCount')}
                  type='number'
                  name='tolkCount'
                  value={tolk}
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.tolkCount} error={formik.errors.tolkCount} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { PaymentFormModal }
