import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IPlacementModel } from '../../models/responses/PlacementModel'

class PlacementApi {

  async GetPlacements(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPlacementModel[]>>(`s/Admin/Placements${generateQueryParams(filter)}`)
  }

  async DeletePlacement(itemId: string) {
    return await API.delete<boolean>('s/Admin/Placements/' + itemId)
  }
}

export default new PlacementApi()
