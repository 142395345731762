import * as Yup from 'yup'

const TaskDefineFormValidations = () => {

  return Yup.object().shape({

  })

}

export { TaskDefineFormValidations }
