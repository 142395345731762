import { FC, useEffect, useState } from 'react'
import UserApi from '../../../../infrastructure/api/UserApi'
import { userInitValues } from '../../../../models/responses/UserModel'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { PasswordFormValidations } from '../../../validations/UserFormValidations'
import { useFormik } from 'formik'
import { IUserPasswordRequestModel } from '../../../../models/requests/UserRequestModel'
import { useHistory } from "react-router-dom";
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'


type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const PasswordModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const history = useHistory();
  const [doneId, setDoneId] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [complete, setComplete] = useState(false)

  const formik = useFormik({
    initialValues: userInitValues,
    enableReinitialize: true,
    validationSchema: PasswordFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        let model: IUserPasswordRequestModel = {
          id: itemId,
          password: values.password
        }

        processUser(model);
      }, 500)
    },
  })

  async function processUser(model: IUserPasswordRequestModel) {
    try {
      setLoading(true)
      if (model.id != undefined && model.id != '') {
        var result = await UserApi.UpdateUserPassword(model)
        if (result.data.hasError) {
          setErrors(result.data.errors)
        } else {
          setComplete(true)
          setErrors([])
          formik.resetForm()
          handleClose()
          setDoneId(result.data);
        }
      }
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (doneId !== '') {
      setTimeout(() => history.push('/account/users/' + doneId), 2000);
    }
  }, [doneId, show]);


  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='fw-bolder'>Change User Password</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mh-500px  mx-5 my-7'>
            <div className='row'>
              <div className='col-12'>
                {
                  errors.length > 0 && <div className='alert alert-danger'>  <h4 className="alert-heading">Please fix the following issues and try again!</h4>
                    <p>
                      <ol>
                        {errors.map((item, key) => {
                          return (
                            <li key={key}>{item}</li>
                          )
                        })}
                      </ol>
                    </p>
                  </div>
                }
              </div>


              <div className='col-12 mb-6'>
                <label className='fs-5 fw-bolder form-label mb-2 required'>Password</label>
                <input
                  {...formik.getFieldProps('password')}
                  type='password'
                  name='password'
                  className='form-control form-control-solid'
                  placeholder='Password'
                />
              </div>
              <div className='col-12 mb-6'>
                <label className='fs-5 fw-bolder form-label mb-2 required'>Password Valid</label>
                <input
                  {...formik.getFieldProps('passwordValid')}
                  type='password'
                  name='passwordValid'
                  className='form-control form-control-solid'
                  placeholder='Password Valid'
                />
              </div>
              <div className='col-12'>
                {formik.touched.passwordValid && formik.errors.passwordValid && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.passwordValid}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form >
    </Modal >
  )
}

export { PasswordModal }