import { FC, useEffect, useState } from 'react'
import ContactApi from '../../../../infrastructure/api/ContactApi'
import { IContactModel } from '../../../../models/responses/ContactModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { Link } from 'react-router-dom'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const ContactListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [contacts, setContacts] = useState<PagedResponseModel<IContactModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getContacts() {
      var response = await ContactApi.GetContacts(paginationFilter)
      setContacts(response.data)
    }
    getContacts()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Contacts'
        data={contacts?.items}
        currentTotalRecords={contacts?.totalRecords!!}
        currentPageNumber={contacts?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: 'Name',
            name: 'name',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          { label: 'Email', name: 'email' },
          { label: 'Phone', name: 'phone' },
          { label: 'Subject', name: 'subject' },
          { label: 'Date', name: 'createdAt' },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={contacts?.items[dataIndex]?.id!!}
                    itemParam={contacts?.items[dataIndex]?.name!!}
                    types={[TableHelperButtonEnum.View, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { ContactListModal }
