import { FC, Fragment, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import * as level from '../../../pages/levels/redux/LevelRedux'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { SelectModel } from '../../../models/SelectModel'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import PlacementApi from '../../../../infrastructure/api/PlacementApi'
import { IPlacementModel, IPlacementScore } from '../../../../models/responses/PlacementModel'
import { PlacementTypeEnum } from '../../../../enums/PlacementTypeEnum'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { Link } from 'react-router-dom'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const PlacementListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [placements, setPlacements] = useState<PagedResponseModel<IPlacementModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]

  const dispatch = useDispatch()

  async function init() {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }

    var response = await PlacementApi.GetPlacements(paginationFilter)

    if (response.data.items) {
      var responseUsers = await UserApi.GetUserDictionaryIds(
        [
          ...response.data.items.flatMap(i => i.studentId),
          ...response.data.items.filter(i => i.trainerId != undefined).flatMap(i => i.trainerId!)
        ]);
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setUsers(dataUsers)

    }
    setPlacements(response.data)
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  const generateRow = (scores: IPlacementScore[]) => {
    return (
      <Fragment>
        <tr>
          <td colSpan={2}></td>
          <td>
            {scores.length > 0 ?
              <TableContainer component={Paper} className={"mt-5"}>
                <Table size={'small'} aria-label="a dense table">
                  <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                    <TableRow>
                      <TableCell>Level</TableCell>
                      <TableCell align='right'>Scores</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scores.map((row, index) => {
                      return (
                        <Fragment key={row.levelId}>
                          <TableRow>
                            <TableCell >{levelDictionary.find(i => i.value == row.levelId.toString())?.label}</TableCell>
                            <TableCell align='right' >{row.scores.join(" ")}</TableCell>
                          </TableRow>
                        </Fragment>
                      )
                    }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              :
              <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-5">
                <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                  <span>No other companies affiliated with this company were found.</span>
                </div>
              </div>

            }
          </td>
        </tr>
      </Fragment >

    )
  }

  const options = {
    search: false,
    rowsPerPage: pageSize,
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      return (
        generateRow(placements?.items[rowMeta.rowIndex].scores!!)
      );
    },
  };

  return (
    <>
      <GenericTable
        title='Placements'
        data={placements?.items}
        currentTotalRecords={placements?.totalRecords!!}
        currentPageNumber={placements?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Student',
            name: 'studentId',
            options: {
              customBodyRender: (value) => {
                return <b><Link to={'/account/users/' + value + '/overview'}>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</Link></b>
              },
            },
          },          
          {
            label: 'Present Level',
            name: 'levelId',
            options: {
              customBodyRender: (value) => {
                return <b>{levelDictionary.find(i => i.value == value)?.label}</b>
              },
            },
          },
          {
            label: 'Trainer',
            name: 'trainerId',
            options: {
              customBodyRender: (value) => {
                return <b>{value ? `${users.find(i => i.id == value)?.firstName} ${users.find(i => i.id == value)?.lastName}` : '-'}</b>
              },
            },
          },
          {
            label: 'Type',
            name: 'type',
            options: {
              customBodyRender: (value) => {
                return <b>{PlacementTypeEnum[value]}</b>
              },
            },
          },
          {
            label: 'Date',
            name: 'date',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={placements?.items[dataIndex]?.id!!}
                    itemParam={placements?.items[dataIndex]?.id!!}
                    types={[TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={options}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { PlacementListModal }
