import { FC, useEffect, useState } from 'react'
import UserApi from '../../../../infrastructure/api/UserApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { UserImportFormValidations } from '../../../validations/UserFormValidations'
import { useFormik } from 'formik'
import { IUserImportRequestModel } from '../../../../models/requests/UserRequestModel'
import { RoleModel } from '../../../../models/responses/RoleModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import * as level from '../../../pages/levels/redux/LevelRedux'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import Select from 'react-select';
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'
import JobInterviewApi from '../../../../infrastructure/api/JobInterviewApi'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import LevelApi from '../../../../infrastructure/api/LevelApi'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const UserImportModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [roles, setRoles] = useState([] as RoleModel[])
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [errors, setErrors] = useState([])
  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)
  const [jobInterviews, setJobInterviews] = useState<SelectModel[]>([])
  const [selectedJobInterview, setSelectedJobInterview] = useState<SelectModel>(selectInitValues)
  const [selectedStartupLevel, setSelectedStartupLevel] = useState<SelectModel>(selectInitValues)
  const [selectedPresentLevel, setSelectedPresentLevel] = useState<SelectModel>(selectInitValues)

  const dispatch = useDispatch()

  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const [loadFile, setLoadFile] = useState<any>(undefined)

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  const formik = useFormik({
    initialValues: { companyId: undefined, startupLevelId: undefined, presentLevelId: undefined, jobInterviewId: undefined },
    enableReinitialize: true,
    validationSchema: UserImportFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        let model: IUserImportRequestModel = {
          roles: roles.filter(i => i.name == "Student").map(i => i.name),
          claims: []
        }
        var permissions: string[] = [];
        roles.filter(i => i.name == "Student").map(role => {
          permissions.push(...role.permissions!!)
        })

        model.claims = permissions.filter(onlyUnique).map(item => {
          var object: DictionaryModel = { type: "Permission", value: item };
          return object
        });

        if (selectedCompany.value != "") model.claims.push({ type: "CompanyId", value: selectedCompany.value })
        if (selectedPresentLevel.value != "") model.claims.push({ type: "PresentLevelId", value: selectedPresentLevel.value })
        if (selectedStartupLevel.value != "") model.claims.push({ type: "StartupLevelId", value: selectedStartupLevel.value })
        if (selectedJobInterview.value != "") model.claims.push({ type: "JobInterviewId", value: selectedJobInterview.value })

        processUser(model);
      }, 500)
    },
  })

  async function processUser(request: IUserImportRequestModel) {
    try {
      setLoading(true)

      let model = new FormData()
      model.append('file', loadFile)
      model.append('claims', JSON.stringify(request.claims))
      model.append('roles', JSON.stringify(request.roles))

      var result = await UserApi.ImportUser(model)
      if (result.data.hasError) {
        setErrors(result.data.errors)
      } else {
        setErrors([])
        formik.resetForm()
        handleClose()
      }
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }


  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  const handleChangeJobInterview = (e: any) => {
    setSelectedJobInterview(e);
  }

  const handleChangePresentLevel = (e: any) => {
    setSelectedPresentLevel(e);
  }

  const handleChangeStartupLevel = (e: any) => {
    setSelectedStartupLevel(e);
  }

  const handleChangeFile = (event: any) => {
    setLoadFile(event.target.files[0])
  }

  const init = async () => {
    var response = await RoleApi.GetRoles()
    response.data.items.forEach(item => {
      item.isActive = false;
    })
    setRoles(response.data.items)

    var companies = await CompanyApi.GetSubCompaniesDictionary()
    var dataParentCompanies: SelectModel[] = [selectInitValues,
      ...companies.data.map((d) => ({
        value: d.id.toString(),
        label: d.name,
      })),
    ]
    setCompanies(dataParentCompanies)

    var jobInterviews = await JobInterviewApi.GetJobInterviewDictionary()
    if (jobInterviews != null) {
      var dataJobInterviews: SelectModel[] = [selectInitValues,
        ...jobInterviews.data.map((d) => ({
          value: d.id.toString(),
          label: d.title,
        })),
      ]
      setJobInterviews(dataJobInterviews)
    }

    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    } else {
      var dataLevels = levelDictionary;
    }

  }

  useEffect(() => {
    init();
  }, [show]);

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-750px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='fw-bolder'>Import Students</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body  mx-5 my-7'>
            <div className='row'>
              <div className='col-12'>
                {
                  errors.length > 0 && <div className='alert alert-danger'>  <h4 className="alert-heading">Please fix the following issues and try again!</h4>
                    <p>
                      <ol>
                        {errors.map((item, key) => {
                          return (
                            <li key={key}>{item}</li>
                          )
                        })}
                      </ol>
                    </p>
                  </div>
                }
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-6 mb-6'>
                    <label className='form-label fw-bolder required'>Company</label>
                    <Select
                      {...formik.getFieldProps('companyId')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => handleChangeCompany(event)}
                      value={selectedCompany}
                      options={companies}
                    />
                    <FormikValidationError touched={formik.touched.companyId} error={formik.errors.companyId} />
                  </div>
                  <div className='col-6 mb-6'>
                    <label className='form-label fw-bolder required'>Job Interview</label>
                    <Select
                      {...formik.getFieldProps('jobInterviewId')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => handleChangeJobInterview(event)}
                      value={selectedJobInterview}
                      options={jobInterviews}
                    />
                    <FormikValidationError touched={formik.touched.jobInterviewId} error={formik.errors.jobInterviewId} />
                  </div>
                  <div className='col-6 mb-6'>
                    <label className='form-label fw-bolder required'>Present Level</label>
                    <Select
                      {...formik.getFieldProps('presentLevelId')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => handleChangePresentLevel(event)}
                      value={selectedPresentLevel}
                      options={levelDictionary}
                    />
                    <FormikValidationError touched={formik.touched.presentLevelId} error={formik.errors.presentLevelId} />
                  </div>
                  <div className='col-6 mb-6'>
                    <label className='form-label fw-bolder required'>Startup Level</label>
                    <Select
                      {...formik.getFieldProps('startupLevelId')}
                      styles={customSelectStyles(true)}
                      onChange={(event) => handleChangeStartupLevel(event)}
                      value={selectedStartupLevel}
                      options={levelDictionary}
                    />
                    <FormikValidationError touched={formik.touched.startupLevelId} error={formik.errors.startupLevelId} />
                  </div>
                  <div className='mb-6'>
                    <label className='form-label fw-bolder required'>File</label>
                    <input
                      {...formik.getFieldProps('file')}
                      type="file"
                      value={undefined}
                      className='form-control form-control-solid'
                      onChange={(event) => handleChangeFile(event)} accept=".xlsx"
                    />

                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='alert'>
                  <ul>
                    <li><a href="https://cdn.educall.com/files/other/Empty%20-%20Educall%20Bulk%20Insert.xlsx"> Download Empty file</a></li>
                    <li><a href="https://cdn.educall.com/files/other/Sample%20-%20Educall%20Bulk%20Insert.xlsx"> Download Sample file</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form >
    </Modal >
  )
}

export { UserImportModal }