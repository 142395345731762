import { FC, Fragment, useEffect, useState } from 'react'

import PermissionApi from '../../../../infrastructure/api/PermissionApi'
import { PanelModel } from '../../../../models/responses/PanelModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const PermissionListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [permissions, setPermissions] = useState([] as PanelModel[])

  useEffect(() => {
    getPermissions()
  }, [refresh])

  async function getPermissions() {
    var response = await PermissionApi.GetPermissions();
    setPermissions(response.data)
  }

  return (
    <>
      <div className='table-responsive'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-25px'>#</th>
              <th className='min-w-100px'>Module</th>
              <th className='min-w-150px'>Permission</th>
            </tr>
          </thead>
          <tbody>

            {permissions.map((panel, i) => {
              return (
                <Fragment key={i}>
                  <tr>
                    <td className="text-gray-400 fw-bold mb-0 pe-2" colSpan={3}>{panel.name}</td>
                  </tr>
                  {
                    panel.modules.map((module, j) => {
                      return (

                        <tr>
                          <td>
                            <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                              {j + 1}
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                              {module.name.split(/(?=[A-Z])/).join(" ")}
                            </a>
                          </td>
                          <td>
                            {module.permissions.map((permission, j) => {
                              return (
                                <div key={j} className="d-flex align-items-center py-2">
                                  <span className="bullet bg-primary me-3"></span> {permission.name} - {permission.description}
                                </div>
                              )
                            })
                            }
                          </td>
                        </tr>
                      )

                    })
                  }
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export { PermissionListModal }
