import * as Yup from 'yup'

const AutoCommentFormValidations = () => {

  return Yup.object().shape({
  
  })

}

export { AutoCommentFormValidations }
