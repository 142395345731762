import { FC, useEffect, useState } from 'react'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import Select from 'react-select'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { ITrainerCallReportRequestModel } from '../../../../models/requests/TrainerCallReportRequestModel'
import TrainerCallReportApi from '../../../../infrastructure/api/TrainerCallReportApi'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

type Props = {
    handleClose: () => void
    trainerList: SelectModel[]
}

const TrainerCallReportFormModal: FC<Props> = ({ handleClose, trainerList }) => {

    let now = new Date();
    let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(7, "days");
    let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

    const [start, setStart] = useState(startTime)
    const [end, setEnd] = useState(endTime)

    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)


    const dayList = EnumToList(DayOfWeekEnum);
    const [selectedDay, setSelectedDay] = useState<SelectModel>(selectInitValues)

    const [selectedTrainer, setSelectedTrainer] = useState<SelectModel>(selectInitValues)

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
    }

    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false        
    }

    


    const handleChangeDay = (e: any) => {
        setSelectedDay(e);
    }


    async function createTrainerCallReport() {
        try {
            var model: ITrainerCallReportRequestModel = {
                startDate: start.format("YYYY-MM-DD H:m:s"),
                endDate: end.format("YYYY-MM-DD H:m:s"),
                trainerId: selectedTrainer.value,
                dayOfWeek: parseInt(selectedDay.value),

            }
            var result = await TrainerCallReportApi.CreateTrainerCallReport(model)
            handleClose()
            setComplete(true)
        } catch (error) {
            alert('Error: ' + error)
        }

    }

    let maxDate = moment(start).add(24, "hour")

    return (
        <>
            <div className='row'>
                <div className='col-lg-3'>
                    <label className='form-label fw-bolder'>Date Range</label>
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={start}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={end}                                                                    
                        local={local}
                        applyCallback={applyCallback}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${start.format(local.format)} - ${end.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                </div>
                <div className='col-lg-3'>
                    <label className='form-label fw-bolder'>Day of Week</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => handleChangeDay(event)}
                        value={selectedDay}
                        options={[selectInitValues, ...dayList]}
                    />
                </div>
                <div className='col-lg-3'>
                    <label className={`form-label fw-bolder`}>Trainer</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedTrainer(event!)}
                        value={selectedTrainer}
                        options={[selectInitValues, ...trainerList]}
                    />
                </div>
                <div className='col-lg-3'>
                    <SaveChangesButton
                        loading={loading}
                        setLoading={setLoading}
                        complete={complete}
                        setComplete={setComplete}
                        valid={true}
                        submit={true}
                        setSubmit={createTrainerCallReport} />
                </div>
            </div>

        </>
    )
}

export { TrainerCallReportFormModal }

