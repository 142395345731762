import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IScheduleModel, ISchedulesModel, IStudentCommentModel, ITrainerCommentModel } from '../../models/responses/ScheduleModel'
import { IGetSchedulesRequestModel } from '../../models/requests/GetSchedulesRequestModel'
import { DayOfWeekEnum } from '../../enums/DayOfWeekEnum'
import { ITrainerCallReportModel } from '../../models/responses/TrainerCallReportModel'
import { IScheduleRequestModel } from '../../models/requests/ScheduleRequestModel'
import { ITroubledTopicsReportModel } from '../../models/responses/TroubledTopicsReportModel'

export interface ICreateScheduleRequestModel {
    dayOfWeek: DayOfWeekEnum
}

class ScheduleApi {

    async GetSchedule(id: string) {
        return await API.get<IScheduleModel>('s/Admin/Schedules/' + id)
    }

    async CreateSchedule(model: ICreateScheduleRequestModel) {
        return await API.post('s/Admin/Schedules', model)
    }

    async GetSchedules(filter: IPaginationFilter, model: IGetSchedulesRequestModel) {
        return await API.post<PagedResponseModel<ISchedulesModel[]>>(`s/Admin/Schedules/List${generateQueryParams(filter)}`, model)
    }

    async GetTrainerComments(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<ITrainerCommentModel[]>>(`s/Admin/Schedules/TrainerComments${generateQueryParams(filter)}`)
    }

    async GetStudentComments(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<IStudentCommentModel[]>>(`s/Admin/Schedules/StudentComments${generateQueryParams(filter)}`)
    }

    async GetTroubledTopicsReport(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<ITroubledTopicsReportModel[]>>(`s/Admin/Schedules/TroubledTopicsReport${generateQueryParams(filter)}`)
    }

    async GetPreviousSchedules(filter: IPaginationFilter, model: IGetSchedulesRequestModel) {
        return await API.post<PagedResponseModel<ISchedulesModel[]>>(`s/Admin/Schedules/Previous/${generateQueryParams(filter)}`, model)
    }


    async GetTrainerCallReports(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<ITrainerCallReportModel[]>>(`s/Admin/Schedules/TrainerCallReports${generateQueryParams(filter)}`)
    }

    async UpdateSchedule(model: IScheduleRequestModel) {
        return await API.put('s/Admin/Schedules/' + model.id, model)
    }

    async DeleteSchedule(itemId: string) {
        return await API.delete<boolean>('s/Admin/Schedules/' + itemId)
    }
}

export default new ScheduleApi()