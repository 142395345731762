import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'

type Props = {
  itemId: any
  itemName: string
  type: string
  confirm: (itemId: any) => void
  show: boolean
  handleClose: () => void
}
const DeleteModal: FC<Props> = ({ itemId, itemName, type, confirm, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-650px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='text-center'>
            <h1 className='mb-3'>Are you sure you want to delete?</h1>
            <div className='text-muted fw-bold fs-5'>
              The {type} named <b>'{itemName}'</b> will be deleted.
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={handleClose}>
            Cancel
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              handleClose()
              confirm(itemId)
            }}
          >
            Sure, delete it!
          </button>
        </div>
      </div>
    </Modal>
  )
}

export { DeleteModal }
