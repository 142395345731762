/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

import { UserModel, checkPermission } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { PermissionEnum } from '../../../../enums/PermissionEnum'

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {(checkPermission(user, PermissionEnum.MenuAccessEducall)) &&
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>EDUCALL</span>
          </div>
        </div>
      }
      {(checkPermission(user, PermissionEnum.AnnouncementRead)
        || checkPermission(user, PermissionEnum.TaskDefineRead)
        || checkPermission(user, PermissionEnum.ConfigRead)
        || checkPermission(user, PermissionEnum.JobApplicationRead)
        || checkPermission(user, PermissionEnum.ContactRead)
        || checkPermission(user, PermissionEnum.CampaignParticipantRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='System'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg'
        >
          {checkPermission(user, PermissionEnum.AnnouncementRead) && <AsideMenuItem to='/system/announcements' title='Announcements' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.ConfigRead) && <AsideMenuItem to='/system/configs' title='Configs' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.JobApplicationRead) && <AsideMenuItem to='/system/jobApplications' title='Job Applications' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.ContactRead) && <AsideMenuItem to='/system/contacts' title='Contacts' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.ContactRead) && <AsideMenuItem to='/system/helpDesk' title='Help Desk' hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
      {(checkPermission(user, PermissionEnum.UserRead)
        || checkPermission(user, PermissionEnum.RoleRead)
        || checkPermission(user, PermissionEnum.CompanyRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Account'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg'
        >
          {checkPermission(user, PermissionEnum.UserRead) && <AsideMenuItem to='/account/users' title='Users' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.RoleRead) && <AsideMenuItem to='/account/roles' title='Roles' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.CompanyRead) && <AsideMenuItem to='/account/companies' title='Companies' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.UserRead) && <AsideMenuItem to='/account/logins' title='Logins' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.UserCreate) && <AsideMenuItem to='/account/student/pageViewings' title='Student Page Viewings' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.PermissionProcessRead) && <AsideMenuItem to='/account/permitLogs' title='Permit Logs' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.RoleRead) && <AsideMenuItem to='/account/permissions' title='Permissions' hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Task Manager'
        fontIcon='bi-user'
        icon='/media/icons/duotone/Code/Compiling.svg'
      >
        <AsideMenuItem to='/task/myTasks' title='My Tasks' hasBullet={true} />
        {checkPermission(user, PermissionEnum.TaskProcessRead) && <AsideMenuItem to='/task/process' title='Processes' hasBullet={true} />}
        {checkPermission(user, PermissionEnum.TaskDefineRead) && <AsideMenuItem to='/task/defines' title='Defines' hasBullet={true} />}
      </AsideMenuItemWithSub>

      {checkPermission(user, PermissionEnum.MenuAccessSchedule) &&
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>SCHEDULE</span>
          </div>
        </div>
      }
      {checkPermission(user, PermissionEnum.ScheduleRead) && <AsideMenuItem to='/schedule/list' title='Schedule' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.StudentPlanRead) && <AsideMenuItem to='/schedule/studentPlans' title='Student Plans' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.TrainerPlanRead) && <AsideMenuItem to='/schedule/trainerWorkPlans' title='Trainer Work Plans' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.GroupPlanRead) && <AsideMenuItem to='/schedule/groupPlans' title='Group Plans' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.StudentCommentRead) && <AsideMenuItem to='/schedule/studentComments' title='Student Comments' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.TrainerCommentRead) && <AsideMenuItem to='/schedule/trainerComments' title='Trainer Comments' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.JobInterviewRead) && <AsideMenuItem to='/schedule/jobInterviews' title='Job Interviews' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}


      {(checkPermission(user, PermissionEnum.TrainerCallReportRead)
        || checkPermission(user, PermissionEnum.PlacementRead)
        || checkPermission(user, PermissionEnum.TrainerPlanCreate)
        || checkPermission(user, PermissionEnum.DescendingTolkRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Reports'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg'
        >
          {checkPermission(user, PermissionEnum.ScheduleRead) && <AsideMenuItem to='/report/daily' title='Daily' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.StudentPlanRead) && <AsideMenuItem to='/report/studentPlanningLogs' title='Student Planning Logs' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.TrainerCallReportRead) && <AsideMenuItem to='/report/student/reports' title='Student Reports' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.TrainerCallReportRead) && <AsideMenuItem to='/report/trainer/callReports' title='Trainer Call Reports' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.TrainerPlanCreate) && <AsideMenuItem to='/report/emergencies/trainer' title='Trainer Emergencies' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.DescendingTolkRead) && <AsideMenuItem to='/schedule/descendingTolks' title='Descending Tolks' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.PlacementRead) && <AsideMenuItem to='/schedule/placements' title='Placements' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.StudentPlanRead) && <AsideMenuItem to='/schedule/ourStudentsWithoutPlan' title='Our Students Without Plan' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.TopicRead) && <AsideMenuItem to='/schedule/troubledTopicsReport' title='Troubled Topics Report' hasBullet={true} />}

        </AsideMenuItemWithSub>
      }

      {checkPermission(user, PermissionEnum.MenuAccessExam) &&
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>EXAM CENTER</span>
          </div>
        </div>
      }
      {checkPermission(user, PermissionEnum.ExamRead) && <AsideMenuItem to='/exam/exams' title='Exams' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.ExamTypeRead) && <AsideMenuItem to='/exam/types' title='Exam Types' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.ResultRead) && <AsideMenuItem to='/exam/results' title='Results' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}


      {(checkPermission(user, PermissionEnum.MenuAccessContent)) &&

        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CONTENT</span>
          </div>
        </div>
      }
      {(checkPermission(user, PermissionEnum.BlogPostRead)
        || checkPermission(user, PermissionEnum.BlogCategoryRead)
        || checkPermission(user, PermissionEnum.BlogCommentRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Blog'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg' >

          {checkPermission(user, PermissionEnum.BlogPostRead) && <AsideMenuItem to='/blog/posts' title='Posts' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.BlogCategoryRead) && <AsideMenuItem to='/blog/categories' title='Categories' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.BlogCommentRead) && <AsideMenuItem to='/blog/comments' title='Comments' hasBullet={true} />}

        </AsideMenuItemWithSub>
      }
      {(checkPermission(user, PermissionEnum.LevelRead)
        || checkPermission(user, PermissionEnum.LevelGroupRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Levels'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg' >
          {checkPermission(user, PermissionEnum.LevelRead) && <AsideMenuItem to='/level/list' title='List' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.LevelGroupRead) && <AsideMenuItem to='/level/groups' title='Groups' hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
      {checkPermission(user, PermissionEnum.UnitRead) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Units'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg' >
          <AsideMenuItem to='/unit/grammars' title='Grammar Units' hasBullet={true} />
          <AsideMenuItem to='/unit/grades' title='Grades' hasBullet={true} />
        </AsideMenuItemWithSub>
      }
      {checkPermission(user, PermissionEnum.TopicRead) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Topic'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg' >
          <AsideMenuItem to='/topic/topics' title='Topics' hasBullet={true} />
          {checkPermission(user, PermissionEnum.DescendingTolkRead) && <AsideMenuItem to='/topic/descendingTopics' title='Descending Topics' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.VocabularyRead) && <AsideMenuItem to='/topic/vocabularies' title='Vocabularies' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.PartofSpeechRead) && <AsideMenuItem to='/topic/partofSpeechs' title='Part of Speechs' hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
      {checkPermission(user, PermissionEnum.AutoCommentRead) && <AsideMenuItem to='/autoComments' title='Auto Comments' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.MissionRead) && <AsideMenuItem to='/missions' title='Missions' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}

      {checkPermission(user, PermissionEnum.PopupRead) && <AsideMenuItem to='/popups' title='Popups' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.VideoRead) && <AsideMenuItem to='/videos' title='Videos' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.PresentationRead) && <AsideMenuItem to='/presentations' title='Presentations' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}
      {checkPermission(user, PermissionEnum.GuideRead) && <AsideMenuItem to='/guides' title='Grammar Guides' fontIcon='bi-user' icon='/media/icons/duotone/Code/Compiling.svg' />}


      {(checkPermission(user, PermissionEnum.MenuAccessMarketing)) &&
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MARKETING</span>
          </div>
        </div>
      }
      {(checkPermission(user, PermissionEnum.PricingRead)
        || checkPermission(user, PermissionEnum.PricingCouponRead)
        || checkPermission(user, PermissionEnum.CampaignRead)
        || checkPermission(user, PermissionEnum.CampaignParticipantRead)
        || checkPermission(user, PermissionEnum.PaymentRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Pricing'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg'
        >
          {checkPermission(user, PermissionEnum.PricingRead) && <AsideMenuItem to='/pricing/pricings' title='Pricing' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.PricingCouponRead) && <AsideMenuItem to='/pricing/coupons' title='Coupons' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.CampaignRead) && <AsideMenuItem to='/pricing/campaigns' title='Campaigns' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.CampaignParticipantRead) && <AsideMenuItem to='/campaign/participants' title='Campaign Participants' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.PaymentRead) && <AsideMenuItem to='/payments' title='Payments' hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
      {(checkPermission(user, PermissionEnum.ShopProductRead)
        || checkPermission(user, PermissionEnum.ShopOrderRead)) &&
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Edushop'
          fontIcon='bi-user'
          icon='/media/icons/duotone/Code/Compiling.svg'
        >
          {checkPermission(user, PermissionEnum.ShopProductRead) && <AsideMenuItem to='/edushop/products' title='Products' hasBullet={true} />}
          {checkPermission(user, PermissionEnum.ShopOrderRead) && <AsideMenuItem to='/edushop/orders' title='Orders' hasBullet={true} />}
        </AsideMenuItemWithSub>
      }
    </>
  )
}
