export interface IUserDetailsFormModel {
    companyId: string,
    isTestAccount: number,
    consultantId: string,
    marketerCode: string,
    referenceCode: string,
    whoReference: string
}

const userDetailsInitValues: IUserDetailsFormModel = {
    companyId: '',
    isTestAccount: 0,
    consultantId: '',
    marketerCode: '',
    referenceCode: '',
    whoReference: ''
}

export { userDetailsInitValues }