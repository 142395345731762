import * as Yup from 'yup'

const ContactFormValidations = () => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    return Yup.object().shape({
        type: Yup.string()
            .min(4, 'Minimum 4 symbols')
            .max(20, 'Maximum 20 symbols')
            .required('Type is required'),
        value: Yup.string()
            .min(4, 'Minimum 4 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Value is required')
            .when("type", {
                is: (val: string | any[]) => (val && val == "PhoneNumber" ? true : false),
                then: Yup.string().matches(phoneRegExp, 'Phone number is not valid')

            })
            .when("type", {
                is: (val: string | any[]) => (val && val == "Email" ? true : false),
                then: Yup.string().email('Email is not valid')
            })
    })
}

export { ContactFormValidations }