import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { StudentReportListModal } from './_modals/StudentReportListModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { StudentReportFormModal } from './_modals/StudentReportFormModal'
import { StudentReportViewModal } from './_modals/StudentReportViewModal'
import { SelectModel } from '../../models/SelectModel'
import { DictionaryModel } from '../../../models/responses/DictionaryModel'
import UserApi from '../../../infrastructure/api/UserApi'
import { RoleEnum } from '../../../enums/RoleEnum'
import { IUserDictionaryModel } from '../../../models/responses/UserDictionaryModel'

const StudentReportPage: FC = () => {
    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleShowDelete = () => setShowDelete(true)


    const [refresh, setRefresh] = useState<number>(0)
    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
    const handleShowForm = () => setShowForm(true)

    const [showView, setShowView] = useState(false)
    const handleCloseView = () => setShowView(false)
    const handleShowView = () => setShowView(true)

    const [trainerDictionary, setTrainerDictionary] = useState<SelectModel[]>([])

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    async function init() {

        var filters: DictionaryModel[] = []
        filters.push({ type: "Role", value: RoleEnum.Trainer });

        if (filters.length > 0 && trainerDictionary.length == 0) {
            var responseUsers = await UserApi.GetUserDictionaryFilter(filters)
            var dataUsers: IUserDictionaryModel[] = responseUsers.data
            setTrainerDictionary([...dataUsers.map(d => ({
                value: d.id.toString(),
                label: `${d.firstName} ${d.lastName} - ${d.email}`
            }))]);
        }

    }

    useEffect(() => {
        init()
    }, [])


    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'view':
                handleShowView()
                break
            case 'delete':
                handleShowDelete()
                break
            default:
                handleShowForm()
                break
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>Generate New Report</h3>
                </div>
                <div className='card-body py-3'>
                    <StudentReportFormModal trainerList={trainerDictionary} handleClose={handleCloseForm} />

                </div>
            </div>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                </div>

                <div className='card-body py-3'>
                    <StudentReportViewModal itemId={itemId} show={showView} handleClose={handleCloseView} />
                    <StudentReportListModal trainerList={trainerDictionary} refresh={refresh} onClickItem={onClickItem} />
                </div>
            </div>
        </>
    )
}

const StudentReportWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Schedule</PageTitle>
            <StudentReportPage />
        </>
    )
}

export { StudentReportWrapper }
