import { StudentDetails } from './cards/StudentDetails'
import { IUserModel } from '../../../../../models/responses/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { UserDetails } from './cards/UserDetails'
import { RoleEnum } from '../../../../../enums/RoleEnum'
import { TrainerDetails } from './cards/TrainerDetails'
import { CompanyManagerDetails } from './cards/CompanyManagerDetails'
import { Contacts } from './cards/Contacts'

export function Settings() {
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  return (
    <>
      <div className='row'>
        <div className='col'>
          <UserDetails />
          <Contacts />
        </div>
        {user.roles?.some(s => s == RoleEnum.Student) &&
          <div className='col'>
            <StudentDetails />
          </div>
        }
        {user.roles?.some(s => s == RoleEnum.Trainer) &&
          <div className='col'>
            <TrainerDetails />
          </div>
        }
        {user.roles?.some(s => s == RoleEnum.CompanyManager) &&
          <div className='col'>
            <CompanyManagerDetails />
          </div>
        }
      </div>
    </>
  )
}
