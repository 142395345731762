import { FC, useEffect, useState } from 'react'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { ISchedulePlanModel, ITrainersModel } from '../../../../models/responses/TrainerModel'
import { BasicTable } from '../../../components/tables/BasicTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const ScheduleGroupListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('id_desc')

    const d = new Date();
    let weekDay = d.getUTCDay()
    var now = `${d.getUTCHours().toString().padStart(2, "0")}:${d.getUTCMinutes().toString().padStart(2, "0")}:${d.getUTCSeconds().toString().padStart(2, "0")}`
    const [groups, setGroups] = useState<ISchedulePlanModel[]>([])
    const [users, setUsers] = useState<IUserDictionaryModel[]>([])

    async function init() {

        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
        }

        var response = await TrainerApi.GetTrainerSchedulePlans(paginationFilter, { type: ScheduleTypeEnum.Group })
        setGroups(response.data.items)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort])

    return (
        <>
            <BasicTable
                title='Group Plans'
                data={groups}
                columns={[
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <div className='d-flex justify-content-start flex-shrink-0'>{dataIndex + 1}</div>
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: "Title", name: "title", options: {
                            customBodyRender: (value) => {
                                return <>{value ?? "-"}</>
                            }
                        }
                    },
                    {
                        label: "Trainer", name: "trainerFullName", options: {
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "Day", name: "dayOfWeek", options: {
                            customBodyRender: (value) => {
                                return <>{DayOfWeekEnum[value]}</>
                            }
                        }
                    },
                    {
                        label: "Date", name: "startDate", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Duration", name: "duration", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Capacity", name: "capacity", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Empty", name: "empty", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={groups?.[dataIndex].id!!}
                                        itemParam={groups?.[dataIndex].title}
                                        types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },

                ]}
                customOptions={{
                    filter: false,
                    viewColumns: false,
                    serverSide: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
            />
        </>
    )
}

export { ScheduleGroupListModal }
