import * as Yup from 'yup'

const PricingFormValidations = () => {
  return Yup.object().shape({
    amount: Yup.string()
      .required('Amount is required')
  })
}

export { PricingFormValidations }
