/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { shallowEqual, useSelector } from 'react-redux'
import { DictionaryModel } from '../../../models/responses/DictionaryModel'
import { IUserModel } from '../../../models/responses/UserModel'
import { RootState } from '../../../setup'
import { Link, useLocation } from 'react-router-dom'
import { RoleEnum } from '../../../enums/RoleEnum'
import { checkPermission, UserModel } from '../auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'


const AccountHeader: React.FC = () => {
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const userAuth: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const location = useLocation()

  const innerMenu: DictionaryModel[] = [
    { type: "Overview", value: `/account/users/${user?.id}/overview` },
    { type: "Infos", value: `/account/users/${user?.id}/infos` },
    { type: "Settings", value: `/account/users/${user?.id}/settings` },

    /*
      { type: "No Answer Reasons", value: "/accounts/settingSs" },        
      { type: "Task Management", value: "/accounts/settingSs" },          
      { type: "Trainer Comments Inside", value: "/accounts/settingSs" },
      { type: "Trainer Comments", value: "/accounts/trainer-comments" },
      { type: "Notices", value: "/accounts/notices" },
      { type: "Voice Records", value: "/accounts/voice-records" },
    */
  ]

  if (checkPermission(userAuth, PermissionEnum.RoleRead)) innerMenu.push({ type: "Roles", value: `/account/users/${user?.id}/roles` })
  if (user.roles?.some(s => s == RoleEnum.Student || s == RoleEnum.Trainer)) {
    { checkPermission(userAuth, PermissionEnum.ScheduleRead) && innerMenu.push({ type: "Schedules", value: `/account/users/${user?.id}/schedules` }); }
    { checkPermission(userAuth, PermissionEnum.ScheduleRead) && innerMenu.push({ type: "CallPlans", value: `/account/users/${user?.id}/call-plans` }); }
  }
  if (user.roles?.some(s => s == RoleEnum.Student)) {
    { checkPermission(userAuth, PermissionEnum.PaymentRead) && innerMenu.push({ type: "Invoice&Payment", value: `/account/users/${user?.id}/invoice-payment` }); }
    { checkPermission(userAuth, PermissionEnum.MissionRead) && innerMenu.push({ type: "Missions", value: `/account/users/${user?.id}/missions` }); }
    { checkPermission(userAuth, PermissionEnum.TopicListRead) && innerMenu.push({ type: "Topics", value: `/account/users/${user?.id}/topics` }); }
    { checkPermission(userAuth, PermissionEnum.ResultRead) && innerMenu.push({ type: "Exams", value: `/account/users/${user?.id}/exams` }); }
    { checkPermission(userAuth, PermissionEnum.ResultRead) && innerMenu.push({ type: "Placements", value: `/account/users/${user?.id}/placements` }); }
    { checkPermission(userAuth, PermissionEnum.UserCreate) && innerMenu.push({ type: "Viewings", value: `/account/users/${user?.id}/page-viewings` }); }
  }
  innerMenu.push({ type: "Messages", value: `/account/users/${user?.id}/messages` });



  if (user.roles?.some(s => s == RoleEnum.Trainer)) {
    innerMenu.push({ type: "Performance", value: `/account/users/${user?.id}/trainer-performance` });
    innerMenu.push({ type: "Activities", value: `/account/users/${user?.id}/trainer-activities` });
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={user.photo} alt='SelectedUser' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {user?.firstName} {user?.lastName}
                  </a>
                  {user?.isActive == false &&
                    <a href='#'>
                      <KTSVG
                        path='/media/icons/duotone/Design/Verified.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a>
                  }
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>

                  <span
                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotone/Communication/Mail-at.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user?.email}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotone/General/User.svg'
                      className='svg-icon-4 me-1'
                    />
                    {user?.id!! || ''}
                  </span>
                </div>
              </div>
            </div>
            <div className='d-flex flex-wrap flex-stack'>
              {(user?.roles?.length!! > 0) &&
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>

                    <div className='d-flex align-items-center'>

                      {user?.roles?.map((item, i) => {
                        return (
                          <label key={i} className='btn btn-sm btn-light-success fw-bolder ms-1 fs-8 py-1 px-3'>
                            {item}
                          </label>
                        )
                      })}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {innerMenu.map((item, index) => {
              return (
                <li className='nav-item' key={index}>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === item.value && 'active')
                    }
                    to={item.value}
                  >
                    {item.type}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div >
  )
}

export { AccountHeader }
