import { FC, useEffect, useState } from 'react'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { IUserCloudMessageModel } from '../../../../models/responses/UserCloudMessageModel'
import CloudMessageApi from '../../../../infrastructure/api/CloudMessageApi'
import { NotificationChannelEnum } from '../../../../enums/NotificationChannelEnum'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const CloudMessagesListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('date_desc')
    const [cloudMessages, setCloudMessages] = useState<PagedResponseModel<IUserCloudMessageModel[]>>()
    const { id } = useParams<any>()

    let now = new Date();
    let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

    const [start, setStart] = useState(startTime)
    const [end, setEnd] = useState(endTime)

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
        getReport(startDate, endDate)
    }

    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false,
    }

    async function getReport(start: moment.Moment, end: moment.Moment) {
        var startTime = start.format("YYYY-MM-DDT00:00:00");
        var endTime = end.format("YYYY-MM-DDT23:59:59");

        let newFilters: Map<string, string> = new Map()

        newFilters.set("StartDate", startTime)
        newFilters.set("EndDate", endTime)
        if (id != "" && id != undefined) {
            newFilters.set("UserId", id)
        }

        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText
        }

        var response = await CloudMessageApi.GetUserCloudMessages(id, paginationFilter)
        setCloudMessages(response.data)
    }

    async function init() {
        getReport(start, end)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort])

    return (
        <>
            <GenericTable
                title='Cloud Messages'
                data={cloudMessages?.items}
                currentTotalRecords={cloudMessages?.totalRecords!!}
                currentPageNumber={cloudMessages?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: '#',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                            },
                            sort: false,
                            viewColumns: false
                        },
                    },
                    {
                        label: "CreatedAt", name: "createdAt", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Channel", name: "channel", options: {
                            customBodyRender: (value) => {
                                return <b>{NotificationChannelEnum[value]}</b>
                            }
                        }
                    },
                    {
                        label: "Message", name: "message", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Sending Status", name: "#", options: {
                            customBodyRenderLite(dataIndex) {
                                var message = cloudMessages?.items[dataIndex];
                                return (
                                    <>
                                        {message?.isSend ? <>{message!.sendAt}</> : <span className='text-danger'>{message?.result}</span>}
                                    </>
                                )
                            }
                        }
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    sort: false,
                    search: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { CloudMessagesListModal }
