import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Overview } from './components/Overview'
import { Permissions } from './components/Permissions'
import { Roles } from './components/Roles'
import { Infos } from './components/infos/Infos'
import { InvoicePayment } from './components/InvoicePayment'
import { Topics } from './components/Topics'
import { Exams } from './components/Exams'

import { CallPlans } from './components/CallPlans'
import { TrainerCallPlans } from './components/TrainerCallPlans'

import { AccountHeader } from './AccountHeader'
import UserApi from '../../../infrastructure/api/UserApi'
import { useParams } from 'react-router'
import { connect, useDispatch, ConnectedProps, useSelector, shallowEqual } from 'react-redux'
import * as account from './redux/AccountRedux'
import { RootState } from '../../../setup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { IUserModel } from '../../../models/responses/UserModel'
import { RoleEnum } from '../../../enums/RoleEnum'
import { Settings } from './components/settings/Settings'
import { TrainerPerformanceReports } from './components/TrainerPerformanceReports'
import { Schedules } from './components/Schedules'
import { PageViewingReportListModal } from '../../pages/page-viewings/_modals/PageViewingReportListModal'
import { Missions } from './components/Missions'
import { Placements } from './components/Placements'
import { checkPermission, UserModel } from '../auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { TrainerActivities } from './components/TrainerActivities'
import StudentApi from '../../../infrastructure/api/StudentApi'
import { CloudMessagesListModal } from '../../pages/cloud-messages/_modals/CloudMessagesListModal'

const mapState = (state: RootState) => ({ account: state.account })
const connector = connect(mapState, account.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AccountPage: React.FC<PropsFromRedux> = (props) => {
  const updateSelectedUser: boolean = useSelector<RootState>(({ account }) => account.updateSelectedUser, shallowEqual) as boolean

  const [refresh, setRefresh] = useState<number>(0)
  const [itemId, setItemId] = useState("")

  const dispatch = useDispatch()
  const { id } = useParams<any>()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const userModel: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Users',
      path: `/account/users`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [count, setCount] = useState<number>(0)
  async function getUser(id: string) {
    var response = await UserApi.GetUser(id)
    var user = response.data;
    const currentAvatar = user.details?.find(i => i.type == "Photo")?.value!!;
    user.photo = (currentAvatar && currentAvatar != "" ? `${process.env.REACT_APP_CDN_URL}images/avatar/${currentAvatar}` : toAbsoluteUrl('/media/avatars/blank.png'))

    if (user.roles?.some(s => s == RoleEnum.Student)) {
      var responseStudent = await StudentApi.GetStudent(user.id);
      if (responseStudent.data != null) {
        user.presentLevelId = responseStudent.data.presentLevelId;
        user.startupLevelId = responseStudent.data.startupLevelId;
      }
    }

    dispatch(props.setSelectedUser(user))
    dispatch(props.setRoles(user.roles!!))
    dispatch(account.actions.setNotifUsers([
      {
        id: user?.id,
        firstName: user?.firstName, lastName: user?.lastName, phoneNumber: user?.phoneNumber, email: user?.email
      }]));
  }

  useEffect(() => {
    if (updateSelectedUser || count == 0 || itemId != id) {
      setItemId(id)
      setCount(count + 1);
      getUser(id);
      dispatch(props.updateSelectedUser(false))
    }
  }, [updateSelectedUser, id])

  return (
    <>
      <AccountHeader />
      <Switch>
        <Route path='/account/users/:id/overview'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
          <Overview />
        </Route>
        <Route path='/account/users/:id/infos'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Infos</PageTitle>
          <Infos />
        </Route>
        <Route path='/account/users/:id/settings'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
          <Settings />
        </Route>
        <Route path='/account/users/:id/invoice-payment'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Invoice&Payment</PageTitle>
          <InvoicePayment />
        </Route>
        <Route path='/account/users/:id/page-viewings'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Page Viewings</PageTitle>
          {(user.roles?.some(s => s == RoleEnum.Student) ? <div className='card mb-5 mb-xl-8'>
            <div className='card-body py-3 mt-6'><PageViewingReportListModal onClickItem={console.log} refresh={refresh} /></div></div> : <></>)}
        </Route>
        <Route path='/account/users/:id/messages'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Messages</PageTitle>
          <div className='card mb-5 mb-xl-8'>
            <div className='card-body py-3 mt-6'><CloudMessagesListModal onClickItem={console.log} refresh={refresh} /></div>
          </div>
        </Route>
        <Route path='/account/users/:id/call-plans'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Call Plan</PageTitle>
          {(user.roles?.some(s => s == RoleEnum.Student) ? <CallPlans /> : (user.roles?.some(s => s == RoleEnum.Trainer) ? <TrainerCallPlans /> : <></>))}
        </Route>
        <Route path='/account/users/:id/trainer-performance'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Performance</PageTitle>
          {(user.roles?.some(s => s == RoleEnum.Trainer) ? <TrainerPerformanceReports onClickItem={console.log} refresh={refresh} /> : <></>)}
        </Route>
        <Route path='/account/users/:id/trainer-activities'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Activities</PageTitle>
          {(user.roles?.some(s => s == RoleEnum.Trainer) ? <TrainerActivities onClickItem={console.log} refresh={refresh} /> : <></>)}
        </Route>
        <Route path='/account/users/:id/schedules'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Schedules</PageTitle>
          {(user.roles?.some(s => s == RoleEnum.Trainer || s == RoleEnum.Student) ? <Schedules /> : <></>)}
        </Route>
        {(checkPermission(userModel, PermissionEnum.RoleRead)) &&
          <Route path='/account/users/:id/roles'>
            <PageTitle breadcrumbs={accountBreadCrumbs}>Roles</PageTitle>

            <div className='row'>
              <div className='col-md-3'><Roles /></div>
              <div className='col-md-9'><Permissions /></div>
            </div>
          </Route>
        }
        <Route path='/account/users/:id/topics'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Topics</PageTitle>
          <Topics />
        </Route>
        <Route path='/account/users/:id/missions'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Missions</PageTitle>
          <Missions />
        </Route>
        <Route path='/account/users/:id/exams'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Exams</PageTitle>
          <Exams />
        </Route>
        <Route path='/account/users/:id/placements'>
          <PageTitle breadcrumbs={accountBreadCrumbs}>Placements</PageTitle>
          <Placements />
        </Route>
        <Redirect from='/account/users/:id' exact={true} to='/account/users/:id/overview' />
        <Redirect to='/account/users/:id/overview' />
      </Switch>
    </>
  )
}

export default connector(AccountPage)