import { FC, useEffect, useState } from 'react'
import GuideApi from '../../../../infrastructure/api/GuideApi'
import { IGuideModel, guideInitValues } from '../../../../models/responses/GuideModel'
import { useFormik } from 'formik'
import { IGuideRequestModel } from '../../../../models/requests/GuideRequestModel'
import { GuideFormValidations } from '../../../validations/GuideFormValidations'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { useParams } from 'react-router'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'
import { slugify } from '../../../helpers/StringHelper'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'

type Props = { itemId: number, show: boolean, handleClose: () => void }
const GuideFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const { id } = useParams<any>()
  itemId = id ? id : 0

  const [guide, setGuide] = useState<IGuideModel>(guideInitValues)
  const updateGuide = (fieldsToUpdate: Partial<IGuideModel>) => {
    const updatedData = { ...guide, ...fieldsToUpdate }
    setGuide(updatedData)
  }

  const [content, setContent] = useState('')

  const formik = useFormik<IGuideModel>({
    initialValues: guide!!,
    enableReinitialize: true,
    validationSchema: GuideFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IGuideRequestModel = {
          id: guide!!.id,
          name: values.name,
          link: slugify(values.name),
          content: content,
          order: values.order
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IGuideRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === 0) {
        result = await GuideApi.AddGuide(model)
      }
      else {
        result = await GuideApi.UpdateGuide(model)
      }

      setComplete(true)
      formik.resetForm()
      handleClose()
      window.location.pathname="/guides"
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  async function init() {
    let result: IGuideModel
    if (itemId > 0) {
      const response = await GuideApi.GetGuide(itemId)
      result = response.data

    }
    else {
      result = guideInitValues;
    }
    setGuide(result)
  }

  useEffect(() => {
    formik.resetForm();
    init()
  }, [itemId])

  return (
    <form
      className='form'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h1>{itemId === 0 ? 'Add' : 'Edit'} Guide</h1>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='row mb-8'>
          <div className='col-lg-10'>
            <label className='form-label fw-bolder required'>Name</label>
            <input
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className='form-control form-control-solid'
              placeholder='Entry..'
            />
            <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
          </div>
          <div className='col-lg-2'>
            <label className='form-label fw-bolder required'>Order</label>
            <input
              {...formik.getFieldProps('order')}
              type='number'
              name='order'
              className='form-control form-control-solid'
              placeholder='Entry..'
            />
            <FormikValidationError touched={formik.touched.order} error={formik.errors.order} />
          </div>
        </div>
        <div>
          <label className='form-label fw-bolder required'>Content</label>
          <DefaultEditor
            toolbars={[EditorToolbarEnum.Basic, EditorToolbarEnum.Extended, EditorToolbarEnum.Media]}
            setData={setContent}
            fileType={FileTypeEnum.Guide}
            data={guide.content}
          />
        </div>
      </div>
      <div className='card-footer py-3'>
        <div className='d-flex justify-content-end'>
          <SaveChangesButton
            loading={loading}
            setLoading={setLoading}
            complete={complete}
            setComplete={setComplete}
            valid={formik.isValid}
            submit={formik.isSubmitting}
            setSubmit={formik.submitForm} />
        </div>
      </div>
    </form >
  )
}

export { GuideFormModal }