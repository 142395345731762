import { IGuideRequestModel } from '../../models/requests/GuideRequestModel'
import { IGuideModel } from '../../models/responses/GuideModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class GuideApi {

  async GetGuides(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IGuideModel[]>>(`e/Admin/Guides${generateQueryParams(filter)}`)
  }

  async GetGuide(id: number) {
    return await API.get<IGuideModel>('e/Admin/Guides/' + id)
  }

  async AddGuide(model: IGuideRequestModel) {
    return await API.post('e/Admin/Guides', model)
  }

  async UpdateGuide(model: IGuideRequestModel) {
    return await API.put('e/Admin/Guides/' + model.id, model)
  }

  async DeleteGuide(itemId: number) {
    return await API.delete<boolean>('e/Admin/Guides/' + itemId)
  }
}

export default new GuideApi()
