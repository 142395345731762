import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import parse from 'html-react-parser'
import {
  IUserDictionaryModel
} from '../../../../models/responses/UserDictionaryModel'
import TaskApi from '../../../../infrastructure/api/TaskApi'
import { BasicTable } from '../../../components/tables/BasicTable'
import { ITaskDefineModel, ITaskModel, taskInitValues } from '../../../../models/responses/TaskModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual } from 'react-redux'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const TaskViewModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [task, setTask] = useState<ITaskModel>(taskInitValues)
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])
  const taskDefines: ITaskDefineModel[] = useSelector<RootState>(({ task }) => task.taskDefines, shallowEqual) as ITaskDefineModel[]

  async function init() {
    let result: ITaskModel
    if (itemId !== '') {
      var response = await TaskApi.GetTask(itemId)
      result = response.data

      var responseUsers = await UserApi.GetUserDictionaryIds([
        ...result.assignedUsers.map(i => i),
        ...result.associatedUsers.map(i => i),
        ...result.transactions.flatMap(i => i.userId),
        result.createdBy
      ])
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setUsers(dataUsers)
    } else {
      result = taskInitValues
    }
    setTask(result)
  }

  useEffect(() => {
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body mx-5 pt-0'>
          <div className='text-center mb-6'>
            <h1 className='mb-3'>View Task Status</h1>
            <div className='text-muted fw-bold fs-5 mb-6'>
              Task Management <small>Educall</small>
            </div>
          </div>
          <div className='row'>

            <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 ribbon ribbon-end ribbon-clip ">
              {task.isOneTransaction ?
                <div className="ribbon-label">
                  Enought One Transaction
                  <span className="ribbon-inner bg-dark"></span>
                </div>
                : <></>}
              <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0"><i className="fas fa-tasks"></i></span>
              <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                <h5 className="mb-1">{taskDefines.find(i => i.id == task.taskDefineId)?.title.toUpperCase()}</h5>
                <span>{parse(task.explanation)}</span>
              </div>
            </div>

            <div className="d-flex flex-column mb-6">
              <li className="d-flex align-items-center py-2">
                <span className="bullet bg-primary me-5"></span><b>Created At: </b> {task.createdAt}
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet bg-light me-5"></span><b>Expiration At: </b> {task.expirationAt}
              </li>
            </div>
            <div className='row'>
              <div className='col'>
                <h3 className='mb-3'>Assigned Users: </h3>
                {task.assignedUsers.map((item, key) =>
                  <div key={key}><span className='badge badge-light-primary mb-1 me-1'>{users.find(i => i.id == item)?.email}</span></div>
                )}
              </div>
              {task.associatedUsers.length > 0 && <div className='col'>
                <h3 className='mb-3'>Associated Users: </h3>
                {task.associatedUsers.map((item, key) =>
                  <div key={key}><span className='badge badge-light-primary mb-1 me-1'>{users.find(i => i.id == item)?.email}</span></div>
                )}
              </div>
              }
            </div>
            <BasicTable
              title='Transactions'
              data={task.transactions}
              columns={[
                {
                  label: 'User',
                  name: 'userId',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{users.find(i => i.id == value)?.email}</span>
                    },
                  },
                },
                {
                  label: 'Message',
                  name: 'message',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{parse(value)}</span>
                    },
                  },
                },
                {
                  label: 'Is Complete', name: 'isCompleted',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <span>{task.transactions[dataIndex].isCompleted ? <b>{task.transactions[dataIndex].createdAt}</b> : task.transactions[dataIndex].createdAt}</span>
                    },
                  },
                },
              ]}
              customOptions={{
                filter: false,
                viewColumns: false,
                serverSide: false,
                customTableBodyFooterRender: (opts) => {
                  return <div></div>
                },
              }}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <div className='d-flex flex-center '>
            <button className='btn btn-primary' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export { TaskViewModal }
