import * as Yup from 'yup'

const BlogCommentFormValidations = () => {
  return Yup.object().shape({
    author: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(45, 'Maximum 45 symbols'),
    
    body: Yup.string().min(3, 'Minimum 3 symbols'),
  })
}

export {BlogCommentFormValidations}
