import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { StudentPlanListModal } from './_modals/StudentPlanListModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import StudentApi from '../../../infrastructure/api/StudentApi'
import { InfoModal } from '../../components/modals/InfoModal'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { KTSVG } from '../../../_metronic/helpers'

const StudentPage: FC = () => {
    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleShowDelete = () => setShowDelete(true)

    const [refresh, setRefresh] = useState<number>(0)
    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
    const handleShowForm = () => setShowForm(true)

    const [showInfo, setShowInfo] = useState(false)
    const handleCloseInfo = () => setShowInfo(false)

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


    const reAutoPairPlan = async (studentPlanId?: string) => {
        await StudentApi.ReAutoPairPlans(
            { studentPlanId: studentPlanId }
        )
        setRefresh(refresh + 1)
    }


    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'delete':
                handleShowDelete()
                break
            case 'reAutoPair':
                setShowInfo(true)
                break
            default:
                handleShowForm()
                break
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    {checkPermission(user, PermissionEnum.StudentPlanCreate) &&
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Re Auto Pair All Plans'
                        >
                            <a
                                href='#'
                                onClick={() => onClickItem('', '', 'reAutoPair')}
                                className='btn btn-sm btn-light-danger'
                            >
                                <KTSVG
                                    path='/media/icons/duotone/General/Fire.svg'
                                    className='svg-icon-3'
                                />
                                Re Auto Pair All Plans
                            </a>
                        </div>
                    }
                </div>

                <div className='card-body py-3'>
                    <InfoModal
                        handleClose={handleCloseInfo}
                        show={showInfo}
                        confirm={() => reAutoPairPlan(itemId)}
                        title='Plan Will Be Re Auto Pair Plan!'
                        text='Student`s plan will be auto pair plan, are you sure?'
                    />
                    <StudentPlanListModal refresh={refresh} onClickItem={onClickItem} />
                </div>
            </div>
        </>
    )
}

const StudentWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Schedule</PageTitle>
            <StudentPage />
        </>
    )
}

export { StudentWrapper }
