/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { IContactFormModel, contactInitValues as initialValues } from '../../../../../models/ContactFormModel'
import { IUserModel, userInitValues } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import * as account from './../../../redux/AccountRedux'
import { DeleteModal } from '../../../../../components/modals/DeleteModal'
import { ContactFormValidations } from '../../../../../validations/ContactFormValidations'
import { IContactsRequestModel } from '../../../../../../models/requests/ContactsRequestModel'
import ContactsApi from '../../../../../../infrastructure/api/ContactsApi'
import { IContactsModel } from '../../../../../../models/responses/ContactsModel'

const Contacts: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const [data, setData] = useState<IContactsModel[]>([])
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [itemValue, setItemValue] = useState('')
  const [submitDelete, setSubmitDelete] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)
  const formDatas = [
    {
      key: 'Email',
      title: 'E-mail'
    },
    {
      key: 'PhoneNumber',
      title: 'Phone'
    },
    {
      key: 'TerminalNumber',
      title: 'Terminal'
    },
    {
      key: 'ZoomAddress',
      title: 'Zoom'
    }
  ]

  const init = async () => {
    var response = await ContactsApi.GetContacts(user.id, "")
    setData(response.data)
  }


  useEffect(() => {
    formik.validateForm()
    if (user.id) {
      init()
    }
  }, [user])

  const formik = useFormik<IContactFormModel>({
    initialValues,
    validationSchema: ContactFormValidations,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(async () => {
        var model: IContactsRequestModel = {
          userId: user.id,
          type: values.type,
          value: values.value
        }
        process(model)
      }, 500)
    },
  })

  async function deleteClaim(id: string) {
    try {
      setSubmitDelete(id)
      await ContactsApi.DeleteContact(id);
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  async function process(contact: IContactsRequestModel) {
    try {

      await ContactsApi.AddContact(contact);
      setComplete(true)
      formik.resetForm();
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_connected_accounts'
        aria-expanded='true'
        aria-controls='kt_account_connected_accounts'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Secondary Contacts</h3>
        </div>
      </div>
      <div className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='py-2'>
            {data?.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className={"form-group " + (index == 0 ? "" : "mt-5")}>
                    <label className='fs-5 fw-bolder form-label mb-2'>{item.type}</label>
                    <div className="input-group">
                      <input
                        value={item.value}
                        disabled
                        className='form-control form-control-sm me-2'
                      />
                      <div className="">
                        <button className="btn btn-icon btn-danger"
                          onClick={() => { handleShowDelete(); setItemName(item.type); setItemValue(item.value); setItemId(item.id) }}
                          type="button">
                          {item.value == itemValue && submitDelete == itemValue && <span className='indicator-progress' style={{ display: 'block' }}>
                            <span className='spinner-border spinner-border-sm align-middle'></span>
                          </span>}
                          {submitDelete != item.value && <KTSVG path='/media/icons/duotone/Home/Trash.svg' className='svg-icon-2' />}
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )
            })}

          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <form
            onSubmit={formik.handleSubmit}
            noValidate>
            <div className="mb-5 d-flex">
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    {...formik.getFieldProps('type')}
                    className='form-select form-select-solid form-select-lg'
                  >
                    <option value=''>Select..</option>
                    {formDatas.map((item, index) => {
                      return (<option key={index} value={item.key}>{item.title}</option>)
                    })}
                  </select>
                </div>
                <input
                  {...formik.getFieldProps('value')}
                  className='form-control form-control-sm'
                />
              </div>
            </div>

            <div className='d-flex justify-content-end'>

              {formik.touched.value && formik.errors.value && (
                <div className='text-danger mt-2 me-3'>{formik.errors.value}</div>
              )}
              <SaveChangesButton
                title='<i className=""></i> Add Row'
                customClasses="btn-sm btn-success"
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm} />
            </div>
          </form>
        </div>

      </div>

      <DeleteModal
        itemId={0}
        itemName={itemName}
        handleClose={handleCloseDelete}
        show={showDelete}
        type='Secondary Contact'
        confirm={() => deleteClaim(itemId)}
      />
    </div>

  )
}

export { Contacts }