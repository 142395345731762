import { FC, useEffect, useState } from 'react'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { ITrainersModel } from '../../../../models/responses/TrainerModel'
import { BasicTable } from '../../../components/tables/BasicTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import PermissionProcessApi from '../../../../infrastructure/api/PermissionProcessApi'
import { IPermissionProcessModel } from '../../../../models/responses/PermissionProcessModel'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const PermissionProcessListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('date_desc')

    const d = new Date();
    let weekDay = d.getUTCDay()
    var now = `${d.getUTCHours().toString().padStart(2, "0")}:${d.getUTCMinutes().toString().padStart(2, "0")}:${d.getUTCSeconds().toString().padStart(2, "0")}`
    const [permissionProcesses, setPermissionProcesses] = useState<PagedResponseModel<IPermissionProcessModel[]>>()
    const [users, setUsers] = useState<IUserDictionaryModel[]>([])

    async function init() {

        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
        }

        var response = await PermissionProcessApi.GetPermissionProcesses(paginationFilter)
        setPermissionProcesses(response.data)

        var responseUsers = await UserApi.GetUserDictionaryIds([...response.data.items.map(i => i.userId!!), ...response.data.items.map(i => i.createdBy!!)])
        var dataUsers: IUserDictionaryModel[] = responseUsers.data
        setUsers(dataUsers)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort])

    return (
        <>

            <GenericTable
                title='Permit Logs'
                data={permissionProcesses?.items}
                currentTotalRecords={permissionProcesses?.totalRecords!!}
                currentPageNumber={permissionProcesses?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: '#',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                            },
                            sort: false,
                            viewColumns: false
                        },
                    },
                    {
                        label: "Student", name: "userId", options: {
                            customBodyRender: (value) => {
                                return <b>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</b>
                            }
                        }
                    },
                    {
                        label: "Type", name: "type", options: {
                            customBodyRender: (value) => {
                                return <b><span className='badge badge-info'>{value}</span></b>
                            }
                        }
                    },
                    {
                        label: "Status", name: "isActive", options: {
                            customBodyRender: (value) => {
                                return <b>{value ? <span className='badge badge-success'>Active</span> : <span className='badge badge-danger'>Passive</span>}</b>
                            }
                        }
                    },
                    {
                        label: "Doer", name: "createdBy", options: {
                            customBodyRender: (value) => {
                                return <span>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</span>
                            }
                        }
                    },
                    { label: 'Date', name: 'date' },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={permissionProcesses?.items[dataIndex]?.id!!}
                                        itemParam={permissionProcesses?.items[dataIndex]?.id!!}
                                        types={[]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    search: false,
                    sort: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { PermissionProcessListModal }
