export interface IBlogCategoryModel {
  id: number
  name: string
  link: string
  about: string
  order: number
  parentBlogCategoryName: string
  parentBlogCategoryId: number
}

const blogCategoryInitValues: IBlogCategoryModel = {
  id: 0,
  name: '',
  link: '',
  about: '',
  order: 0,
  parentBlogCategoryId: 0,
  parentBlogCategoryName: ''
}

export { blogCategoryInitValues }