import { IVocabularyRequestModel } from '../../models/requests/VocabularyRequestModel'
import { IVocabularyModel } from '../../models/responses/VocabularyModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class VocabularyApi {

  async GetVocabularies(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IVocabularyModel[]>>(`t/Admin/Vocabularies${generateQueryParams(filter)}`)
  }

  async GetVocabulary(id?: string, word?: string, language?: number, levelId?: number) {
    return await API.get<IVocabularyModel>('t/Admin/Vocabularies/' + (word != '' && levelId != undefined ? `${word}/${language}/${levelId}` : id))
  }

  async AddVocabulary(model: IVocabularyRequestModel) {
    return await API.post('t/Admin/Vocabularies', model)
  }

  async UpdateVocabulary(model: IVocabularyRequestModel) {
    return await API.put('t/Admin/Vocabularies/' + model.id, model)
  }

  async DeleteVocabulary(itemId: string) {
    return await API.delete<boolean>('t/Admin/Vocabularies/' + itemId)
  }
}

export default new VocabularyApi()
