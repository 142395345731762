import { IBlogCommentRequestModel } from '../../models/requests/BlogCommentRequestModel'
import { IBlogCommentModel } from '../../models/responses/BlogCommentModel'
import API from './Api'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'

class BlogCommentApi {
  async GetBlogComments(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IBlogCommentModel[]>>(`e/Admin/BlogComments${generateQueryParams(filter)}`)
  }

  async GetBlogComment(id: number) {
    return await API.get<IBlogCommentModel>('e/Admin/BlogComments/' + id)
  }

  async AddBlogComment(model: IBlogCommentRequestModel) {
    return await API.post('e/Admin/BlogComments', model)
  }

  async UpdateBlogComment(model: IBlogCommentRequestModel) {
    return await API.put('e/Admin/BlogComments/' + model.id, model)
  }

  async DeleteBlogComment(itemId: number) {
    return await API.delete<boolean>('e/Admin/BlogComments/' + itemId)
  }
}

export default new BlogCommentApi()
