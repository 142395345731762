import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { BlogCategoryWrapper } from '../pages/blog-categories/BlogCategoryWrapper'
import { BlogCommentWrapper } from '../pages/blog-comments/BlogCommentWrapper'
import { BlogPostWrapper } from '../pages/blog-posts/BlogPostWrapper'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { UserWrapper } from '../pages/users/UserWrapper'
import { RoleWrapper } from '../pages/roles/RoleWrapper'
import { PermissionWrapper } from '../pages/permissions/PermissionWrapper'
import { GuideWrapper } from '../pages/guides/GuideWrapper'
import { UnitWrapper } from '../pages/units/UnitWrapper'
import { CompanyWrapper } from '../pages/companies/CompanyWrapper'
import { CampaignWrapper } from '../pages/campaigns/CampaignWrapper'
import { PricingCouponWrapper } from '../pages/pricing-coupons/PricingCouponWrapper'
import { ConfigWrapper } from '../pages/configs/ConfigWrapper'
import { PresentationWrapper } from '../pages/presentations/PresentationWrapper'
import { PopupWrapper } from '../pages/popups/PopupWrapper'
import { LevelWrapper } from '../pages/levels/LevelWrapper'
import { LevelGroupWrapper } from '../pages/level-groups/LevelGroupWrapper'
import { PricingWrapper } from '../pages/pricings/PricingWrapper'
import { PaymentWrapper } from '../pages/payments/PaymentWrapper'
import { VideoWrapper } from '../pages/videos/VideoWrapper'
import { ContactWrapper } from '../pages/contacts/ContactWrapper'
import { JobApplicationWrapper } from '../pages/job-applications/JobApplicationWrapper'
import { CampaignParticipantWrapper } from '../pages/campaign-participants/CampaignParticipantWrapper'
import { ShopProductWrapper } from '../pages/shop-products/ShopProductWrapper'
import { ShopOrderWrapper } from '../pages/shop-orders/ShopOrderWrapper'
import { VocabularyWrapper } from '../pages/vocabularies/VocabularyWrapper'
import { PartofSpeechWrapper } from '../pages/part-of-speechs/PartofSpeechWrapper'
import { TopicWrapper } from '../pages/topics/TopicWrapper'
import { TaskWrapper } from '../pages/tasks/TaskWrapper'
import { TaskDefineWrapper } from '../pages/task-defines/TaskDefineWrapper'
import { AnnouncementWrapper } from '../pages/announcements/AnnouncementWrapper'
import { AnnouncementAlert } from '../pages/announcements/components/AnnouncementAlert'
import { ResultWrapper } from '../pages/results/ResultWrapper'
import { ExamWrapper } from '../pages/exams/ExamWrapper'
import { ExamTypeWrapper } from '../pages/exam-types/ExamTypeWrapper'
import { TrainerWrapper } from '../pages/trainers/TrainerWrapper'
import { StudentWrapper } from '../pages/students/StudentWrapper'
import { ScheduleWrapper } from '../pages/schedules/ScheduleWrapper'

import { UserModel, checkPermission } from '../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { PermissionEnum } from '../../enums/PermissionEnum'
import { RootState } from '../../setup'
import { JobInterviewWrapper } from '../pages/job-interviews/JobInterviewWrapper'
import { AutoCommentWrapper } from '../pages/auto-comments/AutoCommentWrapper'
import { UserLogWrapper } from '../pages/user-logs/UserLogWrapper'
import { PermissionProcessWrapper } from '../pages/permission-processess/PermissionProcessWrapper'
import { TrainerCommentWrapper } from '../pages/trainer-comments/TrainerCommentWrapper'
import { TrainerReportWrapper } from '../pages/trainer-reports/TrainerReportWrapper'
import { StudentCommentWrapper } from '../pages/student-comments/StudentCommentWrapper'
import { DescendingTopicWrapper } from '../pages/descending-topics/DescendingTopicWrapper'
import { DescendingTolkWrapper } from '../pages/descending-tolks/DescendingTolkWrapper'
import { PageViewingReportWrapper } from '../pages/page-viewings/PageViewingReportWrapper'
import { ScheduleGroupWrapper } from '../pages/schedule-groups/ScheduleGroupWrapper'
import { MissionWrapper } from '../pages/missions/MissionWrapper'
import { PlacementWrapper } from '../pages/placements/PlacementWrapper'
import { ReportWrapper } from '../pages/reports/ReportWrapper'
import { StudentPlanningLogWrapper } from '../pages/student-planning-logs/StudentPlanningLogWrapper'
import { EmergencyWrapper } from '../pages/emergencies/EmergencyWrapper'
import { OurStudentsWithoutPlanWrapper } from '../pages/ourstudents-withoutplan/OurStudentsWithoutPlanWrapper'
import { TroubledTopicsReportWrapper } from '../pages/troubled-topics-report/TroubledTopicsReportWrapper'
import { StudentReportWrapper } from '../pages/student-reports/StudentReportWrapper'
import { HelpDeskWrapper } from '../pages/help-desks/HelpDeskWrapper'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  return (
    <Suspense fallback={<FallbackView />}>
      <AnnouncementAlert />
      <Switch>

        <Route path='/dashboard' component={DashboardWrapper} />

        {checkPermission(user, PermissionEnum.AnnouncementRead) && <Route path='/system/announcements' component={AnnouncementWrapper} />}
        {checkPermission(user, PermissionEnum.ConfigRead) && <Route path='/system/configs' component={ConfigWrapper} />}
        {checkPermission(user, PermissionEnum.JobApplicationRead) && <Route path='/system/jobApplications' component={JobApplicationWrapper} />}
        {checkPermission(user, PermissionEnum.ContactRead) && <Route path='/system/helpDesk' component={HelpDeskWrapper} />}
        {checkPermission(user, PermissionEnum.ContactRead) && <Route path='/system/contacts' component={ContactWrapper} />}

        <Route path='/task/myTasks' component={TaskWrapper} />
        {checkPermission(user, PermissionEnum.TaskProcessRead) && <Route path='/task/process' component={TaskWrapper} />}
        {checkPermission(user, PermissionEnum.TaskDefineRead) && <Route path='/task/defines' component={TaskDefineWrapper} />}

        {checkPermission(user, PermissionEnum.BlogCategoryRead) && <Route path='/blog/categories' component={BlogCategoryWrapper} />}
        {checkPermission(user, PermissionEnum.BlogCommentRead) && <Route path='/blog/comments' component={BlogCommentWrapper} />}
        {checkPermission(user, PermissionEnum.BlogPostRead) && <Route path='/blog/posts' component={BlogPostWrapper} />}

        {checkPermission(user, PermissionEnum.LevelRead) && <Route path='/level/list' component={LevelWrapper} />}
        {checkPermission(user, PermissionEnum.LevelGroupRead) && <Route path='/level/groups' component={LevelGroupWrapper} />}

        {checkPermission(user, PermissionEnum.MissionRead) && <Route path='/missions' component={MissionWrapper} />}


        {checkPermission(user, PermissionEnum.PopupRead) && <Route path='/popups' component={PopupWrapper} />}
        {checkPermission(user, PermissionEnum.UnitRead) && <Route path='/unit/:type' component={UnitWrapper} />}
        {checkPermission(user, PermissionEnum.VideoRead) && <Route path='/videos' component={VideoWrapper} />}
        {checkPermission(user, PermissionEnum.PresentationRead) && <Route path='/presentations' component={PresentationWrapper} />}
        {checkPermission(user, PermissionEnum.GuideRead) && <Route path='/guides' component={GuideWrapper} />}
        {checkPermission(user, PermissionEnum.MenuAccessSchedule) && <Route path='/autoComments' component={AutoCommentWrapper} />}

        {checkPermission(user, PermissionEnum.TopicRead) && <Route path='/topic/topics' component={TopicWrapper} />}
        {checkPermission(user, PermissionEnum.TopicRead) && <Route path='/topic/descendingTopics' component={DescendingTopicWrapper} />}
        {checkPermission(user, PermissionEnum.VocabularyRead) && <Route path='/topic/vocabularies' component={VocabularyWrapper} />}
        {checkPermission(user, PermissionEnum.PartofSpeechRead) && <Route path='/topic/partofSpeechs' component={PartofSpeechWrapper} />}

        {checkPermission(user, PermissionEnum.UserRead) && <Route exact path='/account/users' component={UserWrapper} />}
        {checkPermission(user, PermissionEnum.UserUpdate) && <Route path='/account/users/:id' component={AccountPage} />}
        {checkPermission(user, PermissionEnum.CompanyRead) && <Route path='/account/companies' component={CompanyWrapper} />}
        {checkPermission(user, PermissionEnum.RoleRead) && <Route path='/account/roles/' component={RoleWrapper} />}
        {checkPermission(user, PermissionEnum.UserRead) && <Route path='/account/logins' component={UserLogWrapper} />}
        {checkPermission(user, PermissionEnum.UserRead) && <Route path='/account/student/:type' component={PageViewingReportWrapper} />}

        {checkPermission(user, PermissionEnum.UserRead) && <Route path='/account/permitLogs' component={PermissionProcessWrapper} />}
        {checkPermission(user, PermissionEnum.RoleRead) && <Route path='/account/permissions' component={PermissionWrapper} />}



        {checkPermission(user, PermissionEnum.CampaignRead) && <Route path='/pricing/campaigns' component={CampaignWrapper} />}
        {checkPermission(user, PermissionEnum.PricingRead) && <Route path='/pricing/pricings' component={PricingWrapper} />}
        {checkPermission(user, PermissionEnum.PricingCouponRead) && <Route path='/pricing/coupons' component={PricingCouponWrapper} />}
        {checkPermission(user, PermissionEnum.PaymentRead) && <Route path='/payments' component={PaymentWrapper} />}
        {checkPermission(user, PermissionEnum.CampaignParticipantRead) && <Route path='/campaign/participants' component={CampaignParticipantWrapper} />}

        {checkPermission(user, PermissionEnum.ShopProductRead) && <Route path='/edushop/products' component={ShopProductWrapper} />}
        {checkPermission(user, PermissionEnum.ShopOrderRead) && <Route path='/edushop/orders' component={ShopOrderWrapper} />}

        {checkPermission(user, PermissionEnum.ResultRead) && <Route path='/exam/results' component={ResultWrapper} />}
        {checkPermission(user, PermissionEnum.ExamRead) && <Route path='/exam/exams' component={ExamWrapper} />}
        {checkPermission(user, PermissionEnum.ExamTypeRead) && <Route path='/exam/types' component={ExamTypeWrapper} />}

        {checkPermission(user, PermissionEnum.ScheduleRead) && <Route path='/schedule/list' component={ScheduleWrapper} />}
        {checkPermission(user, PermissionEnum.TrainerPlanRead) && <Route path='/schedule/trainerWorkPlans' component={TrainerWrapper} />}
        {checkPermission(user, PermissionEnum.StudentPlanRead) && <Route path='/schedule/studentPlans' component={StudentWrapper} />}
        {checkPermission(user, PermissionEnum.GroupPlanRead) && <Route path='/schedule/groupPlans' component={ScheduleGroupWrapper} />}
        {checkPermission(user, PermissionEnum.DescendingTolkRead) && <Route path='/schedule/descendingTolks' component={DescendingTolkWrapper} />}
        {checkPermission(user, PermissionEnum.PlacementRead) && <Route path='/schedule/placements' component={PlacementWrapper} />}
        {checkPermission(user, PermissionEnum.StudentPlanRead) && <Route path='/schedule/ourStudentsWithoutPlan' component={OurStudentsWithoutPlanWrapper} />}
        {checkPermission(user, PermissionEnum.TopicRead) && <Route path='/schedule/troubledTopicsReport' component={TroubledTopicsReportWrapper} />}

        {checkPermission(user, PermissionEnum.JobInterviewRead) && <Route path='/schedule/jobInterviews' component={JobInterviewWrapper} />}

        {checkPermission(user, PermissionEnum.ScheduleRead) && <Route path='/report/daily' component={ReportWrapper} />}
        {checkPermission(user, PermissionEnum.StudentPlanRead) && <Route path='/report/studentPlanningLogs' component={StudentPlanningLogWrapper} />}
        {checkPermission(user, PermissionEnum.TrainerCommentRead) && <Route path='/schedule/trainerComments' component={TrainerCommentWrapper} />}
        {checkPermission(user, PermissionEnum.StudentCommentRead) && <Route path='/schedule/studentComments' component={StudentCommentWrapper} />}

        {checkPermission(user, PermissionEnum.TrainerCallReportRead) && <Route path='/report/trainer/:type' component={TrainerReportWrapper} />}
        {checkPermission(user, PermissionEnum.StudentPlanRead) && <Route path='/report/student/:type' component={StudentReportWrapper} />}

        {checkPermission(user, PermissionEnum.TrainerPlanCreate) && <Route path='/report/emergencies/trainer' component={EmergencyWrapper} />}



        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
