/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { IAccountInfoFormModel, accountInfoInitValues as initialValues } from '../../../../../models/AccountInfoFormModel'
import { FormDataModel } from '../../../../../models/FormDataModel'
import { IUserClaimsRequestModel, userClaimsInitValues } from '../../../../../../models/requests/UserClaimsRequestModel'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import UserApi from '../../../../../../infrastructure/api/UserApi'
import * as account from './../../../redux/AccountRedux'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'

const AccountInfos: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const formDatas: FormDataModel[] = [
    {
      key: 'facebook',
      name: 'Facebook',
      title: 'Facebook',
      icon: '/media/svg/brand-logos/facebook-3.svg'
    },
    {
      key: 'twitter',
      name: 'Twitter',
      title: 'Twitter',
      icon: '/media/svg/brand-logos/twitter.svg'
    },
    {
      key: 'instagram',
      name: 'Instagram',
      title: 'Instagram',
      icon: '/media/svg/brand-logos/instagram-2-1.svg'
    },
    {
      key: 'youtube',
      name: 'YouTube',
      title: 'YouTube',
      icon: '/media/svg/brand-logos/youtube-play.svg'
    },
    {
      key: 'zoom',
      name: 'Zoom',
      title: 'Zoom',
      icon: '/media/svg/brand-logos/zoomus.svg'
    },
    {
      key: 'skype',
      name: 'Skype',
      title: 'Skype',
      icon: '/media/svg/brand-logos/skype.svg'
    },
    {
      key: 'website',
      name: 'Website',
      title: 'Website',
      icon: '/media/svg/brand-logos/microsoft_edge.svg'
    }
  ]

  const formik = useFormik<IAccountInfoFormModel>({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        userClaimsInitValues.claims = []
        let model = userClaimsInitValues
        model.id = user.id
        let infos: any = values
        for (let item of formDatas) {
          model?.claims?.push({ type: item.name, value: infos[item.key] });
        }
        processRole(model)
      }, 500)
    },
  })


  async function processRole(model: IUserClaimsRequestModel) {
    try {
      await UserApi.UpdateUserClaims(model);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user) {
      initialValues.facebook = user.details?.find(i => i.type == "Facebook")?.value || ''
      initialValues.twitter = user.details?.find(i => i.type == "Twitter")?.value || ''
      initialValues.instagram = user.details?.find(i => i.type == "Instagram")?.value || ''
      initialValues.youtube = user.details?.find(i => i.type == "YouTube")?.value || ''
      initialValues.zoom = user.details?.find(i => i.type == "Zoom")?.value || ''
      initialValues.skype = user.details?.find(i => i.type == "Skype")?.value || ''
      initialValues.website = user.details?.find(i => i.type == "Website")?.value || ''
    }
  }, [user?.details])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_connected_accounts'
        aria-expanded='true'
        aria-controls='kt_account_connected_accounts'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Accounts</h3>
        </div>
      </div>
      <div className='collapse show'>
        <form
          onSubmit={formik.handleSubmit}
          noValidate>
          <div className='card-body border-top p-9'>
            <div className='py-2'>
              {formDatas.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {index != 0 && <div className='separator separator-dashed my-5'></div>}
                    <div className='d-flex flex-stack'>
                      <div className='d-flex'>
                        <img
                          src={toAbsoluteUrl(item.icon!!)}
                          className='w-30px me-6'
                          alt=''
                        />
                        <div className='d-flex flex-column'>
                          <label className='fs-5 text-dark fw-bolder'>
                            {item.title}
                          </label>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mw-150px'>
                        <input
                          {...formik.getFieldProps(item.key)}
                          className='form-control form-control-sm me-2' />
                      </div>
                    </div>
                  </Fragment>
                )
              })}
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div>
    </div>
  )
}

export { AccountInfos }