import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { AutoCommentFormValidations } from '../../../validations/AutoCommentFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EnumToList } from '../../../helpers/EnumHelper'
import { autoCommentInitValues, IAutoCommentModel } from '../../../../models/responses/AutoCommentModel'
import { IAutoCommentRequestModel } from '../../../../models/requests/AutoCommentRequestModel'
import AutoCommentApi from '../../../../infrastructure/api/AutoCommentApi'
import { AutoCommentGroupEnum } from '../../../../enums/AutoCommentGroupEnum'
import { AutoCommentPointEnum } from '../../../../enums/AutoCommentPointEnum'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const AutoCommentFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [autoComment, setAutoComment] = useState<IAutoCommentModel>(autoCommentInitValues)
  const [message, setMessage] = useState('')

  const autoCommentGroups = EnumToList(AutoCommentGroupEnum)
  const [selectedAutoCommentGroup, setSelectedAutoCommentGroup] = useState<SelectModel>(selectInitValues)

  const autoCommentPoints = EnumToList(AutoCommentPointEnum)
  const [selectedAutoCommentPoint, setSelectedAutoCommentPoint] = useState<SelectModel>(selectInitValues)

  const formik = useFormik<IAutoCommentModel>({
    initialValues: autoComment,
    enableReinitialize: true,
    validationSchema: AutoCommentFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IAutoCommentRequestModel = {
          id: values.id == '' ? undefined : values.id,
          message: message,
          group: parseInt(selectedAutoCommentGroup.value),
          point: parseInt(selectedAutoCommentPoint.value)
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IAutoCommentRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = await AutoCommentApi.AddAutoComment(model)
      else {
        result = await AutoCommentApi.UpdateAutoComment(model)
      }

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IAutoCommentModel
    if (itemId !== '') {
      var response = await AutoCommentApi.GetAutoComment(itemId)
      result = response.data
      handleChangeGroup(autoCommentGroups.find(i => i.value == result.group.toString()))
      handleChangePoint(autoCommentPoints.find(i => i.value == result.point.toString()))
    } else {

      handleChangeGroup(selectInitValues)
      handleChangePoint(selectInitValues)
      setMessage('')
      result = autoCommentInitValues
    }
    setTimeout(() => {
      setAutoComment(result)
    }, 100);
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  const handleChangeGroup = (e: any) => {
    setSelectedAutoCommentGroup(e);
  }

  const handleChangePoint = (e: any) => {
    setSelectedAutoCommentPoint(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-5 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Auto Comment</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div><div className='row mb-6'>
              <div className='col-6'>
                <label className='form-label fw-bold fs-6'>Group</label>
                <Select
                  {...formik.getFieldProps('group')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeGroup(event)}
                  value={selectedAutoCommentGroup}
                  options={autoCommentGroups}
                />
                <FormikValidationError touched={formik.touched.group} error={formik.errors.group} />
              </div>
              <div className='col-6'>
                <label className='form-label fw-bold fs-6'>Point</label>
                <Select
                  {...formik.getFieldProps('point')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangePoint(event)}
                  value={selectedAutoCommentPoint}
                  options={autoCommentPoints}
                />
                <FormikValidationError touched={formik.touched.point} error={formik.errors.point} />
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Message</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setMessage}
                data={autoComment.message}
              />
            </div>

          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { AutoCommentFormModal }
