import * as Yup from 'yup'

const ScheduleFormValidations = () => {

  return Yup.object().shape({
  
  })

}

export { ScheduleFormValidations }
