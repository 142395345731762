export interface ICampaignModel {
  id: number
  name: string
  link: string
  head: string
  about: string
  image: string
  logo: string
  campaignType: number
  startDate: string
  endDate: string
  status: boolean
  isVisible: boolean
}

const campaignInitValues: ICampaignModel = {
  id: 0,
  name: '',
  link: '',
  head: '',
  about: '',
  image: '',
  logo: '',
  campaignType: 0,
  startDate: '',
  endDate: '',
  status: false,
  isVisible: false
}

export {campaignInitValues}
