import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { UserDetailFormValidations } from '../../../../../validations/UserDetailFormValidations'
import { IUserDetailsFormModel, userDetailsInitValues as initialValues } from '../../../../../models/UserDetailsFormModel'
import UserApi from '../../../../../../infrastructure/api/UserApi'
import AsyncSelect from 'react-select/async';
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import * as account from '../../../redux/AccountRedux'
import { SelectModel, selectInitValues } from '../../../../../models/SelectModel'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { IUserClaimsRequestModel } from '../../../../../../models/requests/UserClaimsRequestModel'
import { ClaimTypeEnum } from '../../../../../../enums/ClaimTypeEnum'
import { FormikValidationError } from '../../../../../components/validations/FormikValidationError'
import { DictionaryModel } from '../../../../../../models/responses/DictionaryModel'
import { IPaginationFilter } from '../../../../../../models/requests/PaginationFilter'
import { IUserDictionaryModel } from '../../../../../../models/responses/UserDictionaryModel'
import debounce from 'es6-promise-debounce'
import { checkPermission, UserModel } from '../../../../auth/models/UserModel'
import { PermissionEnum } from '../../../../../../enums/PermissionEnum'
import { PasswordModal } from '../../PasswordModal'

const UserDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const authUser: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [data, setData] = useState<IUserDetailsFormModel>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IUserDetailsFormModel>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  const [isTestAccount, setTestAccount] = useState<boolean>(false)

  const [consultants, setConsultants] = useState<SelectModel[]>([])
  const [selectedConsultant, setSelectedConsultant] = useState<SelectModel>(selectInitValues)


  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const onClickItem = (value: string, name: string, type: string) => {
    switch (type) {
      default:
        handleShowForm()
        break
    }
  }

  const formik = useFormik<IUserDetailsFormModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        processUser(values)
      }, 500)
    },
  })

  const getConsultantDatas = async (event: string) => {
    if (event.length > 1) {
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 5,
        orderBy: "firstName_asc",
        search: event
      }

      var filters: DictionaryModel[] = [{ type: "Role", value: "Trainer" }]
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var dataConsultants: SelectModel[] = [
        ...response.data.items.map((d) => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })),
      ]
      setConsultants(dataConsultants)
      return dataConsultants;
    }
    return [];
  }


  async function processUser(values: IUserDetailsFormModel) {
    try {

      let modelClaims: IUserClaimsRequestModel = {
        id: user.id,
        claims: [
          { type: ClaimTypeEnum.IsTestAccount, value: (isTestAccount ? 1 : 0).toString() },
          { type: ClaimTypeEnum.MarketerCode, value: values.marketerCode!!.toString() },
          { type: ClaimTypeEnum.ReferenceCode, value: values.referenceCode!!.toString() },
          { type: ClaimTypeEnum.WhoReference, value: values.whoReference!!.toString() },
          { type: ClaimTypeEnum.ConsultantId, value: selectedConsultant.value },
        ]
      }

      await UserApi.UpdateUserClaims(modelClaims);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  const init = async () => {
    let consultantId = user.details?.find(i => i.type == ClaimTypeEnum.ConsultantId)?.value || '';
    if (consultantId != '') {
      var responseUsers = await UserApi.GetUserDictionaryIds([user.details?.find(i => i.type == ClaimTypeEnum.ConsultantId)?.value!!])
      var dataUsers: IUserDictionaryModel[] = responseUsers.data

      let consultant = dataUsers.find(i => i.id == consultantId);

      var selectObject: SelectModel = { value: consultantId, label: `${consultant?.firstName} ${consultant?.lastName} - ${consultant?.email}` }
      setSelectedConsultant(selectObject)
    }

    updateData({
      marketerCode: user.details?.find(i => i.type == ClaimTypeEnum.MarketerCode)?.value!! || '',
      referenceCode: user.details?.find(i => i.type == ClaimTypeEnum.ReferenceCode)?.value!! || '',
      whoReference: user.details?.find(i => i.type == ClaimTypeEnum.WhoReference)?.value!! || '',
    })

    setTestAccount(parseInt(user.details?.find(i => i.type == ClaimTypeEnum.IsTestAccount)?.value!!) == 1)
  }

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user])

  const handleChangeTestAccount = () => {
    setTestAccount(!isTestAccount)
  }


  const loadOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var data = await getConsultantDatas(inputValue);
        setConsultants(data);
        resolve(data);
      }, 1000);
    });


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>User Config</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Test Account</label>
              <div className='col-lg-5 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isTestAccount')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isTestAccount'
                    checked={isTestAccount}
                    onClick={handleChangeTestAccount}
                  />
                  <FormikValidationError touched={formik.touched.isTestAccount} error={formik.errors.isTestAccount} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Consultant User</label>
              <div className='col-lg-8 fv-row'>
                <AsyncSelect
                  cacheOptions
                  {...formik.getFieldProps('consultantId')}
                  styles={customSelectStyles(true)}
                  loadOptions={debounce(loadOptions, 500)}
                  onChange={(event) => setSelectedConsultant(event!)}
                  defaultOptions={consultants}
                  value={selectedConsultant}
                />
                <FormikValidationError touched={formik.touched.consultantId} error={formik.errors.consultantId} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Marketer Code</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('marketerCode')}
                  className='form-control form-control-lg form-control-solid'
                />
                <FormikValidationError touched={formik.touched.marketerCode} error={formik.errors.marketerCode} />

              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Reference Code</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('referenceCode')}
                  className='form-control form-control-lg form-control-solid'
                />
                <FormikValidationError touched={formik.touched.referenceCode} error={formik.errors.referenceCode} />

              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Who Reference</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('whoReference')}
                  className='form-control form-control-lg form-control-solid'
                />
                <FormikValidationError touched={formik.touched.whoReference} error={formik.errors.whoReference} />
              </div>
            </div>
            <div className='row mb-6'>
              {checkPermission(authUser, PermissionEnum.UserUpdate) &&
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add a Mission'
                >
                  <a
                    href='#'
                    onClick={() => onClickItem(user.id, '', '')}
                    className='btn btn-sm btn-danger'
                  >
                    Change Password
                  </a>
                </div>
              }
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div >

      {(checkPermission(authUser, PermissionEnum.UserUpdate)) &&
        <PasswordModal itemId={user.id} show={showForm} handleClose={handleCloseForm} />
      }
    </div >
  )
}

export { UserDetails }
