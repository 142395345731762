export interface IPresentationModel {
  id: number
  name: string
  file: string
  link: string
}

const presentationInitValues: IPresentationModel = {
  id: 0,
  name: '',
  file: '',
  link: '',
}

export {presentationInitValues}
