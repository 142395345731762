import { FC, useEffect, useState } from 'react'
import BlogCategoryApi from '../../../../infrastructure/api/BlogCategoryApi'
import { IBlogCategoryModel } from '../../../../models/responses/BlogCategoryModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { RootState } from '../../../../setup'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'

import { UserModel, checkPermission } from '../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { PermissionEnum } from '../../../../enums/PermissionEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const BlogCategoryListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [blogCategories, setBlogCategories] = useState<PagedResponseModel<IBlogCategoryModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('name_asc')
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText
    }
    async function getBlogCategories() {
      var response = await BlogCategoryApi.GetBlogCategories(paginationFilter)
      setBlogCategories(response.data)
    }
    getBlogCategories()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title="Blog Categories"
        data={blogCategories?.items}
        currentTotalRecords={blogCategories?.totalRecords!!}
        currentPageNumber={blogCategories?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: "Name", name: "name", options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              }
            }
          },
          { label: "About", name: "about" },
          {
            label: "Parent Category", name: "parentBlogCategoryId", options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <div className='d-flex align-items-center'>
                    {blogCategories?.items[dataIndex].parentBlogCategoryId === blogCategories?.items[dataIndex].id ? '-' : blogCategories?.items[dataIndex].parentBlogCategoryName}
                  </div>
                )
              }
            }
          },
          { label: "Order", name: "order" },
          {
            label: "#", name: "Id", options: {
              customHeadLabelRender: (options) => {
                return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
              },
              customBodyRenderLite: (dataIndex) => {

                let operationTypes = []
                if (checkPermission(user, PermissionEnum.BlogCategoryUpdate)) operationTypes.push(TableHelperButtonEnum.Update)
                if (checkPermission(user, PermissionEnum.BlogCategoryDelete)) operationTypes.push(TableHelperButtonEnum.Delete)
                return (
                  <TableHelperButtons
                    itemId={blogCategories?.items[dataIndex]?.id!!}
                    itemParam={blogCategories?.items[dataIndex]?.name!!}
                    types={operationTypes}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false
            },
          }
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          }
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { BlogCategoryListModal }