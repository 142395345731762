import { IJobApplicationRequestModel } from '../../models/requests/JobApplicationRequestModel'
import { IJobApplicationModel } from '../../models/responses/JobApplicationModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class JobApplicationApi {

  async GetJobApplications(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IJobApplicationModel[]>>(`e/Admin/JobApplications${generateQueryParams(filter)}`)
  }

  async GetJobApplication(id: number) {
    return await API.get<IJobApplicationModel>('e/Admin/JobApplications/' + id)
  }

  async AddJobApplication(model: IJobApplicationRequestModel) {
    return await API.post('e/Admin/JobApplications', model)
  }

  async UpdateJobApplication(model: IJobApplicationRequestModel) {
    return await API.put('e/Admin/JobApplications/' + model.id, model)
  }

  async DeleteJobApplication(itemId: number) {
    return await API.delete<boolean>('e/Admin/JobApplications/' + itemId)
  }
}

export default new JobApplicationApi()
