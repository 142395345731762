import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { useParams } from 'react-router-dom'
import { ICancelTrainerPlanModel } from '../../../../models/responses/TrainerModel'
import { CancelTrainerPlanFormValidations } from '../../../validations/CancelTrainerPlanFormValidations'

type Props = {
    itemId: string
    show: boolean
    handleClose: () => void
    handleSuccess: () => void
    plan: ICancelTrainerPlanModel
}

const CancelTrainerCallPlanFormModal: FC<Props> = ({ itemId, show, handleClose, handleSuccess, plan }) => {
    const { id } = useParams<any>()

    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [suspended, setSuspended] = useState(false)

    const formik = useFormik<ICancelTrainerPlanModel>({
        initialValues: plan,
        enableReinitialize: true,
        validationSchema: CancelTrainerPlanFormValidations,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setTimeout(async () => {
                let model = plan;
                model.startDate = values.startDate == '' ? undefined : values.startDate;
                model.endDate = values.endDate;
                model.isSuspended = suspended;
                process(model)
            }, 500)
        },
    })

    async function process(model: ICancelTrainerPlanModel) {
        try {
            var result = undefined
            result = await TrainerApi.CancelTrainerPlan(model)
            formik.resetForm()
            handleClose()
            handleSuccess()
        } catch (error: any) {
            setLoading(false)
        }
        setLoading(false)
    }

    const init = async () => {
    }

    const handleChangeSuspended = () => {
        setSuspended(!suspended);
    }


    useEffect(() => {
        formik.resetForm()
        init()
    }, [itemId, show])

    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-600px h-auto'
            show={show}
            onHide={handleClose}
        >
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Freeze Trainer Plans</h1>
                            <div className='text-muted fw-bold fs-5'>
                                Schedule Management <small>Educall</small>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <div className='col-lg'>
                                <label className='form-label fw-bolder'>Start Date</label>
                                <input
                                    {...formik.getFieldProps('startDate')}
                                    type='datetime-local' name='startDate'
                                    className='form-control form-control-solid'
                                    placeholder='Entry..'
                                />
                                <FormikValidationError touched={formik.touched.startDate} error={formik.errors.startDate} />
                            </div>
                            <div className='col-lg'>
                                <label className='form-label fw-bolder'>End Date</label>
                                <input
                                    {...formik.getFieldProps('endDate')}
                                    type='datetime-local' name='endDate'
                                    className='form-control form-control-solid'
                                    placeholder='Entry..'
                                />
                                <FormikValidationError touched={formik.touched.endDate} error={formik.errors.endDate} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mt-10'>

                                <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                                    <input
                                        {...formik.getFieldProps('isSuspended')}
                                        className='form-check-input h-30px w-50px'
                                        type='checkbox'
                                        name='isSuspended'
                                        checked={suspended}
                                        onClick={handleChangeSuspended}
                                    />
                                    <label className='form-check-label'>Suspended Plans</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <div className='d-flex flex-center '>
                            <SaveChangesButton
                                loading={loading}
                                setLoading={setLoading}
                                complete={complete}
                                setComplete={setComplete}
                                valid={formik.isValid}
                                submit={formik.isSubmitting}
                                setSubmit={formik.submitForm}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </Modal >
    )
}

export { CancelTrainerCallPlanFormModal }
