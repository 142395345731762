import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { ShopOrderFormModal } from './_modals/ShopOrderFormModal'
import { ShopOrderListModal } from './_modals/ShopOrderListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import ShopOrderApi from '../../../infrastructure/api/ShopOrderApi'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'

const ShopOrderPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deleteShopOrder(itemId: number) {
    var response = await ShopOrderApi.DeleteShopOrder(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
        </div>
        <div className='card-body py-3'>
          <ShopOrderListModal refresh={refresh} onClickItem={onClickItem} />
          <ShopOrderFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Shop Order'
            confirm={deleteShopOrder}
          />
        </div>
      </div>
    </>
  )
}

const ShopOrderWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Edushop</PageTitle>
      <ShopOrderPage />
    </>
  )
}

export { ShopOrderWrapper }
