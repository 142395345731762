import { FC, useEffect, useState } from 'react'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { ITrainerCallReportModel } from '../../../../models/responses/TrainerCallReportModel'
import TrainerCallReportApi from '../../../../infrastructure/api/TrainerCallReportApi'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { SelectModel } from '../../../models/SelectModel'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
    trainerList: SelectModel[]
}

const TrainerCallReportListModal: FC<Props> = ({ onClickItem, refresh, trainerList }) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('createdAt_desc')
    const [trainerCallReports, setTrainerCallReports] = useState<PagedResponseModel<ITrainerCallReportModel[]>>()

    async function init() {
        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText
        }

        var response = await TrainerCallReportApi.GetTrainerCallReports(paginationFilter)
        setTrainerCallReports(response.data)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort])

    return (
        <>
            <div className='row'>
                <GenericTable
                    title='Trainer Call Reports'
                    data={trainerCallReports?.items}
                    currentTotalRecords={trainerCallReports?.totalRecords!!}
                    currentPageNumber={trainerCallReports?.pageNumber!!}
                    columns={[
                        {
                            label: '#',
                            name: '#',
                            options: {
                                customBodyRenderLite: (dataIndex) => {
                                    return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                                },
                                sort: false,
                                viewColumns: false
                            },
                        },
                        {
                            label: "Date", name: "createdAt", options: {
                                customBodyRender: (value) => {
                                    return <>{value}</>
                                }
                            }
                        },
                        {
                            label: "Start Date", name: "startDate", options: {
                                customBodyRender: (value) => {
                                    return <>{value}</>
                                }
                            }
                        },
                        {
                            label: "End Date", name: "endDate", options: {
                                customBodyRender: (value) => {
                                    return <>{value}</>
                                }
                            }
                        },
                        {
                            label: "Day", name: "dayOfWeek", options: {
                                customBodyRender: (value) => {
                                    return <>{DayOfWeekEnum[value]}</>
                                }
                            }
                        },
                        {
                            label: "Trainer", name: "trainerId", options: {
                                sort: false,
                                customBodyRender: (value) => {
                                    var trainer = trainerList.find(i => i.value == value)
                                    return value != null ? <span>{trainer?.label}</span> : <b>All</b>
                                },
                                filter: false
                            }
                        },
                        {
                            label: "Status", name: "statusInfo", options: {
                                customBodyRenderLite: (dataIndex) => {
                                    switch (trainerCallReports?.items[dataIndex].status) {
                                        case 1:
                                            return <><span className='text-primary'><span className='spinner-border spinner-border-sm align-middle ms-2'></span>Preparing</span></>
                                            break;
                                        case 2:
                                            return <><span className='text-success'>Completed</span></>
                                            break;
                                        case 3:
                                            return <><span className='text-warning'>Cancelled</span></>
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        },
                        {
                            label: "Completed Date", name: "modifiedAt", options: {
                                customBodyRenderLite: (dataIndex) => {
                                    return <>{trainerCallReports?.items[dataIndex].status == 1 ? "" : trainerCallReports?.items[dataIndex].modifiedAt}</>
                                }
                            }
                        },
                        {
                            label: '#',
                            name: 'Id',
                            options: {
                                customHeadLabelRender: (options) => {
                                    return (
                                        <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                    )
                                },
                                customBodyRenderLite: (dataIndex) => {
                                    return (
                                        <TableHelperButtons
                                            itemId={trainerCallReports?.items[dataIndex]?.id!!}
                                            itemParam={trainerCallReports?.items[dataIndex]?.createdAt}
                                            types={[TableHelperButtonEnum.View]}
                                            onClickItem={onClickItem}
                                        />
                                    )
                                },
                                filter: false,
                                sort: false,
                                viewColumns: false,
                            },
                        },
                    ]}
                    customOptions={{
                        search: false,
                        sort: false,
                        customTableBodyFooterRender: (opts) => {
                            return <div></div>
                        },
                    }}
                    setPageNumber={setPageNumber}
                    setPageSize={setPageSize}
                    setSearchText={setSearchText}
                    setSort={setSort}

                />
            </div>
        </>
    )
}

export { TrainerCallReportListModal }
