import * as Yup from 'yup'


const SendNotificationFormValidations = () => {
    return Yup.object().shape({

    })
}

export { SendNotificationFormValidations }
