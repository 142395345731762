import { FC, useEffect, useState } from 'react'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import LevelGroupApi from '../../../../infrastructure/api/LevelGroupApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { ILevelModel, levelInitValues } from '../../../../models/responses/LevelModel'
import { ILevelRequestModel } from '../../../../models/requests/LevelRequestModel'
import { LevelFormValidations } from '../../../validations/LevelFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const LevelFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [level, setLevel] = useState<ILevelModel>(levelInitValues)
  const updateLevel = (fieldsToUpdate: Partial<ILevelModel>) => {
    const updatedData = { ...level, ...fieldsToUpdate }
    setLevel(updatedData)
  }
  const defaultSelectLevelGroup = {
    value: "0", label: "--SELECT GROUP--"
  };
  const [levelGroups, setLevelGroups] = useState<SelectModel[]>([])
  const [selectedLevelGroup, setSelectedLevelGroup] = useState<SelectModel>(defaultSelectLevelGroup)

  const formik = useFormik<ILevelModel>({
    initialValues: level,
    enableReinitialize: true,
    validationSchema: LevelFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ILevelRequestModel = {
          id: values.id,
          name: values.name,
          difficulty: values.difficulty,
          levelGroupId: parseInt(selectedLevelGroup.value),
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: ILevelRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await LevelApi.AddLevel(model)
      else {
        result = await LevelApi.UpdateLevel(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    var responseLevelGroups = await LevelGroupApi.GetLevelGroups({ page: 1, size: 999, orderBy: 'title_asc', search: '' })
    var dataLevelGroups: SelectModel[] = [...responseLevelGroups.data.items.map(d => ({
      value: d.id.toString(),
      label: d.title
    }))]
    setLevelGroups(dataLevelGroups)

    let result: ILevelModel
    if (itemId >= 0) {
      var response = await LevelApi.GetLevel(itemId)
      result = response.data
      handleChangeLevelGroup(response.data.levelGroupId ? dataLevelGroups.find(i => i.value == response.data.levelGroupId.toString()) : defaultSelectLevelGroup)
    } else {
      result = levelInitValues
    }
    setLevel(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeLevelGroup = (e: any) => {
    setSelectedLevelGroup(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Level</h1>
              <div className='text-muted fw-bold fs-5'>
                Level Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Name</label>
              <input
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Level Group</label>
              <Select
                {...formik.getFieldProps('levelGroupId')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeLevelGroup(event)}
                value={selectedLevelGroup}
                options={levelGroups}
              />
              <FormikValidationError touched={formik.touched.levelGroupId} error={formik.errors.levelGroupId} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Difficulty</label>
              <input
                {...formik.getFieldProps('difficulty')}
                type='number'
                name='difficulty'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.difficulty} error={formik.errors.difficulty} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { LevelFormModal }
