import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { IHelpDesksModel } from '../../../../models/responses/HelpDeskModel'
import HelpDeskApi from '../../../../infrastructure/api/HelpDeskApi'
import { HelpDeskStatusEnum } from '../../../../enums/HelpDeskStatusEnum'
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const HelpDeskListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [helpDesks, setHelpDesks] = useState<PagedResponseModel<IHelpDesksModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getConfigs() {
      var response = await HelpDeskApi.GetHelpDesks(paginationFilter)
      setHelpDesks(response.data)
    }
    getConfigs()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Help Desks'
        data={helpDesks?.items}
        currentTotalRecords={helpDesks?.totalRecords!!}
        currentPageNumber={helpDesks?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: 'Student',
            name: '',
            options: {
              customBodyRenderLite(dataIndex, rowIndex) {
                var values = helpDesks?.items[dataIndex];
                return (<ButtonToolbar>
                  <OverlayTrigger
                    key={dataIndex}
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        <ul>
                          <li>{values!.email}</li>
                          <li>{values!.phoneNumber}</li>
                        </ul>
                      </Tooltip>
                    }
                  >
                    <Link className='btn-sm' to={'/account/users/' + values?.createdBy + '/overview'}>{values?.firstName} {values?.lastName}</Link>
                  </OverlayTrigger>
                </ButtonToolbar>)
              },
            }

          },
          {
            label: 'Subject',
            name: 'subject',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Result', name: 'completedUserId',
            options: {


              customBodyRenderLite: (dataIndex) => {
                var values = helpDesks?.items[dataIndex];
                return <div className='d-flex align-items-center'>{values?.result != undefined && values?.result != '' ? <><Link className='btn-sm' to={'/account/users/' + values?.completedUserId + '/overview'}><i className='fa fa-user'></i></Link>&nbsp;{values?.result}</> : ''}</div>
              },
            }
          },
          {
            label: 'Status', name: 'status', options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>
                  <span className={`badge badge-${value == 2 ? 'success' : 'secondary'}`}>
                  {HelpDeskStatusEnum[value]}
                  </span>
                  </div>
              },
            },
          },
          {
            label: 'File', name: 'file', options: {
              customBodyRenderLite: (dataIndex) => {
                var file = helpDesks?.items[dataIndex].file;
                return (
                  file != undefined ?
                    <a href={process.env.REACT_APP_CDN_URL + 'files/helpdesk/' + file!!} target="_blank" download><i className="fas fa-download"></i></a>
                    : <></>
                )
              },
              sort: false,
            }
          },
          { label: 'Date', name: 'createdAt' },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={helpDesks?.items[dataIndex]?.id!!}
                    itemParam={helpDesks?.items[dataIndex]?.subject!!}
                    types={[TableHelperButtonEnum.Update]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { HelpDeskListModal }
