import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { StudentPlanFormValidations } from '../../../validations/StudentPlanFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { useParams } from 'react-router-dom'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { IUserModel } from '../../../../models/responses/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { IJoinLeaveSchedulePlanRequestModel, joinLeaveSchedulePlanInitValues } from '../../../../models/requests/JoinLeaveSchedulePlanRequestModel'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const StudentGroupCallPlanFormModal: FC<Props> = ({ show, handleClose, handleSuccess }) => {
  const { id } = useParams<any>()

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [plans, setPlans] = useState<SelectModel[]>([])
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };

  const [selectedPlan, setSelectedPlan] = useState<SelectModel>(defaultSelect)

  const formik = useFormik<IJoinLeaveSchedulePlanRequestModel>({
    initialValues: joinLeaveSchedulePlanInitValues,
    enableReinitialize: true,
    validationSchema: StudentPlanFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IJoinLeaveSchedulePlanRequestModel = {
          studentId: id,
          schedulePlanId: selectedPlan.value
        }
        process(model)
      }, 500)
    },
  })


  async function getGroupPlans() {

    const paginationFilter: IPaginationFilter = {
      page: 1,
      size: 100,
      orderBy: "dayOfWeek_asc",
      search: "",
    }

    var response = await TrainerApi.GetTrainerSchedulePlans(paginationFilter, { type: ScheduleTypeEnum.Group, levelId: user.presentLevelId })


    var dataTrainers: SelectModel[] = [...response.data.items.map(d => ({
      value: d.id!!.toString(),
      label: `Empty: ${d.empty} / ${d.title ?? "unnamed"} / ${DayOfWeekEnum[d.dayOfWeek]} - ${d.startDate} / ${d.trainerFullName} /  ${d.duration} mins`
    }))]
    setPlans(dataTrainers)

  }

  async function process(model: IJoinLeaveSchedulePlanRequestModel) {
    try {
      var result = undefined
      result = await TrainerApi.JoinLeaveSchedulePlan(model)
      formik.resetForm()
      handleClose()
      handleSuccess()
    } catch (error: any) {

      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    getGroupPlans();
    formik.resetForm()
  }, [show])

  const handleChangePlan = (e: any) => {
    setSelectedPlan(e);
  }
  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add To Group Plan</h1>
              <div className='text-muted fw-bold fs-5'>
                Schedule Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col'>
                <label className='form-label fw-bolder'>Group Plans</label>
                <Select
                  {...formik.getFieldProps('trainer')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangePlan(event)}
                  value={selectedPlan}
                  options={plans}
                />
                <FormikValidationError touched={formik.touched.schedulePlanId} error={formik.errors.schedulePlanId} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { StudentGroupCallPlanFormModal }
