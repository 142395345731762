export interface IAnnouncementModel {
    id: string,
    createdBy: string,
    title: string,
    message: string,
    isPinned: boolean,
    sendTime: string,
    endTime: string,
    roleId?: string,
    readCount: number,
    sendedCount: number,
    users: IAnnouncementStatusDto[]
}

export interface IAnnouncementStatusDto {
    userId: string,
    isRead: boolean,
    readTime: string
}

const announcementInitValues: IAnnouncementModel = {
    id: '',
    createdBy: '',
    title: '',
    message: '',
    isPinned: false,
    sendTime: '',
    endTime: '',
    roleId: '',
    readCount: 0,
    sendedCount: 0,
    users: []
}

export { announcementInitValues }