import { FC, useEffect, useState } from 'react'
import CampaignApi from '../../../../infrastructure/api/CampaignApi'
import { ICampaignModel } from '../../../../models/responses/CampaignModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { Tooltip } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { CampaignTypeEnum } from '../../../../enums/CampaignTypeEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const CampaignListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [campaigns, setCampaigns] = useState<PagedResponseModel<ICampaignModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('endDate_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getCampaigns() {
      var response = await CampaignApi.GetCampaigns(paginationFilter)
      setCampaigns(response.data)
    }
    getCampaigns()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Campaigns'
        data={campaigns?.items}
        currentTotalRecords={campaigns?.totalRecords!!}
        currentPageNumber={campaigns?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: 'Image',
            name: 'image',
            options: {
              customBodyRender: (value) => {
                return (
                  <img src={`${process.env.REACT_APP_CDN_URL}images/campaign/${value}`} width={80} />
                )
              },
              sort: false
            },
          },
          {
            label: 'Name',
            name: 'name',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Type',
            name: 'campaignType',
            options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{CampaignTypeEnum[value]}</div>
              },
            },
          },
          { label: 'Start Date', name: 'startDate' },
          { label: 'End Date', name: 'endDate' },
          {
            label: 'Status',
            name: 'status',
            options: {
              customBodyRender: (value) => {
                if (value === true)
                  return (
                    <Tooltip title='Avaible'>
                      <CheckCircle htmlColor="#4caf50" />
                    </Tooltip>
                  )
                else
                  return (
                    <Tooltip title='Time Expired'>
                      <Cancel color='error' />
                    </Tooltip>
                  )
              },
              sort: false
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={campaigns?.items[dataIndex]?.id!!}
                    itemParam={campaigns?.items[dataIndex]?.name!!}
                    types={[TableHelperButtonEnum.Delete, TableHelperButtonEnum.Update]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { CampaignListModal }
