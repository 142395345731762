import * as Yup from 'yup'

const BlogPostFormValidations = () => {
  return Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(180, 'Maximum 180 symbols')
      .required('Name is required'),
    description: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(180, 'Maximum 180 symbols')
      .required('Description is required'),
  })
}

export { BlogPostFormValidations }
