export interface ILevelModel {
  id: number
  name: string
  difficulty: number
  levelGroupId: number
  levelGroupTitle: string
}

const levelInitValues: ILevelModel = {
  id: 0,
  name: '',
  difficulty: 0,
  levelGroupId: 0,
  levelGroupTitle: '',
}

export {levelInitValues}
