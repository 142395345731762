export interface IPopupModel {
  id: number
  title: string
  message: string
  url: string
  image: string
  popupType: number
  popupWhose: number
  popupWhere: number
  popupWhenType: number
  startDate: string
  endDate: string
}

const popupInitValues: IPopupModel = {
  id: 0,
  title: '',
  message: '',
  url: '',
  image: '',
  popupType: 0,
  popupWhose: 0,
  popupWhere: 0,
  popupWhenType: 0,
  startDate: '',
  endDate: '',
}

export {popupInitValues}
