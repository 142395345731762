export interface IPaymentModel {
  id: number
  code: string
  amount: number
  name: string
  surname: string
  tolkCount: number
  dayCount: number
  monthCount: number
  talkingDuration: number
  response: string
  installmentCount: number
  couponId: number
  couponCode: string
  salePercentage: number
  sellerId?: string
  studentId?: string
  date: string
  paymentType: number
  paymentMethod: number
}

const paymentInitValues: IPaymentModel = {
  id: 0,
  code: '',
  amount: 0,
  name: '',
  surname: '',
  tolkCount: 0,
  dayCount: 0,
  monthCount: 0,
  talkingDuration: 0,
  response: '',
  installmentCount: 0,
  couponId: 0,
  couponCode: '',
  salePercentage: 0,
  sellerId: undefined,
  studentId: undefined,
  date: '',
  paymentMethod: 0,
  paymentType: 0
}

export { paymentInitValues }
