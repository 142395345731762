import React, { useEffect, useState } from 'react'
import { IInvoiceDetailsFormModel, invoiceDetailsInitValues as initialValues } from '../../../../../models/InvoiceDetailsFormModel'
import { useFormik } from 'formik'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import { SelectModel, selectInitValues } from '../../../../../models/SelectModel'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { InvoiceDetailFormValidations } from '../../../../../validations/InvoiceDetailFormValidations'
import UserApi from '../../../../../../infrastructure/api/UserApi'
import { IUserClaimsRequestModel, userClaimsInitValues } from '../../../../../../models/requests/UserClaimsRequestModel'
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import * as account from './../../../redux/AccountRedux'
import { useRegionHelper } from '../../../../../helpers/RegionHelper'

const InvoiceDetails: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const [countries, setCountries] = useState<SelectModel[]>([])
  const [cities, setCities] = useState<SelectModel[]>([])
  const [states, setStates] = useState<SelectModel[]>([])

  const [selectedCountry, setSelectedCountry] = useState<SelectModel>(selectInitValues)
  const [selectedCity, setSelectedCity] = useState<SelectModel>(selectInitValues)
  const [selectedState, setSelectedState] = useState<SelectModel>(selectInitValues)

  const regionHelper = useRegionHelper(user.details!!, "Invoice", setCountries, setCities, setStates, setSelectedCountry, setSelectedCity, setSelectedState)

  const formik = useFormik<IInvoiceDetailsFormModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: InvoiceDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        values.invoiceCountryId = parseInt(selectedCountry?.value!!)
        values.invoiceCityId = parseInt(selectedCity?.value!!)
        values.invoiceStateId = parseInt(selectedState?.value!!)
        userClaimsInitValues.claims = []
        let model = userClaimsInitValues
        model.id = user.id
        let claims: any = values
        Object.keys(values).map((item) => {
          model.claims?.push({ type: item.charAt(0).toUpperCase() + item.substring(1), value: claims[item].toString() });
        })

        processRole(model)
      }, 500)
    },
  })
  async function processRole(model: IUserClaimsRequestModel) {
    try {
      await UserApi.UpdateUserClaims(model);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user) {
      regionHelper.getCountries();
      initialValues.invoiceAddress = user.details?.find(i => i.type == "InvoiceAddress")?.value!! || ''
      initialValues.invoiceTitle = user.details?.find(i => i.type == "InvoiceTitle")?.value!! || ''
      initialValues.invoiceZipcode = user.details?.find(i => i.type == "InvoiceZipcode")?.value!! || ''
      initialValues.taxDepartment = user.details?.find(i => i.type == "TaxDepartment")?.value!! || ''
      initialValues.taxNumber = user.details?.find(i => i.type == "TaxNumber")?.value!! || ''
      initialValues.landPhone = user.details?.find(i => i.type == "LandPhone")?.value!! || ''
    }
  }, [user?.details])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_invoice_details'
        aria-expanded='true'
        aria-controls='kt_account_invoice_details'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Invoice Details</h3>
        </div>
      </div>
      <div id='kt_account_invoice_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Invoice Title</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('invoiceTitle')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.invoiceTitle && formik.errors.invoiceTitle && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceTitle}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Invoice Address</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('invoiceAddress')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.invoiceAddress && formik.errors.invoiceAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceAddress}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Invoice Country</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('invoiceCountryId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => regionHelper.handleChangeCountry(event)}
                  value={selectedCountry}
                  options={countries}
                />
                {formik.touched.invoiceCountryId && formik.errors.invoiceCountryId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceCountryId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Invoice City</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('invoiceCityId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => regionHelper.handleChangeCity(event)}
                  value={selectedCity}
                  options={cities}
                />
                {formik.touched.invoiceCityId && formik.errors.invoiceCityId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceCityId}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Invoice State</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('state')}
                  styles={customSelectStyles(true)}
                  value={selectedState}
                  onChange={(event) => regionHelper.handleChangeState(event)}
                  options={states}
                />
                {formik.touched.invoiceStateId && formik.errors.invoiceStateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceStateId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-6'>
                <label className='col-form-label fw-bold fs-6'>Invoice Zip Code</label>
                <input
                  {...formik.getFieldProps('invoiceZipcode')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.invoiceZipcode && formik.errors.invoiceZipcode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceZipcode}</div>
                  </div>
                )}
              </div>
              <div className='col-6'>
                <label className='col-form-label fw-bold fs-6'>Land Phone</label>
                <input
                  {...formik.getFieldProps('landPhone')}
                  className='form-control form-control-lg form-control-solid'
                />
                {formik.touched.landPhone && formik.errors.landPhone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.landPhone}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <div className='col-6'>
                <label className='col-form-label fw-bold fs-6'>Tax Department</label>
                <input
                  {...formik.getFieldProps('taxDepartment')}
                  className='form-control form-control-solid'
                />
                {formik.touched.invoiceZipcode && formik.errors.invoiceZipcode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.invoiceZipcode}</div>
                  </div>
                )}
              </div>
              <div className='col-6'>
                <label className='col-form-label fw-bold fs-6'>Tax Number</label>
                <input
                  {...formik.getFieldProps('taxNumber')}
                  className='form-control form-control-solid'
                />
                {formik.touched.taxNumber && formik.errors.taxNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.taxNumber}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div>
    </div>
  )
}

export { InvoiceDetails }