import { FC, useEffect, useState } from 'react'
import { UserModel } from '../../auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { BasicTable } from '../../../components/tables/BasicTable'
import moment from 'moment'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import HubLogApi from '../../../../infrastructure/api/HubLogApi'
import { IGetHubLogsRequestModel } from '../../../../models/requests/GetHubLogsRequestModel'
import { IHubLogModel } from '../../../../models/responses/HubLogModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const TrainerActivities: FC<Props> = ({ onClickItem, refresh }) => {
  const user: UserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as UserModel

  const [activities, setActivities] = useState<IHubLogModel[]>()


  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
    getActivities(startDate, endDate)
  }

  let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 3 Days': [moment().subtract(3, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false,
  }


  async function getActivities(start: moment.Moment, end: moment.Moment) {


    var model: IGetHubLogsRequestModel = {
      startDate: start.format("YYYY-MM-DD H:m:s"),
      endDate: end.format("YYYY-MM-DD H:m:s"),
      userId: user.id
    }

    var response = await HubLogApi.GetAvtivities(model)
    setActivities(response.data)

  }

  useEffect(() => {
    getActivities(startTime, endTime)
  }, [refresh])


  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-3 mt-6'>
          <div className='col-lg-3'>
            <label className='form-label fw-bolder'>Date Range</label>
            <DateTimeRangeContainer
              ranges={ranges}
              start={start}
              style={{
                darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
              }}
              end={end}
              local={local}
              applyCallback={applyCallback}
            >
              <input
                id="formControlsTextB"
                className='form-control form-control-solid'
                value={`${start.format(local.format)} - ${end.format(local.format)}`}
                type="text"
              />
            </DateTimeRangeContainer>
          </div>
          <BasicTable
            title='Activities'
            data={activities}
            columns={[
              {
                label: '#',
                name: '#',
                options: {
                  customBodyRenderLite: (dataIndex) => {
                    return <>{dataIndex + 1}</>
                  },
                  sort: false,
                  viewColumns: false,
                }
              },
              {
                label: 'Start Date',
                name: 'startDate'
              },
              {
                label: 'End Date',
                name: 'endDate'
              },
              {
                label: 'Minutes',
                name: 'totalMinutes'
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export { TrainerActivities }
