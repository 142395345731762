import { DictionaryModel } from '../responses/DictionaryModel'

export interface IUserPermissionsRequestModel {
  id: string
  permissions?: Array<DictionaryModel>
}

const userPermissionsRequestModelInitValues: IUserPermissionsRequestModel = {
  id: '',
  permissions: []
}

export { userPermissionsRequestModelInitValues }