import { FC, useEffect, useState } from 'react'
import TrainerApi from '../../../../infrastructure/api/TrainerApi'
import { ITrainersModel } from '../../../../models/responses/TrainerModel'
import { BasicTable } from '../../../components/tables/BasicTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import Select from 'react-select'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { KTSVG } from '../../../../_metronic/helpers'
import { EnumToList } from '../../../helpers/EnumHelper'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const TrainerListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [intervalRefresh, setIntervalRefresh] = useState<NodeJS.Timeout>();

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('id_desc')
    var times: SelectModel[] = [{ value: "0", label: "None" }, { value: "10", label: "10 Seconds" }, { value: "30", label: "30 Seconds" }, { value: "60", label: "60 Seconds" }]
    const d = new Date();
    let weekDay = d.getUTCDay()
    var now = `${d.getUTCHours().toString().padStart(2, "0")}:${d.getUTCMinutes().toString().padStart(2, "0")}:${d.getUTCSeconds().toString().padStart(2, "0")}`
    const [trainers, setTrainers] = useState<ITrainersModel[]>([])
    const [selectedAutoRefreshTime, setSelectedAutoRefreshTime] = useState<SelectModel>(times[0])
    const dayList = EnumToList(DayOfWeekEnum);
    const [selectedDay, setSelectedDay] = useState<SelectModel>(dayList.find(i => i.value == weekDay.toString()) || selectInitValues)

    async function init() {
        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
        }

        var response = await TrainerApi.GetTrainerPlans(paginationFilter,
            {
                dayOfWeek: parseInt(selectedDay.value),
            })
        setTrainers(response.data.items)
    }

    const autoRefresing = (timer: number) => {
        init()
        clearInterval(intervalRefresh);
        if (timer > 0) {
            setIntervalRefresh(setInterval(init, timer * 1000));
        }
    }

    const handleChangeAutoRefreshTime = (e: any) => {
        setSelectedAutoRefreshTime(e);
        autoRefresing(parseInt(e.value))
    }

    const handleChangeDay = (e: any) => {
        setSelectedDay(e);
    }

    useEffect(() => {
        init()
    }, [refresh])

    return (
        <div>

            <div className='row'>
                <div className='col-2'>
                    <label className='form-label fw-bolder'>Day of Week</label>
                    <Select
                        onChange={(event) => handleChangeDay(event)}
                        value={selectedDay}
                        options={dayList}
                    />
                </div>
                <div className='col'></div>
                <div className='col-2 align-items-end flex-column'>
                    <label className='form-label fw-bolder'>Auto Refresh</label>
                    <Select
                        onChange={(event) => handleChangeAutoRefreshTime(event)}
                        value={selectedAutoRefreshTime}
                        options={times}
                    />
                </div>
            </div>
            <BasicTable
                title='Trainer Works'
                data={trainers}
                columns={[
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{dataIndex + 1}</div>
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: "FirstName", name: "firstName", options: {
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "LastName", name: "lastName", options: {
                            customBodyRender: (value) => {
                                return <b>{value}</b>
                            }
                        }
                    },
                    {
                        label: "First Start", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{trainers[dataIndex].trainerPlans.find(s => s.dayOfWeek == parseInt(selectedDay.value))?.startDate}</>
                            }
                        }
                    },
                    {
                        label: "Last Finish", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{trainers[dataIndex].trainerPlans.reverse().find(s => s.dayOfWeek == parseInt(selectedDay.value))?.endDate}</>
                            }
                        }
                    },
                    {
                        label: "Availables", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{trainers[dataIndex].availablePlans.reverse().filter(s => s.dayOfWeek == parseInt(selectedDay.value)).map((item, index) => {
                                    return <span key={index} className='badge badge-light-primary'>{item.startDate}/{item.duration}mins</span>
                                })}</>
                            }
                        }
                    },
                    {
                        label: "Working Hour", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <div className='ps-5 fw-bolder'>
                                    {!trainers[dataIndex].trainerPlans.some(s => s.dayOfWeek == weekDay && s.startDate <= now && now <= s.endDate) ? <KTSVG className='svg-icon-1 svg-icon-light' path='/media/icons/duotone/Design/Circle.svg' /> : <KTSVG className='svg-icon-1 svg-icon-success' path='/media/icons/duotone/Design/Circle.svg' />}
                                </div>
                            }
                        }
                    },
                    {
                        label: "Is Connected", name: "#", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <div className='ps-5 fw-bolder'>
                                    {!trainers[dataIndex].isConnected ? <KTSVG className='svg-icon-1 svg-icon-danger' path='/media/icons/duotone/Design/Circle.svg' /> : <KTSVG className='svg-icon-1 svg-icon-success' path='/media/icons/duotone/Design/Circle.svg' />}
                                </div>
                            }
                        }
                    },
                    {
                        label: "Today Hours", name: "todayMinutes", options: {
                            customBodyRender: (value) => {
                                return <>{(value / 60).toFixed(1)} Hours</>
                            }
                        }
                    },
                    {
                        label: "Weekly Hours", name: "weekMinutes", options: {
                            customBodyRender: (value) => {
                                return <>{(value / 60).toFixed(1)} Hours</>
                            }
                        }
                    },
                    {
                        label: "Level Number", name: "responsibleLevelCount", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Consultant", name: "studentConsultancyCount", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Is Frozen", name: "isFreezeAccount", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <div className='ps-5 fw-bolder text-danger'>
                                    {trainers[dataIndex].isFreezeAccount ? 'Frozen' : null}
                                </div>
                            }
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={trainers[dataIndex]?.id!!}
                                        itemParam={trainers[dataIndex]?.id!!}
                                        links={[
                                            { type: 'edit', value: '/account/users/' + trainers[dataIndex]?.id + '/call-plans' }
                                        ]}
                                        types={[]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    filter: false,
                    viewColumns: false,
                    serverSide: false,
                    customTableBodyFooterRender: (opts) => {
                        return <div></div>
                    },
                }}
            />
        </div>

    )
}

export { TrainerListModal }
