import { FC, useEffect, useState } from 'react'
import AnnouncementApi from '../../../../infrastructure/api/AnnouncementApi'
import { IAnnouncementModel } from '../../../../models/responses/AnnouncementModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { SelectModel } from '../../../models/SelectModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const AnnouncementListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [announcements, setAnnouncements] = useState<PagedResponseModel<IAnnouncementModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [roles, setRoles] = useState<SelectModel[]>([])

  async function init() {

    var responseRoles = await RoleApi.GetRoleDictionary()
    var dataRoles: SelectModel[] = [...responseRoles.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setRoles(dataRoles)

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    var response = await AnnouncementApi.GetAnnouncements(paginationFilter)
    setAnnouncements(response.data)
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Announcements'
        data={announcements?.items}
        currentTotalRecords={announcements?.totalRecords!!}
        currentPageNumber={announcements?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Title',
            name: 'title',
          },
          {
            label: 'Pinned',
            name: 'isPinned',
            options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{value ? <KTSVG path='/media/icons/duotone/Communication/Flag.svg' className='svg-icon-3' /> : ''}</div>
              },
            },
          },
          {
            label: 'Role',
            name: 'roleId',
            options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{roles.find(i => i.value == value)?.label}</div>
              },
            },
          },
          {
            label: 'Read',
            name: 'status',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return (<>
                  {announcements?.items[dataIndex].sendedCount!! > 0 && <div className='d-flex align-items-center'>
                    <span className="badge badge-light-dark">
                      {announcements?.items[dataIndex].readCount}/{announcements?.items[dataIndex].sendedCount}
                    </span>
                  </div>}
                </>)
              },
            },
          },
          {
            label: 'Date',
            name: 'sendTime'
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={announcements?.items[dataIndex]?.id!!}
                    itemParam={announcements?.items[dataIndex]?.title!!}
                    types={[TableHelperButtonEnum.View, TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { AnnouncementListModal }
