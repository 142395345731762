import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import * as account from '../../app/modules/accounts'
import * as blogCategory from '../../app/pages/blog-categories'
import * as level from '../../app/pages/levels'
import * as exam from '../../app/pages/exams'
import * as unit from '../../app/pages/units'
import * as task from '../../app/pages/tasks'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  account: account.reducer,
  blogCategory: blogCategory.reducer,
  level: level.reducer,
  exam: exam.reducer,
  unit: unit.reducer,
  task: task.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
