import { SelectModel } from '../app/models/SelectModel'

export const durationList: SelectModel[] = [
    { value: "10", label: "10 Minutes" },
    { value: "15", label: "15 Minutes" },
    { value: "25", label: "25 Minutes" }
]

export const dayList: SelectModel[] = [
    { value: "1", label: "1 Day" },
    { value: "2", label: "2 Days" },
    { value: "3", label: "3 Days" },
    { value: "4", label: "4 Days" },
    { value: "5", label: "5 Days" },
    { value: "6", label: "6 Days" }
]

export const monthList: SelectModel[] = [
    { value: "1", label: "1 Month" },
    { value: "2", label: "2 Months" },
    { value: "3", label: "3 Months" },
    { value: "4", label: "4 Months" },
    { value: "5", label: "5 Months" },
    { value: "6", label: "6 Months" },
    { value: "7", label: "7 Months" },
    { value: "8", label: "8 Months" },
    { value: "9", label: "9 Months" },
    { value: "10", label: "10 Months" },
    { value: "11", label: "11 Months" },
    { value: "12", label: "12 Months" }
]
