
export interface IJoinLeaveSchedulePlanRequestModel {
    schedulePlanId: string,
    studentId: string
}

const joinLeaveSchedulePlanInitValues = {
    studentId: '',
    schedulePlanId: '',
}

export { joinLeaveSchedulePlanInitValues }
