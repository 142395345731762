import { FC, useEffect, useState } from 'react'
import JobApplicationApi from '../../../../infrastructure/api/JobApplicationApi'
import { IJobApplicationModel } from '../../../../models/responses/JobApplicationModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { Link } from 'react-router-dom'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const JobApplicationListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [jobApplications, setJobApplications] =
    useState<PagedResponseModel<IJobApplicationModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getJobApplications() {
      var response = await JobApplicationApi.GetJobApplications(paginationFilter)
      setJobApplications(response.data)
    }
    getJobApplications()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Job Applications'
        data={jobApplications?.items}
        currentTotalRecords={jobApplications?.totalRecords!!}
        currentPageNumber={jobApplications?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: 'User',
            name: 'name',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <div className='d-flex align-items-center fw-bolder'>
                    {jobApplications?.items[dataIndex].name + ' ' + jobApplications?.items[dataIndex].surname}
                  </div>
                )
              },
            },
          },
          { label: 'Email', name: 'email' },
          { label: 'Phone', name: 'phone' },
          { label: 'Birth Date', name: 'birthdate' },
          { label: 'Address', name: 'address' },
          { label: 'About', name: 'content' },
          {
            label: 'File', name: 'cv', options: {
              customBodyRenderLite: (dataIndex) => {
                return (
                  <a href={process.env.REACT_APP_CDN_URL + 'files/jobapplication/' + jobApplications?.items[dataIndex].cv!!} target="_blank" download><i className="fas fa-download"></i></a>
                )
              },
              sort: false,
            }
          },
          { label: 'Date', name: 'date' },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={jobApplications?.items[dataIndex]?.id!!}
                    itemParam={jobApplications?.items[dataIndex]?.name!!}
                    types={[TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { JobApplicationListModal }
