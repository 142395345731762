import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { SendNotificationFormValidations } from '../../../validations/SendNotificationFormValidations'
import { useFormik } from 'formik'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import Select from 'react-select'
import { ISendNotificationRequestModel, sendNotificationRequestInitValues } from '../../../../models/requests/SendNotificationRequestModel'
import { NotificationChannelEnum } from '../../../../enums/NotificationChannelEnum'
import { CloudMessageTypeEnum } from '../../../../enums/CloudMessageTypeEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import CloudMessageApi from '../../../../infrastructure/api/CloudMessageApi'

type Props = {
    show: boolean
    handleClose: () => void
}

const SendNotificationFormModal: FC<Props> = ({ show, handleClose }) => {
    const notificationUsers: IUserDictionaryModel[] = useSelector<RootState>(({ account }) => account.notifUsers, shallowEqual) as IUserDictionaryModel[]

    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [errors, setErrors] = useState([])

    const [selectedChannel, setSelectedChannel] = useState<SelectModel>(selectInitValues)
    const [selectedType, setSelectedType] = useState<SelectModel>(selectInitValues)


    const channelList = EnumToList(NotificationChannelEnum);
    const typeList = EnumToList(CloudMessageTypeEnum);


    const formik = useFormik({
        initialValues: sendNotificationRequestInitValues,
        enableReinitialize: true,
        validationSchema: SendNotificationFormValidations,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setTimeout(async () => {
                let model: ISendNotificationRequestModel = {
                    title: values.title,
                    image: values.image,
                    channel: parseInt(selectedChannel.value),
                    type: parseInt(selectedType.value) || undefined,
                    message: values.message,
                    plannedAt: values.plannedAt,
                    params: values.params
                }

                model.userIds = notificationUsers.map((item) => item.id);
                if (parseInt(selectedChannel.value) == NotificationChannelEnum.Email) {
                    model.tokens = notificationUsers.map((item) => item.email)
                } else if (parseInt(selectedChannel.value) == NotificationChannelEnum.Sms) {
                    model.tokens = notificationUsers.map((item) => item.phoneNumber)
                }

                process(model);

            }, 500)
        },
    })

    async function process(request: ISendNotificationRequestModel) {
        try {
            setLoading(true)
            var result = await CloudMessageApi.AddCloudMessages(request)
            formik.resetForm()
            handleClose()
        } catch (error) {
            alert('Error: ' + error)
            setLoading(false)
        }
        setLoading(false)
    }

    useEffect(() => {

    }, [show, notificationUsers]);

    return (
        <Modal
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog-centered mw-750px h-auto'
            show={show}
            onHide={handleClose}
        >
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2 className='fw-bolder'>Send Notification to Students ({notificationUsers.length})</h2>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <div className='modal-body  mx-5 my-7'>
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    errors.length > 0 && <div className='alert alert-danger'>  <h4 className="alert-heading">Please fix the following issues and try again!</h4>
                                        <p>
                                            <ol>
                                                {errors.map((item, key) => {
                                                    return (
                                                        <li key={key}>{item}</li>
                                                    )
                                                })}
                                            </ol>
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className='row'>
                                <div className='col-12 mb-6'>
                                    <label className='form-label fw-bolder required'>Channel</label>
                                    <Select
                                        {...formik.getFieldProps('channel')}
                                        styles={customSelectStyles(true)}
                                        onChange={(event) => setSelectedChannel(event!)}
                                        value={selectedChannel}
                                        options={channelList}
                                    />
                                    <FormikValidationError touched={formik.touched.channel} error={formik.errors.channel} />
                                </div>
                                <div className='col-12 mb-6'>
                                    <label className='form-label fw-bolder required'>Date</label>
                                    <input
                                        {...formik.getFieldProps('plannedAt')}
                                        type='datetime-local'
                                        name='plannedAt'
                                        className='form-control form-control-solid'
                                        placeholder='Date'
                                    />
                                    <FormikValidationError touched={formik.touched.plannedAt} error={formik.errors.plannedAt} />
                                </div>
                                {parseInt(selectedChannel.value) == NotificationChannelEnum.PushNotification || parseInt(selectedChannel.value) == NotificationChannelEnum.Email &&
                                    <div className='col-12 mb-6'>
                                        <label className='form-label fw-bolder required'>Title</label>
                                        <input
                                            {...formik.getFieldProps('title')}
                                            type='text'
                                            name='title'
                                            className='form-control form-control-solid'
                                            placeholder='Title'
                                        />
                                        <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
                                    </div>
                                }
                                {parseInt(selectedChannel.value) == NotificationChannelEnum.PushNotification &&
                                    <div className='col-12 mb-6'>
                                        <label className='form-label fw-bolder required'>Push Notification Type</label>
                                        <Select
                                            {...formik.getFieldProps('type')}
                                            styles={customSelectStyles(true)}
                                            onChange={(event) => setSelectedType(event!)}
                                            value={selectedType}
                                            options={typeList}
                                        />
                                        <FormikValidationError touched={formik.touched.type} error={formik.errors.type} />
                                    </div>
                                }
                                <div className='col-12 mb-6'>
                                    <label className='form-label fw-bolder required'>Message</label>
                                    <input
                                        {...formik.getFieldProps('message')}
                                        type='text'
                                        name='message'
                                        className='form-control form-control-solid'
                                        placeholder='Message'
                                    />
                                    <FormikValidationError touched={formik.touched.message} error={formik.errors.message} />
                                </div>
                                {parseInt(selectedChannel.value) == NotificationChannelEnum.PushNotification && <>

                                    <div className='col-12 mb-6'>
                                        <label className='form-label fw-bolder required'>Image Url (Optional)</label>
                                        <input
                                            {...formik.getFieldProps('image')}
                                            type='text'
                                            name='image'
                                            className='form-control form-control-solid'
                                            placeholder='Image'
                                        />
                                        <FormikValidationError touched={formik.touched.image} error={formik.errors.image} />
                                    </div>
                                    {parseInt(selectedType.value) == CloudMessageTypeEnum.OpenExternalWebPage || parseInt(selectedType.value) == CloudMessageTypeEnum.SendAlert &&

                                        <div className='col-12 mb-6'>
                                            <label className='form-label fw-bolder required'>{parseInt(selectedType.value) == CloudMessageTypeEnum.OpenExternalWebPage ? "Link" : "Alert"}</label>
                                            <input
                                                {...formik.getFieldProps('params')}
                                                type='text'
                                                name='params'
                                                className='form-control form-control-solid'
                                                placeholder='Params'
                                            />
                                            <FormikValidationError touched={formik.touched.params} error={formik.errors.params} />
                                        </div>
                                    }
                                </>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <div className='d-flex flex-center '>
                            <SaveChangesButton
                                loading={loading}
                                setLoading={setLoading}
                                complete={complete}
                                setComplete={setComplete}
                                valid={formik.isValid}
                                submit={formik.isSubmitting}
                                setSubmit={formik.submitForm}
                            />
                        </div>
                    </div>
                </div>
            </form >
        </Modal >
    )
}

export { SendNotificationFormModal }