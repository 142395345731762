import {StringifyOptions} from 'querystring'

export interface IVideoModel {
  id: number
  title: string
  link: string
  order: number
  userTypeId: number
  videoType: number
  language: number
  date: string
}

const videoInitValues: IVideoModel = {
  id: 0,
  title: '',
  link: '',
  order: 0,
  userTypeId: 0,
  videoType: 0,
  language: 0,
  date: '',
}

export {videoInitValues}
