import { UserManagerSettings, User, Log, UserManager } from 'oidc-client';
import { UserModel } from '../models/UserModel';
import jwt from 'jwt-decode'

Log.logger = console;
Log.level = Log.DEBUG;

const IDENTITY_SERVER_URL = process.env.REACT_APP_IDENTITY_SERVER_URL
const IDENTITY_CLIENT_ID = process.env.REACT_APP_IDENTITY_CLIENT_ID
const IDENTITY_SCOPE = process.env.REACT_APP_IDENTITY_SCOPE

export const userManagerConfig: UserManagerSettings = {
    authority: IDENTITY_SERVER_URL,
    client_id: IDENTITY_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/auth/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/auth/login`,
    response_type: 'code',
    scope: IDENTITY_SCOPE,
    loadUserInfo: false,
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/auth/silent-renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: false,
    //accessTokenExpiringNotificationTime: 10,
    //silentRequestTimeout: 10000,
};

export const userManager = new UserManager(userManagerConfig)

export async function getUser(): Promise<User | null> {
    return await userManager.getUser();
}

export async function getUserContext(): Promise<UserModel> {
    let user = await getUser();
    const userData: any = jwt(user?.access_token!! || '');
    //userManager.storeUser(user!!);
    var userModel: UserModel = {
        id: user?.profile.sub || '',
        firstname: user?.profile.given_name || '',
        lastname: user?.profile.family_name || '',
        email: userData?.email || '',
        password: "",
        username: user?.profile.preferred_username || '',
        rolesString: Array.isArray(userData?.role) ? userData?.role : [userData?.role],
        permissions: userData?.Permission
    }
    return userModel;
}
export function login() {
    return userManager.signinRedirect();
}
export function renewToken(): Promise<User> {
    return userManager.signinSilent().then(function (data) {
        return data;
    }).catch(function (err) {
        return err;
    });
}

export function signinSilentCallback(): Promise<User | undefined> {
    return userManager.signinSilentCallback();
}
export function signinRedirectCallback(): Promise<User> {
    return userManager.signinRedirectCallback()
}
export function loadUser(): Promise<User | null> {
    return userManager.getUser();
}
export function logout() {
    localStorage.removeItem("accessToken")
    userManager.clearStaleState()
    userManager.revokeAccessToken()
    force_logout();
}

export async function force_logout() {    
    var user = await getUser();
    userManager.signoutRedirect(user?.id_token);
}


userManager.events.addUserLoaded(function (user) {
    localStorage.setItem("accessToken", user.access_token)
});
userManager.events.addUserUnloaded(function () {
});
userManager.events.addAccessTokenExpiring(function () {
});
userManager.events.addSilentRenewError(function (err) {
    //logout();
});
userManager.events.addUserSignedIn(function () {
});
userManager.events.addUserSignedOut(function () {
});