import { FC, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { InfoModal } from '../../../components/modals/InfoModal'
import AnnouncementApi from '../../../../infrastructure/api/AnnouncementApi'
import { UserModel } from '../../../modules/auth/models/UserModel'

import { RootState } from '../../../../setup'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { SelectModel } from '../../../models/SelectModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import parse from 'html-react-parser'
import { IReadAnnouncementRequestModel } from '../../../../models/requests/AnnouncementRequestModel'

const AnnouncementAlert: FC = () => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const [show, setShow] = useState(false)
    const [pinned, setPinned] = useState(false)
    const [pinnedTitle, setPinnedTitle] = useState('')
    const [pinnedMessage, setPinnedMessage] = useState('')
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [id, setId] = useState('')

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    async function confirmAnnouncement() {
        var model: IReadAnnouncementRequestModel = {
            id: id
        }
        await AnnouncementApi.ReadAnnouncement(model)
    }

    const init = async () => {
        if (user != null) {
            const paginationFilter: IPaginationFilter = {
                page: 0,
                size: 0,
                filters: new Map()
            }
            let newFilters: Map<string, string> = new Map()

            var requests = user.rolesString!!.map(async (role, index) => {
                var responseRoles = await RoleApi.GetRoleDictionary()
                newFilters.set("Role", responseRoles.data.find(i => i.name == role)?.id.toString() || '')
            });

            Promise.all(requests).then(async () => {
                paginationFilter.filters = newFilters;
                var response = await AnnouncementApi.GetAnnouncementAlerts(user.id, paginationFilter)
                var pinnedAlert = response.data.find(s => s.isPinned == true);
                var alert = response.data.find(s => s.isPinned == false);
                if (alert != null) {
                    setId(alert.id)
                    setPinned(alert.isPinned)
                    setTitle(alert.title)
                    setMessage(alert.message)
                    handleShow()
                }
                if (pinnedAlert != null) {
                    setPinnedTitle(pinnedAlert.title)
                    setPinnedMessage(pinnedAlert.message)
                }
            })
        }
    }

    useEffect(() => {
        init()
    }, [user])
    return (
        <>
            {pinnedTitle != '' &&
                <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10">
                    <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0"><i className='fa fa-info'></i></span>
                    <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                        <h5 className="mb-1">{pinnedTitle}</h5>
                        <span>{parse(pinnedMessage)}</span>
                    </div>
                </div>
            }
            <InfoModal
                title={title}
                text={message}
                show={show}
                handleClose={handleClose}
                confirm={confirmAnnouncement}
            />
        </>
    )
}

export { AnnouncementAlert }
