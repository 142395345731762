import { FC, useEffect, useState } from 'react'
import ShopProductApi from '../../../../infrastructure/api/ShopProductApi'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IShopProductModel, shopProductInitValues } from '../../../../models/responses/ShopProductModel'
import { IShopProductRequestModel } from '../../../../models/requests/ShopProductRequestModel'
import { ShopProductFormValidations } from '../../../validations/ShopProductFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { ShopProductCategoryEnum } from '../../../../enums/ShopProductCategoryEnum'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { slugify } from '../../../helpers/StringHelper'
import FileApi from '../../../../infrastructure/api/FileApi'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'


type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const ShopProductFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [shopProduct, setShopProduct] = useState<IShopProductModel>(shopProductInitValues)
  const updateShopProduct = (fieldsToUpdate: Partial<IShopProductModel>) => {
    const updatedData = { ...shopProduct, ...fieldsToUpdate }
    setShopProduct(updatedData)
  }

  const defaultSelect = {
    value: "0", label: "--SELECT--"
  };
  const categoryList = EnumToList(ShopProductCategoryEnum);
  const [selectedCategory, setSelectedCategory] = useState<SelectModel>(defaultSelect)
  const [loadFile, setLoadFile] = useState<any>(undefined)
  const [picture, setPicture] = useState('')

  const formik = useFormik<IShopProductModel>({
    initialValues: shopProduct,
    enableReinitialize: true,
    validationSchema: ShopProductFormValidations,
    onSubmit: (values) => {
      setTimeout(async () => {
        let model: IShopProductRequestModel = {
          id: values.id,
          name: values.name,
          image: values.image,
          price: values.price,
          edupoint: values.edupoint,
          stock: values.stock,
          category: parseInt(selectedCategory.value)
        }
        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: IShopProductRequestModel) {
    try {
      if (loadFile !== undefined) {
        let d = new Date();
        const fileName = slugify(`${model.name}-${d.getMilliseconds()}-${d.getMinutes()}`)
        let modelPicture = new FormData()
        modelPicture.append('name', fileName)
        modelPicture.append('file', loadFile)
        modelPicture.append('type', FileTypeEnum.ShopProduct)
        await FileApi.AddFile(modelPicture);

        model.image = (fileName + "." + loadFile.name.split('.').pop())
      }
      var result = undefined
      if (model.id === undefined || model.id === 0)
        result = await ShopProductApi.AddShopProduct(model)
      else {
        result = await ShopProductApi.UpdateShopProduct(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IShopProductModel
    if (itemId > 0) {
      var response = await ShopProductApi.GetShopProduct(itemId)
      result = response.data
      handleChangeCategory(response.data.category ? categoryList.find(i => i.value === response.data.category.toString()) : defaultSelect)
     
    } else {
      result = shopProductInitValues
    }
    setPicture(result.image !== '' ? `${process.env.REACT_APP_CDN_URL}images/product/${result.image}` : toAbsoluteUrl('/media/misc/none.png'));
    setShopProduct(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeCategory = (e: any) => {
    setSelectedCategory(e);
  }

  const handleChangeImage = (event: any) => {
    setLoadFile(event.target.files[0])
    setPicture(URL.createObjectURL(event.target.files[0]));
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Shop Product</h1>
              <div className='text-muted fw-bold fs-5'>
                Shop Product Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-6'>
              <label className='form-label fw-bolder required'>Product Name</label>
                  <input
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.name} error={formik.errors.name} />
              </div>
              <div className='col-lg-6'>
              <label className='form-label fw-bolder'>Product Category</label>
                <Select
                  {...formik.getFieldProps('category')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeCategory(event)}
                  value={selectedCategory}
                  options={categoryList}
                />
                <FormikValidationError touched={formik.touched.category} error={formik.errors.category} />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Picture</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/none.png')})`, minWidth: '100%' }}
                  >
                    <div
                      className='image-input-wrapper'
                      style={{ backgroundImage: `url(${picture})`, minWidth: '100%', backgroundSize: 'contain', backgroundPosition: 'center' }}
                    ></div>
                    <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px form-control form-control-solid shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="" data-bs-original-title="Change avatar">
                      <i className="fas fa-pencil-alt"></i>
                      <input
                        {...formik.getFieldProps('image')}
                        type="file"
                        value={undefined}
                        onChange={(event) => handleChangeImage(event)} accept=".png, .jpg, .jpeg"
                      />
                    </label>
                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="" data-bs-original-title="Cancel avatar">
                      <i className="bi bi-x fs-2"></i>
                    </span>
                  </div>
                  <FormikValidationError touched={formik.touched.image} error={formik.errors.image} />
                </div>

              </div>
              <div className='col-lg-8'>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Edupoint</label>
                  <input
                    {...formik.getFieldProps('edupoint')}
                    type='number'
                    name='edupoint'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.edupoint} error={formik.errors.edupoint} />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bolder'>Stock</label>
                  <input
                    {...formik.getFieldProps('stock')}
                    type='number'
                    name='stock'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <FormikValidationError touched={formik.touched.stock} error={formik.errors.stock} />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { ShopProductFormModal }
