import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BlogCommentApi from '../../../../infrastructure/api/BlogCommentApi'
import { IBlogCommentModel } from '../../../../models/responses/BlogCommentModel'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import parse from 'html-react-parser'

import { useFormik } from 'formik'
import { IBlogCommentRequestModel } from '../../../../models/requests/BlogCommentRequestModel'
import { BlogCommentFormValidations } from '../../../validations/BlogCommentFormValidations'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

type Props = {
  itemId: number
  show: boolean
  itemType: string
  handleClose: () => void
}
const BlogCommentReplyModal: FC<Props> = ({ itemId, show, itemType, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [blogComment, setBlogComment] = useState<IBlogCommentModel | undefined>()
  const [comment, setComment] = useState('')


  const formik = useFormik({
    initialValues: blogComment!!,
    enableReinitialize: true,
    validationSchema: BlogCommentFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IBlogCommentRequestModel = {
          id: values.id,
          author: "Educall",
          email: "info@educall.com.tr",
          body: comment,
          blogPostId: values.blogPostId,
          parentCommentId: values.parentCommentId,
          isActive: true
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IBlogCommentRequestModel) {
    try {
      var result = undefined
      if (model.parentCommentId == model.id) {
        result = await BlogCommentApi.AddBlogComment(model)
      } else {
        result = await BlogCommentApi.UpdateBlogComment(model)
      }
      setComplete(true)
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  async function init() {
    var response = await BlogCommentApi.GetBlogComment(itemId)
    if (response.data.id != response.data.parentCommentId) setComment(response.data.body)
    else setComment('')
    setBlogComment(response.data)
  }

  useEffect(() => {
    formik.resetForm();
    init()
  }, [itemId])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Reply Comment</h1>
              <div className='text-muted fw-bold fs-5'>
                Blog Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <p><label className='form-label fw-bolder'>Post Name:&nbsp;</label>{blogComment?.blogPostName}</p>
              <p><label className='form-label fw-bolder'>Author: &nbsp;</label>{blogComment?.author}</p>
              <div className='bg-light-info fw-bolder text-gray-800 rounded p-5 mb-10'>
                {parse(blogComment?.parentCommentBody || '')}
              </div>
              {blogComment?.subComments && blogComment?.subComments.length > 0 &&
                <div className='mt-5 my-5'>
                  <label className='form-label fw-bolder'>Before replies</label>
                  <ol className='list-group list-group-numbered'>
                    {blogComment?.subComments?.map((sub, subIndex) => {
                      return (
                        <li className='list-group-item d-flex justify-content-between align-items-start' key={subIndex}>
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">{sub.author}</div>
                            {parse(sub.body)}
                          </div>
                          <span className="badge bg-primary rounded-pill">{sub.date}</span>
                        </li>
                      )
                    })}
                  </ol>
                </div>
              }
              <label className='form-label fw-bolder required'>Reply</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setComment}
                data={comment} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex justify-content-end'>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm} />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { BlogCommentReplyModal }
