export interface IPartofSpeechModel {
    id: string,
    name: string,
    displayName: string,
    languageId: number
}

const partofSpeechInitValues: IPartofSpeechModel = {
    id: '',
    name: '',
    displayName: '',
    languageId: 0
}

export { partofSpeechInitValues }