import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { TrainerDetailFormValidations } from '../../../../../validations/TrainerDetailFormValidations'

import UserApi from '../../../../../../infrastructure/api/UserApi'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { IUserModel } from '../../../../../../models/responses/UserModel'
import { RootState } from '../../../../../../setup'
import { SaveChangesButton } from '../../../../../components/buttons/SaveChangesButton'
import { IUserClaimsRequestModel } from '../../../../../../models/requests/UserClaimsRequestModel'

import { FormikValidationError } from '../../../../../components/validations/FormikValidationError'
import { customSelectStyles } from '../../../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../../../models/SelectModel'
import Select from 'react-select';
import { ExchangeEnum } from '../../../../../../enums/ExchangeEnum'
import { EnumToList } from '../../../../../helpers/EnumHelper'
import TrainerApi from '../../../../../../infrastructure/api/TrainerApi'

import { ITrainerSettingsModel, trainerSettingsInitValues as initialValues, trainersInitValues } from '../../../../../../models/responses/TrainerModel'
import { ITrainerRequestModel } from '../../../../../../models/requests/TrainerRequestModel'
import CompanyApi from '../../../../../../infrastructure/api/CompanyApi'
import { ClaimTypeEnum } from '../../../../../../enums/ClaimTypeEnum'
import { FileTypeEnum } from '../../../../../../enums/FileTypeEnum'
import { slugify } from '../../../../../helpers/StringHelper'
import FileApi from '../../../../../../infrastructure/api/FileApi'

const TrainerDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const exchanges = EnumToList(ExchangeEnum);

  const dispatch = useDispatch()
  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel

  const [trainerSettings, setTrainerSettings] = useState<ITrainerSettingsModel>(initialValues)

  const [image, setImage] = useState<any>()
  const [video, setVideo] = useState<any>()

  const [imagePath, setImagePath] = useState<string>('')
  const [videoPath, setVideoPath] = useState<string>('')
  const [isFeatured, setFeatured] = useState<boolean>(false)
  const [isAvailableToCall, setAvailableToCall] = useState<boolean>(false)
  const [isPerformancePay, setPerformancePay] = useState<boolean>(false)

  const [selectedExchange, setSelectedExchange] = useState<SelectModel>(selectInitValues)

  const [companies, setCompanies] = useState<SelectModel[]>([])
  const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)

  const formik = useFormik<ITrainerSettingsModel>({
    initialValues: trainerSettings,
    enableReinitialize: true,
    validationSchema: TrainerDetailFormValidations,
    onSubmit: (values) => {
      setTimeout(() => {
        let model: ITrainerRequestModel = {
          id: user.id,
          shortDescription: values.shortDescription,
          description: values.description,
          image: undefined,
          video: undefined,
          isFeatured: isFeatured,
          performancePay: isPerformancePay,
          isAvailableToCall: isAvailableToCall,
          paymentExchange: parseInt(selectedExchange?.value! || ""),
          paymentRate: values.paymentRate,
          companyId: selectedCompany.value == "" ? undefined : selectedCompany.value
        }
        sendData(model)

      }, 500)
    },
  })

  async function sendData(model: ITrainerRequestModel) {
    try {
      let d = new Date()

      if (image !== undefined) {
        const fileName = slugify(`ti-${model.id}-${d.getMilliseconds()}-${d.getMinutes()}`)
        const imagePath = fileName + '.' + image.name.split('.').pop();
        model.image = imagePath

        let modelFile = new FormData()
        modelFile.append('name', fileName)
        modelFile.append('file', image)
        modelFile.append('type', FileTypeEnum.TrainerImage)
        await FileApi.AddFile(modelFile)

      }

      if (video !== undefined) {

        const fileName = slugify(`tv-${model.id}-${d.getMilliseconds()}-${d.getMinutes()}`)
        const videoPath = fileName + '.' + video.name.split('.').pop();
        model.video = videoPath

        let modelFile = new FormData()
        modelFile.append('name', fileName)
        modelFile.append('file', video)
        modelFile.append('type', FileTypeEnum.TrainerVideo)
        await FileApi.AddFile(modelFile)
      }

      await TrainerApi.UpdateTrainer(model);

      let modelClaims: IUserClaimsRequestModel = {
        id: user.id,
        claims: [
          { type: ClaimTypeEnum.CompanyId, value: selectedCompany.value!!.toString() },
        ]
      }

      await UserApi.UpdateUserClaims(modelClaims);

      setComplete(true)
      await getTrainerData();
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }


  const init = async () => {
    var companies = await CompanyApi.GetSubCompaniesDictionary()
    var dataParentCompanies: SelectModel[] = [selectInitValues,
      ...companies.data.map((d) => ({
        value: d.id.toString(),
        label: d.name,
      })),
    ]
    setCompanies(dataParentCompanies)

    var companyId = user.details?.find(i => i.type == ClaimTypeEnum.CompanyId)?.value!! || '';
    if (companyId != null && companyId != '') {
      handleChangeCompany({
        value: companyId,
        label: dataParentCompanies.find(i => i.value == companyId)?.label
      })
    }


    await getTrainerData();
  }

  const getTrainerData = async () => {
    var responseTrainer = await TrainerApi.GetTrainerSettings(user.id)

    if (responseTrainer.data.id != undefined) {
      var trainer = responseTrainer.data;
      setTrainerSettings(trainer)
      if (trainer.paymentExchange != undefined) handleChangeExchange(exchanges.find(i => i.value == trainer.paymentExchange.toString()))
      setFeatured(trainer.isFeatured)
      setAvailableToCall(trainer.isAvailableToCall)
      setPerformancePay(trainer.performancePay)

    }
  }

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user])
  const handleChangeFeatured = () => {
    setFeatured(!isFeatured)
  }
  const handleChangePerformancePay = () => {
    setPerformancePay(!isPerformancePay)
  }
  const handleChangeAvailableCall = () => {
    setAvailableToCall(!isAvailableToCall)
  }

  const handleChangeExchange = (e: any) => {
    setSelectedExchange(e);
  }

  const handleChangeCompany = (e: any) => {
    setSelectedCompany(e);
  }

  const handleChangeImage = (event: any) => {
    if (event.target.files[0] != null) {
      setImage(event.target.files[0]);
      setImagePath(`(${(event.target.files[0].size / 1000).toFixed()} KB) ${event.target.files[0].name}`)
    }
  }

  const handleChangeVideo = (event: any) => {
    if (event.target.files[0] != null) {
      setVideo(event.target.files[0]);
      setVideoPath(`(${(event.target.files[0].size / 1000).toFixed()} KB) ${event.target.files[0].name}`)
    }
  }


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Trainer Config</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Available To Call</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isAvailableToCall')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isAvailableToCall'
                    checked={isAvailableToCall}
                    onClick={handleChangeAvailableCall}
                  />
                  <FormikValidationError touched={formik.touched.isAvailableToCall} error={formik.errors.isAvailableToCall} />
                </div>
              </div>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Performance Pay</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('performancePay')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='performancePay'
                    checked={isPerformancePay}
                    onClick={handleChangePerformancePay}
                  />
                  <FormikValidationError touched={formik.touched.performancePay} error={formik.errors.performancePay} />
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-1 fw-bold fs-6'>Is Featured</label>
              <div className='col-lg-2 fv-row'>
                <div className='form-check form-check-lg form-switch form-check-custom form-check-solid'>
                  <input
                    {...formik.getFieldProps('isFeatured')}
                    className='form-check-input h-30px w-50px'
                    type='checkbox'
                    name='isFeatured'
                    checked={isFeatured}
                    onClick={handleChangeFeatured}
                  />
                  <FormikValidationError touched={formik.touched.isFeatured} error={formik.errors.isFeatured} />
                </div>
              </div>              
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Short Description</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('shortDescription')}
                  type='text'
                  name='shortDescription'
                  className='form-control form-control-solid'
                />
                <FormikValidationError touched={formik.touched.shortDescription} error={formik.errors.shortDescription} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Description</label>
              <div className='col-lg-8 fv-row'>
                <textarea
                  {...formik.getFieldProps('description')}
                  typeof='text'
                  name='description'
                  rows={3}
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError
                  touched={formik.touched.description}
                  error={formik.errors.description}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Image (200x300)</label>
              <div className='col-lg-8 fv-row'>
                {trainerSettings.image != null && trainerSettings.image != '' ?
                  <img style={{ "height": "150px","width": "100px" }} src={`${process.env.REACT_APP_CDN_URL}images/trainer/${trainerSettings.image}`} />
                  : <></>
                }
                <label className="alert alert-primary align-items-center d-flex bg-light-primary border-dashed"
                  style={{ cursor: "pointer" }}
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title=''
                  data-bs-original-title='Change file'
                >
                  <input
                    hidden
                    type='file'
                    value={undefined}
                    onChange={(event) => handleChangeImage(event)}
                    accept='.png, .jpg, .jpeg, .gif'
                  />
                  <span className="svg-icon svg-icon-3hx svg-icon-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z" fill="black"></path>
                      <path d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z" fill="black"></path>
                      <path d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z" fill="black"></path>
                      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                    </svg>
                  </span>
                  <div className="d-flex flex-column">
                    <h3 className="fs-5 fw-bolder text-gray-900 mb-1">Click to image file</h3>
                    <span className="fw-bold fs-7 text-gray-400">{imagePath}</span>
                  </div>
                </label>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Video</label>
              <div className='col-lg-8 fv-row'>
                {trainerSettings.video != null && trainerSettings.video != '' ?
                  <video width="auto" height="200" controls><source src={`${process.env.REACT_APP_CDN_URL}videos/trainer/${trainerSettings.video}`} /></video>
                  : <></>
                }
                <label className="alert alert-primary align-items-center d-flex bg-light-primary border-dashed"
                  style={{ cursor: "pointer" }}
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title=''
                  data-bs-original-title='Change file'
                >
                  <input
                    hidden
                    type='file'
                    value={undefined}
                    onChange={(event) => handleChangeVideo(event)}
                    accept='.mp3, .wav, .mp4'
                  />
                  <span className="svg-icon svg-icon-3hx svg-icon-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z" fill="black"></path>
                      <path d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z" fill="black"></path>
                      <path d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z" fill="black"></path>
                      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path>
                    </svg>
                  </span>
                  <div className="d-flex flex-column">
                    <h3 className="fs-5 fw-bolder text-gray-900 mb-1">Click to video file</h3>
                    <span className="fw-bold fs-7 text-gray-400">{videoPath}</span>
                  </div>
                </label>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Company</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('companyId')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeCompany(event)}
                  value={selectedCompany}
                  options={companies}
                />
                <FormikValidationError touched={formik.touched.companyId} error={formik.errors.companyId} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Payment Exchange</label>
              <div className='col-lg-8 fv-row'>
                <Select
                  {...formik.getFieldProps('paymentExchange')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeExchange(event)}
                  value={selectedExchange}
                  options={exchanges}
                />
                <FormikValidationError touched={formik.touched.paymentExchange} error={formik.errors.paymentExchange} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 mt-2 fw-bold fs-6'>Payment Rate</label>
              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('paymentRate')}
                  type='text'
                  name='paymentRate'
                  placeholder='14.53'
                  className='form-control form-control-solid'
                />
                <FormikValidationError touched={formik.touched.paymentRate} error={formik.errors.paymentRate} />

              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <SaveChangesButton
              loading={loading}
              setLoading={setLoading}
              complete={complete}
              setComplete={setComplete}
              valid={formik.isValid}
              submit={formik.isSubmitting}
              setSubmit={formik.submitForm} />
          </div>
        </form>
      </div >
    </div >
  )
}

export { TrainerDetails }
