import { FC, useEffect, useState } from 'react'
import PricingApi from '../../../../infrastructure/api/PricingApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IPricingRequestModel } from '../../../../models/requests/PricingRequestModel'
import { PricingFormValidations } from '../../../validations/PricingFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { IPricingModel, pricingInitValues } from '../../../../models/responses/PricingModel'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { IPricingItemModel } from '../../../../models/responses/PricingItemModel'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const PricingFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [pricing, setPricing] = useState<IPricingModel>(pricingInitValues)
  const updatePricing = (fieldsToUpdate: Partial<IPricingModel>) => {
    const updatedData = { ...pricing, ...fieldsToUpdate }
    setPricing(updatedData)
  }

  const defaultSelect = {
    value: "0", label: "SELECT"
  };


  const typeList: SelectModel[] = [
    { value: "1", label: "Private" },
    { value: "2", label: "Group" }
  ]
  const privateMinuteList: SelectModel[] = [
    { value: "10", label: "10 Minutes" },
    { value: "15", label: "15 Minutes" },
    { value: "25", label: "25 Minutes" }
  ]

  const groupMinuteList: SelectModel[] = [
    { value: "30", label: "30 Minutes" },
    { value: "60", label: "60 Minutes" }
  ]

  const dayList: SelectModel[] = [
    { value: "1", label: "1 Day" },
    { value: "2", label: "2 Day" },
    { value: "3", label: "3 Day" },
    { value: "4", label: "4 Day" },
    { value: "5", label: "5 Day" },
    { value: "6", label: "6 Day" },
    { value: "7", label: "7 Day" },
  ]
  const pricingItemList: IPricingItemModel[] = [
    { discount: 0, isCouponActive: true, month: 1 },
    { discount: 0, isCouponActive: true, month: 3 },
    { discount: 0, isCouponActive: true, month: 12 }
  ]


  const [minuteList, setMinuteList] = useState<SelectModel[]>(privateMinuteList)
  const [selectedType, setSelectedType] = useState<SelectModel>(defaultSelect)
  const [selectedMinute, setSelectedMinute] = useState<SelectModel>(defaultSelect)
  const [selectedDay, setSelectedDay] = useState<SelectModel>(defaultSelect)


  const formik = useFormik<IPricingModel>({
    initialValues: pricing,
    enableReinitialize: true,
    validationSchema: PricingFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IPricingRequestModel = {
          id: pricing.id,
          day: parseInt(selectedDay.value),
          minute: parseInt(selectedMinute.value),
          type: parseInt(selectedType.value),
          amount: values.amount,
          pricingItems: pricing.pricingItems.slice(0, pricingItemList.length)
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IPricingRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await PricingApi.AddPricing(model)
      else {
        result = await PricingApi.UpdatePricing(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IPricingModel
    if (itemId > 0) {
      var response = await PricingApi.GetPricing(itemId)
      handleChangeType(response.data.type ? typeList.find(i => i.value == response.data.type.toString()) : defaultSelect)
      handleChangeDay(response.data.day ? dayList.find(i => i.value == response.data.day.toString()) : defaultSelect)
      handleChangeMinute(response.data.minute ? minuteList.find(i => i.value == response.data.minute.toString()) : defaultSelect)
      result = response.data
      if (response.data.pricingItems.length < pricingItemList.length) {
        result.pricingItems = pricingItemList
      }
    } else {
      handleChangeDay(defaultSelect)
      handleChangeMinute(defaultSelect)
      result = pricingInitValues
      result.pricingItems = pricingItemList
    }
    setPricing(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeMinute = (e: any) => {
    setSelectedMinute(e);
  }

  const handleChangeDay = (e: any) => {
    setSelectedDay(e);
  }

  const handleChangeType = (e: any) => {      
    if (e?.value!! == "2")
      setMinuteList(groupMinuteList)
    else
      setMinuteList(privateMinuteList)


    setSelectedType(e);
  }

  const handleOnChangeDiscount = (index: number, e: any) => {
    var tempPricingItems = pricing.pricingItems
    tempPricingItems[index].discount = e.target.value
    updatePricing({ pricingItems: tempPricingItems })
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Pricing</h1>
              <div className='text-muted fw-bold fs-5'>
                Pricing Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-12'>

                <label className='form-label fw-bolder required'>Type</label>
                <Select
                  {...formik.getFieldProps('type')}
                  styles={customSelectStyles(true)}
                  onChange={(value) => handleChangeType(value)}
                  value={selectedType}
                  options={typeList}
                />
                <FormikValidationError touched={formik.touched.minute} error={formik.errors.minute} />
              </div>
              <div className='col-lg-4'>

                <label className='form-label fw-bolder required'>Minute</label>
                <Select
                  {...formik.getFieldProps('minute')}
                  styles={customSelectStyles(true)}
                  onChange={(value) => handleChangeMinute(value)}
                  value={selectedMinute}
                  options={minuteList}
                />
                <FormikValidationError touched={formik.touched.minute} error={formik.errors.minute} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Day</label>
                <Select
                  {...formik.getFieldProps('day')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeDay(event)}
                  value={selectedDay}
                  options={dayList}
                />
                <FormikValidationError touched={formik.touched.day} error={formik.errors.day} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Amount</label>
                <input
                  {...formik.getFieldProps('amount')}
                  type='number'
                  name='amount'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.amount} error={formik.errors.amount} />
              </div>
            </div>
            <div className='row mb-10'>
              <hr />
              <table className="table table-row-dashed table-row-gray-300 gy-7">
                <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Month</th>
                  <th>Discount</th>
                  <th style={{ textAlign: 'right' }}>Coupon</th>
                </tr>
                {pricingItemList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td><span className="text-dark fw-bolder fs-2x lh-0"> {item.month}</span>&nbsp;Month&nbsp;</td>
                      <td>
                        <input
                          type='number'
                          value={pricing?.pricingItems[index]?.discount!! || 0}
                          onChange={(e) => handleOnChangeDiscount(index, e)}
                          className='form-control form-control-solid form-control-sm'
                          placeholder='Discount..'
                        />
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='isActive'
                          checked={pricing?.pricingItems[index]?.isCouponActive}
                          onClick={() => {
                            var tempPricingList = pricing.pricingItems;
                            tempPricingList[index].isCouponActive = !tempPricingList[index].isCouponActive
                            updatePricing({ pricingItems: tempPricingList })
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { PricingFormModal }
