import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IPageViewingModel } from '../../models/responses/PageViewingModel'
import { IAddPageViewingsRequestModel } from '../../models/requests/AddPageViewingsRequestModel'

class PageViewingApi {

    async GetPageViewings(filter: IPaginationFilter) {
        return await API.get<PagedResponseModel<IPageViewingModel[]>>(`e/Admin/PageViewings${generateQueryParams(filter)}`)
    }

    async AddPageViewings(model: IAddPageViewingsRequestModel) {
        return await API.post('e/Admin/PageViewings/queue', model)
    }

}

export default new PageViewingApi()
