import API from './Api'
import { IAutoCommentModel } from '../../models/responses/AutoCommentModel'
import { IContactsRequestModel } from '../../models/requests/ContactsRequestModel'
import { IContactsModel } from '../../models/responses/ContactsModel'

class ContactsApi {
  async GetContacts(userId: string, type: string) {
    return await API.get<IContactsModel[]>(`s/Admin/Contacts?userId=${userId}&type=${type}`)
  }

  async AddContact(model: IContactsRequestModel) {
    return await API.post('s/Admin/Contacts', model)
  }

  async DeleteContact(itemId: string) {
    return await API.delete<boolean>('s/Admin/Contacts/' + itemId)
  }
}

export default new ContactsApi()
