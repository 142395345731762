import * as Yup from 'yup'

const PopupFormValidations = () => {
  return Yup.object().shape({
    title: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 150 symbols')
      .required('Title is required')
  })
}

export { PopupFormValidations }
