import { FC, useEffect, useState } from 'react'
import AnnouncementApi from '../../../../infrastructure/api/AnnouncementApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import {
  IAnnouncementModel,
  announcementInitValues,
} from '../../../../models/responses/AnnouncementModel'
import { IAnnouncementRequestModel } from '../../../../models/requests/AnnouncementRequestModel'
import { AnnouncementFormValidations } from '../../../validations/AnnouncementFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { RoleEnum } from '../../../../enums/RoleEnum'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const AnnouncementFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [announcement, setAnnouncement] = useState<IAnnouncementModel>(announcementInitValues)
  const [message, setMessage] = useState('')
  const [pinned, setPinned] = useState(false)
  const [roles, setRoles] = useState<SelectModel[]>([])

  const [selectedRoles, setSelectedRoles] = useState<SelectModel[]>([])
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  const formik = useFormik<IAnnouncementModel>({
    initialValues: announcement,
    enableReinitialize: true,
    validationSchema: AnnouncementFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IAnnouncementRequestModel = {
          id: values.id == '' ? undefined : values.id,
          title: values.title,
          message: message,
          isPinned: pinned,
          roleId: selectedRoles.length == 1 ? selectedRoles[0].value : undefined,
          endTime: values.endTime == '' ? undefined : values.endTime,
          userIds: users.filter(x => x.check == true).map(i => i.id)
        }
        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: IAnnouncementRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = await AnnouncementApi.AddAnnouncement(model)
      else {
        result = await AnnouncementApi.UpdateAnnouncement(model)
      }

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IAnnouncementModel
    if (itemId !== '') {
      var response = await AnnouncementApi.GetAnnouncement(itemId)
      result = response.data
      setPinned(result.isPinned)
    } else {
      var responseRoles = await RoleApi.GetRoleDictionary()
      var dataRoles: SelectModel[] = [...responseRoles.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      setRoles(dataRoles)
      result = announcementInitValues
    }
    setAnnouncement(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const getUsers = async (e: SelectModel[]) => {
    var filters: DictionaryModel[] = []
    e.forEach(selectRole => {
      if (selectRole.label != RoleEnum.Student) {
        filters.push({ type: "Role", value: selectRole.label!! });
      }
    });
    if (filters.length > 0) {
      var responseUsers = await UserApi.GetUserDictionaryFilter(filters)
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setUsers(dataUsers);
    } else {
      setUsers([])
    }
  }
  const handleChangeSelectUser = (e: any, index: number) => {
    var newUsers = users;
    newUsers[index].check = !newUsers[index].check;
    setUsers([...newUsers]);
  }
  const handleChangeRoles = (e: any) => {
    getUsers(e);
    setSelectedRoles(e);
  }
  const handleChangePinned = () => {
    setPinned(!pinned);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-5 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Announcement</h1>
              <div className='text-muted fw-bold fs-5'>
                Announcement Management <small>Educall</small>
              </div>
            </div>

            <div className='row mb-6'>
              <div className={'col-' + (itemId === '' ? '6' : '9')}>
                <label className='form-label fw-bolder required'>Title</label>
                <input
                  {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  defaultValue={announcement.title}
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
              </div>
              {itemId == '' &&
                <div className='col-lg-3'>
                  <label className='form-label fw-bolder required'>Role</label>
                  <Select
                    {...formik.getFieldProps('roleId')}
                    isMulti={true}
                    styles={customSelectStyles(true)}
                    onChange={(event) => handleChangeRoles(event)}
                    value={selectedRoles}
                    options={roles}
                  />
                  <FormikValidationError touched={formik.touched.roleId} error={formik.errors.roleId} />
                </div>
              }
              <div className='col-3'>
                <label className='form-label fw-bolder'>End Time</label>
                <input
                  {...formik.getFieldProps('endTime')}
                  type='datetime-local'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='End Time'
                />
                {formik.touched.endTime && formik.errors.endTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.endTime}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Message</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setMessage}
                data={announcement.message}
              />
            </div>
            <div className='mb-10'>
              <h4>Sending will {users.length} users</h4>
              {users.map((item, index) => {
                return (
                  <label className='badge badge-light-primary m-1' key={index}>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        defaultChecked={item.check}
                        onChange={(e) => handleChangeSelectUser(e, index)}
                        value={item.email}
                      />
                      &nbsp; {item.email}
                    </div>
                  </label>
                )
              })}
            </div>
            <div className='form-check form-switch form-check-custom form-check-solid me-10'>
              <input
                {...formik.getFieldProps('isPinned')}
                className='form-check-input h-30px w-50px'
                type='checkbox'
                name='isPinned'
                checked={pinned}
                onClick={handleChangePinned}
              />
              <label className='form-check-label'>Pinned</label>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { AnnouncementFormModal }
