export interface IContactModel {
  id: number
  name: string
  email: string
  phone: string
  subject: string
  message: string
  createdAt: string
}

const contactInitValues: IContactModel = {
  id: 0,
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  createdAt: ''
}

export { contactInitValues }
