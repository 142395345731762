import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { ITaskTransactionRequestModel } from '../../../../models/requests/TaskRequestModel'
import { TaskFormValidations } from '../../../validations/TaskFormValidations'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import { ITaskDefineModel, ITaskModel, taskInitValues } from '../../../../models/responses/TaskModel'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import TaskApi from '../../../../infrastructure/api/TaskApi'
import parse from 'html-react-parser'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const TaskTransactionModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [task, setTask] = useState<ITaskModel>(taskInitValues)
  const [message, setMessage] = useState('')
  const [isCompleted, setCompleted] = useState(false)
  const taskDefines: ITaskDefineModel[] = useSelector<RootState>(({ task }) => task.taskDefines, shallowEqual) as ITaskDefineModel[]

  const formik = useFormik<ITaskModel>({
    initialValues: task,
    enableReinitialize: true,
    validationSchema: TaskFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ITaskTransactionRequestModel = {
          id: values.id == '' ? undefined : values.id,
          taskProcessId: task.id,
          message: message,
          isCompleted: isCompleted
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: ITaskTransactionRequestModel) {
    try {
      await TaskApi.AddTransactionToTask(model)

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: ITaskModel
    if (itemId !== '') {
      var response = await TaskApi.GetTask(itemId)
      result = response.data
    } else {
      result = taskInitValues
    }
    setTask(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeCompleted = () => {
    setCompleted(!isCompleted);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-5 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Reply Task</h1>
              <div className='text-muted fw-bold fs-5'>
                Task Management <small>Educall</small>
              </div>
            </div>

            <div className='row mb-6'>
              <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 ribbon ribbon-end ribbon-clip ">
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0"><i className="fas fa-tasks"></i></span>
                <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                  <h5 className="mb-1">{taskDefines.find(i => i.id == task.taskDefineId)?.title.toUpperCase()}</h5>
                  <span>{parse(task.explanation)}</span>
                </div>
              </div>

            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Message</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setMessage}
                data={''}
              />
            </div>

            <div className='form-check form-switch form-check-custom form-check-solid mb-10'>
              <input
                {...formik.getFieldProps('isCompleted')}
                className='form-check-input h-30px w-50px'
                type='checkbox'
                name='isCompleted'
                checked={isCompleted}
                onClick={handleChangeCompleted}
              />
              <label className='form-check-label'>Is Completed</label>
            </div>

          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { TaskTransactionModal }
