import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IAutoCommentModel } from '../../models/responses/AutoCommentModel'
import { IAutoCommentRequestModel } from '../../models/requests/AutoCommentRequestModel'

class AnnouncementApi {
  async GetAutoComments(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IAutoCommentModel[]>>(`s/Admin/AutoComments${generateQueryParams(filter)}`)
  }

  async GetAutoComment(id: string) {
    return await API.get<IAutoCommentModel>('s/Admin/AutoComments/' + id)
  }

  async AddAutoComment(model: IAutoCommentRequestModel) {
    return await API.post('s/Admin/AutoComments', model)
  }

  async UpdateAutoComment(model: IAutoCommentRequestModel) {
    return await API.put('s/Admin/AutoComments/' + model.id, model)
  }

  async DeleteAutoComment(itemId: string) {
    return await API.delete<boolean>('s/Admin/AutoComments/' + itemId)
  }
}

export default new AnnouncementApi()
