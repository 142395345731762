export interface IPricingCouponModel {
  id: number
  code: string
  amount: number
  percent: number
  extraMonth: number
  conditionAmount: number
  conditionMonth: number
  capacity: number
  usedCount: number
  definedUserId: number
  startDate: string
  endDate: string
}

const pricingCouponInitValues: IPricingCouponModel = {
  id: 0,
  code: '',
  amount: 0,
  percent: 0,
  extraMonth: 0,
  conditionAmount: 0,
  conditionMonth: 0,
  capacity: 0,
  usedCount: 0,
  definedUserId: 0,
  startDate: '',
  endDate: '',
}

export {pricingCouponInitValues}
