export interface ITaskModel {
    id: string
    taskDefineId: string
    assignedUsers: Array<string>
    associatedUsers: Array<string>
    isOneTransaction: boolean
    createdAt: string
    createdBy: string
    expirationAt: string
    explanation: string
    isCompleted: boolean
    transactionNumber: number
    transactions: Array<ITaskTransactionModel>
    order: number
}

export interface ITaskTransactionModel {
    userId: string
    message: string
    startedAt: string
    createdAt: string
    isCompleted: boolean
}

const taskInitValues: ITaskModel = {
    id: '',
    taskDefineId: '',
    assignedUsers: [],
    associatedUsers: [],
    isOneTransaction: false,
    createdAt: '',
    createdBy: '',
    expirationAt: '',
    explanation: '',
    isCompleted: false,
    transactions: [],
    transactionNumber: 0,
    order: 0,
}

export interface ITaskDefineModel {
    id: string,
    title: string,
    definition: string,
    order: number,
    expiryDay: number,
    responsibleUsers: Array<string>,
    isOneTransaction: boolean
}

const taskDefineInitValues: ITaskDefineModel = {
    id: '',
    title: '',
    definition: '',
    order: 0,
    expiryDay: 0,
    responsibleUsers: [],
    isOneTransaction: false
}

export { taskInitValues, taskDefineInitValues }
