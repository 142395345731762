import { DictionaryModel } from '../responses/DictionaryModel'

export interface IUserClaimsRequestModel {
  id: string
  claims?: Array<DictionaryModel>
}

const userClaimsInitValues: IUserClaimsRequestModel = {
  id: '',
  claims: []
}

export interface IUserClaimRequestModel {
  id: string
  claim?: DictionaryModel
}

const userClaimInitValues: IUserClaimRequestModel = {
  id: '',
  claim: { type: "", value: "" }
}


export { userClaimsInitValues, userClaimInitValues }