import { FC, Fragment, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { BasicTable } from '../../../components/tables/BasicTable'
import TrainerCallReportApi from '../../../../infrastructure/api/TrainerCallReportApi'
import { ITrainerCallReportModel } from '../../../../models/responses/TrainerCallReportModel'
import TableContainer from '@material-ui/core/TableContainer'
import { Paper } from '@material-ui/core'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { downloadFile } from '../../../helpers/FileHelper'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const TrainerCallReportViewModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState<ITrainerCallReportModel>()
  const excelJS = require("exceljs");



  async function init() {
    if (itemId !== '') {
      var response = await TrainerCallReportApi.GetTrainerCallReport(itemId)
      setReport(response.data)
    }
  }

  useEffect(() => {
    if (show) init()
  }, [itemId, show])

  async function downloadExcel() {

    var data: any[] = []
    for (let index = 0; index < report?.data.length!!; index++) {
      var d = report?.data[index]!!;

      data.push({
        trainer: d.firstName + " " + d.lastName,
        totalWorkMinutes: d.totalWorkMinutes,
        plannedWorkMinutes: d.plannedWorkMinutes,
        intensityRatio: "%" + d.intensityRatio.toFixed(2),
        completed: d.completed,
        cancelled: d.cancelled,
        closed: d.closed,

      })
      report?.data[index].schedules.map(s => (

        data.push({
          trainer: "",
          totalWorkMinutes: "",
          plannedWorkMinutes: "",
          intensityRatio: "",
          completed: "",
          cancelled: "",
          closed: "",

          student: s.firstName + " " + s.lastName,
          duration: s.duration,
          plannedDate: s.plannedDate,
          startedDate: s.startedDate,
          completedDate: s.completedDate,
          violation: parseFloat(s.deviationMinutes.toFixed(2)),
          delay: parseFloat(s.delayMinutes.toFixed(2)),
        })
      ))

    }



    var fields = [
      { key: 'trainer', header: 'Trainer', width: 30 },
      { key: 'totalWorkMinutes', header: 'Total Work Minutes' },
      { key: 'plannedWorkMinutes', header: 'Planned Work Minutes' },
      { key: 'intensityRatio', header: 'Intensity Ratio' },
      { key: 'completed', header: 'Completed' },
      { key: 'cancelled', header: 'Cancelled' },
      { key: 'closed', header: 'Closed' },

      { key: 'student', header: 'Student', width: 30 },
      { key: 'duration', header: 'Duration (mins)' },
      { key: 'plannedDate', header: 'Planned Date', width: 30 },
      { key: 'startedDate', header: 'Started Date', width: 30 },
      { key: 'completedDate', header: 'Completed Date', width: 30 },
      {
        key: 'delay', header: 'Delay (mins)', width: 15, font: {
          color: {
            argb: '00FF0000',
            theme: 1,
          },
        },
      },
      {
        key: 'violation', header: 'Violation (mins)', width: 15, font: {
          color: {
            argb: '00FF0000',
            theme: 1,
          },
        },
      },
    ];

    const workbook = new excelJS.Workbook();
    const worksheet = workbook.addWorksheet("Educall Trainer Call Reports");

    worksheet.columns = fields
    worksheet.getRow(1).eachCell((cell: any) => {
      cell.font = { bold: true };
    });




    worksheet.addRows(data);

    // worksheet.eachRow({ includeEmpty: false }, function (row: any, rowNumber: any) {
    //   if(row.cellCount = 13){

    //   }
    //   console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.cellCount));
    // });

    const column = worksheet.getColumn(13);
    column.style = { font: { bold: true } };

    worksheet.addConditionalFormatting({
      ref: `M2:M${data.length + 1}`,
      rules: [
        {
          type: 'cellIs',
          operator: 'greaterThan',
          formulae: [4],
          style: {
            fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'ff0000' }, fgColor: { argb: 'FFFFFF' } }
          },
        },
        {
          type: 'cellIs',
          operator: 'lessThan',
          formulae: [-4],
          style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'ff0000' }, fgColor: { argb: 'FFFFFF' } } },
        }
      ]
    })

    worksheet.addConditionalFormatting({
      ref: `M2:N${data.length + 1}`,
      rules: [
        {
          type: 'cellIs',
          operator: 'greaterThan',
          formulae: [4],
          style: {
            fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'ff0000' }, fgColor: { argb: 'FFFFFF' } }
          },
        },
        {
          type: 'cellIs',
          operator: 'lessThan',
          formulae: [-4],
          style: { fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: 'ff0000' }, fgColor: { argb: 'FFFFFF' } } },
        }
      ]
    })

    const buffer = await workbook.xlsx.writeBuffer();

    downloadFile(buffer, `Educall Trainer Call Reports - ${report?.createdAt}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;');
    setComplete(true)
  }

  const generateRow = (schedules: any, index: number) => {
    return (
      <Fragment key={index}>
        <tr style={{ backgroundColor: "#F4F4F4" }}>
          <td colSpan={1}></td>
          <td colSpan={9} style={{ width: '100%' }}>
            {schedules.length > 0 ?

              <TableContainer component={Paper} className={"mt-5"}>
                <BasicTable
                  title=''
                  customOptions={{
                    setTableProps: () => {
                      return {
                        size: 'small',
                      };
                    },
                    search: false,
                    sort: true,
                    filter: false,
                    viewColumns: false,
                    rowsPerPageOptions: [5],
                    rowsPerPage: 5
                  }}
                  data={schedules}
                  columns={[
                    {
                      label: '#',
                      name: '#',
                      options: {
                        customBodyRenderLite: (dataIndex) => {
                          return <>{++dataIndex}</>
                        },
                        sort: false,
                        viewColumns: false
                      },
                    },
                    {
                      label: 'Student',
                      name: 'student',
                      options: {
                        sort: false,
                        customBodyRenderLite: (dataIndex) => {
                          return <span>{schedules[dataIndex].firstName} {schedules[dataIndex].lastName}</span>
                        },
                      },
                    },
                    {
                      label: "Duration", name: "duration", options: {
                        customBodyRender: (value) => {
                          return <>{value}</>
                        }
                      }
                    },
                    {
                      label: "PlannedDate", name: "plannedDate", options: {
                        customBodyRender: (value) => {
                          return <>{value}</>
                        }
                      }
                    },
                    {
                      label: "StartedDate", name: "startedDate", options: {
                        customBodyRender: (value) => {
                          return <>{value}</>
                        }
                      }
                    },
                    {
                      label: "CompletedDate", name: "completedDate", options: {
                        customBodyRender: (value) => {
                          return <>{value}</>
                        }
                      }
                    },
                    {
                      label: "Delay", name: "delayMinutes", options: {
                        customBodyRender: (value) => {
                          return value > 4 || value < -4 ? <span className='text-danger'>{value.toFixed(2)}</span> : <span>{value.toFixed(2)}</span>
                        }
                      }
                    },
                    {
                      label: "Violation", name: "deviationMinutes", options: {
                        customBodyRender: (value) => {
                          return value > 4 || value < -4 ? <span className='text-danger'>{value.toFixed(2)}</span> : <span>{value.toFixed(2)}</span>
                        }
                      }
                    },


                  ]}
                />
              </TableContainer>
              :
              <div className="alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10 mt-5">
                <div className="d-flex flex-column text-light pe-0 pe-sm-10">
                  <span>No details.</span>
                </div>
              </div>

            }
          </td>
        </tr>
      </Fragment >

    )
  }

  const options = {
    sort: false,
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      return (
        generateRow(report?.data!![rowMeta.dataIndex].schedules, rowMeta.dataIndex)
      );
    },
  };

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body mx-5 pt-0'>
          <div className='text-center mb-6'>
            <h1 className='mb-3'>View Trainer Call Report</h1>
            <div className='text-muted fw-bold fs-5 mb-6'>
              Report Management <small>Educall</small>
            </div>
          </div>
          <div className='row'>
            <BasicTable
              title={`Report: ${report?.createdAt} ${report?.dayOfWeek != undefined ? DayOfWeekEnum[report?.dayOfWeek!!] : " "}` }
              data={report?.data}
              columns={[
                {
                  label: '#',
                  name: '#',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <>{++dataIndex}</>
                    },
                    sort: false,
                    viewColumns: false
                  },
                },
                {
                  label: 'Trainer',
                  name: 'trainer',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <span>{report?.data[dataIndex].firstName} {report?.data[dataIndex].lastName}</span>
                    },
                  },
                },
                {
                  label: 'Total Work Minutes',
                  name: 'totalWorkMinutes',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value.toFixed(2)}</span>
                    },
                  },
                },
                {
                  label: 'Planned Work Minutes',
                  name: 'plannedWorkMinutes',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value.toFixed(2)}</span>
                    },
                  },
                },
                {
                  label: 'Intensity Ratio',
                  name: 'intensityRatio',
                  options: {
                    customBodyRender: (value) => {
                      return <span>%{value.toFixed(2)}</span>
                    },
                  },
                },
                {
                  label: 'Completed',
                  name: 'completed',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Cancelled',
                  name: 'cancelled',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },
                {
                  label: 'Closed',
                  name: 'closed',
                  options: {
                    customBodyRender: (value) => {
                      return <span>{value}</span>
                    },
                  },
                },


              ]}
              customOptions={options}
              Content={() => {
                return (
                  <SaveChangesButton
                    loading={loading}
                    setLoading={setLoading}
                    complete={complete}
                    setComplete={setComplete}
                    valid={true}
                    submit={true}
                    customClasses={!loading ? "btn-sm bg-transparent btn-outline-primary btn-icon btn-active-light-dark btn-circle btn-custom" : "btn-sm btn-secondary"}
                    title={"<i className='fas fa-download text-gray-700'></i>"}
                    setSubmit={() => downloadExcel()} />
                )
              }}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <div className='d-flex flex-center '>
            <button className='btn btn-primary' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export { TrainerCallReportViewModal }
