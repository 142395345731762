import { IGuideModel } from './GuideModel'

export interface IUnitModel {
  id: number
  title: string
  explanation: string
  levelId: number
  levelName: string
  questionFrequency: number
  unitType: number,
  guides: IGuideModel[]
}

const unitInitValues: IUnitModel = {
  id: 0,
  title: '',
  explanation: '',
  levelId: 0,
  levelName: '',
  questionFrequency: 0,
  unitType: 0,
  guides: []
}

export { unitInitValues }
