export interface IBlogCategoryRequestModel {
  id?: number
  name: string
  about: string
  link: string
  order: number
  parentBlogCategoryId: number
}


const blogCategoryRequestInitValues = {
  id: 0,
  name: '',
  about: '',
  link: '',
  order: 0,
  parentBlogCategoryId: 0
}

export { blogCategoryRequestInitValues }