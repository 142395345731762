import { IContactRequestModel } from '../../models/requests/ContactRequestModel'
import { IContactModel } from '../../models/responses/ContactModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ContactApi {

  async GetContacts(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IContactModel[]>>(`e/Admin/Contacts${generateQueryParams(filter)}`)
  }

  async GetContact(id: number) {
    return await API.get<IContactModel>('e/Admin/Contacts/' + id)
  }

  async AddContact(model: IContactRequestModel) {
    return await API.post('e/Admin/Contacts', model)
  }

  async UpdateContact(model: IContactRequestModel) {
    return await API.put('e/Admin/Contacts/' + model.id, model)
  }

  async DeleteContact(itemId: number) {
    return await API.delete<boolean>('e/Admin/Contacts/' + itemId)
  }
}

export default new ContactApi()
