import { IPaymentRequestModel } from '../../models/requests/PaymentRequestModel'
import { IPaymentModel } from '../../models/responses/PaymentModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PaymentApi {

  async GetPayments(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPaymentModel[]>>(`e/Admin/Payments${generateQueryParams(filter)}`)
  }

  async GetPayment(id: number) {
    return await API.get<IPaymentModel>('e/Admin/Payments/' + id)
  }

  async AddPayment(model: IPaymentRequestModel) {
    return await API.post('e/Admin/Payments', model)
  }

  async UpdatePayment(model: IPaymentRequestModel) {
    return await API.put('e/Admin/Payments/' + model.id, model)
  }

  async DeletePayment(itemId: number) {
    return await API.delete<boolean>('e/Admin/Payments/' + itemId)
  }
}

export default new PaymentApi()
