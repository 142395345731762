export interface IProfileDetailsFormModel {
    photo: any,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    birthDate: string,
    gender: string,
    address: string,
    countryId: number,
    cityId: number,
    stateId: number,
    zipCode: string,
    language: number,
    timeZone: string,
    currency: string
}

const profileDetailsInitValues: IProfileDetailsFormModel = {
    photo: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    birthDate: '',
    gender: '0',
    address: '',
    countryId: 0,
    cityId: 0,
    stateId: 0,
    zipCode: '',
    language: 0,
    timeZone: '',
    currency: ''
}

export { profileDetailsInitValues }