import { IBlogCategoryRequestModel } from '../../models/requests/BlogCategoryRequestModel'
import { IBlogCategoryModel } from '../../models/responses/BlogCategoryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class BlogCategoryApi {

  async GetBlogCategories(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IBlogCategoryModel[]>>(`e/Admin/BlogCategories${generateQueryParams(filter)}`)
  }

  async GetBlogCategoryDictionary() {
    return await API.get<IBlogCategoryModel[]>(`e/Admin/BlogCategories/dictionary`)
  }

  async GetBlogCategory(id: number) {
    return await API.get<IBlogCategoryModel>('e/Admin/BlogCategories/' + id)
  }

  async AddBlogCategory(model: IBlogCategoryRequestModel) {
    return await API.post('e/Admin/BlogCategories', model)
  }

  async UpdateBlogCategory(model: IBlogCategoryRequestModel) {
    return await API.put('e/Admin/BlogCategories/' + model.id, model)
  }

  async DeleteBlogCategory(itemId: number) {
    return await API.delete<boolean>('e/Admin/BlogCategories/' + itemId)
  }
}

export default new BlogCategoryApi()
