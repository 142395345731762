import RegionApi from '../../infrastructure/api/RegionApi'
import { DictionaryModel } from "../../models/responses/DictionaryModel";
import { selectInitValues } from '../models/SelectModel';

class RegionHelper {
    user?: Array<DictionaryModel>
    prefix: string
    setCountries: any
    setCities: any
    setStates: any
    setSelectedCountry: any
    setSelectedCity: any
    setSelectedState: any

    constructor(user: Array<DictionaryModel>,
        prefix: string,
        setCountries: any,
        setCities: any,
        setStates: any,
        setSelectedCountry: any,
        setSelectedCity: any,
        setSelectedState: any) {

        this.user = user
        this.prefix = prefix
        this.setCountries = setCountries
        this.setCities = setCities
        this.setStates = setStates
        this.setSelectedCountry = setSelectedCountry
        this.setSelectedCity = setSelectedCity
        this.setSelectedState = setSelectedState
    }

    getCountries = async () => {
        const response = await RegionApi.GetCountries()
        const countries = response.data.map(d => ({
            "value": d.id.toString(),
            "label": d.name
        }));
        this.setCountries([selectInitValues, ...countries])

        const countryId = this.user?.find(i => i.type == this.prefix + "CountryId")?.value
        if (countryId) {
            this.handleChangeCountry({
                value: countryId,
                label: countries.find(i => i.value == countryId)?.label
            })
        }
    }

    getCities = async (countryId: number) => {
        const response = await RegionApi.GetCities(countryId)
        const cities = response.data.map(d => ({
            "value": d.id.toString(),
            "label": d.name
        }));
        this.setCities([selectInitValues, ...cities])
        const cityId = this.user?.find(i => i.type == this.prefix + "CityId")?.value!!
        if (cityId) {
            this.handleChangeCity({
                value: cityId,
                label: cities.find(i => i.value == cityId)?.label
            })
        }
    }

    getStates = async (cityId: number) => {
        const response = await RegionApi.GetStates(cityId)
        const states = response.data.map(d => ({
            "value": d.id.toString(),
            "label": d.name
        }))
        this.setStates([selectInitValues, ...states])
        const stateId = this.user?.find(i => i.type == this.prefix + "StateId")?.value!!
        if (stateId) {
            this.handleChangeState({
                value: stateId,
                label: states.find(i => i.value == stateId)?.label
            })
        }
    }

    handleChangeCountry = (e: any) => {
        this.setSelectedCountry(e);
        this.getCities(e.value);
    }

    handleChangeCity = (e: any) => {
        this.setSelectedCity(e);
        this.getStates(e.value);
    }

    handleChangeState = (e: any) => {
        this.setSelectedState(e);
    }
}

export const useRegionHelper = (user: Array<DictionaryModel>,
    prefix: string,
    setCountries: any,
    setCities: any,
    setStates: any,
    setSelectedCountry: any,
    setSelectedCity: any,
    setSelectedState: any) => {
    return new RegionHelper(user, prefix, setCountries, setCities, setStates, setSelectedCountry, setSelectedCity, setSelectedState);
}
