import React, { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from "react-router-dom";

import RoleApi from '../../../../infrastructure/api/RoleApi'
import { RoleModel } from '../../../../models/responses/RoleModel'

type Props = {
  onClickItem: (itemId: string, itemName: string, type: string) => void
  refresh: number
}

const RoleListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [roles, setRoles] = useState([] as RoleModel[])
  useEffect(() => {
    getRoles()
  }, [refresh])

  async function getRoles() {
    var response = await RoleApi.GetRoles();
    setRoles(response.data.items)
  }

  return (
    <>
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
        <div className="col-md-4">
          <div className="card h-md-100">
            <div className="card-body d-flex flex-center">
              <button type="button" className="btn btn-clear d-flex flex-column flex-center">
                <img
                  onClick={() => onClickItem('', '', 'create')}
                  src={toAbsoluteUrl('/media/illustrations/sketchy-1/4.png')} alt="" className="mw-100 mh-150px mb-7" />
                <div className="fw-bolder fs-3 text-gray-600 text-hover-primary">Add New Role</div>
              </button>
            </div>
          </div>
        </div>
        {roles.map((item, i) => {
          return (
            <div key={i} className="col-md-4">
              <div className="card card-flush h-md-100">
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{item.name}</span>
                    <span className='text-muted mt-1 fw-bold fs-7'>Have {item.permissions!!.length} permissions</span>
                  </h3>
                </div>
                <div className="card-body pt-1">
                  <div className="d-flex flex-column text-gray-600">
                    {/*item.permissions.sort().map((permission, j) => {
                      return (
                        <div key={i + "-" + j} className="d-flex align-items-center py-2">
                          <span className="bullet bg-primary me-3"></span> {permission.split(/(?=[A-Z])/).join(" ")}
                        </div>
                      )
                    })*/}
                  </div>
                </div>
                <div className="card-footer flex-wrap pt-0">
                  <Link to={'/account/roles/view/' + item.id} className="btn btn-light btn-active-default my-1 me-2">View Role</Link>
                  {(item.name != "Administrator" && item.name != "Trainer" && item.name != "CompanyManager" && item.name != "Student") &&
                    (
                      <>
                        <Link to={'/account/roles/edit/' + item.id} onClick={() => onClickItem(item.id, `${item.name} `, 'update')} className="btn btn-light-primary btn-active-primary my-1 me-2">Edit Role</Link>
                        <Link to={'/account/roles/edit/' + item.id} onClick={() => onClickItem(item.id, `${item.name} `, 'delete')} className="btn btn-light-danger btn-active-danger my-1">Delete</Link>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export { RoleListModal }