import { FC, Fragment, useEffect, useState } from 'react'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { IReportModel } from '../../../../models/responses/ReportModel'
import ReportApi from '../../../../infrastructure/api/ReportApi'
import { BasicTable } from '../../../components/tables/BasicTable'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const ReportListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('title_asc')
    const [reports, setReports] = useState<IReportModel[]>()

    let now = new Date();
    let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

    const [start, setStart] = useState(startTime)
    const [end, setEnd] = useState(endTime)

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
        getReport(startDate, endDate)
    }

    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false,
    }

    async function getReport(start: moment.Moment, end: moment.Moment) {
        var startTime = start.format("YYYY-MM-DDT00:00:00");
        var endTime = end.format("YYYY-MM-DDT23:59:59");
        var response = await ReportApi.GetReports({
            startDate: startTime,
            endDate: endTime
        })

        setReports(response.data)
    }

    async function init() {
        getReport(start, end)
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort])

    const generateRow = (report: IReportModel) => {
        return (
            <Fragment>
                <tr>
                    <td colSpan={2}></td>
                    <td colSpan={6}>
                        <td>
                            <TableContainer component={Paper} className={"mt-5"}>
                                <Table size={'small'} aria-label="a dense table">
                                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                                        <TableRow>
                                            <TableCell>Demo Infos</TableCell>
                                            <TableCell align='right'>Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Complete</TableCell>
                                            <TableCell align='right' >{report.demoComplete}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Cancel</TableCell>
                                            <TableCell align='right' >{report.demoCancel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Status Changes</TableCell>
                                            <TableCell align='right' >{report.demoStatusChanges}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                        <td style={{ "paddingLeft": "10px" }}>
                            <TableContainer component={Paper} className={"mt-5 me-5"}>
                                <Table size={'small'} aria-label="a dense table">
                                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                                        <TableRow>
                                            <TableCell>Individial Infos</TableCell>
                                            <TableCell align='right'>Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Student</TableCell>
                                            <TableCell align='right' >{report.individual.student}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>With Plan</TableCell>
                                            <TableCell align='right' >{report.individual.studentWithPlan}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Without Plan</TableCell>
                                            <TableCell align='right' >{report.individual.studentWithoutPlan}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                        <td style={{ "paddingLeft": "10px" }}>
                            <TableContainer component={Paper} className={"mt-5 me-5"}>
                                <Table size={'small'} aria-label="a dense table">
                                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                                        <TableRow>
                                            <TableCell>Company Infos</TableCell>
                                            <TableCell align='right'>Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Student</TableCell>
                                            <TableCell align='right' >{report.company.student}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>With Plan</TableCell>
                                            <TableCell align='right' >{report.company.studentWithPlan}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Without Plan</TableCell>
                                            <TableCell align='right' >{report.company.studentWithoutPlan}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                        <td style={{ "paddingLeft": "10px" }}>
                            <TableContainer component={Paper} className={"mt-5 me-5"}>
                                <Table size={'small'} aria-label="a dense table">
                                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                                        <TableRow>
                                            <TableCell>Package Infos</TableCell>
                                            <TableCell align='right'>Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>No Tolk</TableCell>
                                            <TableCell align='right' >{report.noTolk}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Min Tolk</TableCell>
                                            <TableCell align='right' >{report.minTolk}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>New Sale</TableCell>
                                            <TableCell align='right' >{report.newSale}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Re Sale</TableCell>
                                            <TableCell align='right' >{report.reSale}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                        <td style={{ "paddingLeft": "10px" }}>
                            <TableContainer component={Paper} className={"mt-5 me-5"}>
                                <Table size={'small'} aria-label="a dense table">
                                    <TableHead style={{ backgroundColor: '#e9e9e9' }}>
                                        <TableRow>
                                            <TableCell>Plan Infos</TableCell>
                                            <TableCell align='right'>Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Create</TableCell>
                                            <TableCell align='right' >{report.createPlan}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Delete</TableCell>
                                            <TableCell align='right' >{report.deletePlan}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </td>
                </tr>
            </Fragment >

        )
    }

    const options = {
        sort: false,
        search: false,
        rowsPerPage: pageSize,
        expandableRows: true,
        renderExpandableRow: (rowData: any, rowMeta: any) => {
            return (
                generateRow(reports!![rowMeta.rowIndex])
            );
        },
    };


    return (
        <>
            <div className='row'>
                <div className='col-lg-3'>
                    <label className='form-label fw-bolder'>Date Range</label>
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={start}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={end}
                        local={local}
                        applyCallback={applyCallback}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${start.format(local.format)} - ${end.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                </div>
            </div>
            <BasicTable
                title='Reports'
                data={reports}
                customOptions={options}
                columns={[
                    {
                        label: '#',
                        name: '#',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                            },
                            sort: false,
                            viewColumns: false
                        },
                    },
                    {
                        label: "Date", name: "date", options: {
                            customBodyRender: (value) => {
                                return <>{value.substring(0, 10)}</>
                            }
                        }
                    },
                    {
                        label: "Complete", name: "complete", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Cancel", name: "cancel", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Close", name: "close", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Student", name: "student", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Demo Student", name: "demoStudent", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                    {
                        label: "Trainer", name: "trainer", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            }
                        }
                    },
                ]}
            />
        </>
    )
}

export { ReportListModal }
