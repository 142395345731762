import { DayOfWeekEnum } from '../../enums/DayOfWeekEnum'
import { ExchangeEnum } from '../../enums/ExchangeEnum'
import { ScheduleTypeEnum } from '../../enums/ScheduleTypeEnum'
import { SuspendedReasonEnum } from '../../enums/SuspendedReasonEnum'

export interface ITrainerModel {
    id?: string
    isFreezeAccount: boolean
    freezeAccountDate: string
    trainerPlans: ITrainerPlanModel[]
    availablePlans: ITrainerPlanModel[]
    schedulePlans: ISchedulePlanModel[]
    responsibleLevels: number[]
}

const trainerInitValues: ITrainerModel = {
    id: undefined,
    isFreezeAccount: false,
    freezeAccountDate: '',
    availablePlans: [],
    trainerPlans: [],
    schedulePlans: [],
    responsibleLevels: []
}

export interface ITrainerSettingsModel {
    id?: string
    description: string,
    shortDescription: string,
    image: string,
    video: string,
    isFeatured: boolean
    isAvailableToCall: boolean
    performancePay: boolean
    paymentExchange: ExchangeEnum
    paymentRate: string,
    companyId: string
}

const trainerSettingsInitValues: ITrainerSettingsModel = {
    id: undefined,
    description: '',
    shortDescription: '',
    image: '',
    video: '',
    isFeatured: false,
    isAvailableToCall: false,
    performancePay: false,
    paymentExchange: ExchangeEnum.TRY,
    paymentRate: '0',
    companyId: ''
}


export interface ITrainersModel {
    id?: string
    firstName: string,
    lastName: string,
    isFreezeAccount: boolean
    trainerPlans: ITrainerPlanModel[]
    availablePlans: ITrainerPlanModel[]
    schedulePlans: ISchedulePlanModel[]
    responsibleLevelCount: number
    studentConsultancyCount: number
    todayMinutes: number
    weekMinutes: number
    isConnected: boolean
}
const trainersInitValues: ITrainersModel = {
    id: undefined,
    firstName: '',
    lastName: '',
    isFreezeAccount: false,
    availablePlans: [],
    trainerPlans: [],
    schedulePlans: [],
    responsibleLevelCount: 0,
    studentConsultancyCount: 0,
    todayMinutes: 0,
    weekMinutes: 0,
    isConnected: false
}


export interface ITrainerDictionaryModel {
    id: string
    firstName: string,
    lastName: string,
    email: string
}
const trainerDictionaryInitValues: ITrainerDictionaryModel = {
    id: '',
    firstName: '',
    lastName: '',
    email: ''
}

export interface ITrainerPlanModel {
    id?: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number
}

const trainerPlanInitValues: ITrainerPlanModel = {
    id: undefined,
    dayOfWeek: DayOfWeekEnum.Monday,
    startDate: '',
    endDate: '',
    duration: 0
}

export interface ICancelTrainerPlanModel {
    trainerId: string,
    schedulePlanId?: string,
    startDate?: string,
    endDate: string,
    isSuspended: boolean,
    suspendedReason: SuspendedReasonEnum
}

const cancelTrainerPlanInitValues: ICancelTrainerPlanModel = {
    trainerId: '',
    schedulePlanId: undefined,
    startDate: undefined,
    endDate: '',
    isSuspended: false,
    suspendedReason: SuspendedReasonEnum.TrainerFreeze
}

export interface IRemoveFreezeModel {
    trainerId: string
}

export interface ISchedulePlanModel {
    id?: string,
    title: string,
    studentId: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number,
    capacity: number,
    empty: number,
    studentIds: string[],
    scheduleType: ScheduleTypeEnum,
    topicId?: string,
    topics?: IScheduleTopicStatus[],
    levelId?: number,
    trainerId?: string
    trainerFullName?: string,
}

export interface IScheduleTopicStatus {
    topicId: string,
    isComplete: boolean
}

const schedulePlanInitValues: ISchedulePlanModel = {
    id: undefined,
    title: '',
    studentId: '',
    dayOfWeek: DayOfWeekEnum.Friday,
    startDate: '',
    endDate: '',
    duration: 0,
    capacity: 0,
    empty: 0,
    studentIds: [],
    scheduleType: ScheduleTypeEnum.Group,
    topicId: undefined,
    topics: [],
    levelId: undefined,
    trainerId: undefined
}


export { trainerInitValues, trainersInitValues, trainerPlanInitValues, trainerSettingsInitValues, schedulePlanInitValues, cancelTrainerPlanInitValues, trainerDictionaryInitValues }
