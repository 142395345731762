import * as Yup from 'yup'

const PartofSpeechFormValidations = () => {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Name is required'),
  })
}

export { PartofSpeechFormValidations }
