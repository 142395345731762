import { FC, useEffect, useState } from 'react'
import PaymentApi from '../../../../infrastructure/api/PaymentApi'
import { IPaymentModel } from '../../../../models/responses/PaymentModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { Link } from 'react-router-dom'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const PaymentListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [payments, setPayments] = useState<PagedResponseModel<IPaymentModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState<Map<string, string>>(new Map())
  const [sort, setSort] = useState('id_desc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
      filters: filters
    }
    async function getPayments() {
      var response = await PaymentApi.GetPayments(paginationFilter)
      setPayments(response.data)
    }
    getPayments()
  }, [refresh, pageNumber, pageSize, searchText, sort, filters])

  return (
    <>
      <GenericTable
        title='Payments'
        data={payments?.items}
        currentTotalRecords={payments?.totalRecords!!}
        currentPageNumber={payments?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              filter: false,
              sort: false,
              viewColumns: false,
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
            },
          },
          { label: 'Code', name: 'code', options: { filter: false } },
          { label: 'Response', name: 'response', options: { filter: false } },
          { label: 'Date', name: 'date', options: { filter: false } },
          {
            label: 'Student',
            name: 'name',
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                return (
                  <div className='d-flex align-items-center'>
                    <Link className='btn-sm' to={'/account/users/' + payments?.items[dataIndex].studentId + '/overview'}> {payments?.items[dataIndex].name + ' ' + payments?.items[dataIndex].surname}</Link>
                  </div>
                )
              },
            },
          },
          { label: 'Amount', name: 'amount', options: { filter: false } },
          { label: 'Tolk', name: 'tolkCount', options: { filter: false } },
          { label: 'Munites', name: 'talkingDuration', options: { filter: false } },
          { label: 'Day', name: 'dayCount', options: { filter: false } },
          { label: 'Month', name: 'monthCount', options: { filter: false } },
          { label: 'Coupon', name: 'couponCode', options: { filter: false, sort: false } },
          { label: 'Status', name: 'response', options: { display: 'excluded', filterOptions: { names: ['Succeed', 'Failed'] } } },
          { label: 'Type', name: 'paymentTypeName', options: { display: 'excluded', filterOptions: { names: ['ManuelSale', 'PackageSale'] } } },
          { label: 'Method', name: 'paymentMethod', options: { display: 'excluded', filterOptions: { names: ['CreditCart', 'BankTransfer'] } } },
          {
            label: '#',
            name: 'Id',
            options: {
              filter: false,
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={payments?.items[dataIndex]?.id!!}
                    itemParam={payments?.items[dataIndex]?.name!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          filter: true,
          onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            setPageNumber(1)
            let newFilters: Map<string, string> = new Map()
            for (let i = 0; i < filterList.length; i++) {
              if (filterList[i].length > 0) {
                if (i == 11) newFilters.set("Response", filterList[i][0].toString())
                else if (i == 12) newFilters.set("PaymentType", filterList[i][0].toString())
                else if (i == 13) newFilters.set("PaymentMethod", filterList[i][0].toString())
              }
            }
            setFilters(newFilters)
          }
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { PaymentListModal }