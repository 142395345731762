import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { IHelpDeskModel, IHelpDesksModel } from '../../models/responses/HelpDeskModel'
import { ICompleteHelpDeskRequestModel } from '../../models/requests/HelpDeskRequestModel'

class HelpDeskApi {

  async GetHelpDesks(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IHelpDesksModel[]>>(`e/Admin/HelpDesks${generateQueryParams(filter)}`)
  }

  async GetHelpDesk(id: string) {
    return await API.get<IHelpDeskModel>('e/Admin/HelpDesks/' + id)
  }

  async CompleteHelpDesk(model: ICompleteHelpDeskRequestModel) {
    return await API.put('e/Admin/HelpDesks/' + model.id, model)
  }
}

export default new HelpDeskApi()
