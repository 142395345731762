import { ITopicRequestModel } from '../../models/requests/TopicRequestModel'
import { ISetTopicListRequestModel } from '../../models/requests/SetTopicListRequestModel'

import { IDescendingTopicsModel, ITopicModel, ITopicsModel } from '../../models/responses/TopicModel'
import { IProcessTopicModel, ITopicListModel } from '../../models/responses/TopicListModel'

import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class TopicApi {

  async GetTopics(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ITopicsModel[]>>(`t/Admin/Topics${generateQueryParams(filter)}`)
  }

  async GetTopic(id: string) {
    return await API.get<ITopicModel>('t/Admin/Topics/' + id)
  }

  async AddTopic(model: ITopicRequestModel) {
    return await API.post('t/Admin/Topics', model)
  }

  async SetTopicLists(model: ISetTopicListRequestModel) {
    return await API.post('t/Admin/TopicLists/set', model)
  }

  async UpdateTopic(model: ITopicRequestModel) {
    return await API.put('t/Admin/Topics/' + model.id, model)
  }

  async DeleteTopic(itemId: string) {
    return await API.delete<boolean>('t/Admin/Topics/' + itemId)
  }

  async GetStudentTopicLists(studentId: string, levelId: number) {
    return await API.get<ITopicListModel>(`t/Admin/TopicLists/student/${studentId}/${levelId}`)
  }

  async GetTopicListsWithLevel(levelId: number) {
    return await API.get<ITopicListModel>(`t/Admin/TopicLists/level/${levelId}`)
  }

  async ProcessTopic(model: IProcessTopicModel) {
    return await API.patch(`t/Admin/TopicLists/ProcessTopic`, model)
  }

  async GetDescendingTopics(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IDescendingTopicsModel[]>>(`t/Admin/DescendingTopics${generateQueryParams(filter)}`)
  }

  async DeleteDescendingTopic(itemId: string) {
    return await API.delete<boolean>('t/Admin/DescendingTopics/' + itemId)
  }
}

export default new TopicApi()
