import { ListModel } from '../../models/responses/ListModel'
import API from './Api'

class RegionApi {
    async GetCountries() {
        return await API.get<ListModel[]>('e/Admin/Regions/Countries')
    }
    async GetCities(countryId: number) {
        return await API.get<ListModel[]>('e/Admin/Regions/Cities/' + countryId)
    }
    async GetStates(cityId: number) {
        return await API.get<ListModel[]>('e/Admin/Regions/States/' + cityId)
    }
}

export default new RegionApi()
