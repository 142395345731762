import { FC, useEffect, useState } from 'react'
import PricingCouponApi from '../../../../infrastructure/api/PricingCouponApi'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IPricingCouponModel, pricingCouponInitValues } from '../../../../models/responses/PricingCouponModel'
import { IPricingCouponRequestModel } from '../../../../models/requests/PricingCouponRequestModel'
import { PricingCouponFormValidations } from '../../../validations/PricingCouponFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import moment from 'moment'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { generateCode } from '../../../helpers/StringHelper'
import { DiscountTypeEnum } from '../../../../enums/DiscountTypeEnum'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const PricingCouponFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [pricingCoupon, setPricingCoupon] = useState<IPricingCouponModel>(pricingCouponInitValues)
  const updatePricingCoupon = (fieldsToUpdate: Partial<IPricingCouponModel>) => {
    const updatedData = { ...pricingCoupon, ...fieldsToUpdate }
    setPricingCoupon(updatedData)
  }

  const [visibles, setVisibles] = useState<string[]>(['price']);

  const discountTypeList: SelectModel[] = [
    { value: "1", label: "Price Discount" },
    { value: "2", label: "Percent Discount" }
  ]

  const [selectedDiscountType, setSelectedDiscountType] = useState<SelectModel>(discountTypeList[0])

  let now = new Date();
  let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endTime = moment(startTime).add(1, "days").subtract(1, "seconds");

  const [start, setStart] = useState(startTime)
  const [end, setEnd] = useState(endTime)

  const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStart(startDate)
    setEnd(endDate)
  }

  let ranges = {
    "Today Only": [moment(), moment()],
    "Yesterday Only": [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "3 Days": [moment().subtract(3, "days"), moment()]
  }

  let local = {
    "format": "YYYY/MM/DD",
    "sundayFirst": false
  }

  const formik = useFormik<IPricingCouponModel>({
    initialValues: pricingCoupon,
    enableReinitialize: true,
    validationSchema: PricingCouponFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IPricingCouponRequestModel = {
          id: pricingCoupon.id,
          code: values.code,
          amount: selectedDiscountType.value == "1" ? values.amount : 0,
          percent: selectedDiscountType.value == "2" ? values.percent : 0,
          capacity: values.capacity,
          usedCount: values.usedCount,
          conditionAmount: values.conditionAmount,
          conditionMonth: values.conditionMonth,
          extraMonth: values.extraMonth,
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD")
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IPricingCouponRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await PricingCouponApi.AddPricingCoupon(model)
      else {
        result = await PricingCouponApi.UpdatePricingCoupon(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }


  async function init() {
    let result: IPricingCouponModel
    if (itemId > 0) {
      var response = await PricingCouponApi.GetPricingCoupon(itemId)
      result = response.data
      setStart(moment(result.startDate))
      setEnd(moment(result.endDate))
      handleChangeDiscountType(response.data.amount > 0 ? discountTypeList[0] : discountTypeList[1])

    } else {
      result = pricingCouponInitValues
    }
    setPricingCoupon(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeDiscountType = (e: any) => {
    setSelectedDiscountType(e);
    switch (parseInt(e.value)) {
      case DiscountTypeEnum.Price: setVisibles(['price']); break;
      case DiscountTypeEnum.Percent: setVisibles(['percent']); break;
      default: break;
    }
  }

  const handleChangeCode = (e: any) => {
    updatePricingCoupon({ code: e })
  }


  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Pricing Coupon</h1>
              <div className='text-muted fw-bold fs-5'>
                Pricing Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder required'>Code</label>
                <div className='d-flex'>
                  <input
                    {...formik.getFieldProps('code')}
                    type='text'
                    name='code'
                    className='form-control form-control-solid'
                    placeholder='Entry..'
                  />
                  <button className="btn btn-info btn-sm" type="button" onClick={() => handleChangeCode(generateCode(10))}>Generate</button>
                </div>
                <FormikValidationError touched={formik.touched.code} error={formik.errors.code} />
              </div>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder'>Date Range</label>
                <DateTimeRangeContainer
                  ranges={ranges}
                  start={start}
                  style={{
                    darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                  }}
                  end={end}
                  local={local}
                  applyCallback={applyCallback}
                >
                  <input
                    {...formik.getFieldProps('dateRange')}
                    id="formControlsTextB"
                    className='form-control form-control-solid'
                    value={`${start.format(local.format)} - ${end.format(local.format)}`}
                    type="text"
                  />
                </DateTimeRangeContainer>
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-12'>
                <h3>Rules</h3>
                <hr />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Condition Month</label>
                <input
                  {...formik.getFieldProps('conditionMonth')}
                  type='number'
                  name='conditionMonth'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.conditionMonth} error={formik.errors.conditionMonth} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Condition Price</label>
                <input
                  {...formik.getFieldProps('conditionAmount')}
                  type='text'
                  name='conditionAmount'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.conditionAmount} error={formik.errors.conditionAmount} />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>User Capacity</label>
                <input
                  {...formik.getFieldProps('capacity')}
                  type='number'
                  name='capacity'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.capacity} error={formik.errors.capacity} />
              </div>
            </div>
            <div className='row mb-10'>
              <div className='col-lg-12'>
                <h3>Discounts</h3>
                <hr />
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder'>Discount Type</label>
                <Select
                  styles={customSelectStyles(true)}
                  onChange={(value) => handleChangeDiscountType(value)}
                  value={selectedDiscountType}
                  options={discountTypeList}
                />
              </div>
              <div className='col-lg-4'>
                {visibles.some(i => i == "price") &&
                  <>
                    <label className='form-label fw-bolder'>Price Discount</label>
                    <input
                      {...formik.getFieldProps('amount')}
                      type='number'
                      name='amount'
                      className='form-control form-control-solid'
                      placeholder='Entry Price..'
                    />
                    <FormikValidationError touched={formik.touched.amount} error={formik.errors.amount} />
                  </>
                }
                {visibles.some(i => i == "percent") &&
                  <>
                    <label className='form-label fw-bolder'>Percent Discount</label>
                    <input
                      {...formik.getFieldProps('percent')}
                      type='number'
                      name='percent'
                      className='form-control form-control-solid'
                      placeholder='Entry Percent..'
                    />
                    <FormikValidationError touched={formik.touched.percent} error={formik.errors.percent} />
                  </>
                }
              </div>
              <div className='col-lg-4'>
                <label className='form-label fw-bolder required'>Extra Month</label>
                <input
                  {...formik.getFieldProps('extraMonth')}
                  type='number'
                  name='extraMonth'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.extraMonth} error={formik.errors.extraMonth} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal >
  )
}

export { PricingCouponFormModal }
