import { DictionaryModel } from '../../models/responses/DictionaryModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'

import API from './Api'
import { IUserLogModel } from '../../models/responses/UserLogModel'

class UserLogApi {
  async GetUserLogsByFilter(filter: IPaginationFilter, filters: DictionaryModel[]) {
    return await API.post<PagedResponseModel<IUserLogModel[]>>(`i/UserLogs/filters${generateQueryParams(filter)}`, filters)
  }
}

export default new UserLogApi()
