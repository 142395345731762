import { FC, useEffect, useState } from "react";
import Select from 'react-select'
import { customSelectStyles } from "../../../helpers/ReactSelectHelper";
import { SelectModel, selectInitValues } from "../../../models/SelectModel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup";
import LevelApi from "../../../../infrastructure/api/LevelApi";
import * as level from '../../../pages/levels/redux/LevelRedux'
import { EnumToList } from "../../../helpers/EnumHelper";
import { TolkPackageEnum } from "../../../../enums/TolkPackageEnum";
import CompanyApi from "../../../../infrastructure/api/CompanyApi";
import UserApi from "../../../../infrastructure/api/UserApi";
import { DictionaryModel } from "../../../../models/responses/DictionaryModel";
import { IUserDictionaryModel } from "../../../../models/responses/UserModel";
import { RoleEnum } from "../../../../enums/RoleEnum";
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
    selectedPresentLevel: SelectModel
    setSelectedPresentLevel: (event: any) => void
    selectedStartupLevel: SelectModel
    setSelectedStartupLevel: (event: any) => void
    selectedTolkPackage: SelectModel
    setSelectedTolkPackage: (event: any) => void
    selectedEducationEnded: SelectModel
    setSelectedEducationEnded: (event: any) => void
    selectedSupportedByEducall: SelectModel
    setSelectedSupportedByEducall: (event: any) => void
    selectedSupportedByCompany: SelectModel
    setSelectedSupportedByCompany: (event: any) => void
    selectedCompany: SelectModel
    setSelectedCompany: (event: any) => void
    selectedOurStudent: SelectModel
    setSelectedOurStudent: (event: any) => void
    selectedToeflStudent: SelectModel
    setSelectedToeflStudent: (event: any) => void
    selectedYdsStudent: SelectModel
    setSelectedYdsStudent: (event: any) => void
    selectedHavePlan: SelectModel
    setSelectedHavePlan: (event: any) => void
    selectedConsultant: SelectModel
    setSelectedConsultant: (event: any) => void
    selectedDemoStudent: SelectModel
    setSelectedDemoStudent: (event: any) => void
    selectedFrozenStudent: SelectModel
    setSelectedFrozenStudent: (event: any) => void
    selectedRemainingTolk: string
    setSelectedRemainingTolk: (event: any) => void
    selectedPreferCallingPlatform: SelectModel
    setSelectedPreferCallingPlatform: (event: any) => void
    selectedJobInterviewStudent: SelectModel
    setSelectedJobInterviewStudent: (event: any) => void
    setFilterType: (value: string) => void
    setPageNumber: (page: number) => void
    setResult: (filters: any) => void
}


const StudentFilters: FC<Props> = ({
    selectedPresentLevel, setSelectedPresentLevel,
    selectedStartupLevel, setSelectedStartupLevel,
    selectedTolkPackage, setSelectedTolkPackage,
    selectedEducationEnded, setSelectedEducationEnded,
    selectedSupportedByEducall, setSelectedSupportedByEducall,
    selectedSupportedByCompany, setSelectedSupportedByCompany,
    selectedCompany, setSelectedCompany,
    selectedOurStudent, setSelectedOurStudent,
    selectedToeflStudent, setSelectedToeflStudent,
    selectedYdsStudent, setSelectedYdsStudent,
    selectedHavePlan, setSelectedHavePlan,
    selectedConsultant, setSelectedConsultant,
    selectedDemoStudent, setSelectedDemoStudent,
    selectedFrozenStudent, setSelectedFrozenStudent,
    selectedRemainingTolk, setSelectedRemainingTolk,
    selectedPreferCallingPlatform, setSelectedPreferCallingPlatform,
    selectedJobInterviewStudent, setSelectedJobInterviewStudent,
    setFilterType, setPageNumber, setResult

}) => {
    const dispatch = useDispatch()
    const [trainerDictionary, setTrainerDictionary] = useState<SelectModel[]>([])
    const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
    const [companies, setCompanies] = useState<SelectModel[]>([])

    const yesno = [selectInitValues, { label: "Yes", value: "1" }, { label: "No", value: "0" }]
    const yes = [selectInitValues, { label: "Yes", value: "1" }]

    const callingPlatforms = [
        selectInitValues,
        { value: "1", label: "GSM" },
        { value: "2", label: "Terminal Extension" },
        { value: "3", label: "Archive Number 1" },
        { value: "4", label: "Archive Number 2" },
        { value: "5", label: "Zoom" },
    ]

    const tolkPackagesList = EnumToList(TolkPackageEnum)
    const tolkPackages = [
        selectInitValues,
        ...tolkPackagesList.map(d => ({
            value: d.value.toString(),
            label: `${d.value.toString()} Minutes`
        })),
    ]

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (levelDictionary.length < 1) {
            var responseLevels = await LevelApi.GetLevelDictionary()
            var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
                value: d.id.toString(),
                label: d.name
            }))]
            dispatch(level.actions.setLevelDictionary(dataLevels));
        }

        var filters: DictionaryModel[] = []
        filters.push({ type: "Role", value: RoleEnum.Trainer });

        if (filters.length > 0) {
            var responseUsers = await UserApi.GetUserDictionaryFilter(filters)
            var dataUsers: IUserDictionaryModel[] = responseUsers.data
            setTrainerDictionary([...dataUsers.map(d => ({
                value: d.id.toString(),
                label: d.email
            }))]);
        }

        var companies = await CompanyApi.GetSubCompaniesDictionary()
        var dataParentCompanies: SelectModel[] = [selectInitValues,
            ...companies.data.map((d) => ({
                value: d.id.toString(),
                label: d.name,
            })),
        ]
        setCompanies(dataParentCompanies)
    }

    async function setFilters() {
        setFilterType("Student");
        setPageNumber(1);

        var companyIds: string[] = [];
        if (selectedCompany.value != "") {
            var companies = await CompanyApi.GetSubCompaniesDictionary(selectedCompany.value)
            companyIds = companies.data.map(i => i.id)
        }

        let filters: Map<string, string> = new Map()
        if (selectedPresentLevel.value != "") filters.set("PresentLevelId", selectedPresentLevel.value)
        if (selectedStartupLevel.value != "") filters.set("StartupLevelId", selectedStartupLevel.value)
        if (selectedTolkPackage.value != "") filters.set("TolkPackage", selectedTolkPackage.value)
        if (selectedRemainingTolk != "") filters.set("TolkRemaining", selectedRemainingTolk)
        if (selectedEducationEnded.value != "") filters.set("EducationEnded", selectedEducationEnded.value)
        if (selectedFrozenStudent.value != "") filters.set("IsFrozenStudent", selectedFrozenStudent.value)
        if (selectedJobInterviewStudent.value != "") filters.set("IsJobInterview", selectedFrozenStudent.value)
        if (selectedPreferCallingPlatform.value != "") filters.set("PreferCallingPlatform", selectedPreferCallingPlatform.value)
        if (selectedOurStudent.value != "") filters.set("IsOurStudent", selectedOurStudent.value)
        if (selectedConsultant.value != "") filters.set("ConsultantId", selectedConsultant.value)
        if (selectedDemoStudent.value != "") filters.set("IsDemoStudent", selectedDemoStudent.value)
        if (selectedSupportedByEducall.value != "") filters.set("IsSupportByEducall", selectedSupportedByEducall.value)
        if (selectedSupportedByCompany.value != "") filters.set("IsSupportByCompany", selectedSupportedByCompany.value)
        if (selectedYdsStudent.value != "") filters.set("IsYdsActive", selectedYdsStudent.value)
        if (selectedToeflStudent.value != "") filters.set("IsToeflActive", selectedToeflStudent.value)
        if (selectedHavePlan.value != "") filters.set("HavePlan", selectedHavePlan.value)
        if (selectedCompany.value != "") filters.set("CompanyIds", companyIds.join(","))
        setResult(filters)
    }

    return (<>
        <div className='row'>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedPresentLevel.value != "" && "text-success"}`}>Present Level</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedPresentLevel(event!)}
                    value={selectedPresentLevel}
                    options={[selectInitValues, ...levelDictionary]}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedStartupLevel.value != "" && "text-success"}`}>Startup Level</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedStartupLevel(event!)}
                    value={selectedStartupLevel}
                    options={[selectInitValues, ...levelDictionary]}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedTolkPackage.value != "" && "text-success"}`}>Tolk Package</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedTolkPackage(event!)}
                    value={selectedTolkPackage}
                    options={tolkPackages}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedEducationEnded.value != "" && "text-success"}`}>Education Date Ended</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedEducationEnded(event!)}
                    value={selectedEducationEnded}
                    options={yesno}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedSupportedByEducall.value != "" && "text-success"}`}>Supported By Educall</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedSupportedByEducall(event!)}
                    value={selectedSupportedByEducall}
                    options={yesno}
                />
            </div>
            <div className='col-lg-2 mb-5'>
                <label className={`form-label fw-bolder ${selectedSupportedByCompany.value != "" && "text-success"}`}>Supported By Company</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedSupportedByCompany(event!)}
                    value={selectedSupportedByCompany}
                    options={yesno}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedCompany.value != "" && "text-success"}`}>Company</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedCompany(event!)}
                    value={selectedCompany}
                    options={companies}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedOurStudent.value != "" && "text-success"}`}>Our Student</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedOurStudent(event!)}
                    value={selectedOurStudent}
                    options={yesno}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedToeflStudent.value != "" && "text-success"}`}>Toefl Students</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedToeflStudent(event!)}
                    value={selectedToeflStudent}
                    options={yesno}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedYdsStudent.value != "" && "text-success"}`}>Yds Students</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedYdsStudent(event!)}
                    value={selectedYdsStudent}
                    options={yesno}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedYdsStudent.value != "" && "text-success"}`}>Have Plan</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedHavePlan(event!)}
                    value={selectedHavePlan}
                    options={yesno}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedConsultant.value != "" && "text-success"}`}>Prefered Consultant</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedConsultant(event!)}
                    value={selectedConsultant}
                    options={[selectInitValues, ...trainerDictionary]}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedDemoStudent.value != "" && "text-success"}`}>Demo Students</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedDemoStudent(event!)}
                    value={selectedDemoStudent}
                    options={yesno}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedRemainingTolk != "" && "text-success"}`}>Call Remaining</label>
                <input
                    type='number'
                    className='form-control form-control-solid'
                    placeholder='Just enter numbers [-,+]'
                    value={selectedRemainingTolk}
                    onChange={(event) => setSelectedRemainingTolk(event.target.value)}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedPreferCallingPlatform.value != "" && "text-success"}`}>Prefer Calling Platform</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedPreferCallingPlatform(event!)}
                    value={selectedPreferCallingPlatform}
                    options={callingPlatforms}
                />
            </div>
            <div className='col-lg-3 mb-5'>
                <label className={`form-label fw-bolder ${selectedJobInterviewStudent.value != "" && "text-success"}`}>Job Interview Student</label>
                <Select
                    styles={customSelectStyles(true)}
                    onChange={(event) => setSelectedJobInterviewStudent(event!)}
                    value={selectedJobInterviewStudent}
                    options={yes}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col text-end'>
                <a
                    href='#'
                    onClick={() => { setFilters(); }}
                    className='btn btn-sm btn-success'
                >
                    <KTSVG
                        path='/media/icons/duotone/General/Save.svg'
                        className='svg-icon-3'
                    />
                    Set Student Filters
                </a>
            </div>
        </div>
    </>)
}

export { StudentFilters }