import * as Yup from 'yup'

const PricingCouponFormValidations = () => {
  return Yup.object().shape({
    code: Yup.string()
      .min(6, 'Minimum 6 symbols')
      .max(20, 'Maximum 20 symbols')
      .required('Code is required')
  })
}

export { PricingCouponFormValidations }
