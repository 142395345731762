/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { userManager } from '../../modules/auth/redux/AuthService'
import { User } from 'oidc-client'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import DashboardApi from '../../../infrastructure/api/DashboardApi'
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { IScheduleStatisticsModel, scheduleStatisticsInitValues } from '../../../models/responses/ScheduleStatisticsModel'
import { AnnouncementAlert } from '../announcements/components/AnnouncementAlert'


const DashboardPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)


  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)
  const [user, setUser] = useState<User>();
  const userModel: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)


  const [scheduleStatistics, setScheduleStatistics] = useState<IScheduleStatisticsModel>(scheduleStatisticsInitValues)
  useEffect(() => {
    const getUser = async () => {
      var response = await DashboardApi.GetStatistics();

      setScheduleStatistics(response.data);
      let user = await userManager.getUser()
      setUser(user!!);
    }
    getUser();
  }, [])

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  return (
    <>
      <AnnouncementAlert />

      <div className='row g-5 g-xl-8'>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotone/Media/Equalizer.svg'
            color='primary'
            iconColor='white'
            title={scheduleStatistics?.startedCallNumber.toString()}
            description='Active Calls'
          />
        </div>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotone/Media/Equalizer.svg'
            color='white'
            iconColor='primary'
            title={scheduleStatistics?.waitingCallNumber.toString()}
            description='Waiting Calls'
          />
        </div>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotone/Media/Equalizer.svg'
            color='success'
            iconColor='white'
            title={scheduleStatistics?.completedCallNumber.toString()}
            description='Completed Calls'
          />
        </div>
      </div>
      <div className='row g-5 g-xl-8'>

        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotone/Media/Equalizer.svg'
            color='warning'
            iconColor='white'
            title={scheduleStatistics?.canceledCallNumber.toString()}
            description='Canceled Calls'
          />
        </div>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotone/Media/Equalizer.svg'
            color='info'
            iconColor='white'
            title={scheduleStatistics?.notAnsweredCallNumber.toString()}
            description='Not Answered Calls'
          />
        </div>
        <div className='col-xl'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotone/Media/Equalizer.svg'
            color='danger'
            iconColor='white'
            title={scheduleStatistics?.closedCallNumber.toString()}
            description='Closed Calls'
          />
        </div>
      </div>

      <div className='card mb-5 mb-xl-8'>
        <div className='card-body py-10'>
          <p><b>Expire:</b> {(parseInt(user?.expires_in.toString() || '') / 60).toFixed(2)} minutes</p>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
