import {FC, useEffect, useState} from 'react'
import ContactApi from '../../../../infrastructure/api/ContactApi'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap-v5'
import {useFormik} from 'formik'
import {IContactModel, contactInitValues} from '../../../../models/responses/ContactModel'
import {IContactRequestModel} from '../../../../models/requests/ContactRequestModel'
import {ContactFormValidations} from '../../../validations/ContactFormValidations'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const ContactFormModal: FC<Props> = ({itemId, show, handleClose}) => {
  const [loading, setLoading] = useState(false)

  const [contact, setContact] = useState<IContactModel>(contactInitValues)
  const updateContact = (fieldsToUpdate: Partial<IContactModel>) => {
    const updatedData = {...contact, ...fieldsToUpdate}
    setContact(updatedData)
  }

  const formik = useFormik<IContactModel>({
    initialValues: contact,
    enableReinitialize: true,
    validationSchema: ContactFormValidations,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setTimeout(async () => {
        let model: IContactRequestModel = {
          id: values.id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          subject: values.subject,
          message: values.message,
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IContactRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === 0) result = await ContactApi.AddContact(model)
      else {
        result = await ContactApi.UpdateContact(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IContactModel
    if (itemId > 0) {
      var response = await ContactApi.GetContact(itemId)
      result = response.data
    } else {
      result = contactInitValues
    }
    setContact(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-800px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Contact</h1>
              <div className='text-muted fw-bold fs-5'>
                Contact Management <small>Educall</small>
              </div>
            </div>
            <div className='row mb-5'>
              <div className='col-3 fw-bolder'>Name</div>
              <div className='col-1' />
              <div className='col-3 fw-bolder'>Email</div>
              <div className='col-1' />
              <div className='col-3 fw-bolder'>Phone</div>
              <div className='col-1' />
              <div className='col-3'>{contact.name}</div>  <div className='col-1' />
              <div className='col-3'>{contact.email}</div> <div className='col-1' />
              <div className='col-3'>{contact.phone}</div> <div className='col-1' />
              <div className='col-2 badge badge-light-success fs-7 fw-bolder mt-10 mb-2' style={{alignSelf:"center"}}>
                Subject
              </div>
              <div className='col-10 mt-10 mb-2'>{contact.subject}</div>
              <div className='col-2 badge badge-light-warning fs-7 fw-bolder' style={{alignSelf:"center"}}>Message</div>
              <div className='col-10'>{contact.message}</div>
            </div>
          </div>
          <div className='modal-footer'></div>
        </div>
      </form>
    </Modal>
  )
}

export {ContactFormModal}
