import { DayOfWeekEnum } from '../../enums/DayOfWeekEnum'
import { PreferCallingPlatformEnum } from '../../enums/PreferCallingPlatformEnum'
import { ScheduleTypeEnum } from '../../enums/ScheduleTypeEnum'

export interface IStudentModel {
    id?: string
    presentLevelId?: number,
    startupLevelId?: number,
    isJobInterview: boolean,
    isDemoStudent: boolean,
    isToeflActive: boolean,
    isYdsActive: boolean,
    isOurStudent: boolean,
    isSupportByCompany: boolean,
    isSupportByEducall: boolean,
    tolkPackage?: number,
    tolkRemaining?: number,
    studentPlans: IStudentPlanModel[],
    blockedTrainerIds: string[],
    preferCallingPlatform: PreferCallingPlatformEnum,
    educationStartDate: string,
    educationEndDate: string
}

const studentInitValues: IStudentModel = {
    id: undefined,
    presentLevelId: undefined,
    startupLevelId: undefined,
    isJobInterview: false,
    isDemoStudent: false,
    isToeflActive: false,
    isYdsActive: false,
    isOurStudent: false,
    isSupportByCompany: false,
    isSupportByEducall: false,
    tolkPackage: undefined,
    tolkRemaining: undefined,
    studentPlans: [],
    blockedTrainerIds: [],
    preferCallingPlatform: 5,
    educationStartDate: '',
    educationEndDate: ''
}

export interface IStudentsModel {
    id?: string,
    firstName: string,
    lastName: string,
    tolkRemaining?: number,
    studentPlanId?: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number,
    type: ScheduleTypeEnum,
    trainerId?: string,
    schedulePlanId?: string,
    isAssigned: boolean,
    skipAutoPair: boolean
}

const studentsInitValues: IStudentsModel = {
    id: undefined,
    firstName: '',
    lastName: '',
    tolkRemaining: 0,
    studentPlanId: undefined,
    dayOfWeek: DayOfWeekEnum.Friday,
    startDate: '',
    endDate: '',
    duration: 0,
    type: ScheduleTypeEnum.Planned,
    trainerId: undefined,
    schedulePlanId: undefined,
    isAssigned: false,
    skipAutoPair: false
}

export interface IStudentPlanModel {
    id?: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number,
    type: ScheduleTypeEnum,
    trainerId?: string,
    schedulePlanId: string
}

export interface IStudentPlansModel {
    id?: string,
    dayOfWeek: DayOfWeekEnum,
    startDate: string,
    endDate: string,
    duration: number,
    type: ScheduleTypeEnum,
    schedule?: PlanSchedule,
    trainer?: PlanUser,
    student?: PlanUser,
    schedulePlanId?: string
    isAssigned: boolean,
    skipAutoPair: boolean
}

export interface PlanSchedule {
    id: string
}

export interface PlanUser {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
}

const studentPlansInitValues: IStudentPlansModel = {
    id: undefined,
    dayOfWeek: DayOfWeekEnum.Friday,
    startDate: '',
    endDate: '',
    duration: 0,
    type: ScheduleTypeEnum.Planned,
    trainer: undefined,
    student: undefined,
    schedulePlanId: undefined,
    isAssigned: false,
    skipAutoPair: false
}


export { studentInitValues, studentsInitValues, studentPlansInitValues }
