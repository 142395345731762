import { FC, useEffect, useState } from 'react'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import Select from 'react-select'
import { SelectModel, selectInitValues } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import AsyncSelect from 'react-select/async'
import debounce from 'es6-promise-debounce'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import * as level from '../../../pages/levels/redux/LevelRedux'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { TolkPackageEnum } from '../../../../enums/TolkPackageEnum'
import { IStudentReportRequestModel } from '../../../../models/requests/StudentReportRequestModel'
import StudentReportApi from '../../../../infrastructure/api/StudentReportApi'
import CompanyApi from '../../../../infrastructure/api/CompanyApi'

type Props = {
    handleClose: () => void
    trainerList: SelectModel[]
}

const StudentReportFormModal: FC<Props> = ({ handleClose, trainerList }) => {

    let now = new Date();
    let startTime = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)).subtract(7, "days");
    let endTime = moment(startTime).add(7, "days").subtract(1, "seconds");

    const [start, setStart] = useState(startTime)
    const [end, setEnd] = useState(endTime)


    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)
    const dispatch = useDispatch()

    const yes = [selectInitValues, { label: "Yes", value: "1" }]
    const yesno = [selectInitValues, { label: "Yes", value: "1" }, { label: "No", value: "0" }]
    const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]

    const [selectedTrainer, setSelectedTrainer] = useState<SelectModel>(selectInitValues)
    const [selectedConsultant, setSelectedConsultant] = useState<SelectModel>(selectInitValues)

    const [companies, setCompanies] = useState<SelectModel[]>([])
    const [selectedCompany, setSelectedCompany] = useState<SelectModel>(selectInitValues)

    const [students, setStudents] = useState<SelectModel[]>([selectInitValues])
    const [selectedStudent, setSelectedStudent] = useState<SelectModel>(selectInitValues)

    const [admins, setAdmins] = useState<SelectModel[]>([selectInitValues])
    const [selectedAdmin, setSelectedAdmin] = useState<SelectModel>(selectInitValues)

    const [selectedStartupLevel, setSelectedStartupLevel] = useState<SelectModel>(selectInitValues)
    const [selectedPresentLevel, setSelectedPresentLevel] = useState<SelectModel>(selectInitValues)

    const levelChangeNumbers = [1, 2, 3, 4, 5].map((i) => {
        return { value: i.toString(), label: i.toString() }
    })
    const [selectedLevelChangeNumber, setSelectedLevelChangeNumber] = useState<SelectModel>(selectInitValues)


    const statusList = EnumToList(ScheduleStatusEnum)
    const [selectedDemoStatus, setSelectedDemoStatus] = useState<SelectModel>(selectInitValues)

    const [selectedJobInterviewStudent, setSelectedJobInterviewStudent] = useState<SelectModel>(selectInitValues)

    const tolkPackagesList = EnumToList(TolkPackageEnum)
    const tolkPackages = [
        selectInitValues,
        ...tolkPackagesList.map(d => ({
            value: d.value.toString(),
            label: `${d.value.toString()} Minutes`
        })),
    ]
    const [selectedTolkPackage, setSelectedTolkPackage] = useState<SelectModel>(selectInitValues)
    const [selectedFirstTolk, setSelectedFirstTolk] = useState<string>("")
    const [selectedLastTolk, setSelectedLastTolk] = useState<string>("")
    const [selectedRemainingCall, setSelectedRemainingTolk] = useState<string>("")

    const [selectedTolkAddedDate, setSelectedTolkAddedDate] = useState<SelectModel>(selectInitValues)
    const [selectedTolkAddedDateValue, setSelectedTolkAddedDateValue] = useState<string>()
    const [selectedOurStudent, setSelectedOurStudent] = useState<SelectModel>(selectInitValues)
    const [selectedEducationEndDate, setSelectedEducationEndDate] = useState<SelectModel>(selectInitValues)

    const [startEducationEndDate, setStartEducationEndDate] = useState(startTime)
    const [endEducationEndDate, setEndEducationEndDate] = useState(endTime)

    const [selectedCompleteCall, setSelectedCompletedCall] = useState<string>("")
    const [selectedCancelCall, setSelectedCancelCall] = useState<string>("")
    const [selectedCloseCall, setSelectedCloseCall] = useState<string>("")
    const [selectedPleasure, setSelectedPleasure] = useState<string>("")

    const applyCallback = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStart(startDate)
        setEnd(endDate)
    }

    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 3 Days': [moment().subtract(2, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    let local = {
        "format": "YYYY/MM/DD",
        "sundayFirst": false
    }


    const getStudentDatas = async (event: string) => {
        if (event.length > 1) {
            const paginationFilter: IPaginationFilter = {
                page: 1,
                size: 5,
                orderBy: "firstName_asc",
                search: event
            }

            var filters: DictionaryModel[] = [{ type: "Role", value: "Student" }]
            var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
            var data: SelectModel[] = [
                ...response.data.items.map((d) => ({
                    value: d.id.toString(),
                    label: `${d.firstName} ${d.lastName} - ${d.email}`,
                })),
            ]
            setStudents(data)
            return data;
        }
        return [];
    }


    const getAdminDatas = async (event: string) => {
        if (event.length > 1) {
            const paginationFilter: IPaginationFilter = {
                page: 1,
                size: 5,
                orderBy: "firstName_asc",
                search: event
            }

            var filters: DictionaryModel[] = [{ type: "Role", value: "Administrator" }]
            var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
            var data: SelectModel[] = [
                ...response.data.items.map((d) => ({
                    value: d.id.toString(),
                    label: `${d.firstName} ${d.lastName} - ${d.email}`,
                })),
            ]
            setAdmins(data)
            return data;
        }
        return [];
    }



    const loadOptions = (inputValue: string) =>
        new Promise<SelectModel[]>((resolve) => {
            setTimeout(async () => {
                var data = await getStudentDatas(inputValue);
                setStudents([selectInitValues, ...data]);
                resolve(data);
            }, 1000);
        });


    const loadAdminOptions = (inputValue: string) =>
        new Promise<SelectModel[]>((resolve) => {
            setTimeout(async () => {
                var data = await getAdminDatas(inputValue);
                setAdmins([selectInitValues, ...data]);
                resolve(data);
            }, 1000);
        });


    async function createStudentReport() {
        try {
            var model: IStudentReportRequestModel = {
                startDate: start.format("YYYY-MM-DD H:m:s"),
                endDate: end.format("YYYY-MM-DD H:m:s"),
                trainerId: selectedTrainer.value != "" ? selectedTrainer.value : undefined,
                consultantId: selectedConsultant.value != "" ? selectedConsultant.value : undefined,
                studentId: selectedStudent.value != "" ? selectedStudent.value : undefined,
                companyId: selectedCompany.value != "" ? selectedCompany.value : undefined,
                startupLevelId: selectedStartupLevel.value != "" ? parseInt(selectedStartupLevel.value) : undefined,
                presentLevelId: selectedPresentLevel.value != "" ? parseInt(selectedPresentLevel.value) : undefined,
                levelChangeNumber: selectedLevelChangeNumber.value != "" ? parseInt(selectedLevelChangeNumber.value) : undefined,
                demoStatus: selectedDemoStatus.value != "" ? parseInt(selectedDemoStatus.value) : undefined,
                isJobInterview: selectedJobInterviewStudent.value != "" ? selectedJobInterviewStudent.value == "1" : undefined,
                tolkPackage: selectedTolkPackage.value != "" ? parseInt(selectedTolkPackage.value) : undefined,
                firstTolkNumber: selectedFirstTolk != "" ? parseInt(selectedFirstTolk) : undefined,
                callRemaining: selectedRemainingCall != "" ? parseInt(selectedRemainingCall) : undefined,
                numberOfTolkAddedLater: selectedLastTolk != "" ? parseInt(selectedLastTolk) : undefined,
                tolkAddedDate: selectedTolkAddedDate.value != "" ? selectedTolkAddedDateValue : undefined,
                whoAddedTolk: selectedAdmin.value != "" ? selectedAdmin.value : undefined,
                completedCallNumber: selectedCompleteCall != "" ? parseInt(selectedCompleteCall) : undefined,
                cancelledCallNumber: selectedCancelCall != "" ? parseInt(selectedCancelCall) : undefined,
                closedCallNumber: selectedCloseCall != "" ? parseInt(selectedCloseCall) : undefined,
                pleasure: selectedPleasure != "" ? parseInt(selectedPleasure) : undefined,
                educationEndDateStartDate: selectedEducationEndDate.value != "" ? startEducationEndDate.format("YYYY-MM-DDT00:00:00") : undefined,
                educationEndDateEndDate: selectedEducationEndDate.value != "" ? endEducationEndDate.format("YYYY-MM-DDT23:59:59") : undefined,
                isOurStudent: selectedOurStudent.value != "" ? selectedOurStudent.value == "1" : undefined
            }


            var result = await StudentReportApi.CreateStudentReport(model)
            handleClose()
            setComplete(true)
        } catch (error) {
            alert('Error: ' + error)
        }

    }

    const applyCallbackEducationEndDate = (startDate: moment.Moment, endDate: moment.Moment) => {
        setStartEducationEndDate(startDate)
        setEndEducationEndDate(endDate)
    }

    useEffect(() => {
        init();
    }, []);

    async function init() {
        if (companies.length < 1) {
            var responsecompanies = await CompanyApi.GetSubCompaniesDictionary()
            var data: SelectModel[] = [
                ...responsecompanies.data.map((d) => ({
                    value: d.id.toString(),
                    label: d.name,
                })),
            ]
            setCompanies([selectInitValues, ...data])
        }

        if (levelDictionary.length < 1) {
            var responseLevels = await LevelApi.GetLevelDictionary()
            var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
                value: d.id.toString(),
                label: d.name
            }))]
            dispatch(level.actions.setLevelDictionary(dataLevels));
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-lg-3 mb-5'>
                    <label className='form-label fw-bolder'>Date Range</label>
                    <DateTimeRangeContainer
                        ranges={ranges}
                        start={start}
                        style={{
                            darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                        }}
                        end={end}
                        local={local}
                        applyCallback={applyCallback}
                    >
                        <input
                            id="formControlsTextB"
                            className='form-control form-control-solid'
                            value={`${start.format(local.format)} - ${end.format(local.format)}`}
                            type="text"
                        />
                    </DateTimeRangeContainer>
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder`}>Trainer</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedTrainer(event!)}
                        value={selectedTrainer}
                        options={[selectInitValues, ...trainerList]}
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder`}>Consultant</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedConsultant(event!)}
                        value={selectedConsultant}
                        options={[selectInitValues, ...trainerList]}
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder`}>Student</label>
                    <AsyncSelect
                        cacheOptions
                        styles={customSelectStyles(true)}
                        loadOptions={debounce(loadOptions, 500)}
                        onChange={(event) => setSelectedStudent(event!)}
                        defaultOptions={students}
                        value={selectedStudent}
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className='form-label fw-bolder required'>Company</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedCompany(event!)}
                        value={selectedCompany}
                        options={companies}
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedPresentLevel.value != "" && "text-success"}`}>Present Level</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedPresentLevel(event!)}
                        value={selectedPresentLevel}
                        options={[selectInitValues, ...levelDictionary]}
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedStartupLevel.value != "" && "text-success"}`}>Startup Level</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedStartupLevel(event!)}
                        value={selectedStartupLevel}
                        options={[selectInitValues, ...levelDictionary]}
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedLevelChangeNumber.value != "" && "text-success"}`}>Level Change Number</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedLevelChangeNumber(event!)}
                        value={selectedLevelChangeNumber}
                        options={[selectInitValues, ...levelChangeNumbers]}
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedDemoStatus.value != "" && "text-success"}`}>Demo Status</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedDemoStatus(event!)}
                        value={selectedDemoStatus}
                        options={[selectInitValues, ...statusList]}
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedJobInterviewStudent.value != "" && "text-success"}`}>Job Interview Student</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedJobInterviewStudent(event!)}
                        value={selectedJobInterviewStudent}
                        options={yes}
                    />
                </div>

                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedTolkPackage.value != "" && "text-success"}`}>Tolk Package</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedTolkPackage(event!)}
                        value={selectedTolkPackage}
                        options={tolkPackages}
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedTolkAddedDate.value != "" && "text-success"}`}>Tolk Added Date</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedTolkAddedDate(event!)}
                        value={selectedTolkAddedDate}
                        options={yes}
                    />
                    {selectedTolkAddedDate.value == "1" &&
                        <input
                            type="datetime-local"
                            className='form-control form-control-solid'
                            value={selectedTolkAddedDateValue}
                            onChange={(event) => setSelectedTolkAddedDateValue(event.target.value!)} />
                    }

                </div>

                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedFirstTolk != "" && "text-success"}`}>First Tolk</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedFirstTolk(event.target.value)}
                        value={selectedFirstTolk}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedRemainingCall != "" && "text-success"}`}>Remaining Tolk</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedRemainingTolk(event.target.value)}
                        value={selectedRemainingCall}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg-2 mb-5'>
                    <label className={`form-label fw-bolder ${selectedLastTolk != "" && "text-success"}`}>Last Added Tolk</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedLastTolk(event.target.value)}
                        value={selectedLastTolk}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg-5 mb-5'>
                    <label className={`form-label fw-bolder`}>Who Added Tolk</label>
                    <AsyncSelect
                        cacheOptions
                        styles={customSelectStyles(true)}
                        loadOptions={debounce(loadAdminOptions, 500)}
                        onChange={(event) => setSelectedAdmin(event!)}
                        defaultOptions={admins}
                        value={selectedAdmin}
                    />
                </div>
                <div className='col-lg-4 mb-5'>
                    <label className={`form-label fw-bolder ${selectedEducationEndDate.value != "" && "text-success"}`}>Education End Date</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedEducationEndDate(event!)}
                        value={selectedEducationEndDate}
                        options={yes}
                    />
                    {selectedEducationEndDate.value == "1" &&
                        <DateTimeRangeContainer
                            ranges={ranges}
                            start={startEducationEndDate}
                            style={{
                                darkMode: true, standaloneLayout: { display: 'flex', maxWidth: 'fit-content' }
                            }}
                            end={endEducationEndDate}
                            local={local}
                            applyCallback={applyCallbackEducationEndDate}
                        >
                            <input
                                id="formControlsTextB"
                                className='form-control form-control-solid'
                                value={`${startEducationEndDate.format(local.format)} - ${endEducationEndDate.format(local.format)}`}
                                type="text"
                            />
                        </DateTimeRangeContainer>
                    }
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedOurStudent.value != "" && "text-success"}`}>Our Student</label>
                    <Select
                        styles={customSelectStyles(true)}
                        onChange={(event) => setSelectedOurStudent(event!)}
                        value={selectedOurStudent}
                        options={yesno}
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedCompleteCall != "" && "text-success"}`}>Complete Call</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedCompletedCall(event.target.value)}
                        value={selectedCompleteCall}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedCancelCall != "" && "text-success"}`}>Cancel Call</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedCancelCall(event.target.value)}
                        value={selectedCancelCall}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedCloseCall != "" && "text-success"}`}>Close Call</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedCloseCall(event.target.value)}
                        value={selectedCloseCall}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg-3 mb-5'>
                    <label className={`form-label fw-bolder ${selectedPleasure != "" && "text-success"}`}>Pleasure</label>
                    <input
                        type='number'
                        onChange={(event) => setSelectedPleasure(event.target.value)}
                        value={selectedPleasure}
                        className='form-control form-control-solid'
                    />
                </div>
                <div className='col-lg mb-5 text-end'>
                    <SaveChangesButton
                        loading={loading}
                        setLoading={setLoading}
                        complete={complete}
                        title='Create Report'
                        setComplete={setComplete}
                        valid={true}
                        submit={true}
                        setSubmit={createStudentReport} />
                </div>
            </div>

        </>
    )
}

export { StudentReportFormModal }