import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { PopupFormModal } from './_modals/PopupFormModal'
import { PopupListModal } from './_modals/PopupListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import PopupApi from '../../../infrastructure/api/PopupApi'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'

const PopupPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deletePopup(itemId: number) {
    var response = await PopupApi.DeletePopup(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
          {checkPermission(user, PermissionEnum.PopupCreate) &&
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a Popup'
            >
              <a
                href='#'
                onClick={() => onClickItem(0, '', 'create')}
                className='btn btn-sm btn-light-primary'
              >
                <KTSVG
                  path='/media/icons/duotone/Code/Plus.svg'
                  className='svg-icon-3'
                />
                Add Popup
              </a>
            </div>
          }
        </div>

        <div className='card-body py-3'>
          <PopupListModal refresh={refresh} onClickItem={onClickItem} />
          <PopupFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          <DeleteModal
            itemId={itemId}
            itemName={itemName}
            handleClose={handleCloseDelete}
            show={showDelete}
            type='Popup'
            confirm={deletePopup}
          />
        </div>
      </div>
    </>
  )
}

const PopupWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Popup</PageTitle>
      <PopupPage />
    </>
  )
}

export { PopupWrapper }
