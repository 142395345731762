import * as Yup from 'yup'

const UserDetailFormValidations = () => {
    return Yup.object().shape({
        referenceCode: Yup.string(),
        marketerCode: Yup.string(),
        whoReference: Yup.string()
    })
}

export { UserDetailFormValidations }