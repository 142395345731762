import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { SelectModel } from '../../../models/SelectModel'
import RoleApi from '../../../../infrastructure/api/RoleApi'
import { ITaskDefineModel, ITaskModel } from '../../../../models/responses/TaskModel'
import TaskApi from '../../../../infrastructure/api/TaskApi'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual } from 'react-redux'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import UserApi from '../../../../infrastructure/api/UserApi'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const TaskDefineListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [taskDefines, setTaskDefines] = useState<PagedResponseModel<ITaskDefineModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')

  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  const getUsers = async (userIds: string[]) => {
    var responseUsers = await UserApi.GetUserDictionaryIds(userIds)
    var dataUsers: IUserDictionaryModel[] = responseUsers.data
    setUsers([...users, ...dataUsers]);
  }

  async function getTaskDefines() {

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    var response = await TaskApi.GetTaskDefines(paginationFilter)
    getUsers([...response.data.items.flatMap(s => s.responsibleUsers)])
    setTaskDefines(response.data)
  }

  useEffect(() => {
    getTaskDefines()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Task Defines'
        data={taskDefines?.items}
        currentTotalRecords={taskDefines?.totalRecords!!}
        currentPageNumber={taskDefines?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Title',
            name: 'title',
          },
          {
            label: 'Responsible Users',
            name: 'responsibleUsers',
            options: {
              customBodyRender: (value: string[]) => {
                return <>{value!!.map((item, key) => {
                  return (
                    <div key={key}><span className='badge badge-light-primary mb-1'>{users.find(i => i.id == item)?.email}</span><br /></div>
                  )
                }
                )
                }</>
              },
            },
          },
          {
            label: 'Expiry Day',
            name: 'expiryDay'
          },
          {
            label: 'Order',
            name: 'order'
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={taskDefines?.items[dataIndex]?.id!!}
                    itemParam={taskDefines?.items[dataIndex]?.title!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { TaskDefineListModal }
