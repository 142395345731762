import { IConfigRequestModel } from '../../models/requests/ConfigRequestModel'
import { IConfigModel } from '../../models/responses/ConfigModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class ConfigApi {

  async GetConfigs(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IConfigModel[]>>(`e/Admin/Configs${generateQueryParams(filter)}`)
  }

  async GetConfig(id: number) {
    return await API.get<IConfigModel>('e/Admin/Configs/' + id)
  }

  async AddConfig(model: IConfigRequestModel) {
    return await API.post('e/Admin/Configs', model)
  }

  async UpdateConfig(model: IConfigRequestModel) {
    return await API.put('e/Admin/Configs/' + model.id, model)
  }

  async DeleteConfig(itemId: number) {
    return await API.delete<boolean>('e/Admin/Configs/' + itemId)
  }
}

export default new ConfigApi()
