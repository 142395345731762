import * as Yup from 'yup'

const VocabularyValidations = () => {
  return Yup.object().shape({
    word: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(45, 'Maximum 45 symbols')
      .required('Word is required'),
  })
}

export { VocabularyValidations }
