import { FC, useEffect, useState } from 'react'
import AnnouncementApi from '../../../../infrastructure/api/AnnouncementApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import parse from 'html-react-parser'
import {
  IUserDictionaryModel
} from '../../../../models/responses/UserDictionaryModel'
import {
  IAnnouncementModel,
  announcementInitValues,
} from '../../../../models/responses/AnnouncementModel'
import UserApi from '../../../../infrastructure/api/UserApi'
import { BasicTable } from '../../../components/tables/BasicTable'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const AnnouncementViewModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [announcement, setAnnouncement] = useState<IAnnouncementModel>(announcementInitValues)
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])

  async function init() {
    let result: IAnnouncementModel
    if (itemId !== '') {
      var response = await AnnouncementApi.GetAnnouncement(itemId)
      result = response.data

      var responseUsers = await UserApi.GetUserDictionaryIds(result.users.map(i => i.userId))
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setUsers(dataUsers)
    } else {
      result = announcementInitValues
    }
    setAnnouncement(result)
  }

  useEffect(() => {
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body mx-5 pt-0'>
          <div className='text-center mb-6'>
            <h1 className='mb-3'>View Announcement Status</h1>
            <div className='text-muted fw-bold fs-5 mb-6'>
              Announcement Management <small>Educall</small>
            </div>
          </div>
          <div className='row'>

            <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10 ribbon ribbon-end ribbon-clip ">
              {announcement.isPinned ?
                <div className="ribbon-label">
                  Pinned
                  <span className="ribbon-inner bg-info"></span>
                </div>
                : <></>}
              <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0"><i className="fas fa-bullhorn"></i></span>
              <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                <h5 className="mb-1">{announcement.title}</h5>
                <span>{parse(announcement.message)}</span>
              </div>
            </div>

            <div className="d-flex flex-column mb-6">
              <li className="d-flex align-items-center py-2">
                <span className="bullet bg-primary me-5"></span><b>Send Time: </b> {announcement.sendTime}
              </li>
              <li className="d-flex align-items-center py-2">
                <span className="bullet bg-light me-5"></span><b>End Time: </b> {announcement.endTime}
              </li>
            </div>
            <BasicTable
              title='Sended Users'
              data={announcement.users}
              columns={[
                {
                  label: 'Is Read', name: '',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <b>{announcement.users[dataIndex].isRead ?
                        <button className='btn btn-sm btn-icon btn-color-success symbol-label'>
                          <KTSVG path='/media/icons/duotone/General/Visible.svg' className='svg-icon-1' />
                        </button>
                        : <button className='btn btn-sm btn-icon btn-color-dark symbol-label'>
                          <KTSVG path='/media/icons/duotone/General/Visible.svg' className='svg-icon-1' />
                        </button>
                      }
                      </b>
                    },
                  },
                },
                {
                  label: 'User',
                  name: 'userId',
                  options: {
                    customBodyRender: (value) => {
                      return <b>{users.find(i => i.id == value)?.firstName} {users.find(i => i.id == value)?.lastName}</b>
                    },
                  },
                },
                {
                  label: 'Read Time', name: 'isRead',
                  options: {
                    customBodyRenderLite: (dataIndex) => {
                      return <b>{announcement.users[dataIndex].isRead ? announcement.users[dataIndex].readTime : ''}</b>
                    },
                  },
                },
              ]}
              customOptions={{
                filter: false,
                viewColumns: false,
                serverSide: false,
                customTableBodyFooterRender: (opts) => {
                  return <div></div>
                },
              }}
            />
          </div>
        </div>
        <div className='modal-footer'>
          <div className='d-flex flex-center '>
            <button className='btn btn-primary' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal >
  )
}

export { AnnouncementViewModal }
