import { IScheduleStatisticsModel } from '../../models/responses/ScheduleStatisticsModel'
import API from './Api'

class DashboardApi {
  async GetStatistics() {
    return await API.get<IScheduleStatisticsModel>('s/Admin/Dashboards/statistics')
  }

}

export default new DashboardApi()
