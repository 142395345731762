import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { MissionFormValidations } from '../../../validations/MissionFormValidations'
import { IMissionModel, missionInitValues } from '../../../../models/responses/MissionModel'
import MissionApi from '../../../../infrastructure/api/MissionApi'
import { IMissionRequestModel } from '../../../../models/requests/MissionRequestModel'
import { MissionTypeEnum } from '../../../../enums/MissionTypeEnum'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import * as level from '../../../pages/levels/redux/LevelRedux'
import * as exam from '../../exams/redux/ExamRedux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { EdugameEnum } from '../../../../enums/EdugameEnum'
import ExamApi from '../../../../infrastructure/api/ExamApi'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
  userId?: string,
  companyId?: string
}

const MissionFormModal: FC<Props> = ({ itemId, show, handleClose, userId, companyId }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()

  const [mission, setMission] = useState<IMissionModel>(missionInitValues)
  const levelDictionary: SelectModel[] = useSelector<RootState>(({ level }) => level.levelDictionary, shallowEqual) as SelectModel[]
  const [selectedLevel, setSelectedLevel] = useState<SelectModel>(selectInitValues)

  const examDictionary: SelectModel[] = useSelector<RootState>(({ exam }) => exam.examDictionary, shallowEqual) as SelectModel[]


  const missionTypes = EnumToList(MissionTypeEnum)
  const [selectedMissionType, setSelectedMissionTypes] = useState<SelectModel>(selectInitValues)

  const edugames = EnumToList(EdugameEnum)

  const [selectedParam, setSelectedParam] = useState<SelectModel>(selectInitValues)


  const formik = useFormik<IMissionModel>({
    initialValues: mission,
    enableReinitialize: true,
    validationSchema: MissionFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IMissionRequestModel = {
          id: values.id === '' ? undefined : values.id,
          title: values.title,
          type: parseInt(selectedMissionType.value),
          param: selectedParam.value,
          levelId: parseInt(selectedLevel.value),
          requiredNumber: values.requiredNumber,
          isCompleted: false,
          completedNumber: values.completedNumber,
          conditionScore: values.conditionScore,
          userId: userId,
          companyId: companyId
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IMissionRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '' || userId != mission.userId) {
        if (userId != mission.userId && itemId != '') {
          model.baseMissionId = itemId;
        }
        result = await MissionApi.AddMission(model)
      }
      else {
        result = await MissionApi.UpdateMission(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    if (levelDictionary.length < 1) {
      var responseLevels = await LevelApi.GetLevelDictionary()
      var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
        value: d.id.toString(),
        label: d.name
      }))]
      dispatch(level.actions.setLevelDictionary(dataLevels));
    }
    if (examDictionary.length < 1) {
      var responseExams = await ExamApi.GetExamDictionary()
      var dataExams: SelectModel[] = [...responseExams.data.map(d => ({
        value: d.id.toString(),
        label: d.title
      }))]
      dispatch(exam.actions.setExamDictionary(dataExams));
    }

    let result: IMissionModel
    if (itemId !== '') {
      var response = await MissionApi.GetMission(itemId)
      result = response.data

      setSelectedLevel(levelDictionary.find(i => i.value == result?.levelId?.toString()) || selectInitValues)
      setSelectedMissionTypes(missionTypes.find(i => i.value == result?.type?.toString()) || selectInitValues)

      if (result.type == MissionTypeEnum.Game)
        setSelectedParam(edugames.find(i => i.value == result?.param?.toString()) || selectInitValues)
      else if (result.type == MissionTypeEnum.Exam)
        setSelectedParam(examDictionary.find(i => i.value == result?.param?.toString()) || selectInitValues)
    } else {

      result = missionInitValues
    }
    setMission(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Mission</h1>
              <div className='text-muted fw-bold fs-5'>
                Mission Management <small>Educall</small>
              </div>
            </div>
            <div className='col-12 mb-6'>
              <label className='form-label fw-bolder required'>Title</label>
              <input
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
            </div>
            <div className='col-12 mb-6'>
              <label className='form-label fw-bolder required'>Level</label>
              <Select
                {...formik.getFieldProps('levelId')}
                styles={customSelectStyles(true)}
                onChange={(event) => setSelectedLevel(event!)}
                value={selectedLevel}
                options={levelDictionary}
              />
              <FormikValidationError
                touched={formik.touched.levelId}
                error={formik.errors.levelId}
              />
            </div>
            <div className='col-12 mb-6'>
              <label className='form-label fw-bolder required'>Type</label>
              <Select
                {...formik.getFieldProps('group')}
                styles={customSelectStyles(true)}
                onChange={(event) => {
                  setSelectedMissionTypes(event!)
                  setSelectedParam(selectInitValues)
                }}
                value={selectedMissionType}
                options={missionTypes}
              />
              <FormikValidationError touched={formik.touched.type} error={formik.errors.type} />
            </div>
            {selectedMissionType.value == MissionTypeEnum.Game.toString() &&
              <div className='col-12 mb-6'>
                <label className='form-label fw-bolder required'>Edugame</label>
                <Select
                  styles={customSelectStyles(true)}
                  onChange={(event) => setSelectedParam(event!)}
                  value={selectedParam}
                  options={edugames}
                />
                <FormikValidationError touched={formik.touched.param} error={formik.errors.param} />
              </div>
            }
            {selectedMissionType.value == MissionTypeEnum.Exam.toString() &&
              <div className='col-12 mb-6'>
                <label className='form-label fw-bolder required'>Exam</label>
                <Select
                  styles={customSelectStyles(true)}
                  onChange={(event) => setSelectedParam(event!)}
                  value={selectedParam}
                  options={examDictionary}
                />
                <FormikValidationError touched={formik.touched.param} error={formik.errors.param} />
              </div>
            }
            <div className='row'>
              <div className='col-md-6 mb-6'>
                <label className='form-label fw-bolder required'>Required Number</label>
                <input
                  {...formik.getFieldProps('requiredNumber')}
                  type='number'
                  name='requiredNumber'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.requiredNumber} error={formik.errors.requiredNumber} />
              </div>
              <div className='col-md-6 mb-6'>
                <label className='form-label fw-bolder required'>Condition Score</label>
                <input
                  {...formik.getFieldProps('conditionScore')}
                  type='number'
                  name='conditionScore'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.conditionScore} error={formik.errors.conditionScore} />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { MissionFormModal }