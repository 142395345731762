import { FC, useEffect, useState } from 'react'
import VideoApi from '../../../../infrastructure/api/VideoApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IVideoModel, videoInitValues } from '../../../../models/responses/VideoModel'
import { IVideoRequestModel } from '../../../../models/requests/VideoRequestModel'
import { VideoValidations } from '../../../validations/VideoValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { LanguageEnum } from '../../../../enums/LanguageEnum'
import { EnumToList } from '../../../helpers/EnumHelper'
import { VideoTypeEnum } from '../../../../enums/VideoTypeEnum'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const VideoFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const languages = EnumToList(LanguageEnum)
  const [selectedLanguage, setSelectedLanguage] = useState<SelectModel>()
  const videoTypes = EnumToList(VideoTypeEnum)
  const [selectedVideoType, setSelectedVideoType] = useState<SelectModel>()
  const [video, setVideo] = useState<IVideoModel>(videoInitValues)

  const updateVideo = (fieldsToUpdate: Partial<IVideoModel>) => {
    const updatedData = { ...video, ...fieldsToUpdate }
    setVideo(updatedData)
  }

  const formik = useFormik<IVideoModel>({
    initialValues: video,
    enableReinitialize: true,
    validationSchema: VideoValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IVideoRequestModel = {
          id: values.id,
          title: values.title,
          link: values.link,
          order: values.order,
          userTypeId: values.userTypeId,
          videoType: parseInt(selectedVideoType?.value!!),
          language: parseInt(selectedLanguage?.value!!),
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IVideoRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === 0) result = await VideoApi.AddVideo(model)
      else {
        result = await VideoApi.UpdateVideo(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IVideoModel
    if (itemId > 0) {
      var response = await VideoApi.GetVideo(itemId)
      result = response.data
      if (response.data.language) {
        handleChangeLanguage({
          value: response.data.language.toString(),
          label: languages.find((i) => i.value === response.data.language.toString())?.label,
        })
      }
      if (response.data.videoType) {
        handleChangeVideoType({
          value: response.data.videoType.toString(),
          label: videoTypes.find((i) => i.value === response.data.videoType.toString())?.label,
        })
      }
    } else {
      result = videoInitValues
    }
    setVideo(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeVideoType = (e: any) => {
    setSelectedVideoType(e)
  }
  const handleChangeLanguage = (e: any) => {
    setSelectedLanguage(e)
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Video</h1>
              <div className='text-muted fw-bold fs-5'>
                Video Management <small>Educall</small>
              </div>
            </div>
            <div className="row mb-10">
              <div className='col-lg-6'>
                <label className='form-label fw-bolder required'>For Whom</label>
                <Select
                  {...formik.getFieldProps('videoType')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeVideoType(event)}
                  value={selectedVideoType}
                  options={videoTypes}
                />
                <FormikValidationError
                  touched={formik.touched.videoType}
                  error={formik.errors.videoType}
                />
              </div>
              <div className='col-lg-6'>
                <label className='form-label fw-bolder required'>Language</label>
                <Select
                  {...formik.getFieldProps('language')}
                  styles={customSelectStyles(true)}
                  onChange={(event) => handleChangeLanguage(event)}
                  value={selectedLanguage}
                  options={languages}
                />
                <FormikValidationError
                  touched={formik.touched.language}
                  error={formik.errors.language}
                />
              </div>
            </div>
            <div className='alert alert-info'>To add it to the group, prepend "$$groupname$$".Do not use spaces. For example; <b>$$Grammar$$Hello World!</b></div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Title</label>
              <input
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Youtube Link</label>
              <input
                {...formik.getFieldProps('link')}
                type='text'
                name='link'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.link} error={formik.errors.link} />
            </div>
            <div>
              <label className='form-label fw-bolder'>Order</label>
              <input
                {...formik.getFieldProps('order')}
                type='number'
                name='order'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.order} error={formik.errors.order} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { VideoFormModal }
