import { FC, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import StudentApi from '../../../../infrastructure/api/StudentApi'
import { IOurStudentsWithoutPlanModel } from '../../../../models/responses/OurStudentsWithoutPlanModel'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const OurStudentsWithoutPlanListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [ourStudents, setOurStudents] = useState<PagedResponseModel<IOurStudentsWithoutPlanModel[]>>()


  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')

  async function init() {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    var response = await StudentApi.GetOurStudentsWithoutPlan(paginationFilter)
    setOurStudents(response.data)
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Our Students Without Plan'
        data={ourStudents?.items}
        currentTotalRecords={ourStudents?.totalRecords!!}
        currentPageNumber={ourStudents?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: "Student", name: "id", options: {
              customBodyRender: (value) => {
                return <b>{ourStudents?.items?.find(i => i.id == value)?.firstName} {ourStudents?.items?.find(i => i.id == value)?.lastName}</b>
              }
            }
          },
          {
            label: 'Tolk Package',
            name: 'tolkPackage',
          },
          {
            label: 'Remaining Tolk',
            name: 'tolkRemaining',
          },
          {
            label: "Educall Supported", name: "isSupportByEducall", options: {
              customBodyRender: (value) => {
                return <div className='ps-5 fw-bolder text-success'>
                  {value != "" ? '✓' : null}
                </div>
              },
              filter: false,
              searchable: false
            }
          },
          {
            label: "Company Supported", name: "isSupportByCompany", options: {
              customBodyRender: (value) => {
                return <div className='ps-5 fw-bolder text-success'>
                  {value != "" ? '✓' : null}
                </div>
              },
              filter: false,
              searchable: false
            }
          },
          {
            label: 'Education Start Date',
            name: 'educationStartDate',
          },
          {
            label: 'Education End Date',
            name: 'educationEndDate',
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={ourStudents?.items[dataIndex]?.id!!}
                    itemParam={ourStudents?.items[dataIndex]?.id!!}
                    types={[]}
                    links={[
                      { type: 'user', value: '/account/users/' + ourStudents?.items[dataIndex]?.id!! + '/settings' }
                    ]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { OurStudentsWithoutPlanListModal }
