import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { TaskDefineFormValidations } from '../../../validations/TaskDefineFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EditorToolbarEnum } from '../../../enums/EditorToolbarEnum'
import { DefaultEditor } from '../../../components/editors/DefaultEditor'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { DictionaryModel } from '../../../../models/responses/DictionaryModel'
import { RoleEnum } from '../../../../enums/RoleEnum'
import { ITaskDefineModel, taskDefineInitValues } from '../../../../models/responses/TaskModel'
import TaskApi from '../../../../infrastructure/api/TaskApi'
import AsyncSelect from 'react-select/async'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { ITaskDefineRequestModel } from '../../../../models/requests/TaskDefineRequestModel'
import debounce from 'es6-promise-debounce'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const TaskDefineFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [taskDefine, setTaskDefine] = useState<ITaskDefineModel>(taskDefineInitValues)
  const [definition, setDefinition] = useState('')
  const [isOneTransaction, setOneTransaction] = useState(false)

  const [responsibleUsers, setResponsibleUsers] = useState<SelectModel[]>([])
  const [selectedResponsibleUsers, setSelectedResponsibleUsers] = useState<SelectModel[]>([])

  const formik = useFormik<ITaskDefineModel>({
    initialValues: taskDefine,
    enableReinitialize: true,
    validationSchema: TaskDefineFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ITaskDefineRequestModel = {
          id: values.id == '' ? undefined : values.id,
          title: values.title,
          definition: definition,
          order: values.order,
          responsibleUsers: selectedResponsibleUsers.map(i => i.value),
          isOneTransaction: isOneTransaction,
          expiryDay: values.expiryDay,
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: ITaskDefineRequestModel) {
    try {
      var result = undefined
      if (model.id === undefined || model.id === '')
        result = await TaskApi.AddTaskDefine(model)
      else {
        result = await TaskApi.UpdateTaskDefine(model)
      }

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: ITaskDefineModel
    if (itemId != '') {
      var response = await TaskApi.GetTaskDefine(itemId)
      result = response.data

      var responseUsers = await UserApi.GetUserDictionaryIds(result.responsibleUsers.map(i => i))
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      var selectedResponsibles = result.responsibleUsers.map(i => ({ "value": i, "label": dataUsers.find(j => j.id == i)?.email }));
      setSelectedResponsibleUsers(selectedResponsibles)

      setOneTransaction(response.data.isOneTransaction)
    } else {
      result = taskDefineInitValues
      setSelectedResponsibleUsers([])

      setOneTransaction(false)
    }    

    setTaskDefine(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])


  const getUserDatas = async (event: string, setUser: any) => {
    if (event.length > 1) {
      const paginationFilter: IPaginationFilter = {
        page: 1,
        size: 5,
        orderBy: "firstName_asc",
        search: event
      }

      var filters: DictionaryModel[] = []
      var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
      var datas: SelectModel[] = [
        ...response.data.items.map((d) => ({
          value: d.id.toString(),
          label: `${d.firstName} ${d.lastName} - ${d.email}`,
        })),
      ]
      setUser(datas)
      return datas;
    }
    return [];
  }

  const handleChangeOneTransaction = () => {
    setOneTransaction(!isOneTransaction);
  }

  const handleResponsibleChange = (e: any) => {
    setSelectedResponsibleUsers(e);
  }



  const loadResponsibleOptions = (inputValue: string) =>
    new Promise<SelectModel[]>((resolve) => {
      setTimeout(async () => {
        var data = await getUserDatas(inputValue, setResponsibleUsers);
        resolve(data);
      }, 1000);
    });

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-5 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Task Define</h1>
              <div className='text-muted fw-bold fs-5'>
                Task Management <small>Educall</small>
              </div>
            </div>

            <div className='row mb-6'>
              <div className={'col-8 mb-6'}>
                <label className='form-label fw-bolder required'>Title</label>
                <input
                  {...formik.getFieldProps('title')}
                  type='text'
                  name='title'
                  className='form-control form-control-solid'
                  placeholder='Entry..'
                />
                <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
              </div>
              <div className='col-2 mb-6'>
                <label className='form-label fw-bolder required'>Order</label>
                <input
                  {...formik.getFieldProps('order')}
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Order'
                />
                <FormikValidationError touched={formik.touched.expiryDay} error={formik.errors.expiryDay} />
              </div>
              <div className='col-2 mb-6'>
                <label className='form-label fw-bolder required'>Expiry Day</label>
                <input
                  {...formik.getFieldProps('expiryDay')}
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Expiry Day'
                />
                <FormikValidationError touched={formik.touched.expiryDay} error={formik.errors.expiryDay} />
              </div>
              <div className='col-12'>
                <label className='form-label fw-bolder required'>Responsible Users</label>
                <AsyncSelect
                  cacheOptions
                  {...formik.getFieldProps('responsibleUsers')}
                  styles={customSelectStyles(true)}
                  isMulti={true}
                  loadOptions={debounce(loadResponsibleOptions, 500)}
                  onChange={(event) => handleResponsibleChange(event)}
                  defaultOptions={responsibleUsers}
                  value={selectedResponsibleUsers}
                />
              </div>

            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Definition</label>
              <DefaultEditor
                toolbars={[EditorToolbarEnum.Basic]}
                setData={setDefinition}
                data={itemId != '' ? taskDefine.definition : ''}
                onInit={() => {
                }}
              />
            </div>
            <div className='form-check form-switch form-check-custom form-check-solid mb-10'>
              <input
                {...formik.getFieldProps('isOneTransaction')}
                className='form-check-input h-30px w-50px'
                type='checkbox'
                name='isOneTransaction'
                checked={isOneTransaction}
                onClick={handleChangeOneTransaction}
              />
              <label className='form-check-label'>Is Enough One Transaction</label>
            </div>

          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { TaskDefineFormModal }
