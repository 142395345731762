import { IReportModel } from '../../models/responses/ReportModel'
import API from './Api'
import { IReportGetModel, IReportRequestModel } from '../../models/requests/ReportRequestModel'

class ReportApi {
  async GetReports(model: IReportGetModel) {
    return await API.post<IReportModel[]>(`s/Admin/Reports/daily`, model)
  }

  async CreateReport(model: IReportRequestModel) {
    return await API.post('s/Admin/Reports', model)
  }

}

export default new ReportApi()
