import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { RoleModel } from '../../../models/responses/RoleModel'
import { IUserModel } from '../../../models/responses/UserModel'
import { Link, useParams } from "react-router-dom";
import RoleApi from '../../../infrastructure/api/RoleApi'
import UserApi from '../../../infrastructure/api/UserApi'
import { DictionaryModel } from '../../../models/responses/DictionaryModel'

import { IPaginationFilter } from '../../../models/requests/PaginationFilter'
import { GenericTable } from '../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../models/responses/PagedResponseModel'
import { TableHelperButtons } from '../../components/buttons/TableHelperButtons'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
}

const RoleViewPage: React.FC<Props> = ({ onClickItem }) => {
  const [roleView, setRoleView] = useState<RoleModel | undefined>()
  const { id } = useParams<any>()

  const [users, setUsers] = useState<PagedResponseModel<IUserModel[]>>()

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('firstName_asc')

  const paginationFilter: IPaginationFilter = {
    page: pageNumber,
    size: pageSize,
    orderBy: sort,
    search: searchText
  }

  useEffect(() => {
    getRole(id)
  }, [id, pageNumber, pageSize, searchText, sort])


  async function getUsers(roleName: string) {
    var filters: DictionaryModel[] = [{ type: "Role", value: roleName }]
    var response = await UserApi.GetUsersByFilter(paginationFilter, filters)
    setUsers(response.data)
  }

  async function getRole(id: string) {
    var response = await RoleApi.GetRole(id)
    getUsers(response.data.name)
    setRoleView(response.data)
  }
  return (
    <>
      <div className='row'>
        <div className="col-md-3">
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{roleView?.name || undefined}</span>
                <span className='text-muted mt-1 fw-bold fs-7'>Have {roleView?.permissions?.length || undefined} permissions</span>
              </h3>
            </div>
            <div className="card-body pt-1">
              <div className="d-flex flex-column text-gray-600">
                {roleView?.permissions?.sort().map((permission, j) => {
                  return (
                    <div key={j} className="d-flex align-items-center py-2">
                      <span className="bullet bg-primary me-3"></span> {permission.split(/(?=[A-Z])/).join(" ")}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="card-footer flex-wrap pt-0">
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className='card mb-5 mb-xl-10' >
            <div className="card-header pt-5">
              <div className="card-title">
                <h2 className="d-flex align-items-center">Users Assigned
                  <span className="text-gray-600 fs-6 ms-1">({users?.totalRecords})</span></h2>
              </div>
              <div className="card-toolbar">
              </div>
            </div>
            <div className="card-body pt-0">

            <GenericTable
                title=""
                data={users?.items}
                currentTotalRecords={users?.totalRecords!!}
                currentPageNumber={users?.pageNumber!!}
                columns={[
                  {
                    label: "#", name: "#", options: {
                      customBodyRenderLite: (dataIndex) => {
                        return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                      },
                      sort: false,
                      viewColumns: false
                    }
                  },
                  {
                    label: "FullName", name: "firstName", options: {
                      customBodyRenderLite: (dataIndex) => {
                        return (
                          <b>{users?.items[dataIndex].firstName} {users?.items[dataIndex].lastName}</b>
                        )
                      },
                    },
                  },
                  { label: "Email", name: "email" },
                  { label: "Phone", name: "phoneNumber" },
                  {
                    label: "#", name: "Id", options: {
                      customHeadLabelRender: (options) => {
                        return (<div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>)
                      },
                      customBodyRenderLite: (dataIndex) => {
                        return (
                          <TableHelperButtons
                            itemId={users?.items[dataIndex]?.id!!}
                            itemParam={`${users?.items[dataIndex]?.firstName!!} ${users?.items[dataIndex]?.lastName!!}`}
                            links={[
                              { type: 'user', value: '/account/users/' + users?.items[dataIndex]?.id!! }
                            ]}
                            types={[]}
                            onClickItem={onClickItem}
                          />
                        )
                      },
                      filter: false,
                      sort: false,
                      viewColumns: false
                    },
                  }
                ]}
                customOptions={{
                  rowsPerPage: pageSize,
                  customTableBodyFooterRender: (opts) => {
                    return <div></div>
                  }
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RoleViewPage
