import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { HelpDeskStatusEnum } from '../../../../enums/HelpDeskStatusEnum'
import { ICompleteHelpDeskRequestModel } from '../../../../models/requests/HelpDeskRequestModel'
import { IHelpDeskModel, helpDeskInitValues } from '../../../../models/responses/HelpDeskModel'
import HelpDeskApi from '../../../../infrastructure/api/HelpDeskApi'

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const HelpDeskFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [helpDesk, setHelpDesk] = useState<IHelpDeskModel>(helpDeskInitValues)

  const statuses = EnumToList(HelpDeskStatusEnum);
  const [selectedStatus, setSelectedStatus] = useState<SelectModel>()

  const formik = useFormik<IHelpDeskModel>({
    initialValues: helpDesk,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ICompleteHelpDeskRequestModel = {
          id: values.id,
          result: values.result,
          status: parseInt(selectedStatus?.value!!),
        }
        processForm(model)
      }, 500)
    },
  })

  async function processForm(model: ICompleteHelpDeskRequestModel) {
    try {
      var result = await HelpDeskApi.CompleteHelpDesk(model)

      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IHelpDeskModel
    if (itemId != '') {
      var response = await HelpDeskApi.GetHelpDesk(itemId)
      result = response.data

      handleChangeStatus({
        value: response.data.status.toString(),
        label: statuses.find(i => i.value == response.data.status.toString())?.label
      })

    } else {
      result = helpDeskInitValues
    }
    setHelpDesk(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeStatus = (e: any) => {
    setSelectedStatus(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === '' ? 'Add' : 'Edit'} Help</h1>
              <div className='text-muted fw-bold fs-5'>
                Help Desk <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <div className='alert alert-primary'>
                <h4 className="alert-heading">{helpDesk.subject}</h4>
                
                {helpDesk.file != undefined ?
                    <><a href={process.env.REACT_APP_CDN_URL + 'files/helpdesk/' + helpDesk.file!!} target="_blank" download><i className="fas fa-download"></i></a><br /></>
                    : <></>}
                    {helpDesk.content}
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Status</label>
              <Select
                {...formik.getFieldProps('status')}
                styles={customSelectStyles(true)}
                onChange={(event) => handleChangeStatus(event)}
                value={selectedStatus}
                options={statuses}
              />
              <FormikValidationError touched={formik.touched.status} error={formik.errors.status} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Result</label>
              <input
                {...formik.getFieldProps('result')}
                type='text'
                name='result'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.result} error={formik.errors.result} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { HelpDeskFormModal }
