import { useFormik } from 'formik'
import { Fragment, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PermissionApi from '../../../../infrastructure/api/PermissionApi'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserPermissionsRequestModel, userPermissionsRequestModelInitValues } from '../../../../models/requests/UserPermissionsRequestModel'
import { PanelModel } from '../../../../models/responses/PanelModel'
import { IUserModel } from '../../../../models/responses/UserModel'
import { RootState } from '../../../../setup'
import * as account from './../redux/AccountRedux'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

export function Permissions() {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const dispatch = useDispatch()

  const user: IUserModel = useSelector<RootState>(({ account }) => account.selectedUser, shallowEqual) as IUserModel
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [permissions, setPermissions] = useState([] as PanelModel[])


  async function getPermissions() {
    var response = await PermissionApi.GetPermissions()
    var permissionList: PanelModel[] = []
    response.data.forEach(panel => {
      panel.modules.forEach(module => {
        module.permissions.forEach(permission => {
          var isActive = user.permissions!!.some(s => s == `${module.name}${permission.name}`)
          permission.isActive = isActive;
        })
      });
      permissionList.push(panel);
    });
    setPermissions(permissionList)
  }

  const formik = useFormik({
    initialValues: userPermissionsRequestModelInitValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IUserPermissionsRequestModel = {
          id: user.id,
          permissions: []
        }
        permissions.map((panel) => {
          panel.modules.map((module) => {
            module.permissions.filter(i => i.isActive == true).map((permission) => {
              model.permissions?.push({ type: "Permission", value: `${module.name}${permission.name}` });
            });
          })
        })

        processRole(model)
      }, 500)
    },
  })


  async function processRole(model: IUserPermissionsRequestModel) {
    try {
      await UserApi.UpdateUserPermissions(model);
      setComplete(true)
      dispatch(account.actions.updateSelectedUser(true))
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user) {
      getPermissions()
    }
  }, [user?.permissions])

  const handleOnClick = (panelIndex: number, moduleIndex: number, permissionIndex: number) => {
    var tempPermissions = permissions;
    tempPermissions[panelIndex].modules[moduleIndex].permissions[permissionIndex].isActive = !tempPermissions[panelIndex].modules[moduleIndex].permissions[permissionIndex].isActive
    setPermissions(tempPermissions)
  };

  const handleAllCheckOnClick = () => {
    setIsAllChecked(!isAllChecked)
    var tempPermissions = permissions;
    tempPermissions.map((panel, ix) => {
      panel.modules.map((module, i) => {
        module.permissions.map((permission, j) => {
          permission.isActive = !isAllChecked;
        })
      })
    })
    setPermissions(tempPermissions)
  }

  const setButtonDefault = () => {
    setTimeout(() => {
      setLoading(false);
      setComplete(false);
    }, 2000)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Permissions</h3>
          </div>
        </div>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='card-body p-9'>


            <div className="table-responsive">
              <table className="table align-middle table-row-dashed fs-6 gy-5">
                <tbody className="text-gray-600 fw-bold">
                  <tr>
                    <td className="text-gray-800 min-w-200px">Full Access
                      <i className="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip" title="" data-bs-original-title="Allows a full access to the system"
                        aria-label="Allows a full access to the system"></i></td>
                    <td>
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                        <input className="form-check-input" type="checkbox" value=""
                          onChange={handleAllCheckOnClick}
                          checked={isAllChecked || false}
                        />
                        <span className="form-check-label">Select all</span>
                      </label>
                    </td>
                  </tr>
                  {permissions.map((panel, ip) => {
                    return (
                      <Fragment key={ip}>
                        <tr>
                          <td className="text-gray-400 fw-bold mb-0 pe-2" colSpan={2}>{panel.name}</td>
                        </tr>
                        {panel.modules.map((module, im) => {
                          return (
                            <tr key={im + "-" + ip}>
                              <td className="text-gray-800">{module.name.split(/(?=[A-Z])/).join(" ")}</td>
                              <td>
                                <div className="d-flex">
                                  {module.permissions.map((permission, i) => {
                                    return (
                                      <label key={i} className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                        <input className="form-check-input" type="checkbox"
                                          {...formik.getFieldProps('permissions')}
                                          onClick={() => handleOnClick(ip, im, i)}
                                          value={`${module.name}${permission.name}`}
                                          checked={permission.isActive || false}
                                        />
                                        <span className="form-check-label">{permission.name}</span>
                                      </label>
                                    )
                                  })}
                                </div>
                              </td>
                            </tr>
                          )
                        })
                        }
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>

          <div className='card-footer'>
            <div className='text-end'>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm} />
            </div>

          </div>
        </form>
      </div>

    </>
  )
}
