import { ICampaignParticipantRequestModel } from '../../models/requests/CampaignParticipantRequestModel'
import { ICampaignParticipantModel } from '../../models/responses/CampaignParticipantModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class CampaignParticipantApi {

  async GetCampaignParticipants(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ICampaignParticipantModel[]>>(`e/Admin/CampaignParticipants${generateQueryParams(filter)}`)
  }

  async GetCampaignParticipant(id: number) {
    return await API.get<ICampaignParticipantModel>('e/Admin/CampaignParticipants/' + id)
  }

  async AddCampaignParticipant(model: ICampaignParticipantRequestModel) {
    return await API.post('e/Admin/CampaignParticipants', model)
  }

  async UpdateCampaignParticipant(model: ICampaignParticipantRequestModel) {
    return await API.put('e/Admin/CampaignParticipants/' + model.id, model)
  }

  async DeleteCampaignParticipant(itemId: number) {
    return await API.delete<boolean>('e/Admin/CampaignParticipants/' + itemId)
  }
}

export default new CampaignParticipantApi()
