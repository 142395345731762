import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PricingFormModal } from './_modals/PricingFormModal'
import { PricingListModal } from './_modals/PricingListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import PricingApi from '../../../infrastructure/api/PricingApi'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'

const PricingPage: FC = () => {
  const [itemId, setItemId] = useState(0)
  const [itemName, setItemName] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: number, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deletePricing(itemId: number) {
    var response = await PricingApi.DeletePricing(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <PricingListModal refresh={refresh} onClickItem={onClickItem} />
      <PricingFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
      <DeleteModal
        itemId={itemId}
        itemName={itemName}
        handleClose={handleCloseDelete}
        show={showDelete}
        type='Pricing'
        confirm={deletePricing}
      />

    </>
  )
}

const PricingWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Pricing</PageTitle>
      <PricingPage />
    </>
  )
}

export { PricingWrapper }
