export interface PagedResponseModel<T> {
    items: T
    pageNumber: number,
    pageSize: number,
    totalRecords: number,
    totalPages: number,
}
const initPagedResponse: PagedResponseModel<any> = {

    items: [],
    pageNumber: 0,
    pageSize: 0,
    totalRecords: 0,
    totalPages: 0,
}

export { initPagedResponse }
