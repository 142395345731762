import { IPresentationRequestModel } from '../../models/requests/PresentationRequestModel'
import { IPresentationModel } from '../../models/responses/PresentationModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class PresentationApi {

  async GetPresentations(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPresentationModel[]>>(`e/Admin/Presentations${generateQueryParams(filter)}`)
  }

  async GetPresentation(id: number) {
    return await API.get<IPresentationModel>('e/Admin/Presentations/' + id)
  }

  async AddPresentation(model: IPresentationRequestModel) {
    return await API.post('e/Admin/Presentations', model)
  }

  async UpdatePresentation(model: IPresentationRequestModel) {
    return await API.put('e/Admin/Presentations/' + model.id, model)
  }

  async DeletePresentation(itemId: number) {
    return await API.delete<boolean>('e/Admin/Presentations/' + itemId)
  }
}

export default new PresentationApi()
