import { FC, useEffect, useState } from 'react'
import LevelGroupApi from '../../../../infrastructure/api/LevelGroupApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { ILevelGroupModel, levelGroupInitValues } from '../../../../models/responses/LevelGroupModel'
import { ILevelGroupRequestModel } from '../../../../models/requests/LevelGroupRequestModel'
import { LevelGroupFormValidations } from '../../../validations/LevelGroupFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const LevelGroupFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [levelGroup, setLevelGroup] = useState<ILevelGroupModel>(levelGroupInitValues)
  const updateLevelGroup = (fieldsToUpdate: Partial<ILevelGroupModel>) => {
    const updatedData = { ...levelGroup, ...fieldsToUpdate }
    setLevelGroup(updatedData)
  }


  const formik = useFormik<ILevelGroupModel>({
    initialValues: levelGroup,
    enableReinitialize: true,
    validationSchema: LevelGroupFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: ILevelGroupRequestModel = {
          id: values.id,
          title: values.title
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: ILevelGroupRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await LevelGroupApi.AddLevelGroup(model)
      else {
        result = await LevelGroupApi.UpdateLevelGroup(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: ILevelGroupModel
    if (itemId > 0) {
      var response = await LevelGroupApi.GetLevelGroup(itemId)
      result = response.data
    } else {
      result = levelGroupInitValues
    }
    setLevelGroup(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Level</h1>
              <div className='text-muted fw-bold fs-5'>
                Level Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-5'>              
              <label className='form-label fw-bolder required'>Title</label>
              <input
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { LevelGroupFormModal }
