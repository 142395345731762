export interface IHelpDeskModel {
  id: string
  subject: string
  content: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  createdAt: string
  result: string
  status: number
  file: string
}

const helpDeskInitValues: IHelpDeskModel = {
  id: '',
  subject: '',
  content: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  createdAt: '',
  result: '',
  status: 0,
  file: ''
}

export interface IHelpDesksModel {
  id: string
  subject: string
  createdBy: string
  completedUserId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  createdAt: string
  result: string
  status: number
  file: string
}



const helpDesksInitValues: IHelpDesksModel = {
  id: '',
  subject: '',
  createdBy: '',
  completedUserId: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  createdAt: '',
  result: '',
  status: 0,
  file: ''
}

export { helpDeskInitValues, helpDesksInitValues }
