import { FC, useEffect, useRef, useState } from 'react'
import ExamApi from '../../../../infrastructure/api/ExamApi'
import { useFormik } from 'formik'
import {
  IExamModel,
  examInitValues
} from '../../../../models/responses/ExamModel'
import { IQuestionModel } from '../../../../models/responses/QuestionModel'
import { IExamRequestModel } from '../../../../models/requests/ExamRequestModel'
import { ExamFormValidations } from '../../../validations/ExamFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select'
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import ExamTypeApi from '../../../../infrastructure/api/ExamTypeApi'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import { useParams } from 'react-router-dom'
import { Questions } from '../components/Questions'
import { IExamQuestionFormModel } from '../../../models/ExamQuestionFormModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { QuestionTypeEnum } from '../../../../enums/QuestionTypeEnum'
import UnitApi from '../../../../infrastructure/api/UnitApi'
import { FileTypeEnum } from '../../../../enums/FileTypeEnum'
import FileApi from '../../../../infrastructure/api/FileApi'
import { slugify } from '../../../helpers/StringHelper'
import { isVideo, isSound, isImage } from '../../../helpers/FileHelper'

const defaultSelect: SelectModel = {
  value: '0', label: "N/A"
};

type Props = {
  itemId: string
  show: boolean
  handleClose: () => void
}

const ExamFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const nameForm = useRef(null)

  const { id } = useParams<any>()
  itemId = id ? id : ''
  const [exam, setExam] = useState<IExamModel>(examInitValues)

  const [levelDictionary, setLevelDictionary] = useState<SelectModel[]>([])
  const [unitDictionary, setUnitDictionary] = useState<SelectModel[]>([])

  const [selectedLevel, setSelectedLevel] = useState<SelectModel>()
  const [selectedUnit, setSelectedUnit] = useState<SelectModel>()

  const [examTypeDictionary, setExamTypeDictionary] = useState<SelectModel[]>([])
  const [selectedExamType, setSelectedExamType] = useState<SelectModel>()
  const [isRandomQuestion, setRandomQuestion] = useState<boolean>(false)
  const [isVisible, setVisible] = useState<boolean>(false)
  const [questions, setQuestions] = useState<IExamQuestionFormModel[]>([])
  const questionTypeList = EnumToList(QuestionTypeEnum);

  const updateExam = (fieldsToUpdate: Partial<IExamModel>) => {
    const updatedData = { ...exam, ...fieldsToUpdate }
    setExam(updatedData)
  }

  const formik = useFormik<IExamModel>({
    initialValues: exam,
    enableReinitialize: true,
    validationSchema: ExamFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IExamRequestModel = {
          id: itemId == '' ? undefined : itemId,
          title: values.title,
          levelId: parseInt(selectedLevel?.value!!),
          unitId: parseInt(selectedUnit?.value!!),
          examTypeId: selectedExamType?.value!!,
          isRandomQuestion: isRandomQuestion,
          isVisible: isVisible,
          questionNumber: values.questionNumber,
          questions: []
        }
        questions.forEach(i => {
          var question: IQuestionModel = {
            id: i.id,
            title: i.title,
            comment: i.comment!!,
            sound: i.sound!!,
            image: i.image!!,
            video: i.video!!,
            text: i.text!!,
            questionType: i.questionType!!,
            unitId: i.unitId!!,
            levelId: i.level!!,
            examId: values.id == '' ? undefined : values.id,
            answers: [],
            order: i.order!!
          }

          i.answers?.forEach(j => {
            question.answers.push({
              id: j.id,
              text: j.text!!,
              isCorrect: j.isCorrect!!,
              image: j.image!!
            })
          })
          model.questions.push(question)
        })

        setLoading(false)

        process(model)
      }, 500)
    },
  })


  async function process(model: IExamRequestModel) {
    try {
      let d = new Date()

      var requests = questions.map(async (question, questionIndex) => {
        if (question.file !== undefined) {
          const fileName = slugify(`q-${question.title}-${questionIndex}-${d.getMilliseconds()}-${d.getMinutes()}`)
          const ext = question.file.name.split('.').pop();
          const filePath = fileName + '.' + ext;
          if (isImage(ext)) {
            model.questions[questionIndex].image = filePath
            model.questions[questionIndex].video = ''
            model.questions[questionIndex].sound = ''
          } else if (isVideo(ext)) {
            model.questions[questionIndex].video = filePath
            model.questions[questionIndex].image = ''
            model.questions[questionIndex].sound = ''
          } else if (isSound(ext)) {
            model.questions[questionIndex].sound = filePath
            model.questions[questionIndex].image = ''
            model.questions[questionIndex].video = ''
          }
          let modelFile = new FormData()
          modelFile.append('name', fileName)
          modelFile.append('file', question.file)
          modelFile.append('type', FileTypeEnum.Exam)
          var uploadFile = await FileApi.AddFile(modelFile)
        }
        question.answers!!.map(async (answer, index) => {
          if (answer.file !== undefined) {
            const fileName = slugify(`a-${answer.text}-${questionIndex}-${index}-${d.getMilliseconds()}-${d.getMinutes()}`)
            const imagePath = fileName + '.' + answer.file.name.split('.').pop();
            model.questions[questionIndex].answers[index].image = imagePath
            let modelFile = new FormData()
            modelFile.append('name', fileName)
            modelFile.append('file', answer.file)
            modelFile.append('type', FileTypeEnum.Exam)
            var uploadFile = await FileApi.AddFile(modelFile)
          }
        });
        model.questions[questionIndex].answers = model.questions[questionIndex].answers.filter(i => i.text?.trim() !== '')
      })
      model.questions = model.questions.filter(i => i.title.trim() !== '')
      Promise.all(requests).then(async () => {
        var result = undefined
        if (model.id === undefined || model.id === '')
          result = await ExamApi.AddExam(model)
        else {
          result = await ExamApi.UpdateExam(model)
        }
        formik.resetForm()
      })
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    var responseLevels = await LevelApi.GetLevelDictionary()
    var dataLevels: SelectModel[] = [...responseLevels.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setLevelDictionary(dataLevels)
    var responseUnits = await UnitApi.GetUnitDictionary()
    var dataUnits: SelectModel[] = [defaultSelect, ...responseUnits.data.map(d => ({
      value: d.id.toString(),
      label: d.name
    }))]
    setUnitDictionary(dataUnits)

    var responseExamTypes = await ExamTypeApi.GetExamTypes({
      page: 1,
      size: 9999,
      orderBy: 'title_asc',
      search: '',
    })
    var dataExamTypes: SelectModel[] = [...responseExamTypes.data.items.map(d => ({
      value: d.id.toString(),
      label: d.title
    }))]
    setExamTypeDictionary(dataExamTypes)

    let result: IExamModel
    if (itemId !== '') {
      var response = await ExamApi.GetExam(itemId)
      result = response.data
      var questions: IExamQuestionFormModel[] = [...result.questions.map(d => ({
        id: d.id,
        title: d.title,
        comment: d.comment,
        order: d.order,
        level: d.levelId,
        unitId: d.unitId,
        questionType: d.questionType,
        sound: d.sound,
        text: d.text,
        image: d.image,
        video: d.video,
        answers: [...d.answers.map(a => ({
          id: a.id,
          text: a.text,
          image: a.image,
          isCorrect: a.isCorrect
        }))]

      }))];
      setQuestions(questions)
      if (response.data.examTypeId) {
        handleChangeExamType({
          value: response.data.examTypeId.toString(),
          label: dataExamTypes.find((i) => i.value === response.data.examTypeId.toString())?.label,
        })
      }
      if (response.data.levelId) {
        handleChangeLevel({
          value: response.data.levelId.toString(),
          label: dataLevels.find((i) => i.value === response.data.levelId.toString())?.label,
        })
      }
      if (response.data.unitId) {
        handleChangeUnit({
          value: response.data.unitId.toString(),
          label: dataUnits.find((i) => i.value === response.data.unitId.toString())?.label,
        })
      }

      setVisible(response.data.isVisible)
      setRandomQuestion(response.data.isRandomQuestion)

    } else {
      result = examInitValues
    }
    setExam(result)
  }

  useEffect(() => {
    formik.resetForm()
    init()
  }, [itemId, show])

  const handleChangeRandomQuestion = () => {
    setRandomQuestion(!isRandomQuestion)
  }
  const handleChangeVisible = () => {
    setVisible(!isVisible)
  }
  const handleChangeExamType = (e: any) => {
    setSelectedExamType(e)
  }
  const handleChangeLevel = (e: any) => {
    setSelectedLevel(e)
  }

  const handleChangeUnit = (e: any) => {
    setSelectedUnit(e)
  }

  const getInput = (name: string) => {
    const form = nameForm.current
    return form!![name]
  }

  return (
    <form className='form' onSubmit={formik.handleSubmit} noValidate>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h1>{itemId === '' ? 'Add' : 'Edit'} Exam</h1>
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='row mb-8'>
          <div className='col-8 mb-6'>
            <label className='form-label fw-bolder required'>Title</label>
            <input
              {...formik.getFieldProps('title')}
              type='text'
              name='title'
              className='form-control form-control-solid'
              placeholder='Entry..'
            />
            <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
          </div>
          <div className='col-4 mb-6'>
            <label className='form-label fw-bolder required'>Exam Type</label>
            <Select
              {...formik.getFieldProps('examTypeId')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeExamType(event)}
              value={selectedExamType}
              options={examTypeDictionary}
            />
            <FormikValidationError
              touched={formik.touched.examTypeId}
              error={formik.errors.examTypeId}
            />
          </div>
          <div className='col-4 mb-6'>
            <label className='form-label fw-bolder'>Level</label>
            <Select
              {...formik.getFieldProps('levelId')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeLevel(event)}
              value={selectedLevel}
              options={levelDictionary}
            />
            <FormikValidationError
              touched={formik.touched.levelId}
              error={formik.errors.levelId}
            />
          </div>
          <div className='col-4 mb-6'>
            <label className='form-label fw-bolder'>Unit</label>
            <Select
              styles={customSelectStyles(true)}
              options={unitDictionary}
              onChange={(event) => handleChangeUnit(event)}
              value={selectedUnit}
            />
          </div>
          <div className='col-4 mb-6'>
            <label className='form-label required form-label fw-bolder'>Question Number</label>
            <input
              {...formik.getFieldProps('questionNumber')}
              type='number'
              name='questionNumber'
              className='form-control form-control-solid'
              placeholder='Question Number'
            />
          </div>
          <Questions setQuestions={setQuestions} questions={questions}
            questionTypes={questionTypeList}
            getInput={getInput} />

          <div className='form-check form-switch form-check-custom form-check-solid mb-10 me-10'>
            <input
              {...formik.getFieldProps('isRandomQuestion')}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='isRandomQuestion'
              checked={isRandomQuestion}
              onClick={handleChangeRandomQuestion}
            />
            <FormikValidationError touched={formik.touched.isRandomQuestion} error={formik.errors.isRandomQuestion} />
            <label className='form-check-label'>Random Questions</label>
          </div>

          <div className='form-check form-switch form-check-custom form-check-solid mb-10 me-10'>
            <input
              {...formik.getFieldProps('isVisible')}
              className='form-check-input h-30px w-50px'
              type='checkbox'
              name='isVisible'
              checked={isVisible}
              onClick={handleChangeVisible}
            />
            <FormikValidationError touched={formik.touched.isVisible} error={formik.errors.isVisible} />
            <label className='form-check-label'>Is Visible</label>
          </div>
        </div>
      </div>
      <div className='card-footer py-3'>
        <div className='d-flex justify-content-end'>
          <SaveChangesButton
            loading={loading}
            setLoading={setLoading}
            complete={complete}
            setComplete={setComplete}
            valid={formik.isValid}
            submit={formik.isSubmitting}
            setSubmit={formik.submitForm}
          />
        </div>
      </div>
    </form>
  )
}

export { ExamFormModal }
