import { FC, useEffect, useState } from 'react'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import StudentApi from '../../../../infrastructure/api/StudentApi'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'
import { IStudentPlansModel } from '../../../../models/responses/StudentModel'
import { ScheduleTypeEnum } from '../../../../enums/ScheduleTypeEnum'
import { GenericTable } from '../../../components/tables/GenericTable'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import Select from 'react-select'
import { EnumToList } from '../../../helpers/EnumHelper'
import { selectInitValues, SelectModel } from '../../../models/SelectModel'
import { ScheduleStatusEnum } from '../../../../enums/ScheduleStatusEnum'
import { Link } from 'react-router-dom'
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap-v5'
import { ScheduleStatusColorizer } from '../../../functions/ScheduleStatusColorizer'

type Props = {
    onClickItem: (itemId: any, itemName: string, type: string) => void
    refresh: number
}

const StudentPlanListModal: FC<Props> = ({ onClickItem, refresh }) => {
    const d = new Date();
    let weekDay = d.getUTCDay();

    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState('startDate_asc')
    const dayList = EnumToList(DayOfWeekEnum);
    const [selectedDay, setSelectedDay] = useState<SelectModel>(dayList.find(i => i.value == weekDay.toString()) || selectInitValues)
    const [startDate, setStartDate] = useState<string>('00:00')
    const [endDate, setEndDate] = useState<string>('23:59')
    const [students, setStudents] = useState<PagedResponseModel<IStudentPlansModel[]>>()
    const [scheduleStatus, setScheduleStatus] = useState<any>(ScheduleStatusEnum.All)
    const [scheduleType, setScheduleType] = useState<ScheduleTypeEnum>()

    let typeList: string[] = EnumToList(ScheduleTypeEnum).map(i => (i.label?.toString() || '')) || [];
    async function init() {
        const paginationFilter: IPaginationFilter = {
            page: pageNumber,
            size: pageSize,
            orderBy: sort,
            search: searchText,
        }

        var response = await StudentApi.GetStudentPlans(paginationFilter,
            {
                dayOfWeek: parseInt(selectedDay.value),
                startDate: startDate + ':00',
                endDate: endDate + ':59',
                scheduleStatus: scheduleStatus,
                scheduleType: scheduleType,
                duration: undefined,
                trainerId: undefined
            })
        setStudents(response.data)
    }

    const handleChangeDay = (e: any) => {
        setSelectedDay(e);
    }

    const handleChangeStartDate = (e: any) => {
        setStartDate(e.target.value);
    }

    const handleChangeEndDate = (e: any) => {
        setEndDate(e.target.value);
    }

    useEffect(() => {
        init()
    }, [refresh, pageNumber, pageSize, searchText, sort, scheduleStatus, scheduleType])

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <label className='form-label fw-bolder'>Day of Week</label>
                    <Select
                        onChange={(event) => handleChangeDay(event)}
                        value={selectedDay}
                        options={dayList}
                    />
                </div>
                <div className='col'>
                    <label className='form-label fw-bolder'>Start Time</label>
                    <input
                        type='time'
                        name='startDate'
                        onChange={(event) => handleChangeStartDate(event)}
                        defaultValue={startDate}
                        className='form-control form-control-solid'
                        placeholder='Entry..'
                    />
                </div>
                <div className='col'>
                    <label className='form-label fw-bolder'>End Time</label>
                    <input
                        type='time'
                        name='endDate'
                        onChange={(event) => handleChangeEndDate(event)}
                        defaultValue={endDate}
                        className='form-control form-control-solid'
                        placeholder='Entry..'
                    />
                </div>
                <div className='col'><button onClick={init} className='btn btn-primary me-4 px-6'>Filter</button>
                </div>
            </div>
            <GenericTable
                title='Student Plans'
                data={students?.items}
                currentTotalRecords={students?.totalRecords!!}
                currentPageNumber={students?.pageNumber!!}
                columns={[
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                    {
                        label: "Name", name: "student", options: {
                            customBodyRender: (item) => {
                                if (item != null) {
                                    return (
                                        <b>
                                            <ButtonToolbar>
                                                <OverlayTrigger
                                                    key={item.id}
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id={`tooltip-bottom`}>
                                                            <ul>
                                                                <li>{item.email}</li>
                                                                <li>{item.phoneNumber}</li>
                                                            </ul>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Link className='btn-sm' to={'/account/users/' + item?.id + '/overview'}>{item?.firstName} {item?.lastName}</Link>
                                                </OverlayTrigger>
                                            </ButtonToolbar>
                                            <br /></b>
                                    )
                                }
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Day", name: "dayOfWeek", options: {
                            customBodyRender: (value) => {
                                return <>{DayOfWeekEnum[value]}</>
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Start Date", name: "startDate", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Duration", name: "duration", options: {
                            customBodyRender: (value) => {
                                return <>{value}</>
                            },
                            filter: false
                        }
                    },
                    {
                        label: "Type", name: "type", options: {
                            customBodyRender: (value) => {
                                return <>{ScheduleTypeEnum[value]}</>
                            },
                            filterOptions: { fullWidth: true, names: typeList }
                        }
                    },
                    {
                        label: "Last Status", name: "schedule", options: {
                            customBodyRender: (value) => {
                                return value?.status != null && <div className='ps-5 fw-bolder text-success'>
                                    <span className={`badge bg-${ScheduleStatusColorizer(parseInt(value.status?.toString() || ''))}`}>
                                        {ScheduleStatusEnum[parseInt(value.status?.toString() || '')]}
                                    </span>
                                </div>
                            },
                            filterOptions: { fullWidth: true, names: ['NotYetCreated', 'Closed', 'Canceled', 'Waiting', 'Completed'] }
                        }
                    },
                    {
                        label: "Is Assigned", name: "isAssigned", options: {
                            customBodyRenderLite: (dataIndex) => {
                                return <>
                                    {students?.items[dataIndex]?.isAssigned == true ? <div className='ps-5 fw-bolder text-success'>✓</div>
                                        : (students?.items[dataIndex]?.skipAutoPair == true ?
                                            <button type="button"
                                                onClick={() => onClickItem(students?.items[dataIndex].id, '', 'reAutoPair')}
                                                className="btn btn-light-dark btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Can't assigned, re pair
                                            </button> : <div className='ps-5 fw-bolder'>Waiting...</div>)}
                                </>
                            },
                            sort: false,
                            filter: false
                        }
                    },
                    {
                        label: "Trainer", name: "trainer", options: {
                            customBodyRender: (value) => {
                                return <b>{value?.firstName} {value?.lastName}</b>
                            },
                            filter: false
                        }
                    },
                    {
                        label: '#',
                        name: 'Id',
                        options: {
                            customHeadLabelRender: (options) => {
                                return (
                                    <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                                )
                            },
                            customBodyRenderLite: (dataIndex) => {
                                return (
                                    <TableHelperButtons
                                        itemId={students?.items[dataIndex]?.student?.id!!}
                                        itemParam={students?.items[dataIndex]?.student?.id!!}
                                        links={[{ type: 'edit', value: '/account/users/' + students?.items[dataIndex]?.student?.id + '/call-plans' }]}
                                        types={[]}
                                        onClickItem={onClickItem}
                                    />
                                )
                            },
                            filter: false,
                            sort: false,
                            viewColumns: false,
                        },
                    },
                ]}
                customOptions={{
                    rowsPerPage: pageSize,
                    filter: true,
                    onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
                        setPageNumber(1)
                        let newFilters: Map<string, string> = new Map()
                        setScheduleStatus(ScheduleStatusEnum.All)
                        setScheduleType(undefined)
                        for (let i = 0; i < filterList.length; i++) {
                            if (filterList[i].length > 0) {
                                if (i == 5) {
                                    var val = filterList[i][0];
                                    const strEnum = val as unknown as ScheduleTypeEnum;
                                    setScheduleType(strEnum)
                                } else if (i == 6) {
                                    var val = filterList[i][0];
                                    if (val == "NotYetCreated") {
                                        setScheduleStatus(null);
                                    }
                                    else {
                                        const strEnum = val as unknown as ScheduleStatusEnum;
                                        setScheduleStatus(strEnum);
                                    }
                                }
                            }
                        }
                    }
                }}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                setSearchText={setSearchText}
                setSort={setSort}
            />
        </>
    )
}

export { StudentPlanListModal }
