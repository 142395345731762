import { FC, Fragment, useEffect, useState } from 'react'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import * as level from '../../../pages/levels/redux/LevelRedux'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import { SelectModel } from '../../../models/SelectModel'
import { RootState } from '../../../../setup'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import LevelApi from '../../../../infrastructure/api/LevelApi'
import UserApi from '../../../../infrastructure/api/UserApi'
import { IUserDictionaryModel } from '../../../../models/responses/UserDictionaryModel'
import { Link } from 'react-router-dom'
import StudentPlanningLogApi from '../../../../infrastructure/api/StudentPlanningLogApi'
import { IStudentPlanningLogModel } from '../../../../models/responses/StudentPlanningModel'
import { StudentPlanningTypeEnum } from '../../../../enums/StudentPlanningTypeEnum'
import { DayOfWeekEnum } from '../../../../enums/DayOfWeekEnum'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const StudentPlanningLogListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [logs, setLogs] = useState<PagedResponseModel<IStudentPlanningLogModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('date_desc')
  const [users, setUsers] = useState<IUserDictionaryModel[]>([])


  async function init() {

    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }

    var response = await StudentPlanningLogApi.GetStudentPlanningLogs(paginationFilter)

    if (response.data.items) {
      var responseUsers = await UserApi.GetUserDictionaryIds(
        [
          ...response.data.items.flatMap(i => i.studentId),
          ...response.data.items.flatMap(i => i.createdBy),
          ...response.data.items.filter(i => i.trainerId != undefined).flatMap(i => i.trainerId!)
        ]);
      var dataUsers: IUserDictionaryModel[] = responseUsers.data
      setUsers(dataUsers)

    }
    setLogs(response.data)
  }

  useEffect(() => {
    init()
  }, [refresh, pageNumber, pageSize, searchText, sort])


  return (
    <>
      <GenericTable
        title='Student Planning Logs'
        data={logs?.items}
        currentTotalRecords={logs?.totalRecords!!}
        currentPageNumber={logs?.pageNumber!!}
        customOptions={
          { rowsPerPage: pageSize }
        }
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false,
            },
          },
          {
            label: 'Student',
            name: 'studentId',
            options: {
              customBodyRender: (value) => {
                return <b><Link to={'/account/users/' + value + '/overview'}>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</Link></b>
              },
            },
          },
          {
            label: 'Trainer',
            name: 'trainerId',
            options: {
              customBodyRender: (value) => {
                return <b>{value ? `${users.find(i => i.id == value)?.firstName} ${users.find(i => i.id == value)?.lastName}` : '-'}</b>
              },
            },
          },
          {
            label: 'Type',
            name: 'type',
            options: {
              customBodyRender: (value) => {
                return <b>{StudentPlanningTypeEnum[value]}</b>
              },
            },
          },
          {
            label: 'Day',
            name: 'dayOfWeek',
            options: {
              customBodyRender: (value) => {
                return <b>{DayOfWeekEnum[value]}</b>
              },
            },
          },
          {
            label: 'Duration',
            name: 'duration',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Start',
            name: 'startDate',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Who Did',
            name: 'createdBy',
            options: {
              customBodyRender: (value) => {
                return <b><Link to={'/account/users/' + value + '/overview'}>{users?.find(i => i.id == value)?.firstName} {users?.find(i => i.id == value)?.lastName}</Link></b>
              },
            },
          },
          {
            label: 'Date',
            name: 'date',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={logs?.items[dataIndex]?.id!!}
                    itemParam={logs?.items[dataIndex]?.id!!}
                    types={[]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { StudentPlanningLogListModal }
