import { ICampaignRequestModel } from '../../models/requests/CampaignRequestModel'
import { ICampaignModel } from '../../models/responses/CampaignModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'

class CampaignApi {

  async GetCampaigns(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ICampaignModel[]>>(`e/Admin/Campaigns${generateQueryParams(filter)}`)
  }

  async GetCampaign(id: number) {
    return await API.get<ICampaignModel>('e/Admin/Campaigns/' + id)
  }

  async AddCampaign(model: ICampaignRequestModel) {
    return await API.post('e/Admin/Campaigns', model)
  }

  async UpdateCampaign(model: ICampaignRequestModel) {
    return await API.put('e/Admin/Campaigns/' + model.id, model)
  }

  async DeleteCampaign(itemId: number) {
    return await API.delete<boolean>('e/Admin/Campaigns/' + itemId)
  }
}

export default new CampaignApi()
