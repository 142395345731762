import API from './Api'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { ITrainerCallReportModel, ITrainerCallReportDataModel } from '../../models/responses/TrainerCallReportModel'
import { ITrainerCallReportRequestModel } from '../../models/requests/TrainerCallReportRequestModel'

class TrainerCallReportApi {

  async GetTrainerCallReports(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ITrainerCallReportModel[]>>(`s/Admin/TrainerCallReports${generateQueryParams(filter)}`)
  }

  async GetTrainerCallReport(id: string) {
    return await API.get<ITrainerCallReportModel>('s/Admin/TrainerCallReports/' + id)
  }

  async CreateTrainerCallReport(model: ITrainerCallReportRequestModel) {
    return await API.post('s/Admin/TrainerCallReports', model)
  }

}

export default new TrainerCallReportApi()
