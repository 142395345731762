import { FC, useEffect, useState } from 'react'
import ConfigApi from '../../../../infrastructure/api/ConfigApi'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap-v5'
import { useFormik } from 'formik'
import { IConfigModel, configInitValues } from '../../../../models/responses/ConfigModel'
import { IConfigRequestModel } from '../../../../models/requests/ConfigRequestModel'
import { ConfigFormValidations } from '../../../validations/ConfigFormValidations'
import { FormikValidationError } from '../../../components/validations/FormikValidationError'
import Select from 'react-select';
import { SaveChangesButton } from '../../../components/buttons/SaveChangesButton'
import { customSelectStyles } from '../../../helpers/ReactSelectHelper'
import { SelectModel } from '../../../models/SelectModel'
import { EnumToList } from '../../../helpers/EnumHelper'
import { ConfigTypeEnum } from '../../../../enums/ConfigTypeEnum'

type Props = {
  itemId: number
  show: boolean
  handleClose: () => void
}

const ConfigFormModal: FC<Props> = ({ itemId, show, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const [config, setConfig] = useState<IConfigModel>(configInitValues)
  const updateConfig = (fieldsToUpdate: Partial<IConfigModel>) => {
    const updatedData = { ...config, ...fieldsToUpdate }
    setConfig(updatedData)
  }
  const configTypes = EnumToList(ConfigTypeEnum);
  const [selectedConfigType, setSelectedConfigType] = useState<SelectModel>()

  const formik = useFormik<IConfigModel>({
    initialValues: config,
    enableReinitialize: true,
    validationSchema: ConfigFormValidations,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(async () => {
        let model: IConfigRequestModel = {
          id: values.id,
          key: values.key,
          title: values.title,
          value: values.value,
          configType: parseInt(selectedConfigType?.value!!),
        }
        process(model)
      }, 500)
    },
  })

  async function process(model: IConfigRequestModel) {
    try {
      var result = undefined
      if (model.id == undefined || model.id == 0)
        result = await ConfigApi.AddConfig(model)
      else {
        result = await ConfigApi.UpdateConfig(model)
      }
      formik.resetForm()
      handleClose()
    } catch (error) {
      alert('Error: ' + error)
      setLoading(false)
    }
    setLoading(false)
  }

  async function init() {
    let result: IConfigModel
    if (itemId > 0) {
      var response = await ConfigApi.GetConfig(itemId)
      result = response.data
      if (response.data.configType) {
        handleChangeConfigType({
          value: response.data.configType.toString(),
          label: configTypes.find(i => i.value == response.data.configType.toString())?.label
        })
      }
    } else {
      result = configInitValues
    }
    setConfig(result)
  }

  useEffect(() => {
    formik.resetForm()
    if (show) init()
  }, [itemId, show])

  const handleChangeConfigType = (e: any) => {
    setSelectedConfigType(e);
  }

  return (
    <Modal
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-600px h-auto'
      show={show}
      onHide={handleClose}
    >
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{itemId === 0 ? 'Add' : 'Edit'} Config</h1>
              <div className='text-muted fw-bold fs-5'>
                Config Management <small>Educall</small>
              </div>
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Config Type</label>
              <Select
              {...formik.getFieldProps('configType')}
              styles={customSelectStyles(true)}
              onChange={(event) => handleChangeConfigType(event)}
              value={selectedConfigType}
              options={configTypes}
            />
              <FormikValidationError touched={formik.touched.configType} error={formik.errors.configType} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder required'>Key</label>
              <input
                {...formik.getFieldProps('key')}
                type='text'
                name='key'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.key} error={formik.errors.key} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Title</label>
              <input
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.title} error={formik.errors.title} />
            </div>
            <div className='mb-10'>
              <label className='form-label fw-bolder'>Value</label>
              <input
                {...formik.getFieldProps('value')}
                type='text'
                name='value'
                className='form-control form-control-solid'
                placeholder='Entry..'
              />
              <FormikValidationError touched={formik.touched.value} error={formik.errors.value} />
            </div>
          </div>
          <div className='modal-footer'>
            <div className='d-flex flex-center '>
              <SaveChangesButton
                loading={loading}
                setLoading={setLoading}
                complete={complete}
                setComplete={setComplete}
                valid={formik.isValid}
                submit={formik.isSubmitting}
                setSubmit={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export { ConfigFormModal }
