import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { PageViewingReportListModal } from './_modals/PageViewingReportListModal'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'

const PageViewingReportPage: FC = () => {
    const [itemId, setItemId] = useState('')
    const [itemName, setItemName] = useState('')
    const [showDelete, setShowDelete] = useState(false)
    const handleShowDelete = () => setShowDelete(true)


    const [refresh, setRefresh] = useState<number>(0)
    const [showForm, setShowForm] = useState(false)
    const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
    const handleShowForm = () => setShowForm(true)

    const [showView, setShowView] = useState(false)
    const handleCloseView = () => setShowView(false)
    const handleShowView = () => setShowView(true)

    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    const onClickItem = (value: string, name: string, type: string) => {
        setItemId(value)
        setItemName(name)
        switch (type) {
            case 'view':
                handleShowView()
                break
            case 'delete':
                handleShowDelete()
                break
            default:
                handleShowForm()
                break
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                </div>

                <div className='card-body py-3'>
                    <PageViewingReportListModal refresh={refresh} onClickItem={onClickItem} />
                </div>
            </div>
        </>
    )
}

const StudentReportWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>Schedule</PageTitle>
            <PageViewingReportPage />
        </>
    )
}

export { StudentReportWrapper as PageViewingReportWrapper }
