import API from './Api'
import { IGetHubLogsRequestModel } from '../../models/requests/GetHubLogsRequestModel'
import { IHubLogModel } from '../../models/responses/HubLogModel'

class HubLogApi {

    async GetAvtivities(model: IGetHubLogsRequestModel) {
        return await API.post<IHubLogModel[]>(`s/Admin/HubLogs/UserActivity`, model)
    }
}

export default new HubLogApi()
