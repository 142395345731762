export interface ILevelGroupModel {
  id: number
  title: string
}

const levelGroupInitValues: ILevelGroupModel = {
  id: 0,
  title: '',
}

export {levelGroupInitValues}
