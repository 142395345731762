export interface IInvoiceDetailsFormModel {
    invoiceTitle: string,
    invoiceAddress: string,
    invoiceZipcode: string,
    invoiceCountryId: number,
    invoiceCityId: number,
    invoiceStateId: number,
    taxDepartment: string,
    taxNumber: string,
    landPhone: string
}

const invoiceDetailsInitValues: IInvoiceDetailsFormModel = {
    invoiceTitle: "",
    invoiceAddress: "",
    invoiceZipcode: "",
    invoiceCountryId: 0,
    invoiceCityId: 0,
    invoiceStateId: 0,
    taxDepartment: "",
    taxNumber: "",
    landPhone: ""
}

export { invoiceDetailsInitValues }