import { generateQueryParams, IPaginationFilter } from '../../models/requests/PaginationFilter'
import { IPermissionProcessRequestModel } from '../../models/requests/PermissionProcessRequestModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPermissionProcessModel } from '../../models/responses/PermissionProcessModel'
import API from './Api'

class PermissionProcessApi {
  async GetPermissionProcesses(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<IPermissionProcessModel[]>>(`e/Admin/PermissionProcesses${generateQueryParams(filter)}`)
  }

  async AddPermissionProcess(model: IPermissionProcessRequestModel) {
    return await API.post('e/Admin/PermissionProcesses', model)
  }
}

export default new PermissionProcessApi()
