import { FC, useEffect, useState } from 'react'
import VideoApi from '../../../../infrastructure/api/VideoApi'
import { IVideoModel } from '../../../../models/responses/VideoModel'
import { PagedResponseModel } from '../../../../models/responses/PagedResponseModel'
import { IPaginationFilter } from '../../../../models/requests/PaginationFilter'
import { GenericTable } from '../../../components/tables/GenericTable'
import { TableHelperButtons } from '../../../components/buttons/TableHelperButtons'
import { TableHelperButtonEnum } from '../../../enums/TableHelperButtonEnum'
import { VideoTypeEnum } from '../../../../enums/VideoTypeEnum'
import { Link } from 'react-router-dom'

type Props = {
  onClickItem: (itemId: any, itemName: string, type: string) => void
  refresh: number
}

const VideoListModal: FC<Props> = ({ onClickItem, refresh }) => {
  const [videos, setVideos] = useState<PagedResponseModel<IVideoModel[]>>()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState('title_asc')

  useEffect(() => {
    const paginationFilter: IPaginationFilter = {
      page: pageNumber,
      size: pageSize,
      orderBy: sort,
      search: searchText,
    }
    async function getVideos() {
      var response = await VideoApi.GetVideos(paginationFilter)
      setVideos(response.data)
    }
    getVideos()
  }, [refresh, pageNumber, pageSize, searchText, sort])

  return (
    <>
      <GenericTable
        title='Videos'
        data={videos?.items}
        currentTotalRecords={videos?.totalRecords!!}
        currentPageNumber={videos?.pageNumber!!}
        columns={[
          {
            label: '#',
            name: '#',
            options: {
              customBodyRenderLite: (dataIndex) => {
                return <>{(pageNumber - 1) * pageSize + ++dataIndex}</>
              },
              sort: false,
              viewColumns: false
            },
          },
          {
            label: 'Name',
            name: 'title',
            options: {
              customBodyRender: (value) => {
                return <b>{value}</b>
              },
            },
          },
          {
            label: 'Link',
            name: 'link',
            options: {
              customBodyRender: (value) => {
                return (
                  <Link target='_blank' to={{ pathname: value }}>
                    {value}
                  </Link>
                )
              },
            },
          },
          {
            label: 'Type',
            name: 'videoType',
            options: {
              customBodyRender: (value) => {
                return <div className='d-flex align-items-center'>{VideoTypeEnum[value]}</div>
              },
            },
          },
          { label: 'Date', name: 'date' },
          { label: 'Order', name: 'order' },
          {
            label: '#',
            name: 'Id',
            options: {
              customHeadLabelRender: (options) => {
                return (
                  <div className='d-flex justify-content-end flex-shrink-0'>{options.label}</div>
                )
              },
              customBodyRenderLite: (dataIndex) => {
                return (
                  <TableHelperButtons
                    itemId={videos?.items[dataIndex]?.id!!}
                    itemParam={videos?.items[dataIndex]?.title!!}
                    types={[TableHelperButtonEnum.Update, TableHelperButtonEnum.Delete]}
                    onClickItem={onClickItem}
                  />
                )
              },
              filter: false,
              sort: false,
              viewColumns: false,
            },
          },
        ]}
        customOptions={{
          rowsPerPage: pageSize,
          customTableBodyFooterRender: (opts) => {
            return <div></div>
          },
        }}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setSearchText={setSearchText}
        setSort={setSort}
      />
    </>
  )
}

export { VideoListModal }
