import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { AnnouncementViewModal } from './_modals/AnnouncementViewModal'
import { AnnouncementFormModal } from './_modals/AnnouncementFormModal'
import { AnnouncementListModal } from './_modals/AnnouncementListModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import AnnouncementApi from '../../../infrastructure/api/AnnouncementApi'
import { checkPermission, UserModel } from '../../modules/auth/models/UserModel'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import { RootState } from '../../../setup'
import { useSelector, shallowEqual } from 'react-redux'

const AnnouncementPage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)
  const [showView, setShowView] = useState(false)
  const handleCloseView = () => setShowView(false)
  const handleShowView = () => setShowView(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    switch (type) {
      case 'view':
        handleShowView()
        break
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deleteAnnouncement(itemId: string) {
    var response = await AnnouncementApi.DeleteAnnouncement(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'></h3>
          {checkPermission(user, PermissionEnum.AnnouncementCreate) &&
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a Announcement'
            >
              <a
                href='#'
                onClick={() => onClickItem('', '', 'create')}
                className='btn btn-sm btn-light-primary'
              >
                <KTSVG
                  path='/media/icons/duotone/Code/Plus.svg'
                  className='svg-icon-3'
                />
                Add Announcement
              </a>
            </div>
          }
        </div>

        <div className='card-body py-3'>
          <AnnouncementListModal refresh={refresh} onClickItem={onClickItem} />
          <AnnouncementViewModal itemId={itemId} show={showView} handleClose={handleCloseView} />
          {(checkPermission(user, PermissionEnum.AnnouncementCreate) || checkPermission(user, PermissionEnum.AnnouncementUpdate)) &&
            <AnnouncementFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
          }
          {(checkPermission(user, PermissionEnum.AnnouncementDelete)) &&
            <DeleteModal
              itemId={itemId}
              itemName={itemName}
              handleClose={handleCloseDelete}
              show={showDelete}
              type='Announcement'
              confirm={deleteAnnouncement}
            />
          }
        </div>
      </div>
    </>
  )
}

const AnnouncementWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Announcement</PageTitle>
      <AnnouncementPage />
    </>
  )
}

export { AnnouncementWrapper }
