/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect, Route, Switch, Link } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { RoleListModal } from '../roles/_modals/RoleListModal'
import { RoleFormModal } from '../roles/_modals/RoleFormModal'
import { DeleteModal } from '../../components/modals/DeleteModal'
import RoleViewPage from './RoleViewPage'
import RoleApi from '../../../infrastructure/api/RoleApi'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'

const RolePage: FC = () => {
  const [itemId, setItemId] = useState('')
  const [itemName, setItemName] = useState('')
  const [itemType, setItemType] = useState('')

  const [showDelete, setShowDelete] = useState(false)
  const handleShowDelete = () => setShowDelete(true)
  const handleCloseDelete = () => setShowDelete(false)

  const [refresh, setRefresh] = useState<number>(0)
  const [showForm, setShowForm] = useState(false)
  const handleCloseForm = () => { setShowForm(false); setRefresh(refresh + 1) }
  const handleShowForm = () => setShowForm(true)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const onClickItem = (value: string, name: string, type: string) => {
    setItemId(value)
    setItemName(name)
    setItemType(type)
    switch (type) {
      case 'delete':
        handleShowDelete()
        break
      default:
        handleShowForm()
        break
    }
  }

  async function deleteRole(itemId: string) {
    var response = await RoleApi.DeleteRole(itemId)
    setRefresh(refresh + 1)
  }

  return (
    <>
      <RoleFormModal itemId={itemId} show={showForm} handleClose={handleCloseForm} />
      <RoleListModal refresh={refresh} onClickItem={onClickItem} />
      <DeleteModal
        itemId={itemId}
        itemName={itemName}
        handleClose={handleCloseDelete}
        show={showDelete}
        type='Role'
        confirm={deleteRole}
      />
    </>
  )
}

const RoleWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ACCOUNT.ROLES' })}</PageTitle>
      <Switch>
        <Route exact path={["/account/roles", "/account/roles/edit/:id", "/account/roles/new"]}>
          <RolePage />
        </Route>
        <Route exact path={`/account/roles/view/:id`}>
          <Link
            to="/account/roles"
            className='text-start mb-7 btn btn-sm btn-primary'>
            <KTSVG
              path='/media/icons/duotone/Navigation/Arrow-left.svg'
              className='svg-icon-3' />
            BACK
          </Link>
          <RoleViewPage onClickItem={alert} />
        </Route>
      </Switch>
    </>
  )
}

export { RoleWrapper }
