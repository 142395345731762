/* eslint-disable jsx-a11y/anchor-is-valid */
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

export function AuthPage() {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  if (!isAuthorized) { localStorage.setItem("edcRedirectUrl", window.location.href) }
  return (
    <Switch>
      <Route path='/auth/login' component={Login} />
      <Route path='/auth/registration' component={Registration} />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect to='/auth/login' />
    </Switch>
  )
}
