export interface IShopProductModel {
  id: number
  name: string
  image: string
  price: number
  edupoint: number
  stock: number
  category: number
}

const shopProductInitValues: IShopProductModel = {
  id: 0,
  name: '',
  image: '',
  price: 0,
  edupoint: 0,
  stock: 0,
  category: 0,
}

export {shopProductInitValues}
