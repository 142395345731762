import { ITaskRequestModel, ITaskTransactionRequestModel } from '../../models/requests/TaskRequestModel'
import { PagedResponseModel } from '../../models/responses/PagedResponseModel'
import { IPaginationFilter, generateQueryParams } from '../../models/requests/PaginationFilter'
import API from './Api'
import { ITaskDefineModel, ITaskModel } from '../../models/responses/TaskModel'
import { ITaskDefineRequestModel } from '../../models/requests/TaskDefineRequestModel'

class TaskApi {
  async GetTasks(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ITaskModel[]>>(`e/Admin/TaskProcesses${generateQueryParams(filter)}`)
  }

  async GetTask(id: string) {
    return await API.get<ITaskModel>('e/Admin/TaskProcesses/' + id)
  }

  async AddTask(model: ITaskRequestModel) {
    return await API.post('e/Admin/TaskProcesses', model)
  }

  async UpdateTask(model: ITaskRequestModel) {
    return await API.put('e/Admin/TaskProcesses/' + model.id, model)
  }

  async AddTransactionToTask(model: ITaskTransactionRequestModel) {
    return await API.put('e/Admin/TaskProcesses/transaction/' + model.id, model)
  }

  async DeleteTask(itemId: string) {
    return await API.delete<boolean>('e/Admin/TaskProcesses/' + itemId)
  }

  async GetTaskDefines(filter: IPaginationFilter) {
    return await API.get<PagedResponseModel<ITaskDefineModel[]>>(`e/Admin/TaskDefines${generateQueryParams(filter)}`)
  }

  async GetTaskDefine(id: string) {
    return await API.get<ITaskDefineModel>('e/Admin/TaskDefines/' + id)
  }

  async AddTaskDefine(model: ITaskDefineRequestModel) {
    return await API.post('e/Admin/TaskDefines', model)
  }

  async UpdateTaskDefine(model: ITaskDefineRequestModel) {
    return await API.put('e/Admin/TaskDefines/' + model.id, model)
  }

  async DeleteTaskDefine(itemId: string) {
    return await API.delete<boolean>('e/Admin/TaskDefines/' + itemId)
  }
}

export default new TaskApi()
